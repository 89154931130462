
<div class="md-layout">
  <div class="md-layout-item md-size-100">
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-green">
        <div class="card-icon">
          <md-icon>assignment</md-icon>
        </div>
        <h4 class="title">管理ユーザー一覧</h4>
      </md-card-header>
      <md-card-content class="tipsVisible">
        <div class="dataTableWrapper">
          <div class="dataTable"> 
            <div class="tHead">
              <div class="tHead_th">ログイン名</div>
              <div class="tHead_th">氏名</div>
              <div class="tHead_th">店舗名</div>
              <div class="tHead_th">ステータス</div>
              <div class="tHead_th">登録日</div>
              <div class="tHead_th">最終ログイン日</div>
              <div class="tHead_th taR">アクション</div>
            </div>
            <div v-for="item in tableData" :data-id="item.id" class="tItem">
              <div class="tItem_td commentBody"><md-label style="word-break:break-all;">{{ item.login }}</md-label></div>
              <div class="tItem_td commentBody"><md-label style="word-break:break-all;">{{ item.name }}</md-label></div>
              <div class="tItem_td commentBody"><md-label style="word-break:break-all;">{{ item.shopName }}</md-label></div>
              <div class="tItem_td">{{ item.status }}</div>
              <div class="tItem_td">{{ item.contractDay | dateFormat }}</div>
              <div class="tItem_td">{{ item.lastDay | dateFormat }}</div>
              <div class="tItem_td taR td_actionTw">
                <drop-down direction="up" class="dropDown">
                  <md-button slot="title" class="md-just-icon md-warning mg2 dropdown-toggle" data-toggle="dropdown">
                    <md-icon>dvr</md-icon>
                  </md-button>
                  <ul class="dropdown-menu dropdown-menu-right">
                    <li><a @click="editUser(item.id)">編集</a></li>
                    <li><a @click="accessHistory(item.id)">情報・履歴</a></li>
                  </ul>
                </drop-down>
                <md-button
                  class="md-just-icon md-danger mg2"
                  @click.native="handleDelete(item)">
                  <md-icon>close</md-icon>
                </md-button>
              </div>
            </div>
          </div>
        </div>
      </md-card-content>
      <md-card-actions md-alignment="space-between">
        <div class="">
          <p class="card-category">全{{ pageData.totalCount | numberWithDelimiter}}件中 {{from | numberWithDelimiter}}件から {{to | numberWithDelimiter}}件を表示</p>
        </div>
        <Pagination class="Pagination-no-border Pagination-success"
          :currentPage="pageData.currentPage"
          :totalPage="pageData.totalPage"
          @get="getUsers">
        </Pagination>
      </md-card-actions>
    </md-card>
  </div>
</div>

