<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>search</md-icon>
          </div>
          <h4 class="title">会員別売り上げレポート</h4>
        </md-card-header>

        <md-card-content class="md-layout formLayout">
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              集計期間
            </label>
            <div class="md-layout-item md-size-20 md-xsmall-size-100" style="position:relative">
              <vuejs-datepicker
                :format="DatePickerFormat"
                :highlighted="highlighted"
                v-model="selectedStart"
                :language="ja"></vuejs-datepicker>
              <md-button
                class="md-just-icon md-default md-simple dateDelete"
                @click.native="selectedStart = ''">
                <md-icon>close</md-icon>
              </md-button>
            </div>
            <div class="md-layout-item md-size-5 betweenTime is-xs-none"> 〜 </div>
            <div class="md-layout-item md-size-20 md-xsmall-size-100" style="position:relative">
              <vuejs-datepicker
                :format="DatePickerFormat"
                :highlighted="highlighted"
                v-model="selectedEnd"
                :language="ja"></vuejs-datepicker>
              <md-button
                class="md-just-icon md-default md-simple dateDelete"
                @click.native="selectedEnd = ''">
                <md-icon>close</md-icon>
              </md-button>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              性別
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field class="UserStatus">
                <md-select v-model="sex" name="status">
                  <md-option value="選択しない">選択しない</md-option>
                  <md-option value="female">女性</md-option>
                  <md-option value="male">男性</md-option>
                  <md-option value="other">その他</md-option>
                </md-select>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              会員ランク
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <user-rank-select v-model="userRank" :noSelectFlag="true"></user-rank-select>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              注文タイプ
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field class="UserStatus">
                <md-select v-model="orderType" name="status">
                  <md-option value="選択しない">選択しない</md-option>
                  <md-option value="ReserveOrder">事前予約</md-option>
                  <md-option value="PreOrder">事前決済</md-option>
                  <md-option value="ShopOrder">店頭QR決済</md-option>
                </md-select>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              集計タイプ
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field class="UserStatus">
                <md-select v-model="queryType" name="status">
                  <md-option value="選択しない">選択しない</md-option>
                  <md-option value="shop">店舗別</md-option>
                </md-select>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              店舗名
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <shop-select v-model="shopId"></shop-select>
            </div>
          </div>

        </md-card-content>
        <md-card-actions md-alignment="left">
          <md-button class="md-success md-lg btnCenter" @click="validatePeriod">集計</md-button>
        </md-card-actions>
      </md-card>
    </div>
    <transition name="fade">
      <div class="md-layout-item md-size-100" v-if="resultFlag">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-blue">
            <div class="card-icon">
              <md-icon>timeline</md-icon>
            </div>
            <h4 class="title">合計金額集計結果</h4>
          </md-card-header>
          <md-card-content>
            <chartist
              ratio="ct-major-tenth"
              type="Bar"
              :data="graphTotalData.chartData"
              :options="graphTotalData.chartOptions" >
            </chartist>
            <div class="chartLegend">
              <div class="legends orderFemale">女性：事前決済</div>
              <div class="legends reserveFemale">女性：事前予約</div>
              <div class="legends qrFemale">女性：店頭QR決済</div>
              <div class="legends orderMale">男性：事前決済</div>
              <div class="legends reserveMale">男性：事前予約</div>
              <div class="legends qrMale">男性：店頭QR決済</div>
              <div class="legends orderOther">その他：事前決済</div>
              <div class="legends reserveOther">その他：事前予約</div>
              <div class="legends qrOther">その他：店頭QR決済</div>
            </div>
          </md-card-content>
        </md-card>
      </div>
    </transition>
    <transition name="fade">
      <div class="md-layout-item md-size-100" v-if="resultFlag">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-blue">
            <div class="card-icon">
              <md-icon>timeline</md-icon>
            </div>
            <h4 class="title">平均金額集計結果</h4>
          </md-card-header>
          <md-card-content>
            <chartist
              ratio="ct-major-tenth"
              type="Bar"
              :data="graphData.chartData"
              :options="graphData.chartOptions" >
            </chartist>
            <div class="chartLegend">
              <div class="legends orderFemale">女性：事前決済</div>
              <div class="legends reserveFemale">女性：事前予約</div>
              <div class="legends qrFemale">女性：店頭QR決済</div>
              <div class="legends orderMale">男性：事前決済</div>
              <div class="legends reserveMale">男性：事前予約</div>
              <div class="legends qrMale">男性：店頭QR決済</div>
              <div class="legends orderOther">その他：事前決済</div>
              <div class="legends reserveOther">その他：事前予約</div>
              <div class="legends qrOther">その他：店頭QR決済</div>
            </div>
          </md-card-content>
        </md-card>
      </div>
    </transition>
    <transition name="fade">
      <div class="md-layout-item md-size-100" v-if="resultFlag">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>assignment</md-icon>
            </div>
            <h4 class="title">集計結果</h4>
          </md-card-header>
          <md-card-content>
            <div class="dataTableWrapper">
              <div class="dataTable"> 
                <div class="tHead">
                  <div class="tHead_th">会員種別</div>
                  <div class="tHead_th">性別</div>
                  <div class="tHead_th">注文タイプ</div>
                  <div class="tHead_th" v-show="shopDisplayFlag">店舗名</div>
                  <div class="tHead_th taR">注文数</div>
                  <div class="tHead_th taR">平均注文額</div>
                  <div class="tHead_th taR">注文合計金額</div>
                </div>
                <div v-for="item in tableData" :data-id="item.id" class="tItem">
                  <div class="tItem_td" :class="item.colorClass">{{ item.rank }}</div>
                  <div class="tItem_td" :class="item.sexClass">{{ item.sex }}</div>
                  <div class="tItem_td">{{ item.type }}</div>
                  <div class="tItem_td" v-show="shopDisplayFlag">{{ item.shop }}</div>
                  <div class="tItem_td taR">{{ item.orderNum }}</div>
                  <div class="tItem_td taR boldCell">¥{{ item.averagePrice | fewWithDelimiter}}</div>
                  <div class="tItem_td taR boldCell">¥{{ item.totalPrice | numberWithDelimiter}}</div>
                </div>
              </div>
            </div>
          </md-card-content>
          <md-card-actions md-alignment="space-between">
            <div class="">
              <p class="card-category">全{{ pageData.totalCount | numberWithDelimiter }}件中 {{from | numberWithDelimiter}}件から {{to | numberWithDelimiter}}件を表示</p>
            </div>
            <Pagination class="Pagination-no-border Pagination-success"
              :currentPage="pageData.currentPage"
              :totalPage="pageData.totalPage"
              @get="analysisTrigger">
            </Pagination>
          </md-card-actions>
        </md-card>
      </div>
    </transition>
  </div>

</template>

<script>
  import Pagination from '../components/additional/Pagination.vue'
  import ShopSelect from '../components/additional/ShopMultiSelect.vue'
  import ChartCard from '../components/Cards/ChartCard.vue'
  import router from '../entrypoints/router.js'
  import Fuse from 'fuse.js'
  import UserRankSelect from '../components/additional/UserRankSelect.vue'
  import role from "../mixins/role.js"

  export default {
    mixins: [ role ],
    components: {
      Pagination,
      ShopSelect,
      UserRankSelect
    },
    computed: {
      to () {
        return (this.pageData.currentPage - 1) * 25 + this.tableData.length
      },
      from () {
        return (this.pageData.currentPage - 1) * 25 + 1
      }
    },
    data () {
      return {
        resultFlag: false,
        shopId: [],
        selectedStart: '',
        selectedEnd: '',
        orderType: '選択しない',
        sex: '選択しない',
        userRank: '選択しない',
        queryType: '選択しない',
        shopDisplayFlag: true,
        pageData: {
          totalPage: 0,
          currentPage: 0,
          nextPage: null,
          prevPage: null,
          totalCount: 0
        },
        tableData: [],
        shopSelect: [],
        graphData: {
          chartData: {
            labels: [],
            series: [
              [],
              [],
              [],
              [],
              [],
              [],
              [],
              [],
              []
            ]
          },
          chartOptions: {
            lineSmooth: false,
            axisY: {
              offset: 80,
              labelInterpolationFnc: function(value) {
                return '¥' + value
              }
            }
          }
        },
        graphTotalData: {
          chartData: {
            labels: ['a','a','a','a','a','a','a','a','a'],
            series: [
              [],
              [],
              [],
              [],
              [],
              [],
              [],
              [],
              []
            ]
          },
          chartOptions: {
            lineSmooth: false,
            axisY: {
              offset: 80,
              labelInterpolationFnc: function(value) {
                return '¥' + value
              }
            }
          }
        }
      }
    },
    methods: {
      validatePeriod() {
        if (this.selectedStart === '' || this.selectedEnd === '') {
          this.warningMessage(`集計期間を入力してください`)
        } else {
          this.getAnalysis(1)
          this.getChartAnalysis(1)
        }
      },
      analysisTrigger (page) {
        this.getAnalysis(page)
        this.getChartAnalysis(page)
      },
      getAnalysis (page) {
        var condition = {
          page: page,
          shop_id: this.shopId.join(),
          period_from: this.initDate(this.selectedStart),
          period_to: this.initDate(this.selectedEnd),
          order_type: this.orderType === '選択しない' ? '' :  this.orderType,
          user_rank: this.userRank === '選択しない' ? '' :  this.userRank,
          query_type: this.queryType === '選択しない' ? '' :  this.queryType,
          sex: this.sex === '選択しない' ? '' : this.sex
        }
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/sales_analysis_users/',
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
          params: condition
        }).then(response => {
          if (response.data.data.length === 0) {
            this.resultFlag = false
            this.noSearchResults()
          } else {
            if (this.queryType === '選択しない' ) {
              this.shopDisplayFlag = false
            } else {
              this.shopDisplayFlag = true
            }
            this.createList(response.data)
            this.pageData.totalPage = response.data.meta['total-pages']
            this.pageData.currentPage = response.data.meta['current-page']
            this.pageData.totalCount = response.data.meta['total-count']
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('クーポン情報を取得できませんでした')
          }
        })
      },
      getChartAnalysis (page) {
        var condition = {
          page: page,
          shop_id: this.shopId.join(),
          period_from: this.initDate(this.selectedStart),
          period_to: this.initDate(this.selectedEnd),
          order_type: this.orderType === '選択しない' ? '' :  this.orderType,
          user_rank: this.userRank === '選択しない' ? '' :  this.userRank,
          query_type: '',
          sex: this.sex === '選択しない' ? '' : this.sex
        }
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/sales_analysis_users/',
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
          params: condition
        }).then(response => {
          this.createChartList(response.data)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('クーポン情報を取得できませんでした')
          }
        })
      },
      createList (obj) {
        var res = obj.data
        this.tableData.splice(0)
        var currentRank = ''
        var currentCount = 1
        for (var i in res) {
          if (currentRank === '') {
            currentRank = res[i].attributes['rank-name']
          } else if (currentRank !== res[i].attributes['rank-name']) {
            currentRank = res[i].attributes['rank-name']
            currentCount++
            if (currentCount >= 11) {
              currentCount = 1
            }
          }
          var item = {
            rank: res[i].attributes['rank-name'],
            colorClass: 'colorGroup' + currentCount,
            sex: res[i].attributes.sex === 'female' ? '女性' : res[i].attributes.sex === 'male' ? '男性' : 'その他',
            sexClass: res[i].attributes.sex === 'female' ? 'classFemale'
                    : res[i].attributes.sex === 'male' ? 'classMale'
                    : 'classOther',
            type: res[i].attributes['order-type'] === 'ReserveOrder' ? '事前予約' : res[i].attributes['order-type'] === 'PreOrder' ? '事前決済' : '店頭QR決済',
            shop: res[i].attributes['shop-name'],
            orderNum: res[i].attributes['order-count'],
            averagePrice: res[i].attributes['avg-price'],
            totalPrice: res[i].attributes['order-sum-price']
          }
          this.tableData.push(item)
        }
        this.resultFlag = true
      },
      createChartList (obj) {
        var res = obj.data
        this.graphData.chartData.labels.splice(0)
        this.graphTotalData.chartData.labels.splice(0)
        for (var i in this.graphData.chartData.series) {
          this.graphData.chartData.series[i].splice(0)
          this.graphTotalData.chartData.series[i].splice(0)
        }

        var currentRank = ''
        for (var i in res) {
          if (currentRank !== res[i].attributes['rank-name']) {

            currentRank = res[i].attributes['rank-name']
            this.graphData.chartData.labels.push(res[i].attributes['rank-name'])
            this.graphTotalData.chartData.labels.push(res[i].attributes['rank-name'])
          }

          switch (res[i].attributes['sex']) {
            case 'female':
              switch (res[i].attributes['order-type']) {
                case 'ReserveOrder':
                  this.graphData.chartData.series[1].push(res[i].attributes['avg-price'])
                  this.graphTotalData.chartData.series[1].push(res[i].attributes['order-sum-price'])
                  break;
                case 'PreOrder':
                  this.graphData.chartData.series[0].push(res[i].attributes['avg-price'])
                  this.graphTotalData.chartData.series[0].push(res[i].attributes['order-sum-price'])
                  break;
                case 'ShopOrder':
                  this.graphData.chartData.series[2].push(res[i].attributes['avg-price'])
                  this.graphTotalData.chartData.series[2].push(res[i].attributes['order-sum-price'])
                  break;
              }
              break;
            case 'male':
              switch (res[i].attributes['order-type']) {
                case 'ReserveOrder':
                  this.graphData.chartData.series[4].push(res[i].attributes['avg-price'])
                  this.graphTotalData.chartData.series[4].push(res[i].attributes['order-sum-price'])
                  break;
                case 'PreOrder':
                  this.graphData.chartData.series[3].push(res[i].attributes['avg-price'])
                  this.graphTotalData.chartData.series[3].push(res[i].attributes['order-sum-price'])
                  break;
                case 'ShopOrder':
                  this.graphData.chartData.series[5].push(res[i].attributes['avg-price'])
                  this.graphTotalData.chartData.series[5].push(res[i].attributes['order-sum-price'])
                  break;
              }
              break;
            case 'other':
              switch (res[i].attributes['order-type']) {
                case 'ReserveOrder':
                  this.graphData.chartData.series[7].push(res[i].attributes['avg-price'])
                  this.graphTotalData.chartData.series[7].push(res[i].attributes['order-sum-price'])
                  break;
                case 'PreOrder':
                  this.graphData.chartData.series[6].push(res[i].attributes['avg-price'])
                  this.graphTotalData.chartData.series[6].push(res[i].attributes['order-sum-price'])
                  break;
                case 'ShopOrder':
                  this.graphData.chartData.series[8].push(res[i].attributes['avg-price'])
                  this.graphTotalData.chartData.series[8].push(res[i].attributes['order-sum-price'])
                  break;
              }
              break;
          }
        }
      },
      customSort (value) {
        return value.sort((a, b) => {
          const sortBy = this.currentSort
          if (this.currentSortOrder === 'desc') {
            return a[sortBy].localeCompare(b[sortBy])
          }
          return b[sortBy].localeCompare(a[sortBy])
        })
      },
      getShop (page) {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/shops/select_list',
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
        }).then(response => {
          this.createShopSelect(response.data)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('店舗リストを取得できませんでした')
          }
        })
      },
      createShopSelect (obj) {
        var res = obj.data
        this.shopSelect.splice(0)
        for (var i in res) {
          var item = {
            id: res[i].id,
            name: res[i].attributes.name
          }
          this.shopSelect.push(item)
        }
      },
      defaultDate () {
        var a = this.initDate(new Date())
        var array = a.split('-')
        array.pop()
        array.push('01')
        this.selectedStart = array.join('-')
        this.selectedEnd = this.initDate(new Date(array[0], array[1], 0))
      }
    },
    mounted () {
      if (this.shopFlag) {
        router.push({ path: '/admin/login'})
        return false
      }
      this.defaultDate()
      this.getShop()
    },
    watch: {
    }
  }
</script>

<style lang="css" scoped>
.fade-enter-active, .fade-leave-active {
  will-change: opacity;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.fade-enter, .fade-leave-to {
  opacity: 0
}

.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.btnCenter{
  margin-left: auto !important;
  margin-right: auto !important;
}

.priceCell{
  font-weight: bold;
  text-align: right;
}

.formLayout{
  flex-basis: auto;
}

.mg0{
  margin: 0;
}

.btnSpace{
  margin: 0 2px;
}

.betweenTime{
  line-height: 59px;
}

.dateDelete{
  position: absolute;
  top: 0;
  right: 0;
}

.numberInput{
  text-align: right;
  width: 100%;
}

.colorGroup0{
  background: #ffcdd2;
}
.colorGroup1{
  background: #F8BBD0;
}
.colorGroup2{
  background: #E1BEE7;
}
.colorGroup3{
  background: #D1C4E9;
}
.colorGroup4{
  background: #C5CAE9;
}
.colorGroup5{
  background: #BBDEFB;
}
.colorGroup6{
  background: #B3E5FC;
}
.colorGroup7{
  background: #B2EBF2;
}
.colorGroup8{
  background: #B2DFDB;
}
.colorGroup9{
  background: #C8E6C9;
}
.colorGroup10{
  background: #DCEDC8;
}

.classFemale{
  background: #ef9a9a;
}

.classMale{
  background: #9FA8DA;
}

.classOther{
  background: #80CBC4;
}
.chartLegend{
  margin-top: 10px;
  text-align: right;
}

.chartLegend .legends{
  display: inline-block;
  font-size: 12px;
  line-height: 15px;
  color: rgba(0, 0, 0, .4);
  padding-left: 20px;
  position: relative;
  margin-left: 30px;
}

.chartLegend .legends:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 15px;
  height: 15px;
}

.orderFemale:before{
  background: #00bcd4;
}

.reserveFemale:before{
  background: #f44336;
}

.qrFemale:before{
  background: #ff9800;
}

.orderMale:before{
  background: #9c27b0;
}

.reserveMale:before{
  background: #4caf50;
}

.qrMale:before{
  background: #9C9B99;
}

.orderOther:before{
  background: #999999;
}

.reserveOther:before{
  background: #dd4b39;
}

.qrOther:before{
  background: #35465c;
}


@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }

  .is-xs-none{
    display: none;
  }

  .is-xs-block{
    display: block;
  }
}
</style>
../entrypoints/router.js