
<div>
  <draggable @sort="sortList" :options="{handle:'.dragHandle',animation:200}">
    <div class="middleCategory" v-for="item, index in middleCategory" :key="item.id" :data-id='item.id'>
      <div class="md-layout categoryDetail">
        <div class="md-layout-item md-size-70 md-xsmall-size-60 categoryName">
          <div class="dragHandle"><md-icon>reorder</md-icon></div>
          <label style="word-break:break-all;">{{item.name}}</label>
        </div>
        <div class="md-layout-item md-size-30 md-xsmall-size-40 btnArea">
          <drop-down direction="up" class="btnAlign">
            <md-button slot="title" class="md-just-icon md-simple md-warning dropdown-toggle" data-toggle="dropdown">
              <md-icon>edit</md-icon>
            </md-button>
            <ul class="dropdown-menu dropdown-menu-right">
              <li><a @click="handleEdit(item.id)">編集</a></li>
              <li><a @click="handleDelete(item.id)">削除</a></li>
            </ul>
          </drop-down>
          <md-button
            class="md-just-icon md-simple btnAlign"
            @click.native="item.openFlag = !item.openFlag">
            <md-icon v-show="!item.openFlag && item.children.length !== 0">keyboard_arrow_down</md-icon>
            <md-icon v-show="item.openFlag && item.children.length !== 0">keyboard_arrow_up</md-icon>
            <md-icon v-show="item.children.length === 0">add</md-icon>
          </md-button>
        </div>
      </div>
      <slide-y-up-transition>
        <div class="progenyCategory" v-show="item.openFlag">
          <small-category :childCategory.sync="item.children" :parentId.sync="item.id"></small-category>
        </div>
      </slide-y-up-transition>
    </div>
  </draggable>
  <div class="addCategory">
    <md-button class="md-success" @click="handleAdd(parent_id)">新しい中カテゴリを追加</md-button>
  </div>
  <category-editor
    ref="categoryEdit"
    @addCategory="addCategory"
    @editCategory="editCategory"
  ></category-editor>
</div>
