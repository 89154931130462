
<div class="user">
  <div class="photo">
    <i class="material-icons icons" style="color:#ffffff !important">account_circle</i>
  </div>
  <div class="user-info">
    <a class="users">
      <span>{{id}}</span>
      <span class="belongs">{{belongs}}</span>
    </a>
  </div>
</div>
