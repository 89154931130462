
<div class="full-page">
  <notifications></notifications>
  <div class="wrapper wrapper-full-page">
    <div class="page-header header-filter" :class="setPageClass" filter-color="black" :style="setBgImage">
      <div class="container md-offset">
        <zoom-center-transition :duration="pageTransitionDuration" mode="out-in">
          <div class="md-layout text-center">
            <div class="md-layout-item md-size-33 md-medium-size-50 md-small-size-70 md-xsmall-size-100">
              <login-card header-color="green">
                <div slot="description" style="height:36px;">
                新しいパスワードを入力してください
                </div>
                <md-field class="md-form-group" slot="inputs" :class="[
                    {'md-invalid': errors.has('パスワード')},
                    {'md-error': errors.has('パスワード')}]">
                  <md-icon>lock_outline</md-icon>
                  <label>パスワード</label>
                  <md-input v-model="password" name="password" data-vv-name="パスワード" type="password" v-validate="'required|alpha_num|min:6|max:12'"></md-input>
                  <span class="md-error">{{ errors.first('パスワード') }}</span>
                </md-field>
                  <md-button slot="footer" class="md-button md-success md-theme-default" @click="submitPassword" style="height: 36px"> 
                    次へ
                  </md-button>
              </login-card>
            </div>
          </div>
        </zoom-center-transition>
      </div>
      <footer class="footer">
        <div class="container md-offset">
            <div class="copyright text-center">
              &copy; {{ new Date().getFullYear() }} <a href="https://www.samurai-net.co.jp" target="_blank">Samurai, inc</a>&nbsp;All Rights Reserved.
            </div>
        </div>
      </footer>
    </div>
  </div>
</div>
