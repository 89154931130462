
<ul class="Pagination" :class="PaginationClass">
  <li class="page-item prev-page" :class="{'disabled': currentPage === 1, 'no-arrows': noArrows}">
    <a class="page-link" aria-label="Previous" @click="prevPage">
      <i class="fas fa-angle-double-left"></i>
    </a>
  </li>
  <li class="page-item"
      v-for="item in range(minPage, maxPage)"
      :key="item"
      :class="{active: currentPage === item}">
    <a class="page-link" @click="changePage(item)">{{item}}</a>
  </li>
  <li class="page-item page-pre next-page" :class="{ 'disabled': currentPage === totalPages, 'no-arrows': noArrows}">
    <a class="page-link" aria-label="Next" @click="nextPage">
      <i class="fas fa-angle-double-right"></i>
    </a>
  </li>
</ul>
