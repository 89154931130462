
<div class="md-layout">
  <div class="md-layout-item md-size-100">
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-green">
        <div class="card-icon">
          <md-icon>assignment</md-icon>
        </div>
        <h4 class="title">スタッフ基本情報設定</h4>
      </md-card-header>

      <md-card-content class="md-layout formLayout">
        <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100" v-if="superFlag">
          <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
            企業名
          </label>
          <div class="md-layout-item md-xsmall-size-100">
            <md-field :class="[
              {'md-error': errors.has('companyId')},
              {'md-valid': !errors.has('companyId') && touched.companyId}]">
              <md-select
                v-model="companyId"
                data-vv-name="companyId"
                v-validate= "superFlag || switch1 ? modelValidations.companyId : ''"
                required>
                <md-option v-for="item in companyList" :value="item.id">{{item.name}}</md-option>
              </md-select>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('companyId')">【必須】必ず一つ選択してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('companyId')" style="margin-right:20px">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('companyId') && touched.companyId" style="margin-right:20px">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>
        <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100" v-show="this.superFlag">
          <!-- レイアウト調整用 -->
        </div>


        <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
          <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
            店鋪名
          </label>
          <div class="md-layout-item md-xsmall-size-100">
            <shop-select v-model="shopId" :touchedFlag="true"></shop-select>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
          <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
            スタッフコード
          </label>
          <div class="md-layout-item md-xsmall-size-100">
            <md-field :class="[
              {'md-error': errors.has('code')},
              {'md-valid': !errors.has('code') && touched.code}]">
              <md-input
                v-model="code"
                data-vv-name="code"
                type="text"
                v-validate= "modelValidations.code"
                required>
              </md-input>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('code')">【必須】255文字以内で入力してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('code')">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('code') && touched.code">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
          <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
            姓
          </label>
          <div v-if="opt_adminUser" class="md-layout-item md-xsmall-size-100">
            <md-field :class="[
              {'md-error': errors.has('lastName')},
              {'md-valid': !errors.has('lastName') && touched.lastName}]">
              <md-input
                v-model="lastName"
                data-vv-name="lastName"
                type="text"
                v-validate="modelValidations.lastName"
                required>
              </md-input>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('lastName')">【必須】50文字以内で入力してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('lastName')">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('lastName') && touched.lastName">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
          <div v-else class="md-layout-item md-xsmall-size-100">
            <p class="md-layout-item md-xsmall-size-100">{{ plain }}</p>
          </div> 
        </div>

        <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
          <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
            名
          </label>
          <div v-if="opt_adminUser" class="md-layout-item md-xsmall-size-100">
            <md-field :class="[
              {'md-error': errors.has('firstName')},
              {'md-valid': !errors.has('firstName') && touched.firstName}]">
              <md-input
                v-model="firstName"
                data-vv-name="firstName"
                type="text"
                v-validate= "modelValidations.firstName"
                required>
              </md-input>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('firstName')">【必須】50文字以内で入力してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('firstName')">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('firstName') && touched.firstName">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
          <div v-else class="md-layout-item md-xsmall-size-100">
            <p class="md-layout-item md-xsmall-size-100">{{ plain }}</p>
          </div> 
        </div>

        <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
          <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
            姓（カナ）
          </label>
          <div v-if="opt_adminUser" class="md-layout-item md-xsmall-size-100">
            <md-field :class="[
              {'md-error': errors.has('lastNameKana')},
              {'md-valid': !errors.has('lastNameKana') && touched.lastNameKana}]">
              <md-input
                v-model="lastNameKana"
                data-vv-name="lastNameKana"
                type="text"
                v-validate= "modelValidations.lastNameKana"
                required>
              </md-input>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('lastNameKana')">【必須】50文字以内の全角カタカナで入力してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('lastNameKana')">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('lastNameKana') && touched.lastNameKana">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
          <div v-else class="md-layout-item md-xsmall-size-100">
            <p class="md-layout-item md-xsmall-size-100">{{ plain }}</p>
          </div> 
        </div>

        <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
          <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
            名（カナ）
          </label>
          <div v-if="opt_adminUser" class="md-layout-item md-xsmall-size-100">
            <md-field :class="[
              {'md-error': errors.has('firstNameKana')},
              {'md-valid': !errors.has('firstNameKana') && touched.firstNameKana}]">
              <md-input
                v-model="firstNameKana"
                data-vv-name="firstNameKana"
                type="text"
                v-validate= "modelValidations.firstNameKana"
                required>
              </md-input>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('firstNameKana')">【必須】50文字以内の全角カタカナで入力してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('firstNameKana')">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('firstNameKana') && touched.firstNameKana">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
          <div v-else class="md-layout-item md-xsmall-size-100">
            <p class="md-layout-item md-xsmall-size-100">{{ plain }}</p>
          </div> 
        </div>

        <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
          <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
            ステータス
          </label>
          <div class="md-layout-item md-xsmall-size-100">
            <md-field :class="[
              {'md-error': errors.has('status')},
              {'md-valid': !errors.has('status') && touched.status}]">
              <md-select
                v-model="status"
                data-vv-name="status"
                v-validate= "modelValidations.status"
                required>
                <md-option value="active">有効</md-option>
                <md-option value="not_active">無効</md-option>
              </md-select>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('status')">【必須】必ず一つ選択してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('status')" style="margin-right:20px">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('status') && touched.status" style="margin-right:20px">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>

      </md-card-content>
    </md-card>
  </div>
  <div class="md-layout-item md-size-100">
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-green">
        <div class="card-icon">
          <md-icon>query_builder</md-icon>
        </div>
        <h4 class="title">シフト時間設定</h4>
      </md-card-header>

      <working-plan ref="wp"></working-plan>

    </md-card>
  </div>

  <div class="md-layout-item md-size-100">
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-green">
        <div class="card-icon">
          <md-icon>settings</md-icon>
        </div>
        <h4 class="title">オプション設定</h4>
      </md-card-header>

      <md-card-content>
        <div class="md-layout md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            イメージ画像
          </label>
          <div class="md-layout-item  md-size-30 md-xsmall-size-100">
            <div class="file-input">
              <div v-if="!imageStaff">
                <div class="image-container">
                  <img :src="dummyImg" title="">
                </div>
              </div>
              <div class="image-container" v-else>
                <img :src="imageStaff" />
              </div>
              <div class="button-container">
                <md-button class="md-danger md-round" @click="removeImage" v-if="imageStaff" data-id="imageStaff"><i class="fa fa-times"></i>削除</md-button>
                <md-button class="md-success md-round md-fileinput">
                  <template v-if="!imageStaff">画像選択</template>
                  <template v-else>変更</template>
                  <input type="file" @change="onFileChange" data-id="imageStaff">
                </md-button>
              </div>
            </div>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100 md-small-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            性別
          </label>
          <div class="md-layout-item pt5">
            <md-radio v-model="sex" value="male">男性</md-radio>
            <md-radio v-model="sex" value="female">女性</md-radio>
            <md-radio v-model="sex" value="other">その他</md-radio>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100 md-small-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            経験年数
          </label>
          <div class="md-layout-item md-size-10 md-xsmall-size-50">
            <md-field :class="[
              {'md-error': errors.has('career')},
              {'md-valid': !errors.has('career') && touched.career}]">
              <md-input
                v-model="career"
                data-vv-name="career"
                v-validate= "modelValidations.career"
                type="number"
                required>
              </md-input>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('career')">2桁以内の半角数字で入力してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('career')" style="margin-right:10px">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('career') && touched.career" style="margin-right:10px">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100" v-if="opt_adminUser">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            生年月日
          </label>
          <div class="md-layout-item md-size-30 md-xsmall-size-100" style="position:relative">
            <vuejs-datepicker
              :format="DatePickerFormat"
              :highlighted="highlighted"
              v-model="birthDay"
              :language="ja"
              data-vv-name="birthDay"
              v-validate= "modelValidations.birthDay"
              ></vuejs-datepicker>
            <md-button
              class="md-just-icon md-default md-simple dateDelete"
              @click.native="displayDate = ''">
              <md-icon>close</md-icon>
            </md-button>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100" v-if="opt_adminUser">
          <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
            ニックネーム
          </label>
          <div class="md-layout-item md-xsmall-size-100">
            <md-field :class="[
              {'md-error': errors.has('nickName')},
              {'md-valid': !errors.has('nickName') && touched.nickName}]">
              <md-input
                v-model="nickName"
                data-vv-name="nickName"
                type="text"
                v-validate= "modelValidations.nickName"
                required>
              </md-input>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('nickName')">255文字以内で入力してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('nickName')">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('nickName') && touched.login">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            プロフィール
          </label>
          <div class="md-layout-item">
            <md-field>
              <md-textarea id="txtAreaProfile" class="txtArea" v-model="profile" md-counter="140" maxlength="140"></md-textarea>
            </md-field>
            <div class="iconWrapper">
              <div class="iconTrigger" @click="openEmoticon('txtAreaProfile','data-code1')">顔文字を挿入する</div>
              <transition name="fade">
                <div class="iconModal" v-if="modalFlag1" @mouseleave="closeEmoticon">
                  <div class="icons" @click="insertEmoticon" v-for="(item,index) in icons" :key="index" v-html="icons[index]" :data-code1="icons[index]"></div>
                </div>
              </transition>
            </div>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100 pt20">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            特技
          </label>
          <div class="md-layout-item">
            <md-field>
              <md-textarea id="txtAreaSpecialty" class="txtArea" v-model="specialty" md-counter="140" maxlength="140"></md-textarea>
            </md-field>
            <div class="iconWrapper">
              <div class="iconTrigger" @click="openEmoticon('txtAreaSpecialty','data-code2')">顔文字を挿入する</div>
              <transition name="fade">
                <div class="iconModal" v-if="modalFlag2" @mouseleave="closeEmoticon">
                  <div class="icons" @click="insertEmoticon" v-for="(item,index) in icons" :key="index" v-html="icons[index]" :data-code2="icons[index]"></div>
                </div>
              </transition>
            </div>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100 pt20">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            趣味
          </label>
            <div class="md-layout-item">
              <md-field>
                <md-textarea id="txtAreaHobby" class="txtArea" v-model="hobby" md-counter="140" maxlength="140"></md-textarea>
              </md-field>
              <div class="iconWrapper">
                <div class="iconTrigger" @click="openEmoticon('txtAreaHobby','data-code3')">顔文字を挿入する</div>
                <transition name="fade">
                  <div class="iconModal" v-if="modalFlag3" @mouseleave="closeEmoticon">
                    <div class="icons" @click="insertEmoticon" v-for="(item,index) in icons" :key="index" v-html="icons[index]" :data-code3="icons[index]"></div>
                  </div>
                </transition>
              </div>
            </div>
        </div>

      </md-card-content>
      <md-card-actions md-alignment="left" class="mt60 mx-auto">
        <md-button class="md-warning md-lg" @click.native.prevent="returnBack">戻る</md-button>
        <submit-button class="md-success md-lg mL40" :onclick="validate" :buttonWord=this.buttonWord type="submit"></submit-button>
      </md-card-actions>
    </md-card>
    <div class="linkCont is-xs-none" v-show="!newFlag">
      <div class="bottomLink" @click="goCalendar">休日カレンダーを編集する</div>
    </div>
  </div>
</div>
