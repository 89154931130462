
<div class="md-layout">
  <div class="md-layout-item md-size-100">
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-green">
        <div class="card-icon">
          <md-icon>search</md-icon>
        </div>
        <h4 class="title">QA情報検索</h4>
      </md-card-header>

      <md-card-content class="md-layout formLayout">

        <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
          <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
            カテゴリ名
          </label>
          <div class="md-layout-item md-xsmall-size-100">
            <qa-category-select v-model="qaCategoryId" :noSelectFlag="true"></qa-category-select>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-50">
          <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
            ステータス
          </label>
          <div class="md-layout-item md-xsmall-size-100">
            <md-field class="UserStatus">
              <md-select v-model="status" name="status">
                <md-option value="active">有効</md-option>
                <md-option value="not_active">無効</md-option>
                <md-option value="選択しない">選択しない</md-option>
              </md-select>
            </md-field>
          </div>
        </div>

      </md-card-content>
      <md-card-actions class="mx-auto">
        <md-button class="md-success md-lg" @click="getInfo()">検索</md-button>
        <md-button class="md-info md-lg mL40" @click="handleClear">検索条件クリア</md-button>
      </md-card-actions>
    </md-card>
  </div>
  <transition name="fade">
    <div class="md-layout-item md-size-100" v-if="resultFlag">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">QA情報一覧</h4>
        </md-card-header>
        <md-card-content>
          <div class="dataTableWrapper">
            <draggable @sort="updateList" draggable=".tItem" class="dataTable" :options="{handle:'.cursorGrab',animation:200}">
              <div class="tHead">
                <div class="tHead_th">並び替え</div>
                <div class="tHead_th">QAカテゴリ名</div>
                <div class="tHead_th">QA管理名称</div>
                <div class="tHead_th">登録日</div>
                <div class="tHead_th">更新日</div>
                <div class="tHead_th">ステータス</div>
                <div class="tHead_th taR">アクション</div>
              </div>
              <div v-for="item in tableData" :data-id="item.id" class="tItem">
                <div class="tItem_td cursorGrab"><md-icon class="dragHandle">reorder</md-icon></div>
                <div class="tItem_td">{{ item.categoryName }}</div>
                <div class="tItem_td">{{ item.name }}</div>
                <div class="tItem_td">{{ item.createdat | dateFormat}}</div>
                <div class="tItem_td">{{ item.updatedat | dateFormat}}</div>
                <div class="tItem_td">{{ item.status }}</div>
                <div class="tItem_td taR td_actionTh">
                  <md-button
                    class="md-just-icon md-warning mg0"
                    @click.native="jumpDetail(item)">
                    <md-icon>dvr</md-icon>
                  </md-button>
                  <md-button
                    class="md-just-icon md-danger mg0"
                    @click.native="handleDelete(item)">
                    <md-icon>close</md-icon>
                  </md-button>
                </div>
              </div>
            </draggable>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">全{{ pageData.totalCount | numberWithDelimiter }}件中 {{from | numberWithDelimiter}}件から {{to | numberWithDelimiter}}件を表示</p>
          </div>
          <Pagination class="Pagination-no-border Pagination-success"
            :currentPage="pageData.currentPage"
            :totalPage="pageData.totalPage"
            @get="getInfo">
          </Pagination>
        </md-card-actions>
      </md-card>
    </div>
  </transition>
</div>

