
<div class="md-layout">
  <div class="md-layout-item md-size-100">
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-green">
        <div class="card-icon">
          <md-icon>create</md-icon>
        </div>
        <h4 class="title">企業情報</h4>
      </md-card-header>
      <md-card-content class="md-layout formLayout">
        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            企業名
          </label>
          <div class="md-layout-item">
            <md-field :class="[
              {'md-error': errors.has('companyName')},
              {'md-valid': !errors.has('companyName') && touched.companyName}]">
              <md-input
                v-model="companyName"
                data-vv-name="companyName"
                type="text"
                v-validate= "modelValidations.companyName"
                required>
              </md-input>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('companyName')">【必須】255文字以内で入力してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('companyName')">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('companyName') && touched.companyName">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>
        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            企業コード
          </label>
          <div class="md-layout-item">
            <md-field :class="[
              {'md-error': errors.has('companyCode')},
              {'md-valid': !errors.has('companyCode') && touched.companyCode}]">
              <md-input
                v-model="companyCode"
                data-vv-name="companyCode"
                type="text"
                v-validate= "modelValidations.companyCode"
                required>
              </md-input>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('companyCode')">【必須】255文字以内の半角英数で入力してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('companyCode')">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('companyCode') && touched.companyCode">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>
        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            郵便番号
          </label>
          <div class="md-layout-item md-aSize-100">
            <md-field :md-counter="false" :class="[
              {'md-error': errors.has('zipCode1')},
              {'md-valid': !errors.has('zipCode1') && touched.zipCode1}]">
              <md-input
                maxlength="3"
                v-model="zipCode1"
                data-vv-name="zipCode1"
                type="text"
                v-validate= "modelValidations.zipCode1"
                required>
              </md-input>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('zipCode1') || errors.has('zipCode2')">【必須】前3桁・後4桁の半角数字で入力してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('zipCode1')" style="margin-right:10px">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('zipCode1') && touched.zipCode1" style="margin-right:10px">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
          <div class="md-layout-item md-size-5 zipBetween">ー</div>
          <div class="md-layout-item md-aSize-120">
            <md-field :md-counter="false" :class="[
              {'md-error': errors.has('zipCode2')},
              {'md-valid': !errors.has('zipCode2') && touched.zipCode2}]">
              <md-input
                v-model="zipCode2"
                maxlength="4"
                data-vv-name="zipCode2"
                type="text"
                v-validate= "modelValidations.zipCode2"
                required>
              </md-input>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('zipCode2')" style="margin-right:10px">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('zipCode2') && touched.zipCode2" style="margin-right:10px">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            都道府県
          </label>
          <div class="md-layout-item md-size-20">
            <md-field :class="[
              {'md-error': errors.has('prefecture')},
              {'md-valid': !errors.has('prefecture') && touched.prefecture}]">
              <md-select
                v-model="prefecture"
                data-vv-name="prefecture"
                v-validate= "modelValidations.prefecture"
                required>
                <md-option value="北海道">北海道</md-option>
                <md-option value="青森県">青森県</md-option>
                <md-option value="岩手県">岩手県</md-option>
                <md-option value="宮城県">宮城県</md-option>
                <md-option value="秋田県">秋田県</md-option>
                <md-option value="山形県">山形県</md-option>
                <md-option value="福島県">福島県</md-option>
                <md-option value="茨城県">茨城県</md-option>
                <md-option value="栃木県">栃木県</md-option>
                <md-option value="群馬県">群馬県</md-option>
                <md-option value="埼玉県">埼玉県</md-option>
                <md-option value="千葉県">千葉県</md-option>
                <md-option value="東京都">東京都</md-option>
                <md-option value="神奈川県">神奈川県</md-option>
                <md-option value="新潟県">新潟県</md-option>
                <md-option value="富山県">富山県</md-option>
                <md-option value="石川県">石川県</md-option>
                <md-option value="福井県">福井県</md-option>
                <md-option value="山梨県">山梨県</md-option>
                <md-option value="長野県">長野県</md-option>
                <md-option value="岐阜県">岐阜県</md-option>
                <md-option value="静岡県">静岡県</md-option>
                <md-option value="愛知県">愛知県</md-option>
                <md-option value="三重県">三重県</md-option>
                <md-option value="滋賀県">滋賀県</md-option>
                <md-option value="京都府">京都府</md-option>
                <md-option value="大阪府">大阪府</md-option>
                <md-option value="兵庫県">兵庫県</md-option>
                <md-option value="奈良県">奈良県</md-option>
                <md-option value="和歌山県">和歌山県</md-option>
                <md-option value="鳥取県">鳥取県</md-option>
                <md-option value="島根県">島根県</md-option>
                <md-option value="岡山県">岡山県</md-option>
                <md-option value="広島県">広島県</md-option>
                <md-option value="山口県">山口県</md-option>
                <md-option value="徳島県">徳島県</md-option>
                <md-option value="香川県">香川県</md-option>
                <md-option value="愛媛県">愛媛県</md-option>
                <md-option value="高知県">高知県</md-option>
                <md-option value="福岡県">福岡県</md-option>
                <md-option value="佐賀県">佐賀県</md-option>
                <md-option value="長崎県">長崎県</md-option>
                <md-option value="熊本県">熊本県</md-option>
                <md-option value="大分県">大分県</md-option>
                <md-option value="宮崎県">宮崎県</md-option>
                <md-option value="鹿児島県">鹿児島県</md-option>
                <md-option value="沖縄県">沖縄県</md-option>
              </md-select>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('prefecture')">【必須】必ず一つ選択してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('prefecture')" style="margin-right:20px">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('prefecture') && touched.prefecture" style="margin-right:20px">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            市区町村
          </label>
          <div class="md-layout-item">
            <md-field :class="[
              {'md-error': errors.has('city')},
              {'md-valid': !errors.has('city') && touched.city}]">
              <md-input
                v-model="city"
                data-vv-name="city"
                type="text"
                v-validate= "modelValidations.city"
                required>
              </md-input>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('city')">【必須】255文字以内で入力してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('city')">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('city') && touched.city">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            番地
          </label>
          <div class="md-layout-item">
            <md-field :class="[
              {'md-error': errors.has('street1')},
              {'md-valid': !errors.has('street1') && touched.street1}]">
              <md-input
                v-model="street1"
                data-vv-name="street1"
                type="text"
                v-validate= "modelValidations.street1"
                required>
              </md-input>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('street1')">【必須】255文字以内で入力してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('street1')">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('street1') && touched.street1">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            建物名
          </label>
          <div class="md-layout-item">
            <md-field :class="[
              {'md-error': errors.has('street2')},
              {'md-valid': !errors.has('street2') && touched.street2}]">
              <md-input
                v-model="street2"
                data-vv-name="street2"
                type="text"
                v-validate= "modelValidations.street2">
              </md-input>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('street2')">255文字以内で入力してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('street2')">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('street2') && touched.street2">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            電話番号
          </label>
          <div class="md-layout-item md-size-10 md-xsmall-size-30">
            <md-field :class="[
              {'md-error': errors.has('tel1')},
              {'md-valid': !errors.has('tel1') && touched.tel1}]">
              <md-input
                v-model="tel1"
                data-vv-name="tel1"
                type="number"
                v-validate= "modelValidations.tel1"
                required>
              </md-input>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('tel1') || errors.has('tel2') || errors.has('tel3')">【必須】それぞれ４桁以内の半角数字で入力してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('tel1')" style="margin-right:10px">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('tel1') && touched.tel1" style="margin-right:10px">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
          <div class="md-layout-item md-size-5 zipBetween">ー</div>
          <div class="md-layout-item md-size-10 md-xsmall-size-30">
            <md-field :class="[
              {'md-error': errors.has('tel2')},
              {'md-valid': !errors.has('tel2') && touched.tel2}]">
              <md-input
                v-model="tel2"
                data-vv-name="tel2"
                type="number"
                v-validate= "modelValidations.tel2"
                required>
              </md-input>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('tel2')" style="margin-right:10px">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('tel2') && touched.tel2" style="margin-right:10px">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
          <div class="md-layout-item md-size-5 zipBetween">ー</div>
          <div class="md-layout-item md-size-10 md-xsmall-size-30">
            <md-field :class="[
              {'md-error': errors.has('tel3')},
              {'md-valid': !errors.has('tel3') && touched.tel3}]">
              <md-input
                v-model="tel3"
                data-vv-name="tel3"
                type="number"
                v-validate= "modelValidations.tel3"
                required>
              </md-input>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('tel3')" style="margin-right:10px">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('tel3') && touched.tel3" style="margin-right:10px">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            契約期間
          </label>
          <div class="md-layout-item md-size-30 md-xsmall-size-100" style="position:relative">
            <div>
              <vuejs-datepicker
                :format="DatePickerFormat"
                :highlighted="highlighted"
                v-model="selectedStart"
                :language="ja"
                data-vv-name="selectedStart"
                v-validate= "modelValidations.selectedStart"
                ></vuejs-datepicker>
              <md-button
                class="md-just-icon md-default md-simple dateDelete"
                @click.native="selectedStart = ''">
                <md-icon>close</md-icon>
              </md-button>
              <slide-y-up-transition>
                <div class="vErrorText" style="margin-left: 15px" v-show="errors.has('selectedStart')">【必須】日付を選択してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error dateError" v-show="errors.has('selectedStart')">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success dateSuccess" v-show="!errors.has('selectedStart') && touched.selectedStart">done</md-icon>
              </slide-y-down-transition>
            </div>
          </div>
          <div class="md-layout-item md-size-5 betweenTime is-xs-none"> 〜 </div>
          <div class="md-layout-item md-size-30 md-xsmall-size-100" style="position:relative">
            <vuejs-datepicker
              :format="DatePickerFormat"
              :highlighted="highlighted"
              v-model="selectedEnd"
              :language="ja"
              data-vv-name="selectedEnd"
              v-validate= "modelValidations.selectedEnd"
              ></vuejs-datepicker>
            <md-button
              class="md-just-icon md-default md-simple dateDelete"
              @click.native="selectedEnd = ''">
              <md-icon>close</md-icon>
            </md-button>
            <slide-y-up-transition>
              <div class="vErrorText" style="margin-left: 15px" v-show="errors.has('selectedEnd')">【必須】日付を選択してください</div>
            </slide-y-up-transition>
            <slide-y-down-transition>
              <md-icon class="error dateError" v-show="errors.has('selectedEnd')">close</md-icon>
            </slide-y-down-transition>
            <slide-y-down-transition>
              <md-icon class="success dateSuccess" v-show="!errors.has('selectedEnd') && touched.selectedEnd">done</md-icon>
            </slide-y-down-transition>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            ステータス
          </label>
          <div class="md-layout-item md-size-20 md-xsmall-size-100">
            <md-field :class="[
              {'md-error': errors.has('status')},
              {'md-valid': !errors.has('status') && touched.status}]">
              <md-select
                v-model="status"
                data-vv-name="status"
                v-validate= "modelValidations.status"
                required>
                <md-option value="active">有効</md-option>
                <md-option value="not_active">無効</md-option>
              </md-select>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('status')">【必須】255文字以内の必ず一つ選択してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('status')" style="margin-right:20px">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('status') && touched.status" style="margin-right:20px">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            ソニーペイメントコード
          </label>
          <div class="md-layout-item">
            <md-field :class="[
              {'md-error': errors.has('spCode')},
              {'md-valid': !errors.has('spCode') && touched.spCode}]">
              <md-input
                v-model="spCode"
                data-vv-name="spCode"
                type="text"
                v-validate= "modelValidations.spCode">
              </md-input>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('spCode')">255文字以内の半角英数で入力してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('spCode')">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('spCode') && touched.spCode">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            ソニーペイメントパスワード
          </label>
          <div class="md-layout-item">
            <md-field :class="[
              {'md-error': errors.has('spPassword')},
              {'md-valid': !errors.has('spPassword') && touched.spPassword}]">
              <md-input
                v-model="spPassword"
                data-vv-name="spPassword"
                type="text"
                v-validate= "modelValidations.spPassword">
              </md-input>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('spPassword')">指定された形式で入力してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('spPassword')">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('spPassword') && touched.spPassword">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            ソニーペイメントトークン
          </label>
          <div class="md-layout-item">
            <md-field :class="[
              {'md-error': errors.has('spToken')},
              {'md-valid': !errors.has('spToken') && touched.spToken}]">
              <md-input
                v-model="spToken"
                data-vv-name="spToken"
                type="text"
                v-validate= "modelValidations.spToken">
              </md-input>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('spToken')">255文字以内の半角英数で入力してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('spToken')">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('spToken') && touched.spToken">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            e-SCOTT パスワード
          </label>
          <div class="md-layout-item">
            <md-field :class="[
              {'md-error': errors.has('escottPassword')},
              {'md-valid': !errors.has('escottPassword') && touched.escottPassword}]">
              <md-input
                v-model="escottPassword"
                data-vv-name="escottPassword"
                type="text"
                v-validate= "modelValidations.escottPassword">
              </md-input>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('escottPassword')">6文字以上12文字以内の半角英数で入力してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('escottPassword')">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('escottPassword') && touched.escottPassword">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100 md-small-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            売上送信タイミング
          </label>
          <div class="md-layout-item pt5">
            <md-radio v-model="captureKind" value="shipped">出荷時</md-radio>
            <md-radio v-model="captureKind" value="ordered">注文時</md-radio>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100 md-small-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            店頭支払い
          </label>
          <div class="md-layout-item pt5">
            <md-radio v-model="shopPaymentStatus" value="payable">可能</md-radio>
            <md-radio v-model="shopPaymentStatus" value="not_payable">不可</md-radio>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            利用サービス
          </label>
          <!-- <div class="md-layout-item md-inline-checkboxes"> -->
          <div class="md-layout-item pt5">
            <md-checkbox v-model="opt_preOrder">事前注文</md-checkbox>
            <md-checkbox v-model="opt_qrPayment">QR決済</md-checkbox>
            <md-checkbox v-model="opt_reserveOrder">事前予約</md-checkbox>
            <md-checkbox v-model="opt_houseMoney">ハウスマネー</md-checkbox>
          <!-- </div>
          <div class="md-layout-item md-inline-checkboxes"> -->
            <md-checkbox v-model="opt_stamp">スタンプサービス</md-checkbox>
            <md-checkbox v-model="opt_point">ポイントサービス</md-checkbox>
            <md-checkbox v-model="opt_coupon">クーポンサービス</md-checkbox>
            <md-checkbox v-model="opt_pushNotice">Push通知</md-checkbox>
            <md-checkbox v-model="opt_mailMagazine">メルマガ</md-checkbox>
          <!-- </div>
          <div class="md-layout-item md-inline-checkboxes"> -->
            <md-checkbox v-model="opt_ecOrder">通販サービス</md-checkbox>
            <md-checkbox v-model="opt_api">API</md-checkbox>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100 md-small-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            税形式
          </label>
          <div class="md-layout-item pt5">
            <md-radio v-model="taxType" value="include_tax">内税</md-radio>
            <md-radio v-model="taxType" value="exclude_tax">外税</md-radio>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100 md-small-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            課税規則
          </label>
          <div class="md-layout-item pt5">
            <md-radio v-model="taxRule" value="truncate">切捨て</md-radio>
            <md-radio v-model="taxRule" value="ceil">切上げ</md-radio>
            <md-radio v-model="taxRule" value="round">四捨五入</md-radio>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            アクセス許可IPアドレス
          </label>
          <div class="md-layout-item md-size-85">
            <div v-for="(item,index) in ipAddressList" :key="index">
              <md-field :class="[
                {'md-error': errors.has('ipAddress' + index)},
                {'md-valid': !errors.has('ipAddress' + index)}]"
                v-if="item._destroy === false">
                <md-input
                  v-model="item.ip_address"
                  :name="'ipAddress' + index"
                  type="text"
                  v-validate="modelValidations.ipAddress"
                  required>
                </md-input>
                <md-button
                  v-if="index !== 0"
                  class="md-just-icon md-simple ipAddressDelete"
                  @click="removeIpAddress(index)">
                <md-icon>close</md-icon>
                </md-button>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('ipAddress' + index)">【必須】正しいIPアドレスの形式で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="ipAddressError" v-show="errors.has('ipAddress' + index)">close</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
            <div class="BtnAdd" @click="addIpAddress"><md-icon>add</md-icon><span>IPアドレスを追加する</span></div>
          </div>
        </div>

      </md-card-content>
      <md-card-actions md-alignment="left" class="mt60 mx-auto">
        <md-button class="md-warning md-lg" @click.native.prevent="returnBack">戻る</md-button>
        <submit-button class="md-success md-lg mL40" :onclick="validate" :buttonWord=this.buttonWord type="submit"></submit-button>
      </md-card-actions>
    </md-card>
    <div class="linkCont" v-show="!newFlag">
      <div class="bottomLink" @click="goService">サービス情報を編集する</div>
      <div class="bottomLink" @click="goDelivery">配信情報を編集する</div>
      <div class="bottomLink" @click="goApp">アプリ用画像情報を編集する</div>
    </div>
  </div>
</div>

