<template>
  <div>
    <transition name="fade">
      <div class="sliderBg" v-show='editorBgFlag' @click="closeModal"></div>
    </transition>
    <transition name="fade">
      <div id="sliderContainer" class="sliderContainer md-size-60 md-xsmall-size-100" style="height:85%;overflow:scroll;" v-show="editorFlag">
        <div class="btnClose" @click="closeModal"><md-icon>close</md-icon></div>
        <div class="md-layout">
          <label class="md-layout-item md-size-20 md-form-label">
            カテゴリコード
          </label>
          <div class="md-layout-item md-size-80">
            <md-field :class="[
              {'md-error': errors.has('categoryCode')},
              {'md-valid': !errors.has('categoryCode') && touched.categoryCode}]">
              <md-input
                v-model="categoryCode"
                data-vv-name="categoryCode"
                type="text"
                v-validate= "modelValidations.categoryCode">
              </md-input>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('categoryCode')">【必須】255文字以内の半角英数字で入力してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('categoryCode')">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('categoryCode') && touched.categoryCode">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>
        <div class="md-layout">
          <label class="md-layout-item md-size-20 md-form-label">
            カテゴリ名
          </label>
          <div class="md-layout-item md-size-80">
            <md-field :class="[
              {'md-error': errors.has('categoryName')},
              {'md-valid': !errors.has('categoryName') && touched.categoryName}]">
              <md-input
                v-model="categoryName"
                data-vv-name="categoryName"
                type="text"
                v-validate= "modelValidations.categoryName"
                required>
              </md-input>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('categoryName')">【必須】255文字以内で入力してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('categoryName')">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('categoryName') && touched.categoryName">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>
        <div class="md-layout">
          <label class="md-layout-item md-size-20 md-form-label">
            カテゴリ画像
          </label>
          <div class="md-layout-item md-size-80 pt20">
            <div class="imgContainer">
              <div class="file-input">
                <div v-if="!imageMain">
                  <div class="image-container">
                    <img :src="dummyImg" title="">
                  </div>
                </div>
                <div class="image-container" v-else>
                  <img :src="imageMain" />
                </div>
                <div class="button-container">
                  <md-button class="md-danger md-round" @click="removeMainImage" v-if="imageMain" data-id="imageMain"><i class="fa fa-times"></i>削除</md-button>
                  <md-button class="md-success md-round md-fileinput">
                    <template v-if="!imageMain">画像選択</template>
                    <template v-else>変更</template>
                    <input type="file" @input="onFileMainChange" data-id="imageMain">
                  </md-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="md-layout">
          <label class="md-layout-item md-size-20 md-form-label">
            カテゴリ内容
          </label>
          <div class="md-layout-item md-size-80">
            <md-field>
              <md-textarea v-model="description" md-counter="140" maxlength="140"></md-textarea>
            </md-field>
          </div>
        </div>
        <div class="md-layout">
          <label class="md-layout-item md-size-20 md-form-label">
            ステータス
          </label>
          <div class="md-layout-item md-size-20 md-xsmall-size-40">
            <md-field>
              <md-select v-model="categoryStatus">
                <md-option value="active">有効</md-option>
                <md-option value="not_active">無効</md-option>
              </md-select>
            </md-field>
          </div>
        </div>
        <div class="btnModal">
          <md-button class="md-success md-sm" @click="validate">{{buttonWord}}</md-button>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
  import Fuse from 'fuse.js'
  import swal from 'sweetalert2'
  export default {
    components: {
    },
    data() {
      return {
        editorBgFlag: false,
        editorFlag: false,
        addFlag: true,
        buttonWord : '作成',
        currentId: '',
        categoryCode: '',
        categoryName: '',
        imageMain: '',
        description: '',
        categoryStatus: 'active',
        touched: {
          categoryCode: false,
          categoryName: false
        },
        modelValidations: {
          categoryCode: {
            required: true,
            alpha_num: true,
            max: 255
          },
          categoryName: {
            required: true,
            max: 255
          }
        }
      }
    },
    props: {
      dummyImg: {
        type: String,
        default: '/images/image_placeholder.jpg'
      }
    },
    computed: {
    },
    methods: {
      validate() {
        this.$validator.validateAll().then(isValid => {
          if (isValid) {
            this.updateCategory()
          } else {
            this.validateError()
          }
        })
      },
      onFileMainChange(e) {
        let files = e.target.files || e.dataTransfer.files;
        let ids = e.currentTarget.getAttribute('data-id');
        if (!files.length)
        return;
        this.createMainImage(files[0],ids);
      },
      createMainImage(file,fileId) {
        let reader = new FileReader();
        let $this = this;

        reader.onload = (e) => {
          $this[fileId] = e.target.result;
        };
        reader.readAsDataURL(file);
      },
      removeMainImage: function (e) {
        let ids = e.currentTarget.getAttribute('data-id');
        this[ids]= '';
      },
      handleAdd (parentId) {
        this.currentId = parentId
        this.editorBgFlag = true
        this.editorFlag = true
        this.addFlag = true
      },
      handleEdit (id) {
        this.addFlag = false
        this.currentId = id
        this.getCategoryDetail(id)
      },
      getCategoryDetail (id) {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/product_categories/' + id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.categoryCode = response.data.code
          this.categoryName = response.data.name
          this.imageMain = response.data.image_url
          this.description = response.data.description
          this.categoryStatus = response.data.status
          this.buttonWord = '更新'
          this.editorBgFlag = true
          this.editorFlag = true
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('カテゴリ情報が取得できませんでした')
          }
        })
      },
      updateCategory () {
        var condition = {
          product_category: {
            code: this.categoryCode,
            name: this.categoryName,
            description: this.description,
            status: this.categoryStatus,

          }
        }

        if (this.addFlag === true) {
          condition.product_category.parent_id = this.currentId
        }

        if(this.imageMain === '' || this.imageMain === null){
          condition.product_category.image = ''
        } else if (this.imageMain.indexOf('/uploads/') == -1) {
          condition.product_category.image = this.imageMain
        }

        this.axios({
          method  : this.addFlag === true ? 'POST' : 'PUT',
          url     : this.addFlag === true ? this.AdminApiUrl + '/product_categories/' : this.AdminApiUrl + '/product_categories/' + this.currentId,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
          data: condition
        }).then(response => {
          var $this = this
          swal.fire({
            title: $this.addFlag ? 'カテゴリ情報を登録しました' : 'カテゴリ情報を更新しました',
            buttonsStyling: false,
            icon: 'success',
            customClass: {
              confirmButton: "md-button md-success",
            },
          }).then(function () {
            $this.editorBgFlag = false
            $this.editorFlag = false
            $this.resetData()
            if ($this.addFlag) {
              $this.$emit('addCategory',{
                id: response.data.id,
                name: response.data.name
              })
            } else {
              $this.$emit('editCategory',{
                id: response.data.id,
                name: response.data.name
              })
            }
          })
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('カテゴリ情報の登録に失敗しました')
          }
        })
      },
      handleDelete (id) {
        swal.fire({
          title: 'このカテゴリを削除してもよろしいですか?',
          text: `削除してしまうともとに戻すことは出来ません！子カテゴリを持っている場合は、まとめて削除されます`,
          icon: 'warning',
          showCancelButton: true,
          customClass: {
            confirmButton: "md-button md-success",
            cancelButton: "md-button md-danger"
          },
          confirmButtonText: 'OK',
          buttonsStyling: false
        }).then((result) => {
          if(result.value){
            this.deleteCategory(id)
          }
        })
      },
      deleteCategory (id) {
        this.axios({
          method  : 'DELETE',
          url     : this.AdminApiUrl + '/product_categories/' + id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.successAndBackPage(`カテゴリ情報を削除しました`)
          this.editorBgFlag = false
          this.editorFlag = false
          this.resetData()
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('カテゴリ情報の削除に失敗しました')
          }
        })
      },
      closeModal () {
        this.editorBgFlag = false
        this.editorFlag = false
        this.resetData()
      },
      resetData () {
        this.categoryCode = ''
        this.categoryName = ''
        this.imageMain = ''
        this.description = ''
        this.categoryStatus = 'active'
        this.buttonWord = '作成'
        this.$validator.reset()
      },
    },
    watch: {
      categoryCode() {
        this.touched.categoryCode = true
      },
      categoryName() {
        this.touched.categoryName = true
      }
    }
  }
</script>
<style scoped>

.fade-enter-active, .fade-leave-active {
  will-change: opacity;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.fade-enter, .fade-leave-to {
  opacity: 0
}

.pt20{
  padding-top: 20px;
}

.btnModal{
  text-align: center;
  margin-top: 20px;
}

.btnClose{
  position: absolute;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.sliderBg{
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,.4);
}

.sliderContainer{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 700px;
  box-sizing: border-box;
  padding: 20px;
  border-radius: 8px;
  z-index: 5;
  background: #ffffff;
  /* box-shadow: 0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2); */
  /* border:1px solid #cccccc; */
}

.imgContainer{
  width: 200px;
}

@media screen and (max-width:600px) {
  .btnArea{
    padding-left: 5px;
    padding-right: 5px;
  }

  .btnArea button{
    width: 35px;
    height: 40px;
    margin: 0;
    padding: 0;
  }

  .sliderContainer{
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transform: translate(0,0);
    overflow-y: scroll;
  }
}
</style>
