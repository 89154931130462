<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>create</md-icon>
          </div>
          <h4 class="title">お問合せカテゴリー情報</h4>
        </md-card-header>
        <md-card-content class="md-layout formLayout">
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              お問合せカテゴリ名
            </label>
            <div class="md-layout-item">
              <md-field :class="[
                {'md-error': errors.has('name')},
                {'md-valid': !errors.has('name') && touched.name}]">
                <md-input
                  v-model="name"
                  data-vv-name="name"
                  type="text"
                  v-validate= "modelValidations.name"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('name')">【必須】お問合せカテゴリ名を入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('name')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('name') && touched.name">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              ステータス
            </label>
            <div class="md-layout-item md-size-30 md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('status')},
                {'md-valid': !errors.has('status') && touched.status}]">
                <md-select
                  v-model="status"
                  data-vv-name="status"
                  v-validate= "modelValidations.status"
                  required>
                  <md-option value="active">有効</md-option>
                  <md-option value="not_active">無効</md-option>
                </md-select>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('status')">【必須】必ず一つ選択してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('status')" style="margin-right:20px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('status') && touched.status" style="margin-right:20px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="left" class="mt60 mx-auto">
          <md-button class="md-success md-lg mL40" @click="validate">登録</md-button>
        </md-card-actions>
      </md-card>

      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">お問合せカテゴリ一覧</h4>
        </md-card-header>
        <md-card-content>
          <div class="dataTableWrapper">
            <div class="dataTable"> 
              <div class="tHead">
                <div class="tHead_th">カテゴリ名</div>
                <div class="tHead_th">登録日</div>
                <div class="tHead_th">更新日</div>
                <div class="tHead_th">ステータス</div>
                <div class="tHead_th taR">アクション</div>
              </div>
              <div v-for="item in tableData" :data-id="item.id" class="tItem">
                <div class="tItem_td">{{ item.name }}</div>
                <div class="tItem_td">{{ item.createdat | dateFormat}}</div>
                <div class="tItem_td">{{ item.updatedat | dateFormat}}</div>
                <div class="tItem_td">{{ item.status }}</div>
                <div class="tItem_td taR td_actionTh">
                  <md-button
                    class="md-just-icon md-warning mg0"
                    @click.native="jumpDetail(item)">
                    <md-icon>dvr</md-icon>
                  </md-button>
                  <md-button
                    class="md-just-icon md-danger mg0"
                    @click.native="handleDelete(item)">
                    <md-icon>close</md-icon>
                  </md-button>
                </div>
              </div>
            </div>
          </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
  import router from '../entrypoints/router.js'
  import swal from 'sweetalert2'
  import role from "../mixins/role.js"

  export default {
    mixins: [ role ],
    components: {
    },
    data () {
      return {
        tableData: [],
        name: '',
        status: '',
        touched: {
          name: false,
          status: false
        },
        modelValidations: {
          name: {
            required: true,
            max: 255
          },
          status: {
            required: true
          }
        }
      }
    },
    methods: {
      validate() {
        this.$validator.validateAll().then(isValid => {
          if (isValid) {
            this.updateContactCategory()
          } else {
            this.validateError()
          }
        })
      },
      getContactCategories () {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/contact_categories',
          timeout : 30000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
        }).then(response => {
            this.createList(response.data)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('お問合せカテゴリー情報を正しく取得できませんでした')
          }
        })
      },
      createList (obj) {
        var res = obj.data
        this.tableData.splice(0)
        for (var i in res) {
          var item = {
            id: res[i].id,
            name: res[i].attributes.name,
            status: res[i].attributes.status === 'active' ? '有効' : '無効',
            createdat: res[i].attributes['created-at'],
            updatedat: res[i].attributes['updated-at']
          }
          this.tableData.push(item)
        }
      },
      handleDelete (item) {
        swal.fire({
          title: 'このお問合せカテゴリー情報を削除してもよろしいですか?',
          text: `削除してしまうともとに戻すことは出来ません！`,
          icon: 'warning',
          showCancelButton: true,
          customClass: {
            confirmButton: "md-button md-success",
            cancelButton: "md-button md-danger"
          },
          confirmButtonText: 'OK',
          buttonsStyling: false
        }).then((result) => {
          if(result.value){
            this.deleteContactCategory(item)
          }
        });
      },
      deleteRow(item){
        let indexToDelete = this.tableData.findIndex((tableRow) => tableRow.id === item.id);
        if (indexToDelete >= 0) {
          this.tableData.splice(indexToDelete, 1)
        }
      },
      deleteContactCategory (item) {
        this.axios({
          method  : 'DELETE',
          url     : this.AdminApiUrl + '/contact_categories/' + item.id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          sessionStorage.removeItem('contactCategorySelect')
          this.deleteRow(item)
          this.successAndBackPage(`お問合せカテゴリー情報を削除しました`)
          this.getContactCategories()
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('お問合せカテゴリー情報を正しく削除できませんでした')
          }
        })
      },
      jumpDetail (item) {
        router.push({ path: '/admin/contact_category/edit/' + item.id })
      },
      updateContactCategory () {
        var condition = {
          contact_category: {
            'name': this.name,
            'status': this.status
          }
        }
        this.axios({
          method  : 'POST',
          url     : this.AdminApiUrl + '/contact_categories/',
          timeout : 10000,
          data: condition,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          swal.fire({
            title: `お問合せカテゴリー情報を登録しました`,
            buttonsStyling: false,
            icon: 'success',
            customClass: {
              confirmButton: "md-button md-success",
            },
          }).then(response => {
              sessionStorage.removeItem('contactCategorySelect')
              this.resetData()
              this.getContactCategories()
              router.push({ path: '/admin/contact_category_list/' })
          })
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('お問合せカテゴリー情報を登録できませんでした')
          }
        })
      },
      resetData () {
        this.name = ''
        this.status = ''
        this.$validator.reset()
        setTimeout (times =>{
          for (var key in this.touched) {
            this.touched[key] = false
          }
        },100)
      }
    },
    mounted () {
      if (!this.superFlag) {
        router.push({ path: '/admin/login'})
        return false
      }
      this.getContactCategories()
    },
    watch: {
      name(){
        this.touched.name = true
      },
      status(){
        this.touched.status = true
      }
    }
  }
</script>

<style lang="css" scoped>
.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.btnCenter{
  margin-left: auto !important;
  margin-right: auto !important;
}

.priceCell{
  font-weight: bold;
  text-align: right;
}

.formLayout{
  flex-basis: auto;
}

.mg0{
  margin: 0;
}

.pt20{
  padding-top: 20px;
}

.btnSpace{
  margin: 0 2px;
}

.unitLabel{
  padding: 10px 0 0 0;
  line-height: 46px;
}

.txtArea{
  padding: 10px 0px 0px !important;
}

.productStatus{
  max-width: 120px;
  width: 100%;
  display: inline-block;
}

.errorMsg{
  bottom: -15px !important;
}

.dragHandle{
  margin-left: 10px;
}

.cursorGrab{
  cursor: grab;
}

@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }

  .is-xs-none{
    display: none;
  }

  .is-xs-block{
    display: block;
  }
}
</style>
../entrypoints/router.js