
<div>
  <transition name="fade">
    <div class="sliderBg" v-show='editorBgFlag' @click="closeModal"></div>
  </transition>
  <transition name="fade">
    <div id="sliderContainer" class="sliderContainer md-size-60 md-xsmall-size-100" style="height:85%;overflow:scroll;" v-show="editorFlag">
      <div class="btnClose" @click="closeModal"><md-icon>close</md-icon></div>
      <div class="md-layout">
        <label class="md-layout-item md-size-20 md-form-label">
          カテゴリコード
        </label>
        <div class="md-layout-item md-size-80">
          <md-field :class="[
            {'md-error': errors.has('categoryCode')},
            {'md-valid': !errors.has('categoryCode') && touched.categoryCode}]">
            <md-input
              v-model="categoryCode"
              data-vv-name="categoryCode"
              type="text"
              v-validate= "modelValidations.categoryCode">
            </md-input>
            <slide-y-up-transition>
              <div class="vErrorText" v-show="errors.has('categoryCode')">【必須】255文字以内の半角英数字で入力してください</div>
            </slide-y-up-transition>
            <slide-y-down-transition>
              <md-icon class="error" v-show="errors.has('categoryCode')">close</md-icon>
            </slide-y-down-transition>
            <slide-y-down-transition>
              <md-icon class="success" v-show="!errors.has('categoryCode') && touched.categoryCode">done</md-icon>
            </slide-y-down-transition>
          </md-field>
        </div>
      </div>
      <div class="md-layout">
        <label class="md-layout-item md-size-20 md-form-label">
          カテゴリ名
        </label>
        <div class="md-layout-item md-size-80">
          <md-field :class="[
            {'md-error': errors.has('categoryName')},
            {'md-valid': !errors.has('categoryName') && touched.categoryName}]">
            <md-input
              v-model="categoryName"
              data-vv-name="categoryName"
              type="text"
              v-validate= "modelValidations.categoryName"
              required>
            </md-input>
            <slide-y-up-transition>
              <div class="vErrorText" v-show="errors.has('categoryName')">【必須】255文字以内で入力してください</div>
            </slide-y-up-transition>
            <slide-y-down-transition>
              <md-icon class="error" v-show="errors.has('categoryName')">close</md-icon>
            </slide-y-down-transition>
            <slide-y-down-transition>
              <md-icon class="success" v-show="!errors.has('categoryName') && touched.categoryName">done</md-icon>
            </slide-y-down-transition>
          </md-field>
        </div>
      </div>
      <div class="md-layout">
        <label class="md-layout-item md-size-20 md-form-label">
          カテゴリ画像
        </label>
        <div class="md-layout-item md-size-80 pt20">
          <div class="imgContainer">
            <div class="file-input">
              <div v-if="!imageMain">
                <div class="image-container">
                  <img :src="dummyImg" title="">
                </div>
              </div>
              <div class="image-container" v-else>
                <img :src="imageMain" />
              </div>
              <div class="button-container">
                <md-button class="md-danger md-round" @click="removeMainImage" v-if="imageMain" data-id="imageMain"><i class="fa fa-times"></i>削除</md-button>
                <md-button class="md-success md-round md-fileinput">
                  <template v-if="!imageMain">画像選択</template>
                  <template v-else>変更</template>
                  <input type="file" @input="onFileMainChange" data-id="imageMain">
                </md-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="md-layout">
        <label class="md-layout-item md-size-20 md-form-label">
          カテゴリ内容
        </label>
        <div class="md-layout-item md-size-80">
          <md-field>
            <md-textarea v-model="description" md-counter="140" maxlength="140"></md-textarea>
          </md-field>
        </div>
      </div>
      <div class="md-layout">
        <label class="md-layout-item md-size-20 md-form-label">
          ステータス
        </label>
        <div class="md-layout-item md-size-20 md-xsmall-size-40">
          <md-field>
            <md-select v-model="categoryStatus">
              <md-option value="active">有効</md-option>
              <md-option value="not_active">無効</md-option>
            </md-select>
          </md-field>
        </div>
      </div>
      <div class="btnModal">
        <md-button class="md-success md-sm" @click="validate">{{buttonWord}}</md-button>
      </div>
    </div>
  </transition>
</div>
