
<div class="md-layout">
  <div class="md-layout-item md-size-100">
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-green">
        <div class="card-icon">
          <md-icon>search</md-icon>
        </div>
        <h4 class="title">メルマガ配信検索</h4>
      </md-card-header>

      <md-card-content class="md-layout formLayout">
        <div class="md-layout md-layout-item md-size-100 md-xsmall-size-100">
          <label class="md-layout-item md-size-15 md-xsmall-size-100 md-form-label">
            配信開始日
          </label>
          <div class="md-layout-item md-size-30 md-xsmall-size-100" style="position:relative">
            <vuejs-datepicker
              :format="DatePickerFormat"
              :highlighted="highlighted"
              v-model="startDay"
              :language="ja"></vuejs-datepicker>
            <md-button
              class="md-just-icon md-default md-simple dateDelete"
              @click.native="startDay = ''">
              <md-icon>close</md-icon>
            </md-button>
          </div>
          <time-select class="md-layout-item md-xsmall-size-100" ref="startTimer" v-model="startTime"></time-select>
        </div>
        <div class="md-layout md-layout-item md-size-100 md-xsmall-size-100">
          <label class="md-layout-item md-size-15 md-xsmall-size-100 md-form-label">
            配信終了日
          </label>
          <div class="md-layout-item md-size-30 md-xsmall-size-100" style="position:relative">
            <vuejs-datepicker
              :format="DatePickerFormat"
              :highlighted="highlighted"
              v-model="endDay"
              :language="ja"></vuejs-datepicker>
            <md-button
              class="md-just-icon md-default md-simple dateDelete"
              @click.native="endDay = ''">
              <md-icon>close</md-icon>
            </md-button>
          </div>
          <time-select class="md-layout-item md-xsmall-size-100" ref="endTimer" v-model="endTime"></time-select>
        </div>
      </md-card-content>
      <md-card-actions class="mx-auto">
        <md-button class="md-success md-lg" @click="getNotice()">検索</md-button>
        <md-button class="md-info md-lg mL40" @click="handleClear">検索条件クリア</md-button>
      </md-card-actions>
    </md-card>
  </div>
  <transition name="fade">
    <div class="md-layout-item md-size-100" v-if="resultFlag">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">メルマガ配信一覧</h4>
        </md-card-header>
        <md-card-content>
          <div class="dataTableWrapper">
            <div class="dataTable"> 
              <div class="tHead">
                <div class="tHead_th">配信日時</div>
                <div class="tHead_th">配信ステータス</div>
                <div class="tHead_th taR">配信予定数</div>
                <div class="tHead_th taR">配信完了数</div>
                <div class="tHead_th">件名</div>
                <div class="tHead_th taR">アクション</div>
              </div>
              <div v-for="item in tableData" :data-id="item.id" class="tItem">
                <div class="tItem_td">{{ item.deliveryDay | timeFormat2}}</div>
                <div class="tItem_td">{{ item.status }}</div>
                <div class="tItem_td taR">{{ item.totalCount | numberWithDelimiter }} 件</div>
                <div class="tItem_td taR">{{ item.sendCount | numberWithDelimiter }} 件</div>
                <div class="tItem_td commentBody">{{ item.subject }}</div>
                <div class="tItem_td taR td_actionTw">
                  <md-button
                    class="md-just-icon md-warning mg2"
                    @click.native="handleEdit(item.id)">
                    <md-icon>dvr</md-icon>
                  </md-button>
                  <md-button
                    class="md-just-icon md-danger mg2"
                    @click.native="handleDelete(item)">
                    <md-icon>close</md-icon>
                  </md-button>
                </div>
              </div>
            </div>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">全{{ pageData.totalCount | numberWithDelimiter }}件中 {{from | numberWithDelimiter}}件から {{to | numberWithDelimiter}}件を表示</p>
          </div>
          <Pagination class="Pagination-no-border Pagination-success"
            :currentPage="pageData.currentPage"
            :totalPage="pageData.totalPage"
            @get="getNotice">
          </Pagination>
        </md-card-actions>
      </md-card>
    </div>
  </transition>
</div>

