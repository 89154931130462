
<div class="md-layout">
  <div class="md-layout-item md-size-100">
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-green">
        <div class="card-icon">
          <md-icon>account_box</md-icon>
        </div>
        <h4 class="title">予約詳細</h4>
      </md-card-header>

      <md-card-content class="formLayout">
        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            注文番号
          </label>
          <div class="md-layout-item md-size-85 md-xsmall-size-100">
            <p class="form-control-static">{{orderNumber}}</p>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            支払番号
          </label>
          <div class="md-layout-item md-size-85 md-xsmall-size-100">
            <p class="form-control-static">{{approveNo}}</p>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            予約日
          </label>
          <div class="md-layout-item md-size-85 md-xsmall-size-100">
            <p class="form-control-static">{{orderDate | dateFormat}}</p>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            サービス開始時刻
          </label>
          <div class="md-layout-item md-size-85 md-xsmall-size-100">
            <p class="form-control-static">{{startDay | timeFormat}}</p>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            サービス終了時刻
          </label>
          <div class="md-layout-item md-size-85 md-xsmall-size-100">
            <p class="form-control-static">{{endDay | timeFormat}}</p>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            ステータス
          </label>
          <div class="md-layout-item md-size-85 md-xsmall-size-100" v-if="orderRequestStatus === 'cancel'">
            <p class="form-control-static">キャンセル</p>
          </div>
          <div class="md-layout-item md-size-85 md-xsmall-size-100" v-else-if="orderRequestStatus === 'recieved'">
            <md-field class="productStatus">
              <md-select v-model="orderStatus" name="status">
                <md-option value="recieved">実施済み</md-option>
                <md-option value="cancel">キャンセル</md-option>
              </md-select>
            </md-field>
          </div>
          <div class="md-layout-item md-size-85 md-xsmall-size-100" v-else>
            <md-field class="productStatus">
              <md-select v-model="orderStatus" name="status">
                <md-option value="accept">予約中</md-option>
                <md-option value="recieved">実施済み</md-option>
                <md-option value="cancel">キャンセル</md-option>
              </md-select>
            </md-field>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            支払い方法
          </label>
          <div class="md-layout-item md-size-85 md-xsmall-size-100">
            <p class="form-control-static">{{paymentKind}}</p>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            予約者名
          </label>
          <div v-if="opt_adminUser" class="md-layout-item md-size-85 md-xsmall-size-100">
            <p class="form-control-static">{{ name }}</p>
          </div>
          <div v-else class="md-layout-item md-size-85 md-xsmall-size-100">
            <p class="form-control-static">{{ plain }}</p>
          </div> 
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            予約店舗
          </label>
          <div class="md-layout-item md-size-85 md-xsmall-size-100">
            <p class="form-control-static">{{shopName}}</p>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            担当
          </label>
          <div v-if="opt_adminUser" class="md-layout-item md-size-85 md-xsmall-size-100">
            <p class="form-control-static">{{ staffName }}</p>
          </div>
          <div v-else class="md-layout-item md-size-85 md-xsmall-size-100">
            <p class="form-control-static">{{ plain }}</p>
          </div> 
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            予約金額
          </label>
          <div class="md-layout-item md-size-85 md-xsmall-size-100" v-if="editPeriod">
            <md-field :class="[
              {'md-error': errors.has('totalPrice')},
              {'md-valid': !errors.has('totalPrice') && touched.totalPrice},
              'productStatus']">
              <md-input
                type="number"
                v-model="totalPrice"
                data-vv-name="totalPrice"
                v-validate= "modelValidations.totalPrice"
                required>
              </md-input>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('totalPrice')">【必須】1以上6桁以内の半角数字で入力してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('totalPrice')" style="margin-right:10px">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('totalPrice') && touched.totalPrice" style="margin-right:10px">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
          <div class="md-layout-item md-size-85 md-xsmall-size-100" v-else>
            <p class="form-control-static">{{totalPrice}}</p>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            コメント
          </label>
          <div class="md-layout-item md-size-85 md-xsmall-size-100">
            <md-field>
              <md-textarea v-model="comment"></md-textarea>
            </md-field>
          </div>
        </div>

      </md-card-content>
      <md-card-actions class="mx-auto" md-alignment="left">
        <md-button class="md-warning md-lg" @click.native.prevent="returnBack">戻る</md-button>
        <md-button class="md-success md-lg mL40" @click.native.prevent="validate">更新</md-button>
      </md-card-actions>
    </md-card>

    <md-card class="mt30">
      <md-card-header class="md-card-header-icon md-card-header-green">
        <div class="card-icon">
          <md-icon>assignment</md-icon>
        </div>
        <h4 class="title">予約内容</h4>
      </md-card-header>
      <md-card-content>
        <div class="dataTableWrapper">
          <div class="dataTable"> 
            <div class="tHead">
              <div class="tHead_th t_image">商品画像</div>
              <div class="tHead_th">商品名</div>
              <div class="tHead_th taC">税区分</div>
              <div class="tHead_th taR">商品金額</div>
              <div class="tHead_th taR">個数</div>
              <div class="tHead_th taR">合計</div>
            </div>
            <div v-for="item in shoppingCartTable" :data-id="item.id" class="tItem">
              <div class="tItem_td t_image"><img :src="item.img" alt=""></div>
              <div class="tItem_td"><md-label style="word-break:break-all;">{{ item.name }}</md-label></div>
              <div class="tItem_td taC"><md-icon>{{item.taxIcon}}</md-icon></div>
              <div class="tItem_td taR boldCell">¥{{ item.price | numberWithDelimiter}}</div>
              <div class="tItem_td taR">{{ item.quantity }}</div>
              <div class="tItem_td taR boldCell">¥{{ item.priceIncludingTax * item.quantity | numberWithDelimiter}}</div>
            </div>
          </div>
        </div>
        <div class="table table-stats">
          <div class="td-price td-price-top">
            <div class="td-total">
               税抜合計
            </div>
            <span>
              <small>¥</small>
              {{this.totalPriceWithoutTax | numberWithDelimiter}}
            </span>
          </div>
          <div class="td-price">
            <div class="td-total">
               ポイント利用
            </div>
            <span v-if="usePoint===0">
              なし
            </span>
            <span v-else>
              {{this.usePoint | numberWithDelimiter}}
            </span>
          </div>
          <div class="td-price">
            <div class="td-total">
               クーポン利用
            </div>
            <span v-if="couponDiscount===0">
              なし
            </span>
            <span v-else>
              <small>¥ -</small>
              {{this.couponDiscount | numberWithDelimiter}}
            </span>
          </div>
          <div class="td-price">
            <div class="td-total">
               8%税率対象合計
            </div>
            <span>
              <small>¥</small>
              {{this.taxReduce | numberWithDelimiter}}
            </span>
          </div>
          <div class="td-price">
            <div class="td-total">
              10%税率対象合計
            </div>
            <span>
              <small>¥</small>
              {{this.taxNormal | numberWithDelimiter}}
            </span>
          </div>
          <div class="td-price">
            <div class="td-total">
              税込合計
            </div>
            <span style="font-weight: bold">
              <small style="font-weight: bold">¥</small>
              {{this.totalPrice | numberWithDelimiter}}
            </span>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</div>

