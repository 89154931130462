
<div class="md-layout">
  <div class="md-layout-item md-size-100">
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-green">
        <div class="card-icon">
          <md-icon>create</md-icon>
        </div>
        <h4 class="title">QAカテゴリー情報</h4>
      </md-card-header>
      <md-card-content class="md-layout formLayout">
        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            表示順
          </label>
          <div class="md-layout-item">
            <md-field style="width: 50px" :class="[
              {'md-error': errors.has('number')},
              {'md-valid': !errors.has('number') && touched.number}]">
              <md-input
                v-model="number"
                data-vv-name="number"
                type="text"
                v-validate= "modelValidations.number"
                required>
              </md-input>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('number')">半角数字で入力してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('number')">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('number') && touched.number">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>
        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            カテゴリコード
          </label>
          <div class="md-layout-item">
            <md-field :class="[
              {'md-error': errors.has('code')},
              {'md-valid': !errors.has('code') && touched.code}]">
              <md-input
                v-model="code"
                data-vv-name="code"
                type="text"
                v-validate= "modelValidations.code">
              </md-input>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('code')">255文字以内の半角英数字で入力してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('code')">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('code') && touched.code">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>
        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            カテゴリ名
          </label>
          <div class="md-layout-item">
            <md-field :class="[
              {'md-error': errors.has('name')},
              {'md-valid': !errors.has('name') && touched.name}]">
              <md-input
                v-model="name"
                data-vv-name="name"
                type="text"
                v-validate= "modelValidations.name"
                required>
              </md-input>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('name')">【必須】カテゴリ名入力してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('name')">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('name') && touched.name">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>
        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            ステータス
          </label>
          <div class="md-layout-item md-size-85 md-xsmall-size-100">
            <md-field class="productStatus">
              <md-select v-model="status" name="status">
                <md-option value="active">有効</md-option>
                <md-option value="not_active">無効</md-option>
              </md-select>
            </md-field>
          </div>
        </div>
      </md-card-content>
      <md-card-actions md-alignment="left" class="mt60 mx-auto">
        <md-button class="md-warning md-lg" @click.native.prevent="returnBack">戻る</md-button>
        <submit-button class="md-success md-lg mL40" :onclick="validate" :buttonWord=this.buttonWord type="submit"></submit-button>
      </md-card-actions>
    </md-card>
  </div>
</div>
