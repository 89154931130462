
<div class="md-layout">
  <div class="md-layout-item md-size-100">
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-green">
        <div class="card-icon">
          <md-icon>assignment</md-icon>
        </div>
        <h4 class="title">QA情報設定</h4>
      </md-card-header>

      <md-card-content>
        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            表示順
          </label>
          <div class="md-layout-item">
            <md-field style="width: 50px" :class="[
              {'md-error': errors.has('number')},
              {'md-valid': !errors.has('number') && touched.number}]">
              <md-input
                v-model="number"
                data-vv-name="number"
                type="text"
                v-validate= "modelValidations.number"
                required>
              </md-input>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('number')">【必須】半角数字で入力してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('number')">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('number') && touched.number">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            カテゴリ名
          </label>
          <div class="md-layout-item md-size-30 md-xsmall-size-100">
            <qa-category-select v-model="categoryName" :noSelectFlag="false"></qa-category-select>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            QA管理名称
          </label>
          <div class="md-layout-item">
            <md-field :class="[
              {'md-error': errors.has('name')},
              {'md-valid': !errors.has('name') && touched.name}]">
              <md-input
                v-model="name"
                data-vv-name="name"
                type="text"
                v-validate= "modelValidations.name"
                required>
              </md-input>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('name')">【必須】255文字以内で入力してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('name')">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('name') && touched.name">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            質問内容
          </label>
          <div class="md-layout-item">
            <md-field :class="[
              {'md-error': errors.has('question')},
              {'md-valid': !errors.has('question') && touched.question}]">
              <md-input
                v-model="question"
                data-vv-name="question"
                type="text"
                v-validate= "modelValidations.question"
                required>
              </md-input>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('question')">【必須】入力してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('question')">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('question') && touched.question">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>
        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            回答内容
          </label>
          <div class="md-layout-item pt20">
          <div class="f-field">
            <froala :tag="'textarea'" :config="config" v-model="answer"
                data-vv-name="answer"
                type="text"
                v-validate= "modelValidations.answer"
                required>
            </froala>
            <slide-y-up-transition>
              <div class="vErrorText" v-show="errors.has('answer')">【必須】入力してください</div>
            </slide-y-up-transition>
            </div>
          </div>
        </div>
        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            ステータス
          </label>
          <div class="md-layout-item md-size-15 md-xsmall-size-100">
            <md-field :class="[
              {'md-error': errors.has('status')},
              {'md-valid': !errors.has('status') && touched.status}]">
              <md-select
                v-model="status"
                data-vv-name="status"
                v-validate= "modelValidations.status"
                required>
                <md-option value="active">有効</md-option>
                <md-option value="not_active">無効</md-option>
              </md-select>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('status')">【必須】必ず一つ選択してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('status')" style="margin-right:20px">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('status') && touched.status" style="margin-right:20px">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>

      </md-card-content>
      <md-card-actions md-alignment="left" class="mt60 mx-auto">
        <md-button class="md-warning md-lg" @click.native.prevent="returnBack">戻る</md-button>
        <submit-button class="md-success md-lg mL40" :onclick="validate" :buttonWord=this.buttonWord type="submit"></submit-button>
      </md-card-actions>
    </md-card>
  </div>
</div>
