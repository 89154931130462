
<div class="full-page">
  <notifications></notifications>
  <div class="wrapper wrapper-full-page">
    <div class="page-header header-filter" :class="setPageClass" filter-color="black" :style="setBgImage">
      <div class="container md-offset">
        <zoom-center-transition :duration="pageTransitionDuration" mode="out-in">
          <div class="md-layout text-center">
            <div class="md-layout-item md-size-33 md-medium-size-50 md-small-size-70 md-xsmall-size-100">
              <login-card header-color="green">
                <div slot="description" style="height:36px;">
                パスワードをリセットします
                </div>
                <md-field class="md-form-group" style="margin-top:15;" slot="inputs" :class="[
                    {'md-invalid': errors.has('会社コード')},
                    {'md-error': errors.has('会社コード')}]">
                  <md-icon>business</md-icon>
                  <label>会社コード</label>
                  <md-input v-model="company" name="company" data-vv-name="会社コード" v-validate="'required'"></md-input>
                  <span class="md-error">{{ errors.first('会社コード') }}</span>
                </md-field>
                <md-field class="md-form-group" slot="inputs" :class="[
                    {'md-invalid': errors.has('ログインID')},
                    {'md-error': errors.has('ログインID')}]">
                  <md-icon>face</md-icon>
                  <label>ログインID</label>
                  <md-input v-model="login" type="login" name="login" data-vv-name="ログインID" v-validate="'required'"></md-input>
                  <span class="md-error">{{ errors.first('ログインID') }}</span>
                </md-field>
                  <md-button slot="footer" class="md-button md-warning md-theme-default" @click="returnBack" style="height: 36px">戻る</md-button>
                  <md-button slot="footer" class="md-button md-success md-theme-default mL40" @click="submitReminder" style="height: 36px">次へ</md-button>
              </login-card>
            </div>
          </div>
        </zoom-center-transition>
      </div>
      <footer class="footer">
        <div class="container md-offset">
            <div class="copyright text-center">
              &copy; {{ new Date().getFullYear() }} <a href="https://www.samurai-net.co.jp" target="_blank">Samurai, inc</a>&nbsp;All Rights Reserved.
            </div>
        </div>
      </footer>
    </div>
  </div>
</div>
