
<md-card class="md-card-login" :class="{'md-card-hidden': cardHidden}">
  <!-- <md-card-header :class="getClass(headerColor)">
    <slot name="title"></slot>
    <div class="social-line">
      <slot name="buttons"></slot>
    </div>
  </md-card-header> -->
  <div class="logoImage"><img src="/images/air_beyond_logo.png" alt=""></div>

  <md-card-content>
    <slot name="description"></slot>
    <slot name="inputs"></slot>
  </md-card-content>

  <md-card-actions>
    <slot name="footer"></slot>
  </md-card-actions>
</md-card>
