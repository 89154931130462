
<md-field :class="[
              {'md-error': errors.has('contactCategoryId')},
              {'md-valid': !errors.has('contactCategoryId') && touched.contactCategoryId}]" >
  <md-select
    v-model="contactCategoryId"
    data-vv-name="contactCategoryId"
    v-validate= "modelValidations.contactCategoryId">
    <md-option v-for="item in listData" :value="item.id" :key="item.id">{{item.name}}</md-option>
  </md-select>
  <slide-y-up-transition>
    <div class="vErrorText" v-show="errors.has('contactCategoryId')">【必須】必ず一つ選択してください</div>
  </slide-y-up-transition>
  <slide-y-down-transition>
    <md-icon class="error" v-show="errors.has('contactCategoryId')" style="margin-right:20px">close</md-icon>
  </slide-y-down-transition>
  <slide-y-down-transition>
    <md-icon class="success" v-show="!errors.has('contactCategoryId') && touched.contactCategoryId" style="margin-right:20px">done</md-icon>
  </slide-y-down-transition>
</md-field>
