<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">店舗基本情報設定</h4>
        </md-card-header>

        <md-card-content>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              店舗名
            </label>
            <div class="md-layout-item">
              <md-field :class="[
                {'md-error': errors.has('companyName')},
                {'md-valid': !errors.has('companyName') && touched.companyName}]">
                <md-input
                  v-model="companyName"
                  data-vv-name="companyName"
                  type="text"
                  v-validate= "modelValidations.companyName"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('companyName')">【必須】255文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('companyName')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('companyName') && touched.companyName">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100" v-if="superFlag">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              企業名
            </label>
            <div class="md-layout-item md-size-30 md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('enterpriseName')},
                {'md-valid': !errors.has('enterpriseName') && touched.enterpriseName}]">
                <md-select
                  v-model="enterpriseName"
                  data-vv-name="enterpriseName"
                  v-validate= "superFlag === true ? modelValidations.enterpriseName : ''"
                  required>
                  <md-option v-for="item in enterpriseList" :value="item.id">{{item.name}}</md-option>
                </md-select>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('enterpriseName')">【必須】必ず一つ選択してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('enterpriseName')" style="margin-right:20px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('enterpriseName') && touched.enterpriseName" style="margin-right:20px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              郵便番号
            </label>
            <div class="md-layout-item md-aSize-100">
              <md-field :md-counter="false" :class="[
                {'md-error': errors.has('zipCode1')},
                {'md-valid': !errors.has('zipCode1') && touched.zipCode1}]">
                <md-input
                  v-model="zipCode1"
                  data-vv-name="zipCode1"
                  type="number"
                  v-validate= "modelValidations.zipCode1"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('zipCode1') || errors.has('zipCode2')">【必須】前3桁・後4桁の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('zipCode1')" style="margin-right:10px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('zipCode1') && touched.zipCode1" style="margin-right:10px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
            <div class="md-layout-item md-size-5 zipBetween">ー</div>
            <div class="md-layout-item md-aSize-120">
              <md-field :md-counter="false" :class="[
                {'md-error': errors.has('zipCode2')},
                {'md-valid': !errors.has('zipCode2') && touched.zipCode2}]">
                <md-input
                  v-model="zipCode2"
                  data-vv-name="zipCode2"
                  type="number"
                  v-validate= "modelValidations.zipCode2"
                  required>
                </md-input>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('zipCode2')" style="margin-right:10px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('zipCode2') && touched.zipCode2" style="margin-right:10px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              都道府県
            </label>
            <div class="md-layout-item md-size-20 md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('prefecture')},
                {'md-valid': !errors.has('prefecture') && touched.prefecture}]">
                <md-select
                  v-model="prefecture"
                  data-vv-name="prefecture"
                  v-validate= "modelValidations.prefecture"
                  required>
                  <md-option value="北海道">北海道</md-option>
                  <md-option value="青森県">青森県</md-option>
                  <md-option value="岩手県">岩手県</md-option>
                  <md-option value="宮城県">宮城県</md-option>
                  <md-option value="秋田県">秋田県</md-option>
                  <md-option value="山形県">山形県</md-option>
                  <md-option value="福島県">福島県</md-option>
                  <md-option value="茨城県">茨城県</md-option>
                  <md-option value="栃木県">栃木県</md-option>
                  <md-option value="群馬県">群馬県</md-option>
                  <md-option value="埼玉県">埼玉県</md-option>
                  <md-option value="千葉県">千葉県</md-option>
                  <md-option value="東京都">東京都</md-option>
                  <md-option value="神奈川県">神奈川県</md-option>
                  <md-option value="新潟県">新潟県</md-option>
                  <md-option value="富山県">富山県</md-option>
                  <md-option value="石川県">石川県</md-option>
                  <md-option value="福井県">福井県</md-option>
                  <md-option value="山梨県">山梨県</md-option>
                  <md-option value="長野県">長野県</md-option>
                  <md-option value="岐阜県">岐阜県</md-option>
                  <md-option value="静岡県">静岡県</md-option>
                  <md-option value="愛知県">愛知県</md-option>
                  <md-option value="三重県">三重県</md-option>
                  <md-option value="滋賀県">滋賀県</md-option>
                  <md-option value="京都府">京都府</md-option>
                  <md-option value="大阪府">大阪府</md-option>
                  <md-option value="兵庫県">兵庫県</md-option>
                  <md-option value="奈良県">奈良県</md-option>
                  <md-option value="和歌山県">和歌山県</md-option>
                  <md-option value="鳥取県">鳥取県</md-option>
                  <md-option value="島根県">島根県</md-option>
                  <md-option value="岡山県">岡山県</md-option>
                  <md-option value="広島県">広島県</md-option>
                  <md-option value="山口県">山口県</md-option>
                  <md-option value="徳島県">徳島県</md-option>
                  <md-option value="香川県">香川県</md-option>
                  <md-option value="愛媛県">愛媛県</md-option>
                  <md-option value="高知県">高知県</md-option>
                  <md-option value="福岡県">福岡県</md-option>
                  <md-option value="佐賀県">佐賀県</md-option>
                  <md-option value="長崎県">長崎県</md-option>
                  <md-option value="熊本県">熊本県</md-option>
                  <md-option value="大分県">大分県</md-option>
                  <md-option value="宮崎県">宮崎県</md-option>
                  <md-option value="鹿児島県">鹿児島県</md-option>
                  <md-option value="沖縄県">沖縄県</md-option>
                </md-select>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('prefecture')">【必須】必ず一つ選択してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('prefecture')" style="margin-right:20px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('prefecture') && touched.prefecture" style="margin-right:20px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              市区町村
            </label>
            <div class="md-layout-item">
              <md-field :class="[
                {'md-error': errors.has('city')},
                {'md-valid': !errors.has('city') && touched.city}]">
                <md-input
                  v-model="city"
                  data-vv-name="city"
                  type="text"
                  v-validate= "modelValidations.city"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('city')">【必須】255文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('city')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('city') && touched.city">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              番地
            </label>
            <div class="md-layout-item">
              <md-field :class="[
                {'md-error': errors.has('street1')},
                {'md-valid': !errors.has('street1') && touched.street1}]">
                <md-input
                  v-model="street1"
                  data-vv-name="street1"
                  type="text"
                  v-validate= "modelValidations.street1"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('street1')">【必須】255文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('street1')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('street1') && touched.street1">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              建物名
            </label>
            <div class="md-layout-item">
              <md-field :class="[
                {'md-error': errors.has('street2')},
                {'md-valid': !errors.has('street2') && touched.street2}]">
                <md-input
                  v-model="street2"
                  data-vv-name="street2"
                  type="text"
                  v-validate= "modelValidations.street2">
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('street2')">255文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('street2')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('street2') && touched.street2">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              電話番号
            </label>
            <div class="md-layout-item md-size-10 md-xsmall-size-30">
              <md-field :class="[
                {'md-error': errors.has('tel1')},
                {'md-valid': !errors.has('tel1') && touched.tel1}]">
                <md-input
                  v-model="tel1"
                  data-vv-name="tel1"
                  type="number"
                  v-validate= "modelValidations.tel1"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('tel1') || errors.has('tel2') || errors.has('tel3')">【必須】それぞれ４桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('tel1')" style="margin-right:10px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('tel1') && touched.tel1" style="margin-right:10px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
            <div class="md-layout-item md-size-5 zipBetween">ー</div>
            <div class="md-layout-item md-size-10 md-xsmall-size-30">
              <md-field :class="[
                {'md-error': errors.has('tel2')},
                {'md-valid': !errors.has('tel2') && touched.tel2}]">
                <md-input
                  v-model="tel2"
                  data-vv-name="tel2"
                  type="number"
                  v-validate= "modelValidations.tel2"
                  required>
                </md-input>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('tel2')" style="margin-right:10px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('tel2') && touched.tel2" style="margin-right:10px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
            <div class="md-layout-item md-size-5 zipBetween">ー</div>
            <div class="md-layout-item md-size-10 md-xsmall-size-30">
              <md-field :class="[
                {'md-error': errors.has('tel3')},
                {'md-valid': !errors.has('tel3') && touched.tel3}]">
                <md-input
                  v-model="tel3"
                  data-vv-name="tel3"
                  type="number"
                  v-validate= "modelValidations.tel3"
                  required>
                </md-input>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('tel3')" style="margin-right:10px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('tel3') && touched.tel3" style="margin-right:10px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              ステータス
            </label>
            <div class="md-layout-item md-size-20 md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('status')},
                {'md-valid': !errors.has('status') && touched.status}]">
                <md-select
                  v-model="status"
                  data-vv-name="status"
                  v-validate= "modelValidations.status"
                  required>
                  <md-option value="active">有効</md-option>
                  <md-option value="not_active">無効</md-option>
                </md-select>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('status')">【必須】必ず一つ選択してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('status')" style="margin-right:20px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('status') && touched.status" style="margin-right:20px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              開店・閉店状況
            </label>
            <div class="md-layout-item md-size-20 md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('openStatus')},
                {'md-valid': !errors.has('openStatus') && touched.openStatus}]">
                <md-select
                  v-model="openStatus"
                  data-vv-name="openStatus"
                  v-validate= "modelValidations.openStatus"
                  required>
                  <md-option value="open">開店中</md-option>
                  <md-option value="close">閉店中</md-option>
                </md-select>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('openStatus')">【必須】必ず一つ選択してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('openStatus')" style="margin-right:20px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('openStatus') && touched.openStatus" style="margin-right:20px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              決済上限金額
            </label>
            <div class="md-layout-item md-size-20 md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('maxPrice')},
                {'md-valid': !errors.has('maxPrice') && touched.maxPrice}]">
                <md-input
                  v-model="maxPrice"
                  data-vv-name="maxPrice"
                  type="text"
                  v-validate= "modelValidations.maxPrice"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('maxPrice')">【必須】9桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('maxPrice')" style="margin-right:20px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('maxPrice') && touched.maxPrice" style="margin-right:20px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
            <div class="md-layout-item unitLabel">円</div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              一日あたりの上限注文金額
            </label>
            <div class="md-layout-item md-size-20 md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('maxPricePerDay')},
                {'md-valid': !errors.has('maxPricePerDay') && touched.maxPricePerDay}]">
                <md-input
                  v-model="maxPricePerDay"
                  data-vv-name="maxPricePerDay"
                  type="text"
                  v-validate= "modelValidations.maxPricePerDay"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('maxPricePerDay')">【必須】9桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('maxPricePerDay')" style="margin-right:20px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('maxPricePerDay') && touched.maxPricePerDay" style="margin-right:20px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
            <div class="md-layout-item unitLabel">円</div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              1ヶ月あたりの上限注文金額
            </label>
            <div class="md-layout-item md-size-20 md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('maxPricePerMonth')},
                {'md-valid': !errors.has('maxPricePerMonth') && touched.maxPricePerMonth}]">
                <md-input
                  v-model="maxPricePerMonth"
                  data-vv-name="maxPricePerMonth"
                  type="text"
                  v-validate= "modelValidations.maxPricePerMonth"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('maxPricePerMonth')">【必須】9桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('maxPricePerMonth')" style="margin-right:20px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('maxPricePerMonth') && touched.maxPricePerMonth" style="margin-right:20px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
            <div class="md-layout-item unitLabel">円</div>
          </div>
        </md-card-content>
      </md-card>
    </div>
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>query_builder</md-icon>
          </div>
          <h4 class="title">予約設定</h4>
        </md-card-header>

        <md-card-content>
          <div class="md-layout md-layout-item md-size-100 md-small-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              予約受付可能数
            </label>
            <div class="md-layout-item md-size-10 md-xsmall-size-50">
              <md-field :class="[
                {'md-error': errors.has('reserveCap')},
                {'md-valid': !errors.has('reserveCap') && touched.reserveCap}]">
                <md-input
                  v-model="reserveCap"
                  data-vv-name="reserveCap"
                  type="number"
                  v-validate= "modelValidations.reserveCap"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('reserveCap')">【必須】3桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('reserveCap')" style="margin-right:10px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('reserveCap') && touched.reserveCap" style="margin-right:10px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
            <div class="md-layout-item unitLabel">件</div>
          </div>

          <div class="md-layout md-layout-item md-size-100 md-small-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              当日予約
            </label>
            <div class="md-layout-item">
              <md-radio v-model="reserveSameDay" value="not_available">不可</md-radio>
              <md-radio v-model="reserveSameDay" value="available">可能</md-radio>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100 md-small-size-100" v-show="reserveSameDay==='not_available'">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              予約可能日
            </label>
            <div class="md-layout-item md-size-10 md-xsmall-size-50">
              <md-field :class="[
                {'md-error': errors.has('reserveDay')},
                {'md-valid': !errors.has('reserveDay') && touched.reserveDay}]">
                <md-select
                  v-model="reserveDay"
                  data-vv-name="reserveDay"
                  v-validate= "reserveSameDay === 'not_available' ? modelValidations.reserveDay : ''"
                  required>
                  <md-option v-for="n in 30" :value="n">{{n}}</md-option>
                </md-select>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('reserveDay')">【必須】予約可能日を選択してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('reserveDay')" style="margin-right:10px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('reserveDay') && touched.reserveDay" style="margin-right:10px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
            <div class="md-layout-item unitLabel">日前までの予約必須</div>
          </div>

          <div class="md-layout md-layout-item md-size-100 md-small-size-100" v-show="reserveSameDay==='available'">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              予約可能時間
            </label>
            <div class="md-layout-item md-size-10 md-xsmall-size-50">
              <md-field :class="[
                {'md-error': errors.has('reserveTime')},
                {'md-valid': !errors.has('reserveTime') && touched.reserveTime}]">
                <md-select
                  v-model="reserveTime"
                  data-vv-name="reserveTime"
                  v-validate= "reserveSameDay === 'available' ? modelValidations.reserveTime : ''"
                  required>
                  <md-option v-for="n in 24" :value="n">{{n}}</md-option>
                </md-select>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('reserveTime')">【必須】予約可能時間を選択してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('reserveTime')" style="margin-right:10px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('reserveTime') && touched.reserveTime" style="margin-right:10px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
            <div class="md-layout-item unitLabel">時間前まで予約可能</div>
          </div>
        </md-card-content>
      </md-card>
    </div>
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>query_builder</md-icon>
          </div>
          <h4 class="title">営業時間設定</h4>
        </md-card-header>

        <working-plan ref="wp"></working-plan>

        <div class="md-layout md-layout-item md-size-100 mb-20">
          <label class="md-layout-item md-size-10 md-xsmall-size-20 md-form-label weekLabel is-red pt20 ml-5">
            祝
          </label>
          <div class="md-layout-item pt5">
            <md-radio v-model="holidayStatus" :value="false">開店</md-radio>
            <md-radio v-model="holidayStatus" :value="true">閉店</md-radio>
          </div>
        </div>
      </md-card>
    </div>

    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>settings</md-icon>
          </div>
          <h4 class="title">オプション設定</h4>
        </md-card-header>

        <md-card-content>
          <div class="md-layout md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              イメージ画像
            </label>
            <div class="md-layout-item  md-size-30 md-xsmall-size-100">
              <div class="file-input">
                <div v-if="!imageShop">
                  <div class="image-container">
                    <img :src="dummyImg" title="">
                  </div>
                </div>
                <div class="image-container" v-else>
                  <img :src="imageShop" />
                </div>
                <div class="button-container">
                  <md-button class="md-danger md-round" @click="removeImage" v-if="imageShop" data-id="imageShop"><i class="fa fa-times"></i>削除</md-button>
                  <md-button class="md-success md-round md-fileinput">
                    <template v-if="!imageShop">画像選択</template>
                    <template v-else>変更</template>
                    <input type="file" @change="onFileChange" data-id="imageShop">
                  </md-button>
                </div>
              </div>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              ウェブサイト
            </label>
            <div class="md-layout-item">
              <md-field :class="[
                {'md-error': errors.has('webUrl')},
                {'md-valid': !errors.has('webUrl') && touched.webUrl}]">
                <md-input
                  v-model="webUrl"
                  data-vv-name="webUrl"
                  v-validate= "modelValidations.webUrl">
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('webUrl')">半角英数記号のURL形式で入力してください（255文字以内）</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('webUrl')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('webUrl') && touched.webUrl">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              説明
            </label>
            <div class="md-layout-item pt20">
              <froala :tag="'textarea'" :config="config" v-model="shopDetail"></froala>
            </div>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="left" class="mt60 mx-auto">
          <md-button class="md-warning md-lg" @click.native.prevent="returnBack">戻る</md-button>
          <submit-button class="md-success md-lg mL40" :onclick="validate" :buttonWord=this.buttonWord type="submit"></submit-button>
        </md-card-actions>
      </md-card>
      <div class="linkCont is-xs-none" v-show="!newFlag">
        <div class="bottomLink" @click="goCalendar">休日カレンダーを編集する</div>
      </div>
    </div>
  </div>
</template>

<script>

  import WorkingPlan from '../components/additional/WorkingPlan.vue'
  import router from '../entrypoints/router.js'
  import TimeSetter from '../components/additional/TimeSetter.vue'
  import qs from 'qs'
  import role from "../mixins/role.js"
  import SubmitButton from '../components/additional/SubmitButton.vue'
  import EventBus from '../mixins/event-bus.js'

  export default {
    mixins: [ role ],
    components: {
      SubmitButton,
      WorkingPlan,
      TimeSetter
    },
    computed: {
    },
    props: {
      dummyImg: {
        type: String,
        default: '/images/image_placeholder.jpg'
      }
    },
    data () {
      return {
        id: '',
        buttonWord: '登録',
        companyName: '',
        enterpriseName: '',
        enterpriseList: [],
        companyCode: '',
        zipCode1: '',
        zipCode2: '',
        prefecture: '',
        city: '',
        street1: '',
        street2: '',
        tel1: '',
        tel2: '',
        tel3: '',
        status: '',
        openStatus: 'open',
        maxPrice: 100000,
        maxPricePerDay: 100000,
        maxPricePerMonth: 100000,
        imageShop: '',
        webUrl: '',
        recieveTime: '',
        cancelTime: '',
        reserveCap: '',
        reserveDay: '1',
        reserveTime: '1',
        reserveSameDay: 'not_available',
        holidayStatus: false,
        shopDetail: '',
        newFlag: false,
        workingPlanEmpty: false,
        touched: {
          companyName: false,
          enterpriseName: false,
          zipCode1: false,
          zipCode2: false,
          prefecture: false,
          city: false,
          street1: false,
          street2: false,
          tel1: false,
          tel2: false,
          tel3: false,
          status: false,
          recieveTime: false,
          webUrl: false,
          cancelTime: false,
          reserveCap: false,
          reserveDay: '',
          reserveTime: '',
          openStatus: false,
          maxPrice: false,
          maxPricePerDay: false,
          maxPricePerMonth: false,
        },
        modelValidations: {
          companyName: {
            required: true,
            max: 255
          },
          enterpriseName: {
            required: true
          },
          zipCode1: {
            required: true,
            max: 3,
            min: 3,
            numeric: true
          },
          zipCode2:{
            required: true,
            max: 4,
            min: 4,
            numeric: true
          },
          prefecture: {
            required: true
          },
          city: {
            required: true,
            max: 255
          },
          street1:{
            required: true,
            max: 255
          },
          street2:{
            max: 255
          },
          tel1:{
            required: true,
            max: 4,
            numeric: true
          },
          tel2:{
            required: true,
            max: 4,
            numeric: true
          },
          tel3:{
            required: true,
            max: 4,
            numeric: true
          },
          status:{
            required: true,
          },
          openStatus:{
            required: true,
          },
          maxPrice:{
            required: true,
            numeric: true,
            max: 9
          },
          maxPricePerDay:{
            required: true,
            numeric: true,
            max: 9
          },
          maxPricePerMonth:{
            required: true,
            numeric: true,
            max: 9
          },
          recieveTime:{
            required: true,
          },
          cancelTime:{
            required: true,
            numeric: true
          },
          reserveCap:{
            required: true,
            numeric: true,
            max: 3
          },
          reserveDay:{
            required: true
          },
          reserveTime:{
            required: true
          },
          webUrl:{
            url: true,
            max: 255
          }
        }
      }
    },
    methods: {
      returnBack () {
        router.go(-1)
      },
      goCalendar () {
        router.push({ path: '/admin/shop/calendar/edit/' + this.$route.params.id })
      },
      getShop () {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/shops/' + this.$route.params.id,
          timeout : 30000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.enterpriseName = response.data.company_id
          this.companyName = response.data.name
          this.companyCode = response.data.code
          this.imageShop = response.data.image_url
          this.zipCode1 = response.data.zip1
          this.zipCode2 = response.data.zip2
          this.prefecture = response.data.pref
          this.city = response.data.city
          this.street1 = response.data.street
          this.street2 = response.data.street2
          this.tel1 = response.data.tel1
          this.tel2 = response.data.tel2
          this.tel3 = response.data.tel3
          this.status = response.data.status
          this.openStatus = response.data.open_status
          this.maxPrice = response.data.max_price
          this.maxPricePerDay = response.data.max_price_per_day
          this.maxPricePerMonth = response.data.max_price_per_month
          this.holidayStatus = response.data.holidays_rest
          this.webUrl = response.data.website
          this.shopDetail = response.data.description
          this.reserveCap = response.data.reserve_count
          this.reserveDay = response.data.reserve_available_day
          this.reserveTime = response.data.reserve_available_time
          this.reserveSameDay = response.data.reserve_same_day
          this.recieveTime = response.data.reserve_accept_time
          this.cancelTime = response.data.cancel_possible_time
          this.$refs.wp.workingPlan(response.data.working_plan)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('店舗情報を正しく取得できませんでした')
          }
        })
      },
      onFileChange(e) {
        let files = e.target.files || e.dataTransfer.files;
        let ids = e.currentTarget.getAttribute('data-id');
        if (!files.length)
        return;
        this.createImage(files[0],ids);
      },
      createImage(file,fileId) {
        let reader = new FileReader();
        let $this = this;

        reader.onload = (e) => {
          $this[fileId] = e.target.result;
        };
        reader.readAsDataURL(file);
      },
      removeImage: function (e) {
        let ids = e.currentTarget.getAttribute('data-id');
        this[ids]= '';
      },
      workingPlan (dates) {
        for (var key in dates) {
          if (dates[key] !== null) {
            this.workingPlans[key].start = dates[key].start
            this.workingPlans[key].end = dates[key].end
            if (dates[key].breaks[0] !== undefined) {
              this.workingPlans[key].breaks[0].start = dates[key].breaks[0].start
              this.workingPlans[key].breaks[0].end = dates[key].breaks[0].end
            }
          }
        }
      },
      validate() {
        return new Promise((resolve) => {
          setTimeout(() => {
            EventBus.$emit('validate', this.errors)
            this.$validator.validateAll().then(isValid => {
              if (this.errors.items.length == 0) {
                this.updateShop()
              } else {
                this.validateError()
              }
            })
            resolve();
          }, this.ResolveTime);
        });
      },
      updateShop(){
        var condition = {
          shop: {
            'company_id': this.superFlag === true ? this.enterpriseName : this.$Cookies.get('companyId'),
            'description': this.shopDetail,
            'name': this.companyName,
            'cancel_possible_time': '0',
            'reserve_accept_time': '0',
            'pref': this.prefecture,
            'city': this.city,
            'street': this.street1,
            'street2': this.street2,
            'tel1': this.tel1,
            'tel2': this.tel2,
            'tel3': this.tel3,
            'zip1': this.zipCode1,
            'zip2': this.zipCode2,
            'status': this.status,
            'open_status': this.openStatus,
            'max_price': this.maxPrice,
            'max_price_per_day': this.maxPricePerDay,
            'max_price_per_month': this.maxPricePerMonth,
            'website': this.webUrl,
            'reserve_count': this.reserveCap,
            'reserve_available_day': this.reserveDay,
            'reserve_available_time': this.reserveTime,
            'reserve_same_day': this.reserveSameDay,
            'holidays_rest': this.holidayStatus,
            'working_plan': JSON.stringify(this.$refs.wp.dates())
          }
        }

        // 画像がURLかどうか判別して追加
        if(this.imageShop === '' || this.imageShop === null){
          condition.shop.image = ''
        } else if (this.imageShop.indexOf('/uploads/') == -1) {
          condition.shop.image = this.imageShop
        }

        this.axios({
          method  : this.newFlag === true ? 'POST' : 'PUT',
          url     : this.newFlag === true ? this.AdminApiUrl + '/shops/' : this.AdminApiUrl + '/shops/' + this.$route.params.id,
          timeout : 30000,
          data: qs.stringify(condition),
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          sessionStorage.removeItem('shopSelect')
          this.successAndBackPage(`店舗情報を`+this.buttonWord+`しました`,'/admin/shop_list/')
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify(`店舗情報を正しく`+this.buttonWord+`できませんでした`)
          }
        })
      },
      getCompany () {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/companies/select_list',
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          if (response.data.length === 0) {
            this.successAndBackPage(`選択できる企業がありませんでした`, '')
          } else {
            this.createList(response.data)
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('企業情報を正しく取得できませんでした')
          }
        })
      },
      createList (obj) {
        this.enterpriseList.splice(0)
        for (var i = 0;i < obj.length; i++) {
          var item = {
            id: obj[i].id,
            name: obj[i].name,
          }
          this.enterpriseList.push(item)
        }
      },
      resetData () {
        this.id = ''
        this.companyName = ''
        this.enterpriseName = ''
        this.companyCode = ''
        this.zipCode1 = ''
        this.zipCode2 = ''
        this.prefecture = ''
        this.city = ''
        this.street1 = ''
        this.street2 = ''
        this.tel1 = ''
        this.tel2 = ''
        this.tel3 = ''
        this.status = ''
        this.openStatus = 'open'
        this.maxPrice = ''
        this.maxPricePerDay = ''
        this.maxPricePerMonth = ''
        this.imageShop = ''
        this.webUrl = ''
        this.recieveTime = ''
        this.cancelTime = ''
        this.reserveCap = ''
        this.reserveDay = ''
        this.reserveTime = ''
        this.reserveSameDay = ''
        this.holidayStatus = false
        this.shopDetail = ''
        this.$validator.reset()
        setTimeout (times =>{
          for (var key in this.touched) {
            this.touched[key] = false
          }
        },100)
      }
    },
    mounted () {
      // role check
      if (this.shopFlag) {
        router.push({ path: '/admin/login'})
        return false
      } else if (this.superFlag)  {
        this.getCompany()
      }

      var url = location.href
      url = url.split('/')
      if(url[url.length - 1] === 'new') {
        this.newFlag = true
      } else {
        this.buttonWord = '更新'
        this.getShop()
      }
    },
    watch: {
      '$route.path'(value){
        this.getCompany()
        if (value.match('new')) {
          this.newFlag = true
          this.buttonWord = '登録'
          this.resetData()
        } else {
          this.newFlag = false
          this.buttonWord = '更新'
          this.getShop()
        }
      },
      companyName(){
        this.touched.companyName = true
      },
      enterpriseName(){
        this.touched.enterpriseName = true
      },
      prefecture(){
        this.touched.prefecture = true
      },
      city(){
        this.touched.city = true
      },
      street1(){
        this.touched.street1 = true
      },
      street2(){
        this.touched.street2 = true
      },
      zipCode1(){
        this.touched.zipCode1 = true
      },
      zipCode2(){
        this.touched.zipCode2 = true
      },
      tel1(){
        this.touched.tel1 = true
      },
      tel2(){
        this.touched.tel2 = true
      },
      tel3(){
        this.touched.tel3 = true
      },
      status(){
        this.touched.status = true
      },
      openStatus(){
        this.touched.openStatus = true
      },
      maxPrice(){
        this.touched.maxPrice = true
      },
      maxPricePerDay(){
        this.touched.maxPricePerDay = true
      },
      maxPricePerMonth(){
        this.touched.maxPricePerMonth = true
      },
      webUrl(){
        this.touched.webUrl = true
      },
      recieveTime(){
        this.touched.recieveTime = true
      },
      reserveCap(){
        this.touched.reserveCap = true
      },
      reserveDay(){
        this.touched.reserveDay = true
      },
      reserveTime(){
        this.touched.reserveTime = true
      },
      cancelTime(){
        this.touched.cancelTime = true
      },
      webUrl(){
        this.touched.webUrl = true
      },
    }
  }
</script>

<style lang="css" scoped>
.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.md-size-2{
  width: 2%;
  min-width: 2%;
  max-width: 2%;
}

.md-aSize-100{
  width: 100px;
  min-width: 100px;
  max-width: 100px;
}

.md-aSize-120{
  width: 120px;
  min-width: 120px;
  max-width: 120px;
}

.btnCenter{
  margin-left: auto !important;
  margin-right: auto !important;
}

.mt60{
  margin-top: 60px;
}
.zipBetween{
  padding: 10px 0 0 0;
  text-align: center;
  line-height: 46px;
}

.unitLabel{
  padding: 10px 0 0 0;
  line-height: 46px;
}

.weekLabel{
  font-weight: bold !important;
  text-align: left !important;
}

.timeLabel{
  font-weight: normal !important;
  text-align: left !important;
  padding: 20px 5px 0 0;
  font-size: 12px;
  color: #666666;
}

.weekLabelSelect{
  font-weight: bold !important;
  text-align: left !important;
  padding-top: 0 !important;
}
.is-red{
  color: #f44336 !important;
}

.is-restRed{
  color: #d9333f !important;
}

.is-blue{
  color: #4496d3 !important;
}

.addBtn{
  margin-top: 20px;
}

.addLabel{
  display: inline-block;
  line-height: 41px;
  margin: 5px 0;
}

.pt5{
  padding-top: 5px;
}

.mt-20{
  margin-top: 20px;
}

.ml-5{
  margin-left: 5px;
}

.mb-20{
  margin-bottom: 20px;
}

.pt20{
  padding-top: 20px;
}

.text-center{
  text-align: center;
}

.is-l-none{
  display: none;
}

.error{
  color: #f44336 !important;
}

.success{
  color: #4caf50 !important
}

.md-input{
  width: 100%;
}

.emptyError{
  color: #f44336 !important;
  font-size: 12px;
  position: absolute;
  top: 0;
  left: 0;
}

.is-xs-block{
  display: none;
}

@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }

  .is-xs-none{
    display: none;
  }

  .is-xs-block{
    display: block;
  }

  .timeLabel{
    line-height: 14px;
  }

  .weekLabel{
    margin-left: -5px !important;
  }
}

</style>
../entrypoints/router.js