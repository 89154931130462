
<div class="md-layout">
  <div class="md-layout-item md-size-100">
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-green">
        <div class="card-icon">
          <md-icon>search</md-icon>
        </div>
        <h4 class="title">期間別売り上げレポート</h4>
      </md-card-header>

      <md-card-content class="md-layout formLayout">
        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            集計期間
          </label>
          <div class="md-layout-item md-size-20 md-xsmall-size-100" style="position:relative">
            <vuejs-datepicker
              :format="DatePickerFormat"
              :highlighted="highlighted"
              v-model="selectedStart"
              :language="ja"></vuejs-datepicker>
            <md-button
              class="md-just-icon md-default md-simple dateDelete"
              @click.native="selectedStart = ''">
              <md-icon>close</md-icon>
            </md-button>
          </div>
          <div class="md-layout-item md-size-5 betweenTime is-xs-none"> 〜 </div>
          <div class="md-layout-item md-size-20 md-xsmall-size-100" style="position:relative">
            <vuejs-datepicker
              :format="DatePickerFormat"
              :highlighted="highlighted"
              v-model="selectedEnd"
              :language="ja"></vuejs-datepicker>
            <md-button
              class="md-just-icon md-default md-simple dateDelete"
              @click.native="selectedEnd = ''">
              <md-icon>close</md-icon>
            </md-button>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
          <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
            期間指定
          </label>
          <div class="md-layout-item md-xsmall-size-100">
            <md-field class="UserStatus">
              <md-select v-model="periodType" name="status">
                <!-- <md-option value="選択しない">選択しない</md-option> -->
                <md-option value="day">日単位</md-option>
                <md-option value="month">月単位</md-option>
                <md-option value="week_day">週単位</md-option>
                <md-option value="hour">時間単位</md-option>
              </md-select>
            </md-field>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
          <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
            注文タイプ
          </label>
          <div class="md-layout-item md-xsmall-size-100">
            <md-field class="UserStatus">
              <md-select v-model="orderType" name="status">
                <md-option value="選択しない">選択しない</md-option>
                <md-option value="ReserveOrder">事前予約</md-option>
                <md-option value="PreOrder">事前決済</md-option>
                <md-option value="ShopOrder">店頭QR決済</md-option>
              </md-select>
            </md-field>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
          <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
            集計タイプ
          </label>
          <div class="md-layout-item md-xsmall-size-100">
            <md-field class="UserStatus">
              <md-select v-model="queryType" name="status">
                <md-option value="選択しない">選択しない</md-option>
                <md-option value="shop">店舗別</md-option>
              </md-select>
            </md-field>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
          <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
            店舗名
          </label>
          <div class="md-layout-item md-xsmall-size-100">
            <shop-select v-model="shopId"></shop-select>
          </div>
        </div>

      </md-card-content>
      <md-card-actions md-alignment="left">
        <md-button class="md-success md-lg btnCenter" @click="validatePeriod">集計</md-button>
      </md-card-actions>
    </md-card>
  </div>

  <transition name="fade">
    <div class="md-layout-item md-size-100" v-if="resultFlag">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-blue">
          <div class="card-icon">
            <md-icon>timeline</md-icon>
          </div>
          <h4 class="title">集計結果</h4>
        </md-card-header>
        <md-card-content>
          <LineChart :data="graphData.chartData" />
          <div class="chartLegend" v-if="orderType === '選択しない'">
            <div class="legends orderAverage">事前決済：平均金額</div>
            <div class="legends orderTotal">事前決済：合計金額</div>
            <div class="legends reserveAverage">事前予約：平均金額</div>
            <div class="legends reserveTotal">事前予約：合計金額</div>
            <div class="legends qrAverage">店頭QR決済：平均金額</div>
            <div class="legends qrTotal">店頭QR決済：合計金額</div>
          </div>
          <div class="chartLegend" v-else>
            <div class="legends average">平均金額</div>
            <div class="legends total">合計金額</div>
          </div>
        </md-card-content>
      </md-card>
    </div>
  </transition>

  <transition name="fade">
    <div class="md-layout-item md-size-100" v-if="resultFlag">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">集計結果</h4>
        </md-card-header>
        <md-card-content>
          <div class="dataTableWrapper">
            <div class="dataTable"> 
              <div class="tHead">
                <div class="tHead_th">期間</div>
                <div class="tHead_th">注文タイプ</div>
                <div class="tHead_th" v-show="shopDisplayFlag">店舗名</div>
                <div class="tHead_th taR">注文数</div>
                <div class="tHead_th taR">平均注文額</div>
                <div class="tHead_th taR">注文合計金額</div>
              </div>
              <div v-for="item in tableData" :data-id="item.id" class="tItem">
                <div class="tItem_td" :class="item.colorClass">{{ item.date }}</div>
                <div class="tItem_td">{{ item.type }}</div>
                <div class="tItem_td" v-show="shopDisplayFlag">{{ item.shop }}</div>
                <div class="tItem_td taR">{{ item.orderNum }}件</div>
                <div class="tItem_td taR boldCell">¥{{ item.averagePrice | fewWithDelimiter}}</div>
                <div class="tItem_td taR boldCell">¥{{ item.totalPrice | numberWithDelimiter}}</div>
              </div>
            </div>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">全{{ pageData.totalCount | numberWithDelimiter }}件中 {{from | numberWithDelimiter}}件から {{to | numberWithDelimiter}}件を表示</p>
          </div>
          <Pagination class="Pagination-no-border Pagination-success"
            :currentPage="pageData.currentPage"
            :totalPage="pageData.totalPage"
            @get="analysisTrigger">
          </Pagination>
        </md-card-actions>
      </md-card>
    </div>
  </transition>
</div>

