
<div class="md-layout">
  <div class="md-layout-item md-size-100">
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-green">
        <div class="card-icon">
          <md-icon>search</md-icon>
        </div>
        <h4 class="title">会員履歴検索</h4>
      </md-card-header>

      <md-card-content class="md-layout formLayout">

        <div class="md-layout md-layout-item md-size-50" v-if="opt_adminUser">
          <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
            姓
          </label>
          <div class="md-layout-item md-xsmall-size-100">
            <md-field>
              <md-input v-model="lastName"></md-input>
            </md-field>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-50" v-if="opt_adminUser">
          <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
            名
          </label>
          <div class="md-layout-item md-xsmall-size-100">
            <md-field>
              <md-input v-model="firstName"></md-input>
            </md-field>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
          <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
            電話番号
          </label>
          <div class="md-layout-item md-xsmall-size-100">
            <md-field>
              <md-input v-model="tel"></md-input>
            </md-field>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
          <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
            店舗名
          </label>
          <div class="md-layout-item md-xsmall-size-100">
            <shop-select v-model="shopId" :noSelectFlag="true"></shop-select>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            入会日
          </label>
          <div class="md-layout-item md-size-20 md-xsmall-size-100" style="position:relative">
            <vuejs-datepicker
              :format="DatePickerFormat"
              :highlighted="highlighted"
              v-model="selectedStart"
              :language="ja"></vuejs-datepicker>
            <md-button
              class="md-just-icon md-default md-simple dateDelete"
              @click.native="selectedStart = ''">
              <md-icon>close</md-icon>
            </md-button>
          </div>
          <div class="md-layout-item md-size-5 betweenTime is-xs-none"> 〜 </div>
          <div class="md-layout-item md-size-20 md-xsmall-size-100" style="position:relative">
            <vuejs-datepicker
              :format="DatePickerFormat"
              :highlighted="highlighted"
              v-model="selectedEnd"
              :language="ja"></vuejs-datepicker>
            <md-button
              class="md-just-icon md-default md-simple dateDelete"
              @click.native="selectedEnd = ''">
              <md-icon>close</md-icon>
            </md-button>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
          <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
            会員ステータス
          </label>
          <div class="md-layout-item md-xsmall-size-100">
            <md-field class="UserStatus">
              <md-select v-model="status" name="status">
                <md-option value="not_approve">無効</md-option>
                <md-option value="approved">有効</md-option>
                <md-option value="選択しない">選択しない</md-option>
              </md-select>
            </md-field>
          </div>
        </div>

      </md-card-content>
      <md-card-actions class="mx-auto">
        <md-button class="md-success md-lg" @click="getUsers()">検索</md-button>
        <md-button class="md-info md-lg mL40" @click="handleClear">検索条件クリア</md-button>
      </md-card-actions>
    </md-card>
  </div>
  <transition name="fade">
    <div class="md-layout-item md-size-100" v-if="resultFlag">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">会員一覧</h4>
        </md-card-header>
        <md-card-content>
          <div class="dataTableWrapper">
            <div class="dataTable"> 
              <div class="tHead">
                <div class="tHead_th">氏名</div>
                <div class="tHead_th">E-mail</div>
                <div class="tHead_th">ステータス</div>
                <div class="tHead_th taR">アクション</div>
              </div>
              <div v-for="item in tableData" :data-id="item.id" class="tItem">
                <div v-if="opt_adminUser" class="tItem_td commentBody"><md-label style="word-break:break-all;">{{ item.name }}</md-label></div>
                <div v-else class="tItem_td"><md-label style="word-break:break-all;">{{ plain }}</md-label></div> 
                <div class="tItem_td commentBody">{{item.mail}}</div>
                <div class="tItem_td">{{item.status}}</div>
                <div class="tItem_td taR td_actionTw">
                  <drop-down direction="up" class="dropDown">
                    <md-button slot="title" class="md-just-icon md-warning mg0 mgR2 dropdown-toggle" data-toggle="dropdown">
                      <md-icon>dvr</md-icon>
                    </md-button>
                    <ul class="dropdown-menu dropdown-menu-right">
                      <li><a @click="jumpCharge(item.id)">チャージ履歴</a></li>
                      <li><a @click="jumpPayment(item.id)">支払い履歴</a></li>
                    </ul>
                  </drop-down>
                </div>
              </div>
            </div>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">全{{ pageData.totalCount | numberWithDelimiter }}件中 {{from | numberWithDelimiter}}件から {{to | numberWithDelimiter}}件を表示</p>
          </div>
          <Pagination class="Pagination-no-border Pagination-success"
            :currentPage="pageData.currentPage"
            :totalPage="pageData.totalPage"
            @get="getUsers">
          </Pagination>
        </md-card-actions>
      </md-card>
    </div>
  </transition>
</div>

