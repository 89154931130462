<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">お知らせ基本情報設定</h4>
        </md-card-header>

        <md-card-content>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              表示順
            </label>
            <div class="md-layout-item">
              <md-field style="width: 50px" :class="[
                {'md-error': errors.has('number')},
                {'md-valid': !errors.has('number') && touched.number}]">
                <md-input
                  v-model="number"
                  data-vv-name="number"
                  type="text"
                  v-validate= "modelValidations.number"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('number')">【必須】3桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('number')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('number') && touched.number">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              お知らせ表示日付
            </label>
            <div class="md-layout-item md-size-30 md-xsmall-size-100" style="position:relative">
              <vuejs-datepicker
                :format="DatePickerFormat"
                :highlighted="highlighted"
                v-model="displayDate"
                :language="ja"
                data-vv-name="displayDate"
                v-validate= "modelValidations.displayDate"
                ></vuejs-datepicker>
              <md-button
                class="md-just-icon md-default md-simple dateDelete"
                @click.native="displayDate = ''">
                <md-icon>close</md-icon>
              </md-button>
              <slide-y-up-transition>
                <div class="vErrorText" style="margin-left: 15px" v-show="errors.has('displayDate')">【必須】日付を選択してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error dateError" v-show="errors.has('displayDate')">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success dateSuccess" v-show="!errors.has('displayDate') && touched.displayDate">done</md-icon>
              </slide-y-down-transition>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              お知らせタイトル
            </label>
            <div class="md-layout-item">
              <md-field :class="[
                {'md-error': errors.has('informationTitle')},
                {'md-valid': !errors.has('informationTitle') && touched.informationTitle}]">
                <md-input
                  v-model="informationTitle"
                  data-vv-name="informationTitle"
                  type="text"
                  v-validate= "modelValidations.informationTitle"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('informationTitle')">【必須】255文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('informationTitle')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('informationTitle') && touched.informationTitle">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              お知らせ内容
            </label>
            <div class="md-layout-item pt20">
              <froala :tag="'textarea'" :config="config" v-model="informationDetail"></froala>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              店舗名
            </label>
            <div class="md-layout-item md-size-40  md-xsmall-size-100">
              <shop-select v-model="shopId" :noSelectFlag="true" :touchedFlag="true"></shop-select>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              会員ランク
            </label>
            <div class="md-layout-item">
              <user-rank-select v-model="userRankId" :avoidValidFlag="true" :touchedFlag="true" :mutlipleFlag="true"></user-rank-select>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              ステータス
            </label>
            <div class="md-layout-item md-size-20 md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('status')},
                {'md-valid': !errors.has('status') && touched.status}]">
                <md-select
                  v-model="status"
                  data-vv-name="status"
                  v-validate= "modelValidations.status"
                  required>
                  <md-option value="active">有効</md-option>
                  <md-option value="not_active">無効</md-option>
                </md-select>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('status')">【必須】必ず一つ選択してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('status')" style="margin-right:20px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('status') && touched.status" style="margin-right:20px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

        </md-card-content>
      </md-card>
    </div>

    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>settings</md-icon>
          </div>
          <h4 class="title">お知らせ公開期間設定</h4>
        </md-card-header>

        <md-card-content>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              公開開始日
            </label>
            <div class="md-layout-item md-size-30 md-xsmall-size-100" style="position:relative">
              <vuejs-datepicker
                :format="DatePickerFormat"
                :highlighted="highlighted"
                v-model="selectedStart"
                :language="ja"
                data-vv-name="selectedStart"
                v-validate= "modelValidations.selectedStart"
                ></vuejs-datepicker>
              <md-button
                class="md-just-icon md-default md-simple dateDelete"
                @click.native="selectedStart = ''">
                <md-icon>close</md-icon>
              </md-button>
              <slide-y-up-transition>
                <div class="vErrorText" style="margin-left: 15px" v-show="errors.has('selectedStart')">【必須】日付を選択してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error dateError" v-show="errors.has('selectedStart')">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success dateSuccess" v-show="!errors.has('selectedStart') && touched.selectedStart">done</md-icon>
              </slide-y-down-transition>
            </div>
            <time-select class="md-layout-item md-xsmall-size-100" ref="startTimer" v-model="startTime"></time-select>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              公開終了日
            </label>
            <div class="md-layout-item md-size-30 md-xsmall-size-100" style="position:relative">
              <vuejs-datepicker
                :format="DatePickerFormat"
                :highlighted="highlighted"
                v-model="selectedEnd"
                :language="ja"
                data-vv-name="selectedEnd"
                v-validate= "modelValidations.selectedEnd"
                ></vuejs-datepicker>
              <md-button
                class="md-just-icon md-default md-simple dateDelete"
                @click.native="selectedEnd = ''">
                <md-icon>close</md-icon>
              </md-button>
              <slide-y-up-transition>
                <div class="vErrorText" style="margin-left: 15px" v-show="errors.has('selectedEnd')">【必須】日付を選択してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error dateError" v-show="errors.has('selectedEnd')">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success dateSuccess" v-show="!errors.has('selectedEnd') && touched.selectedEnd">done</md-icon>
              </slide-y-down-transition>
            </div>
            <time-select class="md-layout-item md-xsmall-size-100" ref="endTimer" v-model="endTime"></time-select>
          </div>
        </md-card-content>
      </md-card>
    </div>


    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>settings</md-icon>
          </div>
          <h4 class="title">お知らせ画像設定</h4>
        </md-card-header>

        <md-card-content>
          <div class="md-layout md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              イメージ画像
            </label>
            <div class="md-layout-item  md-size-30 md-xsmall-size-100">
              <div class="file-input">
                <div v-if="!imageInfo">
                  <div class="image-container">
                    <img :src="dummyImg" title="">
                  </div>
                </div>
                <div class="image-container" v-else>
                  <img :src="imageInfo" />
                </div>
                <div class="button-container">
                  <md-button class="md-danger md-round" @click="removeImage" v-if="imageInfo" data-id="imageInfo"><i class="fa fa-times"></i>削除</md-button>
                  <md-button class="md-success md-round md-fileinput">
                    <template v-if="!imageInfo">画像選択</template>
                    <template v-else>変更</template>
                    <input type="file" @change="onFileChange" data-id="imageInfo">
                  </md-button>
                </div>
              </div>
            </div>
          </div>

        </md-card-content>
        <md-card-actions md-alignment="left" class="mt60 mx-auto">
          <md-button class="md-warning md-lg" @click.native.prevent="returnBack">戻る</md-button>
          <submit-button class="md-success md-lg mL40" :onclick="validate" :buttonWord=this.buttonWord type="submit"></submit-button>
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>

<script>
  import ShopSelect from '../components/additional/ShopSelect.vue'
  import router from '../entrypoints/router.js'
  import TimeSelect from '../components/additional/TimeSelect.vue'
  import qs from 'qs'
  import EventBus from '../mixins/event-bus.js';
  import role from "../mixins/role.js"
  import FroalaImageUpload from "../mixins/froala-image-upload.js"
  import SubmitButton from '../components/additional/SubmitButton.vue'
  import UserRankSelect from '../components/additional/UserRankSelect.vue'

  export default {
    mixins: [ role, FroalaImageUpload ],
    components: {
      SubmitButton,
      ShopSelect,
      UserRankSelect,
      TimeSelect
    },
    computed: {
    },
    props: {
      dummyImg: {
        type: String,
        default: '/images/image_placeholder.jpg'
      }
    },
    data () {
      return {
        id: '',
        buttonWord: '登録',
        number: null,
        informationTitle: '',
        shopId: '',
        userRankId: [],
        imageInfo: '',
        informationDetail: '',
        displayDate: '',
        selectedStart: '',
        selectedEnd: '',
        startTime: '',
        endTime: '',
        status: '',
        newFlag: false,
        listData: [],
        touched: {
          number: false,
          status: false,
          informationTitle: false,
          informationCode: false,
          displayDate: false,
          selectedStart: false,
          selectedEnd: false
        },
        modelValidations: {
          number: {
            required: true,
            numeric: true,
            max: 3
          },
          informationTitle: {
            required: true,
            max: 255
          },
          informationDetail: {
            required: true,
            max: 255
          },
          informationCode: {
            required: true,
            max: 255
          },
          shopId: {
            required: true
          },
          status: {
            required: true
          },
          displayDate: {
            required: true
          },
          selectedStart: {
            required: true
          },
          selectedEnd: {
            required: true
          }
        }
      }
    },
    methods: {
      returnBack () {
        router.push({ path: '/admin/information_list/' })
      },
      getInformation () {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/informations/' + this.$route.params.id,
          timeout : 30000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.number = response.data.position
          this.status = response.data.status
          this.informationTitle = response.data.title
          this.informationDetail = response.data.content
          this.imageInfo = response.data.image_url
          response.data.shop_id === null ? this.shopId='選択しない' :  this.shopId = response.data.shop_id
          response.data.user_rank_ids === null ? this.userRankId= [] : this.userRankId = response.data.user_rank_ids
          this.displayDate = response.data.date
          if (response.data.public_from !== null) {
            this.selectedStart = this.dateDivider(response.data.public_from)[0]
            this.startTime = this.dateDivider(response.data.public_from)[1]
            this.$refs.startTimer.unlock()
          }
          if (response.data.public_to !== null) {
            this.selectedEnd = this.dateDivider(response.data.public_to)[0]
            this.endTime = this.dateDivider(response.data.public_to)[1]
            this.$refs.endTimer.unlock()
          }
          
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('お知らせ情報を正しく取得できませんでした')
          }
        })
      },
      onFileChange(e) {
        let files = e.target.files || e.dataTransfer.files;
        let ids = e.currentTarget.getAttribute('data-id');
        if (!files.length)
        return;
        this.createImage(files[0],ids);
      },
      createImage(file,fileId) {
        let reader = new FileReader();
        let $this = this;

        reader.onload = (e) => {
          $this[fileId] = e.target.result;
        };
        reader.readAsDataURL(file);
      },
      removeImage: function (e) {
        let ids = e.currentTarget.getAttribute('data-id');
        this[ids]= '';
      },
      validate() {
        return new Promise((resolve) => {
          setTimeout(() => {
            EventBus.$emit('validate', this.errors)
            this.$validator.validateAll().then(isValid => {
              if (this.errors.items.length == 0) {
                this.updateinformation()
              } else {
                this.validateError()
              }
            })
            resolve();
          }, this.ResolveTime);
        });
      },
      updateinformation(){
        var condition = {
          information: {
            date: this.initDate(this.displayDate),
            position: this.number,
            title: this.informationTitle,
            content: this.informationDetail,
            status:this.status,
            public_from: this.initDate(this.selectedStart) + ' ' + this.startTime,
            public_to: this.initDate(this.selectedEnd) + ' ' + this.endTime,
            shop_id: this.shopId === '選択しない' ? '' : this.shopId,
            user_rank_ids: this.userRankId === '選択しない' ? '' : this.userRankId.join(',')
          }
        }

        // 画像がURLかどうか判別して追加
        if(this.imageInfo === '' || this.imageInfo === null){
          condition.information.image = ''
        } else if (this.imageInfo.indexOf('/uploads/') == -1) {
          condition.information.image = this.imageInfo
        }

        this.axios({
          method  : this.newFlag === true ? 'POST' : 'PUT',
          url     : this.newFlag === true ? this.AdminApiUrl + '/informations/' : this.AdminApiUrl + '/informations/' + this.$route.params.id,
          timeout : 30000,
          data: qs.stringify(condition),
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.successAndBackPage(`お知らせ情報を`+this.buttonWord+`しました`,'/admin/information_list/')
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify(`お知らせ情報を正しく`+this.buttonWord+`できませんでした`)
          }
        })
      },
      dateDivider (dates) {
        var dateArray = dates.split('T')
        return dateArray
      },
      resetData () {
        this.number = ''
        this.status = ''
        this.informationCode = ''
        this.informationTitle = ''
        this.imageInfo = ''
        this.shopId = '選択しない'
        this.userRankId = []
        this.selectedStart = null
        this.displayDate = ''
        this.informationDetail = ''
        this.startTime = '00:00'
        this.$refs.startTimer.lock()
        this.selectedEnd = null
        this.endTime = '00:00'
        this.$refs.endTimer.lock()

        this.$validator.reset()
        setTimeout (times =>{
          for (var key in this.touched) {
            this.touched[key] = false
          }
        },100)
      }
    },
    mounted () {
      if (this.shopFlag) {
        router.push({ path: '/admin/login'})
        return false
      }

      var url = location.href
      url = url.split('/')
      if(url[url.length - 1] === 'new') {
        this.newFlag = true
      } else {
        this.buttonWord = '更新'
        this.getInformation()
      }
    },
    watch: {
      '$route.path'(value){
        if (value.match('new')) {
          this.newFlag = true
          this.buttonWord = '登録'
          this.resetData()
        } else {
          this.newFlag = false
          this.buttonWord = '更新'
          this.getInformation()
        }
      },
      number () {
        this.touched.number = true
      },
      status () {
        this.touched.status = true
      },
      displayDate () {
        this.touched.displayDate = true
      },
      informationTitle(){
        this.touched.informationTitle = true
      },
      informationCode(){
        this.touched.informationCode = true
      },
      selectedStart () {
        this.$refs.startTimer.unlock()
      },
      selectedEnd () {
        this.$refs.endTimer.unlock()
      }
    }
  }
</script>

<style lang="css" scoped>
.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.md-size-2{
  width: 2%;
  min-width: 2%;
  max-width: 2%;
}

.md-aSize-100{
  width: 100px;
  min-width: 100px;
  max-width: 100px;
}

.md-aSize-120{
  width: 120px;
  min-width: 120px;
  max-width: 120px;
}

.btnCenter{
  margin-left: auto !important;
  margin-right: auto !important;
}

.mt60{
  margin-top: 60px;
}
.zipBetween{
  padding: 10px 0 0 0;
  text-align: center;
  line-height: 46px;
}

.unitLabel{
  padding: 10px 0 0 0;
  line-height: 46px;
}

.weekLabel{
  font-weight: bold !important;
  text-align: left !important;
}

.timeLabel{
  font-weight: normal !important;
  text-align: left !important;
  padding: 20px 5px 0 0;
  font-size: 12px;
  color: #666666;
}

.weekLabelSelect{
  font-weight: bold !important;
  text-align: left !important;
  padding-top: 0 !important;
}
.is-red{
  color: #f44336 !important;
}

.is-restRed{
  color: #d9333f !important;
}

.is-blue{
  color: #4496d3 !important;
}

.addBtn{
  margin-top: 20px;
}

.addLabel{
  display: inline-block;
  line-height: 41px;
  margin: 5px 0;
}

.pt5{
  padding-top: 5px;
}

.mt-20{
  margin-top: 20px;
}

.pt20{
  padding-top: 20px;
}

.text-center{
  text-align: center;
}

.is-l-none{
  display: none;
}

.error{
  color: #f44336 !important;
}

.success{
  color: #4caf50 !important
}

.md-input{
  width: 100%;
}

.productStatus{
  max-width: 120px;
  width: 100%;
  display: inline-block;
}

.emptyError{
  color: #f44336 !important;
  font-size: 12px;
  position: absolute;
  top: 0;
  left: 0;
}

.is-xs-block{
  display: none;
}

@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }

  .is-xs-none{
    display: none;
  }

  .is-xs-block{
    display: block;
  }

  .timeLabel{
    line-height: 14px;
  }

  .weekLabel{
    margin-left: -5px !important;
  }
}

</style>
../entrypoints/router.js