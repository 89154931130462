
<div class="md-layout">
  <div class="md-layout-item md-size-100">
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-green">
        <div class="card-icon">
          <md-icon>search</md-icon>
        </div>
        <h4 class="title">事前予約検索</h4>
      </md-card-header>

      <md-card-content class="md-layout formLayout">
        <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
          <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
            注文番号
          </label>
          <div class="md-layout-item md-xsmall-size-100">
            <md-field>
              <md-input v-model="number"></md-input>
            </md-field>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
          <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
            電話番号
          </label>
          <div class="md-layout-item md-xsmall-size-100">
            <md-field>
              <md-input v-model="tel"></md-input>
            </md-field>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
          <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
            店舗名
          </label>
          <div class="md-layout-item md-xsmall-size-100">
            <shop-select v-model="shopId" :noSelectFlag="true"></shop-select>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100" v-if="opt_adminUser">
          <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
            担当
          </label>
          <div class="md-layout-item md-xsmall-size-100">
            <md-field>
              <md-input v-model="staffName"></md-input>
            </md-field>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
          <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
            注文ステータス
          </label>
          <div class="md-layout-item md-xsmall-size-100">
            <md-field class="UserStatus">
              <md-select v-model="status" name="status">
                <md-option value="選択しない">選択しない</md-option>
                <md-option value="accept">予約中</md-option>
                <md-option value="recieved">実施済み</md-option>
                <md-option value="cancel">キャンセル</md-option>
              </md-select>
            </md-field>
          </div>
        </div>
        <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
          <label class="md-layout-item md-size-30 md-xsmall-size-100 md-form-label">
            予約日
          </label>
          <div class="md-layout-item" style="position:relative">
            <vuejs-datepicker
              :format="DatePickerFormat"
              :highlighted="highlighted"
              v-model="reserveDay"
              :language="ja"></vuejs-datepicker>
            <md-button
              class="md-just-icon md-default md-simple dateDelete"
              @click.native="reserveDay = ''">
              <md-icon>close</md-icon>
            </md-button>
          </div>
        </div>
        <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
          <label class="md-layout-item md-size-30 md-xsmall-size-100 md-form-label">
            サービス実施日
          </label>
          <div class="md-layout-item" style="position:relative">
            <vuejs-datepicker
              :format="DatePickerFormat"
              :highlighted="highlighted"
              v-model="treatmentDay"
              :language="ja"></vuejs-datepicker>
            <md-button
              class="md-just-icon md-default md-simple dateDelete"
              @click.native="treatmentDay = ''">
              <md-icon>close</md-icon>
            </md-button>
          </div>
        </div>
      </md-card-content>
      <md-card-actions class="mx-auto">
        <md-button class="md-success md-lg" @click="getOrders()">検索</md-button>
        <md-button class="md-info md-lg mL40" @click="handleClear">検索条件クリア</md-button>
      </md-card-actions>
    </md-card>
  </div>
  <transition name="fade">
    <div class="md-layout-item md-size-100" v-if="resultFlag">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">予約一覧</h4>
        </md-card-header>
        <md-card-content>
          <div class="dataTableWrapper">
            <div class="dataTable"> 
              <div class="tHead">
                <div class="tHead_th">注文番号</div>
                <div class="tHead_th">ステータス</div>
                <div class="tHead_th">商品名</div>
                <div class="tHead_th">お客様名</div>
                <div class="tHead_th">電話番号</div>
                <div class="tHead_th">担当</div>
                <div class="tHead_th">サービス開始時刻</div>
                <div class="tHead_th">サービス終了時刻</div>
                <div class="tHead_th taR">金額</div>
                <div class="tHead_th taR">アクション</div>
              </div>
              <div v-for="item in tableData" :data-id="item.id" class="tItem">
                <div class="tItem_td">{{ item.orderNumber }}</div>
                <div class="tItem_td" v-if="item.requestStatus === 'cancel'">
                  <div value="cancel">キャンセル</div>
                </div>
                <div class="tItem_td" v-else-if="item.requestStatus === 'recieved'">
                  <md-field class="wth100">
                    <md-select v-model="item.status">
                      <md-option value="recieved">実施済み</md-option>
                      <md-option value="cancel">キャンセル</md-option>
                    </md-select>
                  </md-field>
                </div>
                <div class="tItem_td" v-else>
                  <md-field class="wth100">
                    <md-select v-model="item.status">
                      <md-option value="accept">予約中</md-option>
                      <md-option value="recieved">実施済み</md-option>
                      <md-option value="cancel">キャンセル</md-option>
                    </md-select>
                  </md-field>
                </div>
                <div class="tItem_td commentBody"><md-label style="word-break:break-all;">{{ item.courseName }}</md-label></div>
                <div v-if="opt_adminUser" class="tItem_td commentBody"><md-label style="word-break:break-all;">{{ item.name }}</md-label></div>
                <div v-else class="tItem_td"><md-label style="word-break:break-all;">{{ plain }}</md-label></div>                           
                <div class="tHead_th">{{item.tel}}</div>
                <div v-if="opt_adminUser" class="tItem_td commentBody"><md-label style="word-break:break-all;">{{ item.staffName }}</md-label></div>
                <div v-else class="tItem_td"><md-label style="word-break:break-all;">{{ plain }}</md-label></div>                           
                <div class="tItem_td">{{item.startDay | timeFormat2}}</div>
                <div class="tItem_td">{{item.endDay | timeFormat2}}</div>
                <div class="tItem_td taR boldCell">¥{{ item.price | numberWithDelimiter }}</div>
                <div class="tItem_td taR td_actionTw">
                  <md-button
                    class="md-just-icon md-success mg2"
                    @click.native="updateStatus(item)">
                    <md-icon>autorenew</md-icon>
                  </md-button>
                  <md-button
                    class="md-just-icon md-warning mg2"
                    @click.native="handleEdit(item.id)">
                    <md-icon>dvr</md-icon>
                  </md-button>
                </div>
              </div>
            </div>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">全{{ pageData.totalCount | numberWithDelimiter }}件中 {{from}}件から {{to}}件を表示</p>
          </div>
          <Pagination class="Pagination-no-border Pagination-success"
            :currentPage="pageData.currentPage"
            :totalPage="pageData.totalPage"
            @get="getOrders">
          </Pagination>
        </md-card-actions>
      </md-card>
    </div>
  </transition>
</div>

