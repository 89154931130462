
<div class="md-layout">
  <div class="md-layout-item md-size-100">
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-green">
        <div class="card-icon">
          <md-icon>create</md-icon>
        </div>
        <h4 class="title">企業サービス情報</h4>
      </md-card-header>
      <md-card-content class="md-layout formLayout">
        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            サービス名
          </label>
          <div class="md-layout-item">
            <md-field :class="[
              {'md-error': errors.has('serviceName')},
              {'md-valid': !errors.has('serviceName') && touched.serviceName}]">
              <md-input
                v-model="serviceName"
                data-vv-name="serviceName"
                type="text"
                v-validate= "modelValidations.serviceName">
              </md-input>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('serviceName')">255文字以内で入力してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('serviceName')">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('serviceName') && touched.serviceName">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>
        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            アプリURL
          </label>
          <div class="md-layout-item">
            <md-field :class="[
              {'md-error': errors.has('apiUrl')},
              {'md-valid': !errors.has('apiUrl') && touched.apiUrl}]">
              <md-input
                v-model="apiUrl"
                data-vv-name="apiUrl"
                type="text"
                v-validate= "modelValidations.apiUrl">
              </md-input>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('apiUrl')">半角英数記号のURL形式で入力してください（255文字以内）</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('apiUrl')">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('apiUrl') && touched.apiUrl">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>
        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            アナリティクストラッキングID
          </label>
          <div class="md-layout-item">
            <md-field :class="[
              {'md-error': errors.has('trackingId')},
              {'md-valid': !errors.has('trackingId') && touched.trackingId}]">
              <md-input
                v-model="trackingId"
                data-vv-name="trackingId"
                type="text"
                v-validate= "modelValidations.trackingId">
              </md-input>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('trackingId')">255文字以内の半角英数記号で入力してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('trackingId')">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('trackingId') && touched.trackingId">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>
        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            Firebase認証鍵
          </label>
          <div class="md-layout-item">
            <md-field :class="[
              {'md-error': errors.has('fcmKey')},
              {'md-valid': !errors.has('fcmKey') && touched.fcmKey}]">
              <md-input
                v-model="fcmKey"
                data-vv-name="fcmKey"
                type="text"
                v-validate= "modelValidations.fcmKey">
              </md-input>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('fcmKey')">255文字以内の半角英数記号で入力してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('fcmKey')">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('fcmKey') && touched.fcmKey">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            Androidバージョン
          </label>
          <div class="md-layout-item">
            <md-field :class="[
              {'md-error': errors.has('android')},
              {'md-valid': !errors.has('android') && touched.android}]">
              <md-input
                v-model="android"
                data-vv-name="android"
                type="text"
                v-validate= "modelValidations.android"
                required>
              </md-input>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('android')">【必須】半角数字で入力してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('android')">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('android') && touched.android">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            iOSバージョン
          </label>
          <div class="md-layout-item">
            <md-field :class="[
              {'md-error': errors.has('ios')},
              {'md-valid': !errors.has('ios') && touched.ios}]">
              <md-input
                v-model="ios"
                data-vv-name="ios"
                type="text"
                v-validate= "modelValidations.ios"
                required>
              </md-input>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('ios')">【必須】半角数字で入力してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('ios')">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('ios') && touched.ios">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>

        <div class="md-layout md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            サービスロゴ
          </label>
          <div class="md-layout-item  md-size-30 md-xsmall-size-100 pt20">
            <div class="file-input">
              <div v-if="!imageCompany">
                <div class="image-container">
                  <img :src="dummyImg" title="">
                </div>
              </div>
              <div class="image-container" v-else>
                <img :src="imageCompany" />
              </div>
              <div class="button-container">
                <md-button class="md-danger md-round" @click="removeImage" v-if="imageCompany" data-id="imageCompany"><i class="fa fa-times"></i>削除</md-button>
                <md-button class="md-success md-round md-fileinput">
                  <template v-if="!imageCompany">画像選択</template>
                  <template v-else>変更</template>
                  <input type="file" @change="onFileChange" data-id="imageCompany">
                </md-button>
              </div>
            </div>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            特定商取引法
          </label>
          <div class="md-layout-item pt20">
            <froala :tag="'textarea'" :config="config" v-model="commercialLaw"></froala>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            プライバシーポリシー
          </label>
          <div class="md-layout-item pt20">
            <froala :tag="'textarea'" :config="config" v-model="servicePolicy"></froala>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            利用規約
          </label>
          <div class="md-layout-item pt20">
            <froala :tag="'textarea'" :config="config" v-model="serviceTerms"></froala>
          </div>
        </div>


      </md-card-content>

      <md-card-actions md-alignment="left" class="mt60 mx-auto">
        <md-button class="md-warning md-lg" @click.native.prevent="returnBack" v-show="superFlag">戻る</md-button>
        <submit-button class="md-success md-lg mL40" :onclick="validate" :buttonWord=this.buttonWord type="submit"></submit-button>
      </md-card-actions>
    </md-card>

    <div class="linkCont">
      <div class="bottomLink" @click="goSetting" v-show="!basicLock">基本情報を編集する</div>
      <div class="bottomLink" @click="goDelivery">配信情報を編集する</div>
      <div class="bottomLink" @click="goApp">アプリ用画像情報を編集する</div>
    </div>
  </div>
</div>

