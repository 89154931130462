
<div class="md-layout">
  <div class="md-layout-item md-size-100">
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-green">
        <div class="card-icon">
          <md-icon>assignment</md-icon>
        </div>
        <h4 class="title">クーポンコード基本情報設定</h4>
      </md-card-header>

      <md-card-content>
        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            表示順
          </label>
          <div class="md-layout-item">
            <md-field style="width: 50px" :class="[
              {'md-error': errors.has('number')},
              {'md-valid': !errors.has('number') && touched.number}]">
              <md-input
                v-model="number"
                data-vv-name="number"
                type="text"
                v-validate= "modelValidations.number"
                required>
              </md-input>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('number')">【必須】3桁以内の半角数字で入力してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('number')">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('number') && touched.number">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>
        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            クーポンコード
          </label>
          <div class="md-layout-item">
            <md-field :class="[
              {'md-error': errors.has('code')},
              {'md-valid': !errors.has('code') && touched.code}]">
              <md-input
                v-model="code"
                data-vv-name="code"
                type="text"
                v-validate= "modelValidations.code"
                required>
              </md-input>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('code')">【必須】255文字以内で入力してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('code')">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('code') && touched.code">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>
        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            クーポン名
          </label>
          <div class="md-layout-item">
            <md-field :class="[
              {'md-error': errors.has('title')},
              {'md-valid': !errors.has('title') && touched.title}]">
              <md-input
                v-model="title"
                data-vv-name="title"
                type="text"
                v-validate= "modelValidations.title"
                required>
              </md-input>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('title')">【必須】255文字以内で入力してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('title')">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('title') && touched.title">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>
        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            クーポン内容
          </label>
            <div class="md-layout-item pt20">
            <div class="f-field">
              <froala :tag="'textarea'" :config="config" v-model="couponDetail"
                  data-vv-name="couponDetail"
                  type="text"
                  v-validate= "modelValidations.couponDetail"
                  required>
              </froala>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('couponDetail')">【必須】入力してください</div>
              </slide-y-up-transition>
            </div>
          </div>
        </div>
        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            店舗名
          </label>
          <div class="md-layout-item md-size-40  md-xsmall-size-100">
            <shop-select v-model="shopId" :noSelectFlag="true" :touchedFlag="true"></shop-select>
          </div>
        </div>
        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            会員ランク
          </label>
          <div class="md-layout-item">
            <user-rank-select v-model="userRankId" :avoidValidFlag="true" :touchedFlag="true" :mutlipleFlag="true"></user-rank-select>
          </div>
        </div>
        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            ステータス
          </label>
          <div class="md-layout-item md-size-20 md-xsmall-size-100">
            <md-field :class="[
              {'md-error': errors.has('status')},
              {'md-valid': !errors.has('status') && touched.status}]">
              <md-select
                v-model="status"
                data-vv-name="status"
                v-validate= "modelValidations.status"
                required>
                <md-option value="active">有効</md-option>
                <md-option value="not_active">無効</md-option>
              </md-select>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('status')">【必須】必ず一つ選択してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('status')" style="margin-right:20px">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('status') && touched.status" style="margin-right:20px">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100 md-small-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100 ptb">
            値引き
          </label>
          <div class="md-layout-item">
            <md-radio v-model="kind" value="fixed_amount">定額値引き</md-radio>
            <md-radio v-model="kind" value="fixed_rate">定率値引き</md-radio>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100 md-small-size-100" v-show="kind==='fixed_amount'">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            定額値引き
          </label>
          <div class="md-layout-item md-size-10 md-xsmall-size-50">
            <md-field :class="[
              {'md-error': errors.has('kindFixedAmount')},
              {'md-valid': !errors.has('kindFixedAmount') && touched.kindFixedAmount}]">
              <md-input
                v-model="kindFixedAmount"
                data-vv-name="kindFixedAmount"
                v-validate= "kind === 'fixed_amount' ? modelValidations.kindFixedAmount : ''"
                type="number"
                required>
              </md-input>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('kindFixedAmount')">【必須】6桁以内の半角数字で入力してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('kindFixedAmount')" style="margin-right:10px">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('kindFixedAmount') && touched.kindFixedAmount" style="margin-right:10px">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
          <div class="md-layout-item unitLabel">円</div>
        </div>

        <div class="md-layout md-layout-item md-size-100 md-small-size-100" v-show="kind==='fixed_rate'">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            定率値引き
          </label>
          <div class="md-layout-item md-size-10 md-xsmall-size-50">
            <md-field :class="[
              {'md-error': errors.has('kindFixedRate')},
              {'md-valid': !errors.has('kindFixedRate') && touched.kindFixedRate}]">
              <md-input
                v-model="kindFixedRate"
                data-vv-name="kindFixedRate"
                v-validate= "kind === 'fixed_rate' ? modelValidations.kindFixedRate : ''"
                type="number"
                required>
              </md-input>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('kindFixedRate')">【必須】2桁以内の半角数字で入力してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('kindFixedRate')" style="margin-right:10px">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('kindFixedRate') && touched.kindFixedRate" style="margin-right:10px">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
          <div class="md-layout-item unitLabel">％</div>
        </div>

      </md-card-content>
    </md-card>
  </div>

  <div class="md-layout-item md-size-100">
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-green">
        <div class="card-icon">
          <md-icon>settings</md-icon>
        </div>
        <h4 class="title">クーポン期間設定</h4>
      </md-card-header>

      <md-card-content>
        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            公開開始日
          </label>
          <div class="md-layout-item md-size-30 md-xsmall-size-100" style="position:relative">
            <vuejs-datepicker
              :format="DatePickerFormat"
              :highlighted="highlighted"
              v-model="selectedStart"
              :language="ja"
              data-vv-name="selectedStart"
              v-validate= "modelValidations.selectedStart"
              ></vuejs-datepicker>
            <md-button
              class="md-just-icon md-default md-simple dateDelete"
              @click.native="selectedStart = ''">
              <md-icon>close</md-icon>
            </md-button>
            <slide-y-up-transition>
              <div class="vErrorText" style="margin-left: 15px" v-show="errors.has('selectedStart')">【必須】日付を選択してください</div>
            </slide-y-up-transition>
            <slide-y-down-transition>
              <md-icon class="error dateError" v-show="errors.has('selectedStart')">close</md-icon>
            </slide-y-down-transition>
            <slide-y-down-transition>
              <md-icon class="success dateSuccess" v-show="!errors.has('selectedStart') && touched.selectedStart">done</md-icon>
            </slide-y-down-transition>
          </div>
          <time-select class="md-layout-item md-xsmall-size-100" ref="startTimer" v-model="startTime"></time-select>
        </div>
        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            公開終了日
          </label>
          <div class="md-layout-item md-size-30 md-xsmall-size-100" style="position:relative">
            <vuejs-datepicker
              :format="DatePickerFormat"
              :highlighted="highlighted"
              v-model="selectedEnd"
              :language="ja"
              data-vv-name="selectedEnd"
              v-validate= "modelValidations.selectedEnd"
              ></vuejs-datepicker>
            <md-button
              class="md-just-icon md-default md-simple dateDelete"
              @click.native="selectedEnd = ''">
              <md-icon>close</md-icon>
            </md-button>
            <slide-y-up-transition>
              <div class="vErrorText" style="margin-left: 15px" v-show="errors.has('selectedEnd')">【必須】日付を選択してください</div>
            </slide-y-up-transition>
            <slide-y-down-transition>
              <md-icon class="error dateError" v-show="errors.has('selectedEnd')">close</md-icon>
            </slide-y-down-transition>
            <slide-y-down-transition>
              <md-icon class="success dateSuccess" v-show="!errors.has('selectedEnd') && touched.selectedEnd">done</md-icon>
            </slide-y-down-transition>
          </div>
          <time-select class="md-layout-item md-xsmall-size-100" ref="endTimer" v-model="endTime"></time-select>
        </div>
      </md-card-content>
    </md-card>
  </div>


  <div class="md-layout-item md-size-100">
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-green">
        <div class="card-icon">
          <md-icon>settings</md-icon>
        </div>
        <h4 class="title">クーポン画像設定</h4>
      </md-card-header>

      <md-card-content>
        <div class="md-layout md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            イメージ画像
          </label>
          <div class="md-layout-item  md-size-30 md-xsmall-size-100">
            <div class="file-input">
              <div v-if="!imageCoupon">
                <div class="image-container">
                  <img :src="dummyImg" title="">
                </div>
              </div>
              <div class="image-container" v-else>
                <img :src="imageCoupon" />
              </div>
              <div class="button-container">
                <md-button class="md-danger md-round" @click="removeImage" v-if="imageCoupon" data-id="imageCoupon"><i class="fa fa-times"></i>削除</md-button>
                <md-button class="md-success md-round md-fileinput">
                  <template v-if="!imageCoupon">画像選択</template>
                  <template v-else>変更</template>
                  <input type="file" @change="onFileChange" data-id="imageCoupon">
                </md-button>
              </div>
            </div>
          </div>
        </div>

      </md-card-content>
      <md-card-actions md-alignment="left" class="mt60 mx-auto">
        <md-button class="md-warning md-lg" @click.native.prevent="returnBack">戻る</md-button>
        <submit-button class="md-success md-lg mL40" :onclick="validate" :buttonWord=this.buttonWord type="submit"></submit-button>
      </md-card-actions>
    </md-card>
  </div>
</div>
