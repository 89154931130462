
<div class="md-layout">
  <div class="md-layout-item md-size-100">
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-green">
        <div class="card-icon">
          <md-icon>assignment</md-icon>
        </div>
        <h4 class="title">お問合せ詳細</h4>
      </md-card-header>

      <md-card-content>
        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
            ニックネーム
          </label>
          <div class="md-layout-item">
            <md-field>
              <md-input
                v-model="nickname"
                type="text"
                class="readonlyBg"
                :readonly="true">
              </md-input>
            </md-field>
          </div>
        </div>
        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
            メールアドレス
          </label>
          <div class="md-layout-item">
            <md-field  class="readonlyBg" >
              <md-input v-model="email"></md-input>
            </md-field>
          </div>
        </div>
        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
            問合せ内容
          </label>
          <div class="md-layout-item">
            <md-field class="readonlyBg" style="padding-top:10px">
              <md-textarea v-model="message" style="padding:0"></md-textarea>
            </md-field>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
            備考・メモ
          </label>
          <div class="md-layout-item">
            <md-field style="padding-top:10px">
              <md-textarea id="txtAreaProfile" class="txtArea" v-model="memo" style="padding:0"></md-textarea>
            </md-field>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
            お問合せカテゴリ名
          </label>
          <div class="md-layout-item md-size-50 md-xsmall-size-100">
            <contact-category-select v-model="categoryId" :touchedFlag="true"></contact-category-select>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
            問合せ日時
          </label>
          <div class="md-layout-item md-size-50 md-xsmall-size-100">
            <p class="readonlyBg">{{createdAt | timeFormat2}}</p>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
            対応ステータス
          </label>
          <div class="md-layout-item md-size-30 md-xsmall-size-100">
            <md-field :class="[
              {'md-error': errors.has('status')},
              {'md-valid': !errors.has('status') && touched.status}]">
              <md-select
                v-model="status"
                data-vv-name="status"
                v-validate= "modelValidations.status"
                required>
                <md-option value="not_reply">未回答</md-option>
                <md-option value="reply">回答済み</md-option>
                <md-option value="no_need">回答不要</md-option>
              </md-select>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('status')">【必須】必ず一つ選択してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('status')" style="margin-right:20px">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('status') && touched.status" style="margin-right:20px">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>

      </md-card-content>
      <md-card-actions md-alignment="left" class="mt60 mx-auto">
        <md-button class="md-warning md-lg" @click.native.prevent="returnBack">戻る</md-button>
        <submit-button class="md-success md-lg mL40" :onclick="validate" :buttonWord=this.buttonWord type="submit"></submit-button>
      </md-card-actions>
    </md-card>
  </div>
</div>
