<template>
  <rates :rateData="rateData"></rates>
</template>

<script>
import Rates from '../components/additional/RateList.vue'

export default {
  components: {
    Rates,
  },
  data () {
    return { 
      rateData: {
        title: "ポイント",
        returnBack: '/admin/point_rate_list/',
        editUrl: '/admin/point_rate/edit/',
        apiUrl: '/point_rates/'
      }}
  }
}
</script>