<template>
  <rates :rateData="rateData"></rates>
</template>

<script>
import Rates from '../components/additional/RateList.vue'

export default {
  components: {
    Rates,
  },
  data () {
    return { 
      rateData: {
        title: "スタンプ",
        returnBack: '/admin/stamp_rate_list/',
        editUrl: '/admin/stamp_rate/edit/',
        apiUrl: '/stamp_rates/'
      }}
  }
}
</script>