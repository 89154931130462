<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>account_box</md-icon>
          </div>
          <h4 class="title">管理ユーザー情報</h4>
        </md-card-header>

        <md-card-content class="formLayout">
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              ユーザー名
            </label>
            <div class="md-layout-item md-size-85 md-xsmall-size-100">
              <p class="form-control-static">{{name}}</p>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              ログインID
            </label>
            <div class="md-layout-item md-size-85 md-xsmall-size-100">
              <p class="form-control-static">{{login}}</p>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              所属企業
            </label>
            <div class="md-layout-item md-size-85 md-xsmall-size-100">
              <p class="form-control-static">{{company}}</p>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              アカウント登録日
            </label>
            <div class="md-layout-item md-size-85 md-xsmall-size-100">
              <p class="form-control-static">{{created_at}}</p>
            </div>
          </div>
          <!-- <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              アカウント更新日
            </label>
            <div class="md-layout-item md-size-85 md-xsmall-size-100">
              <p class="form-control-static">{{updated_at}}</p>
            </div>
          </div> -->
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              最終ログイン日時
            </label>
            <div class="md-layout-item md-size-85 md-xsmall-size-100">
              <p class="form-control-static">{{lastSignTime}}</p>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              最終ログインIP
            </label>
            <div class="md-layout-item md-size-85 md-xsmall-size-100">
              <p class="form-control-static">{{lastSignIp}}</p>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              ユーザーエージェント
            </label>
            <div class="md-layout-item md-size-85 md-xsmall-size-100">
              <p class="form-control-static">{{userAgent}}</p>
            </div>
          </div>

        </md-card-content>
        <md-card-actions class="mx-auto" md-alignment="left">
          <md-button class="md-warning md-lg" @click.native.prevent="returnBack">戻る</md-button>
        </md-card-actions>
      </md-card>
    </div>
  </div>

</template>

<script>
  import router from '../entrypoints/router.js'

  export default {
    components: {
    },
    computed: {
 
    },
    props: {
    },
    data () {
      return {
        name: '',
        company: '',
        created_at: '',
        lastSignTime: '',
        lastSignIp: '',
        login: '',
        updated_at: '',
        userAgent: ''
      }
    },
    methods: {
      returnBack () {
        router.push({ path: '/admin/admin_user_list' })
      },
      getHistory () {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/admin_users/' + this.$route.params.id,
          timeout : 30000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.name = response.data.last_name + ' ' + response.data.first_name
          this.company = response.data.company.name
          if (response.data.created_at !== null) {
            this.created_at = this.dateDivider(response.data.created_at)[0] + ' ' + this.timeAdjust(response.data.created_at)
          }
          if (response.data.last_sign_in_at !== null) {
            this.lastSignTime = this.dateDivider(response.data.last_sign_in_at)[0] + ' ' + this.timeAdjust(response.data.last_sign_in_at)
          }
          this.lastSignIp = response.data.last_sign_in_ip
          this.login = response.data.login
          this.userAgent = response.data.user_agent
        })
        .catch(error => {
          this.dangerNotify('管理ユーザー情報の取得に失敗しました')
        })
      },
      dateDivider (dates) {
        var dateArray = dates.split('T')
        return dateArray
      },
      timeAdjust (dates) {
        var dateArray = dates.split('T')
        var times = dateArray[1].substring(8, 0)
        return times
      }
    },
    mounted () {
      this.getHistory()
    },
    watch: {
    }
  }
</script>

<style lang="scss" scoped>
.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.form-control-static{
  margin-top: 9px;
}

.mt30{
  margin-top: 30px;
}

.productStatus{
  max-width: 120px;
  width: 100%;
  display: inline-block;
}

.btnUpdate{
  margin: 10px 0 0 20px;
}

.text-right ::v-deep .md-table-cell-container{
  display: flex;
  justify-content: flex-end;
}
.md-table ::v-deep .md-table-head:last-child{
  text-align: right;
}

.table-stats {
  display: flex;
  align-items: center;
  text-align: right;
  flex-flow: row wrap;

  .td-price .td-total{
    display: inline-flex;
    font-weight: 500;
    font-size: 14px;
    margin-right: 50px;
  }

  &.table-striped .td-price{
    border-bottom: 0;
  }

  .td-price{
    font-size: 16px;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  }

  .td-price,
  > div{
    flex: 0 0 100%;
    padding: 12px 8px;
  }
}

.tableNamed{
  font-size: 14px !important;
  font-weight: normal;
}

.td-image{
  min-width: 150px;
}

.table-shopping ::v-deep .md-table-head:nth-child(5),
.table-shopping ::v-deep .md-table-head:nth-child(7),
.table-shopping ::v-deep .md-table-head:nth-child(6){
  text-align: right;
}

.md-table{
  display: block;
}

.btnCenter{
  margin-left: auto !important;
  margin-right: auto !important;
}

.mt30{
  margin-top: 30px;
}

.mt60{
  margin-top: 60px;
}

.pr60{
  padding-right: 60px;
}

.is-l-none{
  display: none;
}

@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }

  .is-xs-none{
    display: none;
  }

  .is-xs-block{
    display: block;
  }
}

</style>
../entrypoints/router.js