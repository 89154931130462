<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>search</md-icon>
          </div>
          <h4 class="title">企業検索</h4>
        </md-card-header>

        <md-card-content class="md-layout formLayout">

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              企業名
            </label>
            <div class="md-layout-item">
              <md-field>
                <md-input v-model="companyName"></md-input>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              契約日
            </label>
            <div class="md-layout-item md-xsmall-size-100" style="position:relative">
              <vuejs-datepicker
                :format="DatePickerFormat"
                :highlighted="highlighted"
                v-model="selectedStart"
                :language="ja"></vuejs-datepicker>
              <md-button
                class="md-just-icon md-default md-simple dateDelete"
                @click.native="selectedStart = ''">
                <md-icon>close</md-icon>
              </md-button>
            </div>
            <div class="md-layout-item md-size-5 betweenTime is-xs-none"> 〜 </div>
            <div class="md-layout-item md-xsmall-size-100" style="position:relative">
              <vuejs-datepicker
                :format="DatePickerFormat"
                :highlighted="highlighted"
                v-model="selectedEnd"
                :language="ja"></vuejs-datepicker>
              <md-button
                class="md-just-icon md-default md-simple dateDelete"
                @click.native="selectedEnd = ''">
                <md-icon>close</md-icon>
              </md-button>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100 md-small-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              ステータス
            </label>
            <div class="md-layout-item md-size-20 md-xsmall-size-100">
              <md-field class="UserStatus">
                <md-select v-model="status" name="status">
                  <md-option value="active">有効</md-option>
                  <md-option value="not_active">無効</md-option>
                  <md-option value="選択しない">選択しない</md-option>
                </md-select>
              </md-field>
            </div>
          </div>

        </md-card-content>
        <md-card-actions class="mx-auto">
          <md-button class="md-success md-lg" @click.native="getCompany()">検索</md-button>
          <md-button class="md-info md-lg mL40" @click="handleClear">検索条件クリア</md-button>
        </md-card-actions>
      </md-card>
    </div>
    <transition name="fade">
      <div class="md-layout-item md-size-100" v-if="resultFlag">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>assignment</md-icon>
            </div>
            <h4 class="title">企業一覧</h4>
          </md-card-header>
          <md-card-content>
            <div class="dataTableWrapper">
              <div class="dataTable"> 
                <div class="tHead">
                  <div class="tHead_th">企業名</div>
                  <div class="tHead_th">契約日</div>
                  <div class="tHead_th">ステータス</div>
                  <div class="tHead_th taR">アクション</div>
                </div>
                <div v-for="item in tableData" :data-id="item.id" class="tItem">
                  <div class="tItem_td commentBody"><md-label style="word-break:break-all;">{{ item.name }}</md-label></div>
                  <div class="tItem_td">{{ item.period }}</div>
                  <div class="tItem_td">{{ item.status }}</div>
                  <div class="tItem_td taR td_actionTw">
                    <drop-down direction="up" class="dropDown">
                      <md-button slot="title" class="md-just-icon md-warning mg0 mgR2 dropdown-toggle" data-toggle="dropdown">
                        <md-icon>dvr</md-icon>
                      </md-button>
                      <ul class="dropdown-menu dropdown-menu-right">
                        <li><a class="aSpace" @click="handleEdit(item.id)">基本情報設定</a></li>
                        <li><a class="aSpace" @click="serviceEdit(item.id)">サービス設定</a></li>
                        <li><a class="aSpace" @click="deliveryEdit(item.id)">配信設定</a></li>
                        <li><a class="aSpace" @click="applicationEdit(item.id)">画像設定</a></li>
                      </ul>
                    </drop-down>
                    <md-button
                      class="md-just-icon md-danger mg0"
                      @click.native="handleDelete(item)">
                      <md-icon>close</md-icon>
                    </md-button>
                  </div>
                </div>
              </div>
            </div>
          </md-card-content>
          <md-card-actions md-alignment="space-between">
            <div class="">
              <p class="card-category">全{{ pageData.totalCount | numberWithDelimiter }}件中 {{from | numberWithDelimiter}}件から {{to | numberWithDelimiter}}件を表示</p>
            </div>
            <Pagination class="Pagination-no-border Pagination-success"
              :currentPage="pageData.currentPage"
              :totalPage="pageData.totalPage"
              @get="getCompany">
            </Pagination>
          </md-card-actions>
        </md-card>
      </div>
    </transition>
  </div>

</template>

<script>
  import Pagination from '../components/additional/Pagination.vue'
  import router from '../entrypoints/router.js'
  import Fuse from 'fuse.js'
  import swal from 'sweetalert2'
  import role from "../mixins/role.js"

  export default {
    mixins: [ role ],
    components: {
      Pagination
    },
    computed: {
      to () {
        return (this.pageData.currentPage - 1) * 25 + this.tableData.length
      },
      from () {
        return (this.pageData.currentPage - 1) * 25 + 1
      },
      saveCondition() {
        return this.$store.getters['companyList/condition']
      }
    },
    data () {
      return {
        companyName: '',
        selectedStart: '',
        selectedEnd: '',
        currentSort: 'tel',
        currentSortOrder: 'asc',
        status: '選択しない',
        resultFlag: false,
        tableData:[],
        pageData: {
          totalPage: 0,
          currentPage: 0,
          nextPage: null,
          prevPage: null,
          totalCount: 0
        }
      }
    },
    methods: {
      getCompany (page = 1) {
        var condition = {
          page: page,
          name: this.companyName,
          status: this.status === '選択しない' ? '' : this.status,
          period_from: this.selectedStart === '' ? '' : this.initDate(this.selectedStart),
          period_to: this.selectedEnd === '' ? '' :  this.initDate(this.selectedEnd)
        }
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/companies',
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
          params: condition
        }).then(response => {
          if (response.data.data.length === 0) {
            this.resultFlag = false
            this.noSearchResults()
          } else {
            this.createList(response.data)
            this.pageData.totalPage = response.data.meta['total-pages']
            this.pageData.currentPage = response.data.meta['current-page']
            this.pageData.totalCount = response.data.meta['total-count']
          }
          this.$store.dispatch('companyList/searchParamsAsync', condition)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('企業情報を正しく取得できませんでした')
          }
        })
      },
      createList (obj) {
        var res = obj.data
        this.tableData.splice(0)
        for (var i in res) {
          var item = {
            id: res[i].id,
            name: res[i].attributes.name,
            status: res[i].attributes.status === 'active' ? '有効' : '無効',
            period: this.initDate(res[i].attributes['contract-period-from']) + ' 〜 ' + this.initDate(res[i].attributes['contract-period-to'])
          }
          this.tableData.push(item)
        }
        this.resultFlag = true
      },
      customSort (value) {
        return value.sort((a, b) => {
          const sortBy = this.currentSort
          if (this.currentSortOrder === 'desc') {
            return a[sortBy].localeCompare(b[sortBy])
          }
          return b[sortBy].localeCompare(a[sortBy])
        })
      },
      handleEdit (id) {
        router.push({ path: '/admin/company/edit/' + id })
      },
      serviceEdit (id) {
        router.push({ path: '/admin/company/service/edit/' + id })
      },
      deliveryEdit (id) {
        router.push({ path: '/admin/company/delivery/edit/' + id })
      },
      applicationEdit (id) {
        router.push({ path: '/admin/company/application/edit/' + id })
      },
      handleDelete (item) {
        swal.fire({
          title: 'この企業を削除してもよろしいですか?',
          text: `削除してしまうともとに戻すことは出来ません！`,
          icon: 'warning',
          showCancelButton: true,
          customClass: {
            confirmButton: "md-button md-success",
            cancelButton: "md-button md-danger"
          },
          confirmButtonText: 'OK',
          buttonsStyling: false
        }).then((result) => {
          if(result.value){
            this.deleteCompany(item)
          }
        });
      },
      deleteCompany(item){
        this.axios({
          method  : 'DELETE',
          url     : this.AdminApiUrl + '/companies/' + item.id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.deleteRow(item)
          this.successAndBackPage(`企業情報を削除しました`)
          this.getCompany()
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('企業情報を削除することが出来ませんでした')
          }
        })
      },
      deleteRow(item){
        let indexToDelete = this.tableData.findIndex((tableRow) => tableRow.id === item.id);
        if (indexToDelete >= 0) {
          this.tableData.splice(indexToDelete, 1)
        }
      },
      reloadPage() {
        this.companyName = this.saveCondition.name
        this.selectedStart = this.saveCondition.period_from
        this.selectedEnd = this.saveCondition.period_to
        this.status = this.saveCondition.status || '選択しない'
        this.getCompany(this.saveCondition.page)
      },
      handleClear() {
        this.companyName = ''
        this.selectedStart = ''
        this.selectedEnd = ''
        this.status = '選択しない'
        this.getCompany()
      }
    },
    mounted () {
      // role check
      if (!this.superFlag) {
        router.push({ path: '/admin/login'})
        return false
      }
      if (this.saveCondition) {
        this.reloadPage()
      } else {
        this.$store.dispatch('companyList/searchParamsAsync', null)
        this.handleClear()
      }
    },
    watch: {
    }
  }
</script>

<style lang="css" scoped>
.fade-enter-active, .fade-leave-active {
  will-change: opacity;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.fade-enter, .fade-leave-to {
  opacity: 0
}

.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.priceCell{
  font-weight: bold;
  text-align: right;
}

.formLayout{
  flex-basis: auto;
}

.mg0{
  margin: 0;
}

.mgR2{
  margin-right: 2px;
}

.aSpace{
  font-size:12.5px;
  padding:4.5px 24px;
}

.btnSpace{
  margin: 0 2px;
}

.betweenTime{
  line-height: 59px;
}

.dateDelete{
  position: absolute;
  top: 0;
  right: 0;
}

.md-table-row:first-child .dropup .dropdown-toggle + .dropdown-menu{
  bottom: -100%;
}

@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }

  .is-xs-none{
    display: none;
  }

  .is-xs-block{
    display: block;
  }
}
</style>
../entrypoints/router.js