
<div class="md-layout">
  <div class="md-layout-item md-size-100">
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-green">
        <div class="card-icon">
          <md-icon>search</md-icon>
        </div>
        <h4 class="title">カテゴリ一覧</h4>
      </md-card-header>

      <md-card-content class="md-layout formLayout">
        <div class="" v-show="emptyFlag">カテゴリが登録されていません。新しくカテゴリを作成してください</div> 
        <big-category :rootCategory.sync="categories"></big-category>
      </md-card-content>
    </md-card>
    <transition name="fade">
      <div class="sliderBg" v-show='caterogyBgFlag'></div>
    </transition>
  </div>
</div>

