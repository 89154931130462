<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>create</md-icon>
          </div>
          <h4 class="title">QAカテゴリー情報</h4>
        </md-card-header>
        <md-card-content class="md-layout formLayout">
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              表示順
            </label>
            <div class="md-layout-item">
              <md-field style="width: 50px" :class="[
                {'md-error': errors.has('number')},
                {'md-valid': !errors.has('number') && touched.number}]">
                <md-input
                  v-model="number"
                  data-vv-name="number"
                  type="text"
                  v-validate= "modelValidations.number"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('number')">半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('number')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('number') && touched.number">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              カテゴリコード
            </label>
            <div class="md-layout-item">
              <md-field :class="[
                {'md-error': errors.has('code')},
                {'md-valid': !errors.has('code') && touched.code}]">
                <md-input
                  v-model="code"
                  data-vv-name="code"
                  type="text"
                  v-validate= "modelValidations.code">
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('code')">255文字以内の半角英数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('code')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('code') && touched.code">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              カテゴリ名
            </label>
            <div class="md-layout-item">
              <md-field :class="[
                {'md-error': errors.has('name')},
                {'md-valid': !errors.has('name') && touched.name}]">
                <md-input
                  v-model="name"
                  data-vv-name="name"
                  type="text"
                  v-validate= "modelValidations.name"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('name')">【必須】カテゴリ名入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('name')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('name') && touched.name">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              ステータス
            </label>
            <div class="md-layout-item md-size-85 md-xsmall-size-100">
              <md-field class="productStatus">
                <md-select v-model="status" name="status">
                  <md-option value="active">有効</md-option>
                  <md-option value="not_active">無効</md-option>
                </md-select>
              </md-field>
            </div>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="left" class="mt60 mx-auto">
          <submit-button class="md-success md-lg mL40" :onclick="validate" :buttonWord=this.buttonWord type="submit"></submit-button>
        </md-card-actions>
      </md-card>

      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">QAカテゴリ一覧</h4>
        </md-card-header>
        <md-card-content>
          <div class="dataTableWrapper">
            <draggable @sort="updateList" draggable=".tItem" class="dataTable" :options="{handle:'.cursorGrab',animation:200}">
              <div class="tHead">
                <div class="tHead_th">並び替え</div>
                <div class="tHead_th">QAカテゴリ名</div>
                <div class="tHead_th">登録日</div>
                <div class="tHead_th">更新日</div>
                <div class="tHead_th">ステータス</div>
                <div class="tHead_th taR">アクション</div>
              </div>
              <div v-for="item in tableData" :data-id="item.id" class="tItem">
                <div class="tItem_td cursorGrab"><md-icon class="dragHandle">reorder</md-icon></div>
                <div class="tItem_td">{{ item.name }}</div>
                <div class="tItem_td">{{ item.createdat | dateFormat}}</div>
                <div class="tItem_td">{{ item.updatedat | dateFormat}}</div>
                <div class="tItem_td">{{ item.status }}</div>
                <div class="tItem_td taR td_actionTh">
                  <md-button
                    class="md-just-icon md-warning mg0"
                    @click.native="jumpDetail(item)">
                    <md-icon>dvr</md-icon>
                  </md-button>
                  <md-button
                    class="md-just-icon md-danger mg0"
                    @click.native="handleDelete(item)">
                    <md-icon>close</md-icon>
                  </md-button>
                </div>
              </div>
            </draggable>
          </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
  import SubmitButton from '../components/additional/SubmitButton.vue'
  import EventBus from '../mixins/event-bus.js'
  import router from '../entrypoints/router.js'
  import swal from 'sweetalert2'
  import Draggable from 'vuedraggable'
  import role from "../mixins/role.js"

  export default {
    mixins: [ role ],
    components: {
      SubmitButton,
      Draggable
    },
    data () {
      return {
        tableData: [],
        buttonWord: '登録',
        number: null,
        code: '',
        name: '',
        status: '',
        touched: {
          number: false,
          code: false,
          name: false,
          status: false
        },
        modelValidations: {
          number: {
            required: true,
            numeric: true
          },
          code: {
            alpha_num: true,
            max: 255
          },
          name: {
            required: true,
            max: 255
          },
          status: {
            required: true
          }
        }
      }
    },
    methods: {
      validate() {
        return new Promise((resolve) => {
          setTimeout(() => {
            EventBus.$emit('validate', this.errors)
            this.$validator.validateAll().then(isValid => {
              if (this.errors.items.length == 0) {
                this.updateQaCategory()
              } else {
                this.validateError()
              }
            })
            resolve();
          }, this.ResolveTime);
        });
      },
      getQaCategories () {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/qa_categories',
          timeout : 30000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
        }).then(response => {
            this.createList(response.data)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('QAカテゴリー情報を正しく取得できませんでした')
          }
        })
      },
      createList (obj) {
        var res = obj.data
        this.tableData.splice(0)
        for (var i in res) {
          var item = {
            id: res[i].id,
            code: res[i].attributes.code,
            name: res[i].attributes.name,
            status: res[i].attributes.status === 'active' ? '有効' : '無効',
            createdat: res[i].attributes['created-at'],
            updatedat: res[i].attributes['updated-at'],
            position: res[i].attributes.position
          }
          this.tableData.push(item)
        }
      },
      customSort (value) {
        return value.sort((a, b) => {
          const sortBy = this.currentSort
          if (this.currentSortOrder === 'desc') {
            return a[sortBy].localeCompare(b[sortBy])
          }
          return b[sortBy].localeCompare(a[sortBy])
        })
      },
      handleDelete (item) {
        swal.fire({
          title: 'このQAカテゴリー情報を削除してもよろしいですか?',
          text: `削除してしまうともとに戻すことは出来ません！`,
          icon: 'warning',
          showCancelButton: true,
          customClass: {
            confirmButton: "md-button md-success",
            cancelButton: "md-button md-danger"
          },
          confirmButtonText: 'OK',
          buttonsStyling: false
        }).then((result) => {
          if(result.value){
            this.deleteQaCategory(item)
          }
        });
      },
      deleteRow(item){
        let indexToDelete = this.tableData.findIndex((tableRow) => tableRow.id === item.id);
        if (indexToDelete >= 0) {
          this.tableData.splice(indexToDelete, 1)
        }
      },
      deleteQaCategory (item) {
        this.axios({
          method  : 'DELETE',
          url     : this.AdminApiUrl + '/qa_categories/' + item.id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          sessionStorage.removeItem('qaCategorySelect')
          this.deleteRow(item)
          this.successAndBackPage(`QAカテゴリー情報を削除しました`)
          this.getQaCategories()
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('QAカテゴリー情報を正しく削除できませんでした')
          }
        })
      },
      updateList (event) {
        var id = event.item.getAttribute('data-id')
        this.updateStatus(id, event.newIndex) 
      },
      updateStatus (id, position) {
        var condition = {
          qa_category: {
            'position': position
          }
        }
        this.axios({
          method  : 'PUT',
          url     : this.AdminApiUrl + '/qa_categories/' + id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
          data: condition
        }).then(response => {
          sessionStorage.removeItem('qaCategorySelect')
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('表示順の更新に失敗しました')
          }
        })
      },
      jumpDetail (item) {
        router.push({ path: '/admin/qa_category/edit/' + item.id })
      },
      updateQaCategory () {
        var condition = {
          qa_category: {
            'position': this.number,
            'code': this.code,
            'name': this.name,
            'status': this.status
          }
        }
        this.axios({
          method  : 'POST',
          url     : this.AdminApiUrl + '/qa_categories/',
          timeout : 10000,
          data: condition,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          swal.fire({
            title: `QAカテゴリー情報を登録しました`,
            buttonsStyling: false,
            icon: 'success',
            customClass: {
              confirmButton: "md-button md-success",
            },
          }).then(response => {
              sessionStorage.removeItem('qaCategorySelect')
              this.resetData()
              this.getQaCategories()
              router.push({ path: '/admin/qa_category_list/' })
          })
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('QAカテゴリー情報を登録できませんでした')
          }
        })
      },
      resetData () {
        this.number = null
        this.code = ''
        this.name = ''
        this.status = ''
        this.$validator.reset()
        setTimeout (times =>{
          for (var key in this.touched) {
            this.touched[key] = false
          }
        },100)
      }
    },
    mounted () {
      if (!this.superFlag) {
        router.push({ path: '/admin/login'})
        return false
      }
      this.getQaCategories()
    },
    watch: {
      number () {
        this.touched.number = true
      },
      code(){
        this.touched.code = true
      },
      name(){
        this.touched.name = true
      },
      status(){
        this.touched.status = true
      }
    }
  }
</script>

<style lang="css" scoped>
.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.btnCenter{
  margin-left: auto !important;
  margin-right: auto !important;
}

.priceCell{
  font-weight: bold;
  text-align: right;
}

.formLayout{
  flex-basis: auto;
}

.mg0{
  margin: 0;
}

.pt20{
  padding-top: 20px;
}

.btnSpace{
  margin: 0 2px;
}

.unitLabel{
  padding: 10px 0 0 0;
  line-height: 46px;
}

.txtArea{
  padding: 10px 0px 0px !important;
}

.productStatus{
  max-width: 120px;
  width: 100%;
  display: inline-block;
}

.errorMsg{
  bottom: -15px !important;
}

.dragHandle{
  margin-left: 10px;
}

.cursorGrab{
  cursor: grab;
}

@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }

  .is-xs-none{
    display: none;
  }

  .is-xs-block{
    display: block;
  }
}
</style>
../entrypoints/router.js