<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>search</md-icon>
          </div>
          <h4 class="title">会員履歴検索</h4>
        </md-card-header>

        <md-card-content class="md-layout formLayout">

          <div class="md-layout md-layout-item md-size-50" v-if="opt_adminUser">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              姓
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field>
                <md-input v-model="lastName"></md-input>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50" v-if="opt_adminUser">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              名
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field>
                <md-input v-model="firstName"></md-input>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              電話番号
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field>
                <md-input v-model="tel"></md-input>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              店舗名
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <shop-select v-model="shopId" :noSelectFlag="true"></shop-select>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              入会日
            </label>
            <div class="md-layout-item md-size-20 md-xsmall-size-100" style="position:relative">
              <vuejs-datepicker
                :format="DatePickerFormat"
                :highlighted="highlighted"
                v-model="selectedStart"
                :language="ja"></vuejs-datepicker>
              <md-button
                class="md-just-icon md-default md-simple dateDelete"
                @click.native="selectedStart = ''">
                <md-icon>close</md-icon>
              </md-button>
            </div>
            <div class="md-layout-item md-size-5 betweenTime is-xs-none"> 〜 </div>
            <div class="md-layout-item md-size-20 md-xsmall-size-100" style="position:relative">
              <vuejs-datepicker
                :format="DatePickerFormat"
                :highlighted="highlighted"
                v-model="selectedEnd"
                :language="ja"></vuejs-datepicker>
              <md-button
                class="md-just-icon md-default md-simple dateDelete"
                @click.native="selectedEnd = ''">
                <md-icon>close</md-icon>
              </md-button>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              会員ステータス
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field class="UserStatus">
                <md-select v-model="status" name="status">
                  <md-option value="not_approve">無効</md-option>
                  <md-option value="approved">有効</md-option>
                  <md-option value="選択しない">選択しない</md-option>
                </md-select>
              </md-field>
            </div>
          </div>

        </md-card-content>
        <md-card-actions class="mx-auto">
          <md-button class="md-success md-lg" @click="getUsers()">検索</md-button>
          <md-button class="md-info md-lg mL40" @click="handleClear">検索条件クリア</md-button>
        </md-card-actions>
      </md-card>
    </div>
    <transition name="fade">
      <div class="md-layout-item md-size-100" v-if="resultFlag">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>assignment</md-icon>
            </div>
            <h4 class="title">会員一覧</h4>
          </md-card-header>
          <md-card-content>
            <div class="dataTableWrapper">
              <div class="dataTable"> 
                <div class="tHead">
                  <div class="tHead_th">氏名</div>
                  <div class="tHead_th">E-mail</div>
                  <div class="tHead_th">ステータス</div>
                  <div class="tHead_th taR">アクション</div>
                </div>
                <div v-for="item in tableData" :data-id="item.id" class="tItem">
                  <div v-if="opt_adminUser" class="tItem_td commentBody"><md-label style="word-break:break-all;">{{ item.name }}</md-label></div>
                  <div v-else class="tItem_td"><md-label style="word-break:break-all;">{{ plain }}</md-label></div> 
                  <div class="tItem_td commentBody">{{item.mail}}</div>
                  <div class="tItem_td">{{item.status}}</div>
                  <div class="tItem_td taR td_actionTw">
                    <drop-down direction="up" class="dropDown">
                      <md-button slot="title" class="md-just-icon md-warning mg0 mgR2 dropdown-toggle" data-toggle="dropdown">
                        <md-icon>dvr</md-icon>
                      </md-button>
                      <ul class="dropdown-menu dropdown-menu-right">
                        <li><a @click="jumpCharge(item.id)">チャージ履歴</a></li>
                        <li><a @click="jumpPayment(item.id)">支払い履歴</a></li>
                      </ul>
                    </drop-down>
                  </div>
                </div>
              </div>
            </div>
          </md-card-content>
          <md-card-actions md-alignment="space-between">
            <div class="">
              <p class="card-category">全{{ pageData.totalCount | numberWithDelimiter }}件中 {{from | numberWithDelimiter}}件から {{to | numberWithDelimiter}}件を表示</p>
            </div>
            <Pagination class="Pagination-no-border Pagination-success"
              :currentPage="pageData.currentPage"
              :totalPage="pageData.totalPage"
              @get="getUsers">
            </Pagination>
          </md-card-actions>
        </md-card>
      </div>
    </transition>
  </div>

</template>

<script>
  import Pagination from '../components/additional/Pagination.vue'
  import ShopSelect from '../components/additional/ShopSelect.vue'
  import router from '../entrypoints/router.js'
  import Fuse from 'fuse.js'
  import role from "../mixins/role.js"

  export default {
    mixins: [ role ],
    components: {
      Pagination,
      ShopSelect
    },
    computed: {
      to () {
        return (this.pageData.currentPage - 1) * 25 + this.tableData.length
      },
      from () {
        return (this.pageData.currentPage - 1) * 25 + 1
      },
      saveCondition() {
        return this.$store.getters['houseMoneyList/condition']
      }
    },
    data () {
      return {
        resultFlag: false,
        shopId: '',
        lastName: '',
        firstName: '',
        selectedStart: '',
        plain: '-------',
        selectedEnd: '',
        status: '選択しない',
        tel: '',
        pageData: {
          totalPage: 0,
          currentPage: 0,
          nextPage: null,
          prevPage: null,
          totalCount: 0
        },
        tableData: [],
        shopSelect: []
      }
    },
    methods: {
      getUsers (page = 1) {
        var condition = {
          page: page,
          shop_id: this.shopId === '選択しない' ? '' : this.shopId,
          last_name: this.lastName,
          first_name: this.firstName,
          public_from: this.selectedStart === '' ? '' : this.initDate(this.selectedStart),
          public_to: this.selectedEnd === '' ? '' : this.initDate(this.selectedEnd),
          status: this.status === '選択しない' ? '' : this.status,
          tel: this.tel
        }
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/users/',
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
          params: condition
        }).then(response => {
          if (response.data.data.length === 0) {
            this.resultFlag = false
            this.noSearchResults()
          } else {
            this.createList(response.data)
            this.pageData.totalPage = response.data.meta['total-pages']
            this.pageData.currentPage = response.data.meta['current-page']
            this.pageData.totalCount = response.data.meta['total-count']
          }
          this.$store.dispatch('houseMoneyList/searchParamsAsync', condition)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('会員情報を取得できませんでした')
          }
        })
      },
      createList (obj) {
        var res = obj.data
        this.tableData.splice(0)
        for (var i in res) {
          var item = {
            id: res[i].id,
            name: res[i].attributes['last-name'] + ' ' + res[i].attributes['first-name'],
            mail: res[i].attributes.email,
            status: res[i].attributes.status === 'approved' ? '有効' : '無効'
          }
          this.tableData.push(item)
        }
        this.resultFlag = true
      },
      jumpCharge (id) {
        router.push({ path: '/admin/house_money/charge/' + id })
      },
      jumpPayment (id) {
        router.push({ path: '/admin/house_money/payment/' + id })
      },
      getShop (page) {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/shops/select_list',
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
        }).then(response => {
          this.createShopSelect(response.data)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('店舗リストを取得できませんでした')
          }
        })
      },
      createShopSelect (obj) {
        var res = obj.data
        this.shopSelect.splice(0)
        for (var i in res) {
          var item = {
            id: res[i].id,
            name: res[i].attributes.name
          }
          this.shopSelect.push(item)
        }
      },
      reloadPage() {
        this.lastName = this.saveCondition.last_name
        this.firstName = this.saveCondition.first_name
        this.tel = this.saveCondition.tel
        this.shopId = this.saveCondition.shop_id || '選択しない'
        this.selectedStart = this.saveCondition.public_from
        this.selectedEnd = this.saveCondition.public_to
        this.status = this.saveCondition.status || '選択しない'
        this.getUsers(this.saveCondition.page)
      },
      handleClear() {
        this.lastName = ''
        this.firstName = ''
        this.tel = ''
        this.shopId = '選択しない'
        this.selectedStart = ''
        this.selectedEnd = ''
        this.status = '選択しない'
        this.getUsers()
      }
    },
    mounted () {
      // role check
      if (!this.opt_houseMoney) {
        router.push({ path: '/admin/login'})
        return false
      }
      this.getShop()
      if (!this.$route.query.fromSidebar && this.saveCondition) {
        this.reloadPage()
      } else {
        this.$store.dispatch('houseMoneyList/searchParamsAsync', null)
        this.handleClear()
      }
    }
  }
</script>

<style lang="css" scoped>
.fade-enter-active, .fade-leave-active {
  will-change: opacity;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.fade-enter, .fade-leave-to {
  opacity: 0
}

.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.priceCell{
  font-weight: bold;
  text-align: right;
}

.formLayout{
  flex-basis: auto;
}

.mg0{
  margin: 0;
}

.btnSpace{
  margin: 0 2px;
}


.betweenTime{
  line-height: 59px;
}

.dateDelete{
  position: absolute;
  top: 0;
  right: 0;
}

@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }

  .is-xs-none{
    display: none;
  }

  .is-xs-block{
    display: block;
  }
}
</style>
../entrypoints/router.js