
<div class="md-layout">
  <div class="md-layout-item md-size-100">
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-green">
        <div class="card-icon">
          <md-icon>account_box</md-icon>
        </div>
        <h4 class="title">会員詳細</h4>
      </md-card-header>

      <md-card-content class="formLayout">
        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            e-SCOTT 会員ID
          </label>
          <div class="md-layout-item md-size-85 md-xsmall-size-100">
            <p class="form-control-static">{{escottId}}</p>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            姓名
          </label>
          <div v-if="opt_adminUser" class="md-layout-item md-size-85 md-xsmall-size-100">
            <p class="form-control-static">{{lastName}} {{firstName}}</p>
          </div>
          <div v-else class="md-layout-item md-size-85 md-xsmall-size-100">
            <p class="form-control-static">{{ plain }}</p>
          </div>                           
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            姓名(カナ)
          </label>
          <div v-if="opt_adminUser" class="md-layout-item md-size-85 md-xsmall-size-100">
            <p class="form-control-static">{{lastKana}} {{firstKana}}</p>
          </div>
          <div v-else class="md-layout-item md-size-85 md-xsmall-size-100">
            <p class="form-control-static">{{ plain }}</p>
          </div> 
        </div>

        <!-- <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            生年月日
          </label>
          <div class="md-layout-item md-size-85 md-xsmall-size-100">
            <p class="form-control-static">{{birthDay}}</p>
          </div>
        </div> -->

        <!-- <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            性別
          </label>
          <div class="md-layout-item md-size-85 md-xsmall-size-100">
            <p class="form-control-static">{{gender}}</p>
          </div>
        </div> -->

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            電話番号
          </label>
          <div class="md-layout-item md-size-85 md-xsmall-size-100">
            <p class="form-control-static">{{tel}}</p>
          </div>
        </div>

        <!-- <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            会員番号
          </label>
          <div class="md-layout-item md-size-85 md-xsmall-size-100">
            <p class="form-control-static">{{id}}</p>
          </div>
        </div> -->

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            ステータス
          </label>
          <div class="md-layout-item md-size-85 md-xsmall-size-100">
            <md-field class="productStatus">
              <md-select v-model="memberStatus" name="status">
                <md-option value="not_approve">無効</md-option>
                <md-option value="approved">有効</md-option>
                <md-option value="unsubscribed">退会</md-option>
                <md-option value="model_change">機種変中</md-option>
              </md-select>
            </md-field>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            入会日
          </label>
          <div class="md-layout-item md-size-85 md-xsmall-size-100">
            <p class="form-control-static">{{admissionDay | dateFormat}}</p>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            Email
          </label>
          <div class="md-layout-item md-size-85 md-xsmall-size-100">
            <p class="form-control-static">{{email}}</p>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            会員ランク
          </label>
          <div class="md-layout-item md-size-40 md-xsmall-size-100">
            <user-rank-select v-model='rank' :noSelectFlag="true"></user-rank-select>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            会員ランク有効期限
          </label>
          <div class="md-layout-item md-size-20 md-xsmall-size-100" style="position:relative">
            <vuejs-datepicker
              :format="DatePickerFormat"
              :highlighted="highlighted"
              v-model="rank_expired_at"
              :language="ja"></vuejs-datepicker>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            保有ポイント数
          </label>
          <div class="md-layout-item md-size-85 md-xsmall-size-100">
            <p class="form-control-static">{{point}}</p>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            保有スタンプ数
          </label>
          <div class="md-layout-item md-size-85 md-xsmall-size-100">
            <p class="form-control-static">{{stamp}}</p>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100" v-if="superFlag || (companyFlag && opt_houseMoney)">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            保有ハウスマネー
          </label>
          <div class="md-layout-item md-size-85 md-xsmall-size-100">
            <p class="form-control-static">{{coin | numberWithDelimiter}}</p>
          </div>
        </div>
      </md-card-content>
      <md-card-actions md-alignment="left" class="mt60 mx-auto">
        <md-button class="md-warning md-lg" @click.native.prevent="returnBack">戻る</md-button>
        <md-button class="md-success md-lg mL40" @click="updateStatus">更新</md-button>
      </md-card-actions>
    </md-card>
  </div>
</div>

