
<div class="timeWrapper">
  <div class="sliderBg" v-show='sliderBgFlag' @click="sliderBgFlag = false,sliderFlag = false"></div>
  <input
    :value="value"
    @focus="setTime"
  >
  <transition name="fade">
    <div id="sliderContainer" class="sliderContainer" v-show="sliderFlag">
      <div class="timeTitle"><i class="material-icons icons">query_builder</i>時刻設定</div>
      <div class="timeSet">{{sHour}}:{{sMinute}}</div>
      <div class="hourLabel"><slider v-model="sliderHour"></slider></div>
      <div class="MinuteLabel"><slider v-model="sliderMinute"></slider></div>
      <p class="timeExplain">バーをスライドして任意の時間を選択してください</p>
      <div class="btnArea">
        <md-button class="md-success md-sm" @click="setOutTime">設定する</md-button>
        <md-button class="md-warning md-sm" @click="deleteTime">取り消す</md-button>
      </div>
    </div>
  </transition>
</div>
