
<md-field :class="[
              {'md-error': errors.has('shopId')},
              {'md-valid': !errors.has('shopId') && touched.shopId}]" >
  <md-select v-model="shopId"  
    data-vv-name="shopId"
    v-validate= "avoidValidFlag == true ? '' : modelValidations.shopId" multiple v-if="mutlipleFlag">
          <md-option v-for="item in listData" :value="item.id" :key="item.id">{{item.name}}</md-option>
  </md-select>
  <md-select
    v-model="shopId"
    data-vv-name="shopId"
    v-validate= "avoidValidFlag == true ? '' : modelValidations.shopId" v-if="!mutlipleFlag">
    <md-option v-for="item in listData" :value="item.id" :key="item.id">{{item.name}}</md-option>
  </md-select>
  <slide-y-up-transition>
    <div class="vErrorText" v-show="errors.has('shopId')">【必須】必ず一つ選択してください</div>
  </slide-y-up-transition>
  <slide-y-down-transition>
    <md-icon class="error" v-show="errors.has('shopId')" style="margin-right:20px">close</md-icon>
  </slide-y-down-transition>
  <slide-y-down-transition>
    <md-icon class="success" v-show="!errors.has('shopId') && touched.shopId" style="margin-right:20px">done</md-icon>
  </slide-y-down-transition>
</md-field>
