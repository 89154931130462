
<div class="md-layout">
  <div class="md-layout-item md-size-100">
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-green">
        <div class="card-icon">
          <md-icon>assignment</md-icon>
        </div>
        <h4 class="title">{{titleWord}}履歴</h4>
      </md-card-header>
      <md-card-content class="tipsVisible">
        <div class="dataTableWrapper">
          <div class="dataTable"> 
            <div class="tHead">
              <div class="tHead_th">決済日</div>
              <div class="tHead_th">金額</div>
              <div class="tHead_th">トランザクション番号</div>
              <div class="tHead_th taR">アクション</div>
            </div>
            <div v-for="item in tableData" :data-id="item.id" class="tItem">
              <div class="tItem_td">{{ item.paymentDay | dateFormat }}</div>
              <div class="tItem_td">¥{{ item.price | numberWithDelimiter }}</div>
              <div class="tItem_td">{{ item.number }}</div>
              <div class="tItem_td taR td_actionTw">
                <md-button
                  class="md-just-icon md-warning mg2"
                  @click.native="accessDetail(item.id)">
                  <md-icon>dvr</md-icon>
                </md-button>
              </div>
            </div>
          </div>
        </div>
      </md-card-content>
      <md-card-actions md-alignment="space-between">
        <div class="">
          <p class="card-category">全{{ pageData.totalCount | numberWithDelimiter}}件中 {{from | numberWithDelimiter}}件から {{to | numberWithDelimiter}}件を表示</p>
        </div>
        <Pagination class="Pagination-no-border Pagination-success"
          :currentPage="pageData.currentPage"
          :totalPage="pageData.totalPage"
          @get="getHistory">
        </Pagination>
      </md-card-actions>
    </md-card>
    <div class="btnArea">
      <md-button class="md-warning md-lg" @click.native.prevent="returnBack">戻る</md-button>
    </div>
  </div>
</div>

