<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>account_box</md-icon>
          </div>
          <h4 class="title">会員詳細</h4>
        </md-card-header>

        <md-card-content class="formLayout">
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              e-SCOTT 会員ID
            </label>
            <div class="md-layout-item md-size-85 md-xsmall-size-100">
              <p class="form-control-static">{{escottId}}</p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              姓名
            </label>
            <div v-if="opt_adminUser" class="md-layout-item md-size-85 md-xsmall-size-100">
              <p class="form-control-static">{{lastName}} {{firstName}}</p>
            </div>
            <div v-else class="md-layout-item md-size-85 md-xsmall-size-100">
              <p class="form-control-static">{{ plain }}</p>
            </div>                           
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              姓名(カナ)
            </label>
            <div v-if="opt_adminUser" class="md-layout-item md-size-85 md-xsmall-size-100">
              <p class="form-control-static">{{lastKana}} {{firstKana}}</p>
            </div>
            <div v-else class="md-layout-item md-size-85 md-xsmall-size-100">
              <p class="form-control-static">{{ plain }}</p>
            </div> 
          </div>

          <!-- <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              生年月日
            </label>
            <div class="md-layout-item md-size-85 md-xsmall-size-100">
              <p class="form-control-static">{{birthDay}}</p>
            </div>
          </div> -->

          <!-- <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              性別
            </label>
            <div class="md-layout-item md-size-85 md-xsmall-size-100">
              <p class="form-control-static">{{gender}}</p>
            </div>
          </div> -->

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              電話番号
            </label>
            <div class="md-layout-item md-size-85 md-xsmall-size-100">
              <p class="form-control-static">{{tel}}</p>
            </div>
          </div>

          <!-- <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              会員番号
            </label>
            <div class="md-layout-item md-size-85 md-xsmall-size-100">
              <p class="form-control-static">{{id}}</p>
            </div>
          </div> -->

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              ステータス
            </label>
            <div class="md-layout-item md-size-85 md-xsmall-size-100">
              <md-field class="productStatus">
                <md-select v-model="memberStatus" name="status">
                  <md-option value="not_approve">無効</md-option>
                  <md-option value="approved">有効</md-option>
                  <md-option value="unsubscribed">退会</md-option>
                  <md-option value="model_change">機種変中</md-option>
                </md-select>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              入会日
            </label>
            <div class="md-layout-item md-size-85 md-xsmall-size-100">
              <p class="form-control-static">{{admissionDay | dateFormat}}</p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              Email
            </label>
            <div class="md-layout-item md-size-85 md-xsmall-size-100">
              <p class="form-control-static">{{email}}</p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              会員ランク
            </label>
            <div class="md-layout-item md-size-40 md-xsmall-size-100">
              <user-rank-select v-model='rank' :noSelectFlag="true"></user-rank-select>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              会員ランク有効期限
            </label>
            <div class="md-layout-item md-size-20 md-xsmall-size-100" style="position:relative">
              <vuejs-datepicker
                :format="DatePickerFormat"
                :highlighted="highlighted"
                v-model="rank_expired_at"
                :language="ja"></vuejs-datepicker>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              保有ポイント数
            </label>
            <div class="md-layout-item md-size-85 md-xsmall-size-100">
              <p class="form-control-static">{{point}}</p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              保有スタンプ数
            </label>
            <div class="md-layout-item md-size-85 md-xsmall-size-100">
              <p class="form-control-static">{{stamp}}</p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100" v-if="superFlag || (companyFlag && opt_houseMoney)">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              保有ハウスマネー
            </label>
            <div class="md-layout-item md-size-85 md-xsmall-size-100">
              <p class="form-control-static">{{coin | numberWithDelimiter}}</p>
            </div>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="left" class="mt60 mx-auto">
          <md-button class="md-warning md-lg" @click.native.prevent="returnBack">戻る</md-button>
          <md-button class="md-success md-lg mL40" @click="updateStatus">更新</md-button>
        </md-card-actions>
      </md-card>
    </div>
  </div>

</template>

<script>
  import router from '../entrypoints/router.js'
  import role from "../mixins/role.js"
  import UserRankSelect from '../components/additional/UserRankSelect.vue'

  export default {
    mixins: [ role ],
    components: {
      UserRankSelect
    },
    computed: {
    },
    data () {
      return {
        lastName: '',
        firstName: '',
        lastKana: '',
        firstKana: '',
        birthDay: '',
        sex: '',
        tel: '',
        id: '',
        memberStatus: '',
        admissionDay: '',
        email: '',
        escottId: '',
        point: '',
        stamp: '',
        coin: '',
        rank: '選択しない',
        rank_expired_at: '',
        plain: '-------'
      }
    },
    methods: {
      returnBack () {
        router.push({ path: '/admin/user_list/' })
      },
      getUsers () {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/users/' + this.$route.params.id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.lastName = response.data.last_name
          this.firstName = response.data.first_name
          this.lastKana = response.data.last_name_kana
          this.firstKana = response.data.first_name_kana
          this.birthDay = response.data.birth_day
          this.sex = response.data.sex
          this.tel = response.data.tel
          this.id = response.data.id
          this.memberStatus = response.data.status
          this.admissionDay = response.data.created_at
          this.email = response.data.email
          this.escottId = response.data.escott_id
          this.point = response.data.hold_point
          this.stamp = response.data.hold_stamp
          this.coin = response.data.hold_coin
          this.rank = response.data.user_rank_id === null ? '選択しない' : response.data.user_rank_id
          this.rank_expired_at = response.data.user_rank_expired_at
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('会員情報の取得に失敗しました')
          }
        })
      },
      updateStatus () {
        var condition = {
          user: {
            'status': this.memberStatus,
            'user_rank_id': this.rank === '選択しない' ? '' : this.rank,
            'user_rank_expired_at': this.rank === '選択しない' ? '' : this.rank_expired_at
          }
        }
        this.axios({
          method  : 'PUT',
          url     : this.AdminApiUrl + '/users/' + this.id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
          data: condition
        }).then(response => {
          this.successAndBackPage(`会員情報を更新しました`)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('会員情報の更新に失敗しました')
          }
        })
      }
    },
    mounted () {
      // role check
      if (this.shopFlag) {
        router.push({ path: '/admin/login'})
        return false
      }

      this.getUsers()
    },
    watch: {
    }
  }
</script>

<style lang="css" scoped>
.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.btnCenter{
  margin-left: auto !important;
  margin-right: auto !important;
}

.priceCell{
  font-weight: bold;
  text-align: right;
}

.formLayout{
  flex-basis: auto;
}

.mg0{
  margin: 0;
}

.mt30{
  margin-top: 30px;
}

.productStatus{
  max-width: 120px;
  width: 100%;
}

.form-control-static{
  margin-top: 9px;
}

.mt60{
  margin-top: 60px;
}

.is-l-none{
  display: none;
}

.userRank{
  max-width: 180px;
  width: 100%;
}

@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }

  .is-xs-none{
    display: none;
  }

  .is-xs-block{
    display: block;
  }
}

</style>
../entrypoints/router.js