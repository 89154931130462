<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>account_box</md-icon>
          </div>
          <h4 class="title">支払履歴詳細</h4>
        </md-card-header>

        <md-card-content class="formLayout">
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              支払金額
            </label>
            <div class="md-layout-item md-size-70 md-xsmall-size-100">
              <p class="form-control-static">¥{{price | numberWithDelimiter}}</p>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              種別
            </label>
            <div class="md-layout-item md-size-70 md-xsmall-size-100">
              <p class="form-control-static">{{type}}</p>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              店舗名
            </label>
            <div class="md-layout-item md-size-70 md-xsmall-size-100">
              <p class="form-control-static">{{shopName}}</p>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              トランザクション番号
            </label>
            <div class="md-layout-item md-size-70 md-xsmall-size-100">
              <p class="form-control-static">{{transaction}}</p>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              承認番号
            </label>
            <div class="md-layout-item md-size-70 md-xsmall-size-100">
              <p class="form-control-static">{{number}}</p>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              支払日時
            </label>
            <div class="md-layout-item md-size-70 md-xsmall-size-100">
              <p class="form-control-static">{{charged_at | timeFormat}}</p>
            </div>
          </div>

        </md-card-content>
        <md-card-actions class="mx-auto" md-alignment="left">
          <md-button class="md-warning md-lg" @click.native.prevent="returnBack">戻る</md-button>
        </md-card-actions>
      </md-card>
    </div>
  </div>

</template>

<script>
  import router from '../entrypoints/router.js'
  import role from "../mixins/role.js"

  export default {
    mixins: [ role ],
    data () {
      return {
        price: '',
        type: '',
        shopName: '',
        transaction: '',
        number: '',
        charged_at: ''
      }
    },
    methods: {
      returnBack () {
        router.go(-1)
      },
      getHistory () {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/payments/' + this.$route.params.id,
          timeout : 30000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.price = response.data.total_price
          this.transaction = response.data.transaction_id
          this.shopName = response.data.shop_name
          this.number = response.data.approve_no
          this.charged_at = response.data.created_at
          this.typeCheck(response.data.kind)
        })
        .catch(error => {
          this.dangerNotify('支払履歴情報の取得に失敗しました')
        })
      },
      typeCheck (type) {
        switch (type) {
          case 'shop':
            this.type = '店頭支払'
            break;
          case 'divide':
            this.type = '割り勘'
            break;
          case 'pre_order':
            this.type = '事前決済'
            break;
          case 'reserve_order':
            this.type = '事前予約'
            break;
          case 'ec':
            this.type = '通販'
            break;
          default:
            this.type = 'その他'
            break
        }
      }
    },
    mounted () {
      if (!this.opt_houseMoney) {
        router.push({ path: '/admin/login'})
        return false
      }
      this.getHistory()
    }
  }
</script>

<style lang="scss" scoped>
.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.form-control-static{
  margin-top: 9px;
}

@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }

  .is-xs-none{
    display: none;
  }

  .is-xs-block{
    display: block;
  }
}
</style>
../entrypoints/router.js