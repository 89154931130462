
<div class="md-layout">
  <div class="md-layout-item md-size-100">
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-green">
        <div class="card-icon">
          <md-icon>assignment</md-icon>
        </div>
        <h4 class="title">{{ rateData.title }}倍率設定</h4>
      </md-card-header>

      <md-card-content>
        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-20 md-form-label md-xsmall-size-100">
            キャンペーン名
          </label>
          <div class="md-layout-item">
            <md-field :class="[
              {'md-error': errors.has('campaignName')},
              {'md-valid': !errors.has('campaignName') && touched.campaignName}]">
              <md-input
                v-model="campaignName"
                data-vv-name="campaignName"
                type="text"
                v-validate= "modelValidations.campaignName"
                required>
              </md-input>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('campaignName')">【必須】255文字以内で入力してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('campaignName')">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('campaignName') && touched.campaignName">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>
        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-20 md-form-label md-xsmall-size-100">
            倍率
          </label>
          <div class="md-layout-item  md-size-20 md-xsmall-size-100">
            <md-field :class="[
              {'md-error': errors.has('rate')},
              {'md-valid': !errors.has('rate') && touched.rate}]">
              <md-input
                v-model="rate"
                data-vv-name="rate"
                v-validate= "modelValidations.rate"
                required
                type="number">
              </md-input>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('rate')">【必須】2桁以内の半角数字で入力してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('rate')" style="margin-right:10px">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('rate') && touched.rate" style="margin-right:10px">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>
        <div class="md-layout md-layout-item md-size-100 md-small-size-100">
          <label class="md-layout-item md-size-20 md-form-label md-xsmall-size-100">
            適用範囲
          </label>
          <div class="md-layout-item pt5">
            <md-radio v-model="scale" value="shop">店舗</md-radio>
            <md-radio v-model="scale" value="user_rank">会員ランク</md-radio>
            <md-radio v-model="scale" value="all_scale">全て</md-radio>
          </div>
        </div>
        <div class="md-layout md-layout-item md-size-100" v-if="this.scale==='shop'">
          <label class="md-layout-item md-size-20 md-form-label md-xsmall-size-100">
            店舗名
          </label>
          <div class="md-layout-item">
            <shop-select v-model="shopId" :touchedFlag="true" :mutlipleFlag="true"></shop-select>
          </div>
        </div>
        <div class="md-layout md-layout-item md-size-100" v-if="this.scale==='user_rank'">
          <label class="md-layout-item md-size-20 md-form-label md-xsmall-size-100">
            会員ランク
          </label>
          <div class="md-layout-item">
            <user-rank-select v-model="userRankId" :touchedFlag="true" :mutlipleFlag="true"></user-rank-select>
          </div>
        </div>
        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-20 md-form-label md-xsmall-size-100">
            ステータス
          </label>
          <div class="md-layout-item md-size-20 md-xsmall-size-100">
            <md-field :class="[
              {'md-error': errors.has('status')},
              {'md-valid': !errors.has('status') && touched.status}]">
              <md-select
                v-model="status"
                data-vv-name="status"
                v-validate= "modelValidations.status"
                required>
                <md-option value="active">有効</md-option>
                <md-option value="not_active">無効</md-option>
              </md-select>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('status')">【必須】必ず一つ選択してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('status')" style="margin-right:20px">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('status') && touched.status" style="margin-right:20px">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-20 md-form-label md-xsmall-size-100">
            キャンペーン開始日
          </label>
          <div class="md-layout-item md-size-30 md-xsmall-size-100" style="position:relative">
            <vuejs-datepicker
              :format="DatePickerFormat"
              :highlighted="highlighted"
              v-model="selectedStart"
              :language="ja"
              data-vv-name="selectedStart"
              v-validate= "modelValidations.selectedStart"
              ></vuejs-datepicker>
            <md-button
              class="md-just-icon md-default md-simple dateDelete"
              @click.native="selectedStart = ''">
              <md-icon>close</md-icon>
            </md-button>
            <slide-y-up-transition>
              <div class="vErrorText" style="margin-left: 15px" v-show="errors.has('selectedStart')">【必須】日付を選択してください</div>
            </slide-y-up-transition>
            <slide-y-down-transition>
              <md-icon class="error dateError" v-show="errors.has('selectedStart')">close</md-icon>
            </slide-y-down-transition>
            <slide-y-down-transition>
              <md-icon class="success dateSuccess" v-show="!errors.has('selectedStart') && touched.selectedStart">done</md-icon>
            </slide-y-down-transition>
          </div>
          <time-select class="md-layout-item md-xsmall-size-100" ref="startTimer" v-model="startTime"></time-select>
        </div>
        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-20 md-form-label md-xsmall-size-100">
            キャンペーン終了日
          </label>
          <div class="md-layout-item md-size-30 md-xsmall-size-100" style="position:relative">
            <vuejs-datepicker
              :format="DatePickerFormat"
              :highlighted="highlighted"
              v-model="selectedEnd"
              :language="ja"
              data-vv-name="selectedEnd"
              v-validate= "modelValidations.selectedEnd"
              ></vuejs-datepicker>
            <md-button
              class="md-just-icon md-default md-simple dateDelete"
              @click.native="selectedEnd = ''">
              <md-icon>close</md-icon>
            </md-button>
            <slide-y-up-transition>
              <div class="vErrorText" style="margin-left: 15px" v-show="errors.has('selectedEnd')">【必須】日付を選択してください</div>
            </slide-y-up-transition>
            <slide-y-down-transition>
              <md-icon class="error dateError" v-show="errors.has('selectedEnd')">close</md-icon>
            </slide-y-down-transition>
            <slide-y-down-transition>
              <md-icon class="success dateSuccess" v-show="!errors.has('selectedEnd') && touched.selectedEnd">done</md-icon>
            </slide-y-down-transition>
          </div>
          <time-select class="md-layout-item md-xsmall-size-100" ref="endTimer" v-model="endTime"></time-select>
        </div>
      </md-card-content>
      <md-card-actions md-alignment="left" class="mt60 mx-auto">
        <submit-button class="md-success md-lg" :onclick="validate" :buttonWord=this.buttonWord type="submit"></submit-button>
      </md-card-actions>
    </md-card>
  </div>

  <div class="md-layout-item md-size-100">
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-green">
        <div class="card-icon">
          <md-icon>assignment</md-icon>
        </div>
        <h4 class="title">{{rateData.title}}倍率一覧</h4>
      </md-card-header>
      <md-card-content>
        <div class="dataTableWrapper">
          <draggable @sort="updateList" draggable=".tItem" class="dataTable" :options="{handle:'.cursorGrab',animation:200}">
            <div class="tHead">
              <div class="tHead_th">キャンペーン名</div>
              <div class="tHead_th">倍率</div>
              <div class="tHead_th">適用範囲</div>
              <div class="tHead_th">ステータス</div>
              <div class="tHead_th">キャンペーン期間</div>
              <div class="tHead_th taR">アクション</div>
            </div>
            <div v-for="item in tableData" :data-id="item.id" class="tItem">
              <div class="tItem_td commentBody"><md-label style="word-break:break-all;">{{ item.campaignName }}</md-label></div>
              <div class="tItem_td"><md-label style="word-break:break-all;">{{ item.rate }}</md-label></div>
              <div class="tItem_td"><md-label style="word-break:break-all;">{{ item.scale }}</md-label></div>
              <div class="tItem_td"><md-label style="word-break:break-all;">{{ item.status }}</md-label></div>
              <div class="tItem_td">{{ item.period }}</div>
              <div class="tItem_td taR td_actionTh">
                <md-button
                  class="md-just-icon md-warning mg0"
                  @click.native="jumpDetail(item)">
                  <md-icon>dvr</md-icon>
                </md-button>
                <md-button
                  class="md-just-icon md-danger mg0"
                  @click.native="handleDelete(item)">
                  <md-icon>close</md-icon>
                </md-button>
              </div>
            </div>
          </draggable>
        </div>
      </md-card-content>
    </md-card>
  </div>
</div>
