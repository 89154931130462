
<div class="md-layout">
  <div class="md-layout-item md-size-100">
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-green">
        <div class="card-icon">
          <md-icon>assignment</md-icon>
        </div>
        <h4 class="title">店舗基本情報設定</h4>
      </md-card-header>

      <md-card-content>
        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            店舗名
          </label>
          <div class="md-layout-item">
            <md-field :class="[
              {'md-error': errors.has('companyName')},
              {'md-valid': !errors.has('companyName') && touched.companyName}]">
              <md-input
                v-model="companyName"
                data-vv-name="companyName"
                type="text"
                v-validate= "modelValidations.companyName"
                required>
              </md-input>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('companyName')">【必須】255文字以内で入力してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('companyName')">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('companyName') && touched.companyName">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>
        <div class="md-layout md-layout-item md-size-100" v-if="superFlag">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            企業名
          </label>
          <div class="md-layout-item md-size-30 md-xsmall-size-100">
            <md-field :class="[
              {'md-error': errors.has('enterpriseName')},
              {'md-valid': !errors.has('enterpriseName') && touched.enterpriseName}]">
              <md-select
                v-model="enterpriseName"
                data-vv-name="enterpriseName"
                v-validate= "superFlag === true ? modelValidations.enterpriseName : ''"
                required>
                <md-option v-for="item in enterpriseList" :value="item.id">{{item.name}}</md-option>
              </md-select>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('enterpriseName')">【必須】必ず一つ選択してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('enterpriseName')" style="margin-right:20px">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('enterpriseName') && touched.enterpriseName" style="margin-right:20px">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>
        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            郵便番号
          </label>
          <div class="md-layout-item md-aSize-100">
            <md-field :md-counter="false" :class="[
              {'md-error': errors.has('zipCode1')},
              {'md-valid': !errors.has('zipCode1') && touched.zipCode1}]">
              <md-input
                v-model="zipCode1"
                data-vv-name="zipCode1"
                type="number"
                v-validate= "modelValidations.zipCode1"
                required>
              </md-input>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('zipCode1') || errors.has('zipCode2')">【必須】前3桁・後4桁の半角数字で入力してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('zipCode1')" style="margin-right:10px">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('zipCode1') && touched.zipCode1" style="margin-right:10px">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
          <div class="md-layout-item md-size-5 zipBetween">ー</div>
          <div class="md-layout-item md-aSize-120">
            <md-field :md-counter="false" :class="[
              {'md-error': errors.has('zipCode2')},
              {'md-valid': !errors.has('zipCode2') && touched.zipCode2}]">
              <md-input
                v-model="zipCode2"
                data-vv-name="zipCode2"
                type="number"
                v-validate= "modelValidations.zipCode2"
                required>
              </md-input>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('zipCode2')" style="margin-right:10px">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('zipCode2') && touched.zipCode2" style="margin-right:10px">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            都道府県
          </label>
          <div class="md-layout-item md-size-20 md-xsmall-size-100">
            <md-field :class="[
              {'md-error': errors.has('prefecture')},
              {'md-valid': !errors.has('prefecture') && touched.prefecture}]">
              <md-select
                v-model="prefecture"
                data-vv-name="prefecture"
                v-validate= "modelValidations.prefecture"
                required>
                <md-option value="北海道">北海道</md-option>
                <md-option value="青森県">青森県</md-option>
                <md-option value="岩手県">岩手県</md-option>
                <md-option value="宮城県">宮城県</md-option>
                <md-option value="秋田県">秋田県</md-option>
                <md-option value="山形県">山形県</md-option>
                <md-option value="福島県">福島県</md-option>
                <md-option value="茨城県">茨城県</md-option>
                <md-option value="栃木県">栃木県</md-option>
                <md-option value="群馬県">群馬県</md-option>
                <md-option value="埼玉県">埼玉県</md-option>
                <md-option value="千葉県">千葉県</md-option>
                <md-option value="東京都">東京都</md-option>
                <md-option value="神奈川県">神奈川県</md-option>
                <md-option value="新潟県">新潟県</md-option>
                <md-option value="富山県">富山県</md-option>
                <md-option value="石川県">石川県</md-option>
                <md-option value="福井県">福井県</md-option>
                <md-option value="山梨県">山梨県</md-option>
                <md-option value="長野県">長野県</md-option>
                <md-option value="岐阜県">岐阜県</md-option>
                <md-option value="静岡県">静岡県</md-option>
                <md-option value="愛知県">愛知県</md-option>
                <md-option value="三重県">三重県</md-option>
                <md-option value="滋賀県">滋賀県</md-option>
                <md-option value="京都府">京都府</md-option>
                <md-option value="大阪府">大阪府</md-option>
                <md-option value="兵庫県">兵庫県</md-option>
                <md-option value="奈良県">奈良県</md-option>
                <md-option value="和歌山県">和歌山県</md-option>
                <md-option value="鳥取県">鳥取県</md-option>
                <md-option value="島根県">島根県</md-option>
                <md-option value="岡山県">岡山県</md-option>
                <md-option value="広島県">広島県</md-option>
                <md-option value="山口県">山口県</md-option>
                <md-option value="徳島県">徳島県</md-option>
                <md-option value="香川県">香川県</md-option>
                <md-option value="愛媛県">愛媛県</md-option>
                <md-option value="高知県">高知県</md-option>
                <md-option value="福岡県">福岡県</md-option>
                <md-option value="佐賀県">佐賀県</md-option>
                <md-option value="長崎県">長崎県</md-option>
                <md-option value="熊本県">熊本県</md-option>
                <md-option value="大分県">大分県</md-option>
                <md-option value="宮崎県">宮崎県</md-option>
                <md-option value="鹿児島県">鹿児島県</md-option>
                <md-option value="沖縄県">沖縄県</md-option>
              </md-select>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('prefecture')">【必須】必ず一つ選択してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('prefecture')" style="margin-right:20px">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('prefecture') && touched.prefecture" style="margin-right:20px">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            市区町村
          </label>
          <div class="md-layout-item">
            <md-field :class="[
              {'md-error': errors.has('city')},
              {'md-valid': !errors.has('city') && touched.city}]">
              <md-input
                v-model="city"
                data-vv-name="city"
                type="text"
                v-validate= "modelValidations.city"
                required>
              </md-input>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('city')">【必須】255文字以内で入力してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('city')">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('city') && touched.city">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            番地
          </label>
          <div class="md-layout-item">
            <md-field :class="[
              {'md-error': errors.has('street1')},
              {'md-valid': !errors.has('street1') && touched.street1}]">
              <md-input
                v-model="street1"
                data-vv-name="street1"
                type="text"
                v-validate= "modelValidations.street1"
                required>
              </md-input>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('street1')">【必須】255文字以内で入力してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('street1')">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('street1') && touched.street1">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            建物名
          </label>
          <div class="md-layout-item">
            <md-field :class="[
              {'md-error': errors.has('street2')},
              {'md-valid': !errors.has('street2') && touched.street2}]">
              <md-input
                v-model="street2"
                data-vv-name="street2"
                type="text"
                v-validate= "modelValidations.street2">
              </md-input>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('street2')">255文字以内で入力してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('street2')">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('street2') && touched.street2">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            電話番号
          </label>
          <div class="md-layout-item md-size-10 md-xsmall-size-30">
            <md-field :class="[
              {'md-error': errors.has('tel1')},
              {'md-valid': !errors.has('tel1') && touched.tel1}]">
              <md-input
                v-model="tel1"
                data-vv-name="tel1"
                type="number"
                v-validate= "modelValidations.tel1"
                required>
              </md-input>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('tel1') || errors.has('tel2') || errors.has('tel3')">【必須】それぞれ４桁以内の半角数字で入力してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('tel1')" style="margin-right:10px">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('tel1') && touched.tel1" style="margin-right:10px">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
          <div class="md-layout-item md-size-5 zipBetween">ー</div>
          <div class="md-layout-item md-size-10 md-xsmall-size-30">
            <md-field :class="[
              {'md-error': errors.has('tel2')},
              {'md-valid': !errors.has('tel2') && touched.tel2}]">
              <md-input
                v-model="tel2"
                data-vv-name="tel2"
                type="number"
                v-validate= "modelValidations.tel2"
                required>
              </md-input>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('tel2')" style="margin-right:10px">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('tel2') && touched.tel2" style="margin-right:10px">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
          <div class="md-layout-item md-size-5 zipBetween">ー</div>
          <div class="md-layout-item md-size-10 md-xsmall-size-30">
            <md-field :class="[
              {'md-error': errors.has('tel3')},
              {'md-valid': !errors.has('tel3') && touched.tel3}]">
              <md-input
                v-model="tel3"
                data-vv-name="tel3"
                type="number"
                v-validate= "modelValidations.tel3"
                required>
              </md-input>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('tel3')" style="margin-right:10px">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('tel3') && touched.tel3" style="margin-right:10px">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            ステータス
          </label>
          <div class="md-layout-item md-size-20 md-xsmall-size-100">
            <md-field :class="[
              {'md-error': errors.has('status')},
              {'md-valid': !errors.has('status') && touched.status}]">
              <md-select
                v-model="status"
                data-vv-name="status"
                v-validate= "modelValidations.status"
                required>
                <md-option value="active">有効</md-option>
                <md-option value="not_active">無効</md-option>
              </md-select>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('status')">【必須】必ず一つ選択してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('status')" style="margin-right:20px">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('status') && touched.status" style="margin-right:20px">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>
        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            開店・閉店状況
          </label>
          <div class="md-layout-item md-size-20 md-xsmall-size-100">
            <md-field :class="[
              {'md-error': errors.has('openStatus')},
              {'md-valid': !errors.has('openStatus') && touched.openStatus}]">
              <md-select
                v-model="openStatus"
                data-vv-name="openStatus"
                v-validate= "modelValidations.openStatus"
                required>
                <md-option value="open">開店中</md-option>
                <md-option value="close">閉店中</md-option>
              </md-select>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('openStatus')">【必須】必ず一つ選択してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('openStatus')" style="margin-right:20px">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('openStatus') && touched.openStatus" style="margin-right:20px">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>
        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            決済上限金額
          </label>
          <div class="md-layout-item md-size-20 md-xsmall-size-100">
            <md-field :class="[
              {'md-error': errors.has('maxPrice')},
              {'md-valid': !errors.has('maxPrice') && touched.maxPrice}]">
              <md-input
                v-model="maxPrice"
                data-vv-name="maxPrice"
                type="text"
                v-validate= "modelValidations.maxPrice"
                required>
              </md-input>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('maxPrice')">【必須】9桁以内の半角数字で入力してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('maxPrice')" style="margin-right:20px">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('maxPrice') && touched.maxPrice" style="margin-right:20px">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
          <div class="md-layout-item unitLabel">円</div>
        </div>
        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            一日あたりの上限注文金額
          </label>
          <div class="md-layout-item md-size-20 md-xsmall-size-100">
            <md-field :class="[
              {'md-error': errors.has('maxPricePerDay')},
              {'md-valid': !errors.has('maxPricePerDay') && touched.maxPricePerDay}]">
              <md-input
                v-model="maxPricePerDay"
                data-vv-name="maxPricePerDay"
                type="text"
                v-validate= "modelValidations.maxPricePerDay"
                required>
              </md-input>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('maxPricePerDay')">【必須】9桁以内の半角数字で入力してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('maxPricePerDay')" style="margin-right:20px">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('maxPricePerDay') && touched.maxPricePerDay" style="margin-right:20px">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
          <div class="md-layout-item unitLabel">円</div>
        </div>
        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            1ヶ月あたりの上限注文金額
          </label>
          <div class="md-layout-item md-size-20 md-xsmall-size-100">
            <md-field :class="[
              {'md-error': errors.has('maxPricePerMonth')},
              {'md-valid': !errors.has('maxPricePerMonth') && touched.maxPricePerMonth}]">
              <md-input
                v-model="maxPricePerMonth"
                data-vv-name="maxPricePerMonth"
                type="text"
                v-validate= "modelValidations.maxPricePerMonth"
                required>
              </md-input>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('maxPricePerMonth')">【必須】9桁以内の半角数字で入力してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('maxPricePerMonth')" style="margin-right:20px">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('maxPricePerMonth') && touched.maxPricePerMonth" style="margin-right:20px">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
          <div class="md-layout-item unitLabel">円</div>
        </div>
      </md-card-content>
    </md-card>
  </div>
  <div class="md-layout-item md-size-100">
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-green">
        <div class="card-icon">
          <md-icon>query_builder</md-icon>
        </div>
        <h4 class="title">予約設定</h4>
      </md-card-header>

      <md-card-content>
        <div class="md-layout md-layout-item md-size-100 md-small-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            予約受付可能数
          </label>
          <div class="md-layout-item md-size-10 md-xsmall-size-50">
            <md-field :class="[
              {'md-error': errors.has('reserveCap')},
              {'md-valid': !errors.has('reserveCap') && touched.reserveCap}]">
              <md-input
                v-model="reserveCap"
                data-vv-name="reserveCap"
                type="number"
                v-validate= "modelValidations.reserveCap"
                required>
              </md-input>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('reserveCap')">【必須】3桁以内の半角数字で入力してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('reserveCap')" style="margin-right:10px">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('reserveCap') && touched.reserveCap" style="margin-right:10px">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
          <div class="md-layout-item unitLabel">件</div>
        </div>

        <div class="md-layout md-layout-item md-size-100 md-small-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            当日予約
          </label>
          <div class="md-layout-item">
            <md-radio v-model="reserveSameDay" value="not_available">不可</md-radio>
            <md-radio v-model="reserveSameDay" value="available">可能</md-radio>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100 md-small-size-100" v-show="reserveSameDay==='not_available'">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            予約可能日
          </label>
          <div class="md-layout-item md-size-10 md-xsmall-size-50">
            <md-field :class="[
              {'md-error': errors.has('reserveDay')},
              {'md-valid': !errors.has('reserveDay') && touched.reserveDay}]">
              <md-select
                v-model="reserveDay"
                data-vv-name="reserveDay"
                v-validate= "reserveSameDay === 'not_available' ? modelValidations.reserveDay : ''"
                required>
                <md-option v-for="n in 30" :value="n">{{n}}</md-option>
              </md-select>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('reserveDay')">【必須】予約可能日を選択してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('reserveDay')" style="margin-right:10px">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('reserveDay') && touched.reserveDay" style="margin-right:10px">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
          <div class="md-layout-item unitLabel">日前までの予約必須</div>
        </div>

        <div class="md-layout md-layout-item md-size-100 md-small-size-100" v-show="reserveSameDay==='available'">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            予約可能時間
          </label>
          <div class="md-layout-item md-size-10 md-xsmall-size-50">
            <md-field :class="[
              {'md-error': errors.has('reserveTime')},
              {'md-valid': !errors.has('reserveTime') && touched.reserveTime}]">
              <md-select
                v-model="reserveTime"
                data-vv-name="reserveTime"
                v-validate= "reserveSameDay === 'available' ? modelValidations.reserveTime : ''"
                required>
                <md-option v-for="n in 24" :value="n">{{n}}</md-option>
              </md-select>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('reserveTime')">【必須】予約可能時間を選択してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('reserveTime')" style="margin-right:10px">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('reserveTime') && touched.reserveTime" style="margin-right:10px">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
          <div class="md-layout-item unitLabel">時間前まで予約可能</div>
        </div>
      </md-card-content>
    </md-card>
  </div>
  <div class="md-layout-item md-size-100">
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-green">
        <div class="card-icon">
          <md-icon>query_builder</md-icon>
        </div>
        <h4 class="title">営業時間設定</h4>
      </md-card-header>

      <working-plan ref="wp"></working-plan>

      <div class="md-layout md-layout-item md-size-100 mb-20">
        <label class="md-layout-item md-size-10 md-xsmall-size-20 md-form-label weekLabel is-red pt20 ml-5">
          祝
        </label>
        <div class="md-layout-item pt5">
          <md-radio v-model="holidayStatus" :value="false">開店</md-radio>
          <md-radio v-model="holidayStatus" :value="true">閉店</md-radio>
        </div>
      </div>
    </md-card>
  </div>

  <div class="md-layout-item md-size-100">
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-green">
        <div class="card-icon">
          <md-icon>settings</md-icon>
        </div>
        <h4 class="title">オプション設定</h4>
      </md-card-header>

      <md-card-content>
        <div class="md-layout md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            イメージ画像
          </label>
          <div class="md-layout-item  md-size-30 md-xsmall-size-100">
            <div class="file-input">
              <div v-if="!imageShop">
                <div class="image-container">
                  <img :src="dummyImg" title="">
                </div>
              </div>
              <div class="image-container" v-else>
                <img :src="imageShop" />
              </div>
              <div class="button-container">
                <md-button class="md-danger md-round" @click="removeImage" v-if="imageShop" data-id="imageShop"><i class="fa fa-times"></i>削除</md-button>
                <md-button class="md-success md-round md-fileinput">
                  <template v-if="!imageShop">画像選択</template>
                  <template v-else>変更</template>
                  <input type="file" @change="onFileChange" data-id="imageShop">
                </md-button>
              </div>
            </div>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            ウェブサイト
          </label>
          <div class="md-layout-item">
            <md-field :class="[
              {'md-error': errors.has('webUrl')},
              {'md-valid': !errors.has('webUrl') && touched.webUrl}]">
              <md-input
                v-model="webUrl"
                data-vv-name="webUrl"
                v-validate= "modelValidations.webUrl">
              </md-input>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('webUrl')">半角英数記号のURL形式で入力してください（255文字以内）</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('webUrl')">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('webUrl') && touched.webUrl">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            説明
          </label>
          <div class="md-layout-item pt20">
            <froala :tag="'textarea'" :config="config" v-model="shopDetail"></froala>
          </div>
        </div>
      </md-card-content>
      <md-card-actions md-alignment="left" class="mt60 mx-auto">
        <md-button class="md-warning md-lg" @click.native.prevent="returnBack">戻る</md-button>
        <submit-button class="md-success md-lg mL40" :onclick="validate" :buttonWord=this.buttonWord type="submit"></submit-button>
      </md-card-actions>
    </md-card>
    <div class="linkCont is-xs-none" v-show="!newFlag">
      <div class="bottomLink" @click="goCalendar">休日カレンダーを編集する</div>
    </div>
  </div>
</div>
