
<div class="md-layout">
  <div class="md-layout-item md-size-100">
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-green">
        <div class="card-icon">
          <md-icon>account_box</md-icon>
        </div>
        <h4 class="title">Push通知情報</h4>
      </md-card-header>

      <md-card-content class="formLayout">
        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            配信日時
          </label>
          <div class="md-layout-item md-size-85 md-xsmall-size-100">
            <p class="form-control-static">{{deliveryDay | timeFormat2}}</p>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            ステータス
          </label>
          <div class="md-layout-item md-size-85 md-xsmall-size-100">
            <p class="form-control-static">{{status}}</p>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            店舗
          </label>
          <div class="md-layout-item md-size-85 md-xsmall-size-100">
            <p class="form-control-static">{{shopName}}</p>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            会員ランク
          </label>
          <div class="md-layout-item md-size-85 md-xsmall-size-100">
            <p class="form-control-static">{{userRank}}</p>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            内容
          </label>
          <div class="md-layout-item md-size-85 md-xsmall-size-100">
            <p class="form-control-static">{{content}}</p>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            予定配信数
          </label>
          <div class="md-layout-item md-size-85 md-xsmall-size-100">
            <p class="form-control-static">{{deliveryEstimate | numberWithDelimiter}}件</p>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            完了配信数
          </label>
          <div class="md-layout-item md-size-85 md-xsmall-size-100">
            <p class="form-control-static">{{deliveryComplete | numberWithDelimiter}}件</p>
          </div>
        </div>

      </md-card-content>
      <md-card-actions class="mx-auto" md-alignment="left">
        <md-button class="md-warning md-lg" @click.native.prevent="returnBack">戻る</md-button>
      </md-card-actions>
    </md-card>
  </div>
</div>

