
<div class="md-layout">
  <div class="md-layout-item md-size-100">
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-green">
        <div class="card-icon">
          <md-icon>account_box</md-icon>
        </div>
        <h4 class="title">チャージ履歴詳細</h4>
      </md-card-header>

      <md-card-content class="formLayout">
        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
            チャージ金額
          </label>
          <div class="md-layout-item md-size-70 md-xsmall-size-100">
            <p class="form-control-static">¥{{price | numberWithDelimiter}}</p>
          </div>
        </div>
        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
            種別
          </label>
          <div class="md-layout-item md-size-70 md-xsmall-size-100">
            <p class="form-control-static">{{type}}</p>
          </div>
        </div>
        <div class="md-layout md-layout-item md-size-100" v-show="divideFlag">
          <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
            割り勘ユーザー
          </label>
          <div class="md-layout-item md-size-70 md-xsmall-size-100">
            <p class="form-control-static">{{divideUser}}</p>
          </div>
        </div>
        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
            トランザクション番号
          </label>
          <div class="md-layout-item md-size-70 md-xsmall-size-100">
            <p class="form-control-static">{{transaction}}</p>
          </div>
        </div>
        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
            承認番号
          </label>
          <div class="md-layout-item md-size-70 md-xsmall-size-100">
            <p class="form-control-static">{{number}}</p>
          </div>
        </div>
        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
            チャージ日時
          </label>
          <div class="md-layout-item md-size-70 md-xsmall-size-100">
            <p class="form-control-static">{{charged_at | timeFormat}}</p>
          </div>
        </div>

      </md-card-content>
      <md-card-actions class="mx-auto" md-alignment="left">
        <md-button class="md-warning md-lg" @click.native.prevent="returnBack">戻る</md-button>
      </md-card-actions>
    </md-card>
  </div>
</div>

