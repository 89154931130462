
<div class="md-layout">
  <div class="md-layout-item md-size-100">
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-green">
        <div class="card-icon">
          <md-icon>search</md-icon>
        </div>
        <h4 class="title">請求情報検索</h4>
      </md-card-header>
      <md-card-content class="md-layout formLayout">

        <div class="md-layout md-layout-item md-size-100 md-small-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            企業名
          </label>
          <div class="md-layout-item md-size-40 md-xsmall-size-100">
            <company-select v-model="companyId" :noSelectFlag="true" :touchedFlag="true"></company-select>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100 md-small-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            請求年
          </label>
          <div class="md-layout-item md-size-15 md-xsmall-size-100">
            <md-field class="billingYear">
              <md-select v-model="billingYear">
                <md-option value="選択しない">選択しない</md-option>
                <md-option v-for="(billingYear,index) in years" :value="billingYear" :key="index">{{billingYear}} 年</md-option>
              </md-select>
            </md-field>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100 md-small-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            請求月
          </label>
          <div class="md-layout-item md-size-15 md-xsmall-size-100">
            <md-field class="billingMonth">
              <md-select v-model="billingMonth">
                <md-option value="選択しない">選択しない</md-option>
                <md-option v-for="(billingMonth,index) in monthly" :value="billingMonth" :key="index">{{billingMonth}} 月</md-option>
              </md-select>
            </md-field>
          </div>
        </div>

      </md-card-content>
      <md-card-actions class="mx-auto">
        <md-button class="md-success md-lg" @click.native="getBillingStatement()">検索</md-button>
        <md-button class="md-info md-lg mL40" @click="handleClear">検索条件クリア</md-button>
      </md-card-actions>
    </md-card>
  </div>

  <transition name="fade">
    <div class="md-layout-item md-size-100" v-if="resultFlag">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">請求一覧</h4>
        </md-card-header>
        <md-card-content>
          <div class="dataTableWrapper">
            <div class="dataTable"> 
              <div class="tHead">
                <div class="tHead_th">企業名</div>
                <div class="tHead_th">請求日</div>
                <div class="tHead_th">請求額</div>
                <div class="tHead_th taR">アクション</div>
              </div>
              <div v-for="item in tableData" :data-id="item.id" class="tItem">
                <div class="tItem_td"><md-label style="word-break:break-all;">{{ item.company_name }}</md-label></div>
                <div class="tItem_td"><md-label style="word-break:break-all;">{{ item.billing_date }}</md-label></div>
                <div class="tItem_td"><md-label style="word-break:break-all;">{{ item.total_charge }} 円</md-label></div>
                <div class="tItem_td taR td_actionTw">
                  <md-button
                    class="md-just-icon md-warning mg0"
                    @click.native="jumpDetail(item)">
                    <md-icon>dvr</md-icon>
                  </md-button>
                </div>
              </div>
            </div>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">全{{ pageData.totalCount | numberWithDelimiter }}件中 {{from | numberWithDelimiter}}件から {{to | numberWithDelimiter}}件を表示</p>
          </div>
          <Pagination class="Pagination-no-border Pagination-success"
            :currentPage="pageData.currentPage"
            :totalPage="pageData.totalPage"
            @get="getBillingStatement">
          </Pagination>
        </md-card-actions>
      </md-card>
    </div>
  </transition>
</div>
