
<div class="md-layout">
  <div class="md-layout-item md-size-100">
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-green">
        <div class="card-icon">
          <md-icon>create</md-icon>
        </div>
        <h4 class="title">アプリメイン画像設定</h4>
      </md-card-header>
      <md-card-content class="md-layout formLayout">
        <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
          <div class="md-layout-item md-size-100">
            <div class="labels">iOS用</div>
            <div class="file-input imageWidth">
              <div v-if="!imageMainIos">
                <div class="image-container">
                  <img :src="dummyImg" title="">
                </div>
              </div>
              <div class="image-container" v-else>
                <img :src="imageMainIos" />
              </div>
              <div class="button-container">
                <md-button class="md-danger md-round" @click="removeSingleImage" v-if="imageMainIos" data-id="imageMainIos"><i class="fa fa-times"></i>削除</md-button>
                <md-button class="md-success md-round md-fileinput">
                  <template v-if="!imageMainIos">画像選択</template>
                  <template v-else>変更</template>
                  <input type="file" @change="onFileSingleChange" data-id="imageMainIos">
                </md-button>
              </div>
            </div>
            <ul class="imgAttention">
              <li>推奨サイズは1500px × 900pxです</li>
              <li>表示するデバイスサイズにより、うまく表示できない場合があります</li>
              <li>対応ファイル：jpg,png</li>
            </ul>
          </div>
        </div>
        <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
          <div class="md-layout-item md-size-100">
            <div class="labels">Android用</div>
            <div class="file-input imageWidth">
              <div v-if="!imageMainAnd">
                <div class="image-container">
                  <img :src="dummyImg" title="">
                </div>
              </div>
              <div class="image-container" v-else>
                <img :src="imageMainAnd" />
              </div>
              <div class="button-container">
                <md-button class="md-danger md-round" @click="removeSingleImage" v-if="imageMainAnd" data-id="imageMainAnd"><i class="fa fa-times"></i>削除</md-button>
                <md-button class="md-success md-round md-fileinput">
                  <template v-if="!imageMainAnd">画像選択</template>
                  <template v-else>変更</template>
                  <input type="file" @change="onFileSingleChange" data-id="imageMainAnd">
                </md-button>
              </div>
            </div>
            <ul class="imgAttention">
              <li>推奨サイズは1500px × 900pxです</li>
              <li>表示するデバイスサイズにより、うまく表示できない場合があります</li>
              <li>対応ファイル：jpg,png</li>
            </ul>
          </div>
        </div>
      </md-card-content>

      <md-card-actions md-alignment="left" class="mt60 mx-auto">
        <md-button class="md-success md-lg" @click.native="uploadImage('main')">設定</md-button>
      </md-card-actions>
    </md-card>

    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-green">
        <div class="card-icon">
          <md-icon>create</md-icon>
        </div>
        <h4 class="title">スプラッシュ画像設定</h4>
      </md-card-header>
      <md-card-content class="md-layout formLayout">
        <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
          <div class="md-layout-item md-size-100">
            <div class="labels">iOS用</div>
            <div class="file-input imageWidth">
              <div v-if="!imageSplashIos">
                <div class="image-container">
                  <img :src="dummyImg" title="">
                </div>
              </div>
              <div class="image-container" v-else>
                <img :src="imageSplashIos" />
              </div>
              <div class="button-container">
                <md-button class="md-danger md-round" @click="removeSingleImage" v-if="imageSplashIos" data-id="imageSplashIos"><i class="fa fa-times"></i>削除</md-button>
                <md-button class="md-success md-round md-fileinput">
                  <template v-if="!imageSplashIos">画像選択</template>
                  <template v-else>変更</template>
                  <input type="file" @change="onFileSingleChange" data-id="imageSplashIos">
                </md-button>
              </div>
            </div>
            <ul class="imgAttention">
              <li>推奨サイズは2732px × 2732pxです</li>
              <li>表示するデバイスサイズにより、うまく表示できない場合があります</li>
              <li>対応ファイル：jpg,png</li>
            </ul>
          </div>
        </div>
        <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
          <div class="md-layout-item md-size-100">
            <div class="labels">Android用</div>
            <div class="file-input imageWidth">
              <div v-if="!imageSplashAnd">
                <div class="image-container">
                  <img :src="dummyImg" title="">
                </div>
              </div>
              <div class="image-container" v-else>
                <img :src="imageSplashAnd" />
              </div>
              <div class="button-container">
                <md-button class="md-danger md-round" @click="removeSingleImage" v-if="imageSplashAnd" data-id="imageSplashAnd"><i class="fa fa-times"></i>削除</md-button>
                <md-button class="md-success md-round md-fileinput">
                  <template v-if="!imageSplashAnd">画像選択</template>
                  <template v-else>変更</template>
                  <input type="file" @change="onFileSingleChange" data-id="imageSplashAnd">
                </md-button>
              </div>
            </div>
            <ul class="imgAttention">
              <li>推奨サイズは950px × 950pxです</li>
              <li>表示するデバイスサイズにより、うまく表示できない場合があります</li>
              <li>Android用のスプラッシュ画像は、自動的に画面サイズの最適化が行われる9-patch形式のPNG画像ファイルが推奨されます。</li>
            </ul>
          </div>
        </div>
      </md-card-content>

      <md-card-actions md-alignment="left" class="mt60 mx-auto">
        <md-button class="md-success md-lg" @click.native="uploadImage('splash')">設定</md-button>
      </md-card-actions>
    </md-card>

    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-green">
        <div class="card-icon">
          <md-icon>create</md-icon>
        </div>
        <h4 class="title">アプリアイコン画像設定</h4>
      </md-card-header>
      <md-card-content class="md-layout formLayout">
        <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
          <div class="md-layout-item md-size-100">
            <div class="labels">iOS用</div>
            <div class="file-input imageWidth w200">
              <div v-if="!imageIconIos">
                <div class="image-container">
                  <img :src="dummyImg" title="">
                </div>
              </div>
              <div class="image-container" v-else>
                <img :src="imageIconIos" />
              </div>
              <div class="button-container">
                <md-button class="md-danger md-round" @click="removeSingleImage" v-if="imageIconIos" data-id="imageIconIos"><i class="fa fa-times"></i>削除</md-button>
                <md-button class="md-success md-round md-fileinput">
                  <template v-if="!imageIconIos">画像選択</template>
                  <template v-else>変更</template>
                  <input type="file" @change="onFileSingleChange" data-id="imageIconIos">
                </md-button>
              </div>
            </div>
            <ul class="imgAttention">
              <li>推奨サイズは180px × 180pxです</li>
              <li>対応ファイル：png</li>
            </ul>
          </div>
        </div>
        <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
          <div class="md-layout-item md-size-100">
            <div class="labels">Android用</div>
            <div class="file-input imageWidth w200">
              <div v-if="!imageIconAnd">
                <div class="image-container">
                  <img :src="dummyImg" title="">
                </div>
              </div>
              <div class="image-container" v-else>
                <img :src="imageIconAnd" />
              </div>
              <div class="button-container">
                <md-button class="md-danger md-round" @click="removeSingleImage" v-if="imageIconAnd" data-id="imageIconAnd"><i class="fa fa-times"></i>削除</md-button>
                <md-button class="md-success md-round md-fileinput">
                  <template v-if="!imageIconAnd">画像選択</template>
                  <template v-else>変更</template>
                  <input type="file" @change="onFileSingleChange" data-id="imageIconAnd">
                </md-button>
              </div>
            </div>
            <ul class="imgAttention">
              <li>推奨サイズは192px × 192pxです</li>
              <li>対応ファイル：png</li>
            </ul>
          </div>
        </div>
      </md-card-content>

      <md-card-actions md-alignment="left" class="mt60 mx-auto">
        <md-button class="md-success md-lg" @click.native="uploadImage('logo')">設定</md-button>
      </md-card-actions>
    </md-card>

    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-green">
        <div class="card-icon">
          <md-icon>create</md-icon>
        </div>
        <h4 class="title">ウォークスルー画像設定</h4>
      </md-card-header>
      <md-card-content class="md-layout formLayout">
        <div class="md-layout-item md-size-100 pl30">
          <div v-show="buttonFlag" class="BtnAdd" @click="addImage"><md-icon>add</md-icon><span>ウォークスルー枠を追加する</span></div>
          <div v-show="imgList.length" class="BtnRemove" @click="removeImage"><md-icon>remove</md-icon><span>ウォークスルー枠を削除する</span></div>
        </div>
        <div class="md-layout md-layout-item md-size-100">
          <div class="md-layout-item md-xsmall-size-100">
            <div class="md-layout-item md-size-walk mx-auto file-input imageWalk mb30" v-for="(item,index) in imgList" :key="index">
              <div class="walkLabel">{{index + 1}}枚目</div>
              <div v-if="!item.url">
                <div class="image-container">
                  <img :src="dummyWalk" title="">
                </div>
              </div>
              <div class="image-container" v-else>
                <img :src="item.url" />
              </div>
              <div class="button-container">
                <md-button class="md-danger md-round" @click="removeMultiImage" v-if="item.url" :data-id="index"><i class="fa fa-times"></i>削除</md-button>
                <md-button class="md-success md-round md-fileinput">
                  <template v-if="!item.url">画像選択</template>
                  <template v-else>変更</template>
                  <input type="file" @input="onFileMultiChange" :data-id="index">
                </md-button>
              </div>
            </div>
          </div>
        </div>

        <ul class="imgAttention md-layout-item md-size-100 pl30">
          <li>推奨サイズは1125px × 2000pxです</li>
          <li>ウォークスルー画像は最大６枚まで登録できます</li>
          <li>表示するデバイスサイズにより、うまく表示できない場合があります</li>
          <li>サンプル画像の点線部分にはボタン等が生成されるため、該当箇所の情報が読めなくなる場合があります</li>
          <li>対応ファイル：jpg,png</li>
        </ul>
      </md-card-content>

      <md-card-actions md-alignment="left" class="mt60 mx-auto">
        <md-button class="md-success md-lg" @click.native="uploadImage('walk')">登録</md-button>
      </md-card-actions>
    </md-card>

    <md-card v-show="!basicLock">
      <md-card-header class="md-card-header-icon md-card-header-green">
        <div class="card-icon">
          <md-icon>create</md-icon>
        </div>
        <h4 class="title">画像一括ダウンロード</h4>
      </md-card-header>
      <md-card-actions md-alignment="left" class="mt60 mx-auto">
        <md-button class="md-success md-lg" @click.native="downloadImage">ダウンロード</md-button>
      </md-card-actions>
    </md-card>

    <div class="linkCont">
      <div class="bottomLink" @click="goSetting" v-show="!basicLock">基本情報を編集する</div>
      <div class="bottomLink" @click="goDelivery">配信情報を編集する</div>
      <div class="bottomLink" @click="goService">サービス情報を編集する</div>
    </div>
  </div>
</div>

