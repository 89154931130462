
<div class="md-layout">
  <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-50" v-if="companyFlag || superFlag">
    <stats-card header-color="blue">
      <template slot="header">
        <div class="card-icon">
          <md-icon>today</md-icon>
        </div>
        <p class="category">今日の注文数</p>
        <h3 class="title">
          {{todayOrderNum}}
          <!-- <animated-number :value="todayOrderNum"></animated-number> -->
        </h3>
      </template>

      <template slot="footer">
        <p class="category" v-show="todayOrderNumPer !== ''">
          {{todayOrderNumPer | numberWithDelimiter}}%
          <!-- <span class="text-success"><i class="fas fa-long-arrow-alt-up"></i> <animated-number :value="todayOrderNumPer"></animated-number>% </span> 昨日より増加 -->
        </p>
      </template>
    </stats-card>
  </div>
  <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-50" v-if="companyFlag || superFlag">
    <stats-card header-color="rose">
      <template slot="header">
        <div class="card-icon">
          <md-icon>equalizer</md-icon>
        </div>
        <p class="category">今日の注文額</p>
        <h3 class="title">
          ¥{{todayOrderAmount | numberWithDelimiter}}
          <!-- <animated-number :formatValue="money" :duration="duration" :value="todayOrderAmount"></animated-number> -->
        </h3>
      </template>

      <template slot="footer">
        <p class="category" v-show="todayOrderAmountPer !== ''">
          {{todayOrderAmountPer}}%
          <!-- <span class="text-success"><i class="fas fa-long-arrow-alt-up"></i> <animated-number :value="todayOrderAmountPer"></animated-number>% </span> 昨日より増加 -->
        </p>
      </template>
    </stats-card>
  </div>
  <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-50" v-if="companyFlag || superFlag">
    <stats-card header-color="green">
      <template  slot="header">
        <div class="card-icon">
          <md-icon >store</md-icon>
        </div>
        <p class="category">今月の注文数</p>
        <h3 class="title">
          {{thismonthOrderNum | numberWithDelimiter}}
          <!-- <animated-number :value="thismonthOrderNum"></animated-number> -->
        </h3>
      </template>

      <template slot="footer">
        <p class="category" v-show="thismonthOrderNumPer !== ''">
          {{thismonthOrderNumPer}}%
          <!-- <span class="text-danger"><i class="fas fa-long-arrow-alt-down"></i> <animated-number :value="thismonthOrderNumPer"></animated-number>% </span> 先月より減少 -->
        </p>
      </template>
    </stats-card>
  </div>
  <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-50" v-if="companyFlag || superFlag">
    <stats-card header-color="warning">
      <template slot="header">
        <div class="card-icon">
          <md-icon>weekend</md-icon>
        </div>
        <p class="category">今月の注文額</p>
        <h3 class="title">
          ¥{{thismonthOrderAmount | numberWithDelimiter}}
          <!-- <animated-number :formatValue="money" :duration="duration" :value="thismonthOrderAmount"></animated-number> -->
        </h3>
      </template>

      <template slot="footer">
        <p class="category" v-show="thismonthOrderAmountPer !== ''">
          {{thismonthOrderAmountPer}}%
          <!-- <span class="text-danger"><i class="fas fa-long-arrow-alt-down"></i> <animated-number :value="thismonthOrderAmountPer"></animated-number>% </span> 先月より減少 -->
        </p>
      </template>
    </stats-card>
  </div>
  <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100" v-if="shopFlag">
    <stats-card header-color="warning">
      <template slot="header">
        <div class="card-icon">
          <md-icon>weekend</md-icon>
        </div>
        <p class="category">本日の予約</p>
        <h3 class="title"> {{todayReserveNum}}
          <!-- <animated-number :value="todayReserveNum"></animated-number> -->
        </h3>
      </template>

      <template slot="footer">
        <div class="btnArea">
          <md-button class="md-primary btnConfirm" @click="confirmOrder">今すぐ確認</md-button>
        </div>
      </template>
    </stats-card>
  </div>
</div>
