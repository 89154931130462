
<div class="md-layout">
  <div class="md-layout-item md-size-100">
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-green">
        <div class="card-icon">
          <md-icon>calendar_today</md-icon>
        </div>
        <h4 class="title">営業カレンダー</h4>
      </md-card-header>

      <md-card-content>
        <FullCalendar :options="calendarOptions"  ref="fullCalendar" />
      </md-card-content>
      <md-card-actions md-alignment="left" class="mt60">
        <md-button class="md-success btnCenter" @click.native="updateHoliday">営業カレンダー登録</md-button>
      </md-card-actions>
    </md-card>
    <div class="linkCont">
      <div class="bottomLink" @click="goSetting">基本情報を編集する</div>
    </div>
  </div>
</div>
