<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>search</md-icon>
          </div>
          <h4 class="title">請求情報検索</h4>
        </md-card-header>
        <md-card-content class="md-layout formLayout">

          <div class="md-layout md-layout-item md-size-100 md-small-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              企業名
            </label>
            <div class="md-layout-item md-size-40 md-xsmall-size-100">
              <company-select v-model="companyId" :noSelectFlag="true" :touchedFlag="true"></company-select>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100 md-small-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              請求年
            </label>
            <div class="md-layout-item md-size-15 md-xsmall-size-100">
              <md-field class="billingYear">
                <md-select v-model="billingYear">
                  <md-option value="選択しない">選択しない</md-option>
                  <md-option v-for="(billingYear,index) in years" :value="billingYear" :key="index">{{billingYear}} 年</md-option>
                </md-select>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100 md-small-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              請求月
            </label>
            <div class="md-layout-item md-size-15 md-xsmall-size-100">
              <md-field class="billingMonth">
                <md-select v-model="billingMonth">
                  <md-option value="選択しない">選択しない</md-option>
                  <md-option v-for="(billingMonth,index) in monthly" :value="billingMonth" :key="index">{{billingMonth}} 月</md-option>
                </md-select>
              </md-field>
            </div>
          </div>

        </md-card-content>
        <md-card-actions class="mx-auto">
          <md-button class="md-success md-lg" @click.native="getBillingStatement()">検索</md-button>
          <md-button class="md-info md-lg mL40" @click="handleClear">検索条件クリア</md-button>
        </md-card-actions>
      </md-card>
    </div>

    <transition name="fade">
      <div class="md-layout-item md-size-100" v-if="resultFlag">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>assignment</md-icon>
            </div>
            <h4 class="title">請求一覧</h4>
          </md-card-header>
          <md-card-content>
            <div class="dataTableWrapper">
              <div class="dataTable"> 
                <div class="tHead">
                  <div class="tHead_th">企業名</div>
                  <div class="tHead_th">請求日</div>
                  <div class="tHead_th">請求額</div>
                  <div class="tHead_th taR">アクション</div>
                </div>
                <div v-for="item in tableData" :data-id="item.id" class="tItem">
                  <div class="tItem_td"><md-label style="word-break:break-all;">{{ item.company_name }}</md-label></div>
                  <div class="tItem_td"><md-label style="word-break:break-all;">{{ item.billing_date }}</md-label></div>
                  <div class="tItem_td"><md-label style="word-break:break-all;">{{ item.total_charge }} 円</md-label></div>
                  <div class="tItem_td taR td_actionTw">
                    <md-button
                      class="md-just-icon md-warning mg0"
                      @click.native="jumpDetail(item)">
                      <md-icon>dvr</md-icon>
                    </md-button>
                  </div>
                </div>
              </div>
            </div>
          </md-card-content>
          <md-card-actions md-alignment="space-between">
            <div class="">
              <p class="card-category">全{{ pageData.totalCount | numberWithDelimiter }}件中 {{from | numberWithDelimiter}}件から {{to | numberWithDelimiter}}件を表示</p>
            </div>
            <Pagination class="Pagination-no-border Pagination-success"
              :currentPage="pageData.currentPage"
              :totalPage="pageData.totalPage"
              @get="getBillingStatement">
            </Pagination>
          </md-card-actions>
        </md-card>
      </div>
    </transition>
  </div>
</template>

<script>
  import Pagination from '../components/additional/Pagination.vue'
  import router from '../entrypoints/router.js'
  import Fuse from 'fuse.js'
  import swal from 'sweetalert2'
  import role from "../mixins/role.js"
  import CompanySelect from '../components/additional/CompanySelect.vue'

  export default {
    mixins: [ role ],
    components: {
      Pagination,
      CompanySelect
    },
    computed: {
      to () {
        return (this.pageData.currentPage - 1) * 25 + this.tableData.length
      },
      from () {
        return (this.pageData.currentPage - 1) * 25 + 1
      },
      saveCondition() {
        return this.$store.getters['billingStatementList/condition']
      }
    },
    data () {
      return {
        companyId: '',
        billingYear: new Date().getFullYear(),
        billingMonth: new Date().getMonth() + 1,
        monthly: [1,2,3,4,5,6,7,8,9,10,11,12],
        years: [],
        yearPeriod: 5,
        tableData:[],
        resultFlag: false,
        pageData: {
          totalPage: 0,
          currentPage: 0,
          nextPage: null,
          prevPage: null,
          totalCount: 0
        }
      }
    },
    methods: {
      getBillingStatement (page = 1) {
        var condition = {
          page: page,
          company_id: this.companyId == '選択しない' ? '' : this.companyId,
          billing_year: this.billingYear == '選択しない' ? '' : this.billingYear,
          billing_month: this.billingMonth == '選択しない' ? '' : this.billingMonth
        }
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/billing_statements',
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
          params: condition
        }).then(response => {
          if (response.data.data.length === 0) {
            this.resultFlag = false
            this.noSearchResults()
          } else {
            this.createList(response.data)
            this.pageData.totalPage = response.data.meta['total-pages']
            this.pageData.currentPage = response.data.meta['current-page']
            this.pageData.totalCount = response.data.meta['total-count']
          }
          this.$store.dispatch('billingStatementList/searchParamsAsync', condition)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('請求情報を正しく取得できませんでした')
          }
        })
      },
      createList (obj) {
        var res = obj.data
        this.tableData.splice(0)
        for (var i in res) {
          var item = {
            id: res[i].id,
            company_name: res[i].attributes['company-name'],
            billing_date: res[i].attributes['billing-date'],
            total_charge: res[i].attributes['total-charge']
          }
          this.tableData.push(item)
        }
        this.resultFlag = true
      },
      customSort (value) {
        return value.sort((a, b) => {
          const sortBy = this.currentSort
          if (this.currentSortOrder === 'desc') {
            return a[sortBy].localeCompare(b[sortBy])
          }
          return b[sortBy].localeCompare(a[sortBy])
        })
      },
      jumpDetail (item) {
        router.push({ path: '/admin/billing_statement/' + item.id })
      },
      reloadPage() {
        this.companyId = this.saveCondition.company_id || '選択しない'
        this.billingYear = this.saveCondition.billing_year || '選択しない'
        this.billingMonth = this.saveCondition.billing_month || '選択しない'
        this.getBillingStatement(this.saveCondition.page)
      },
      handleClear() {
        this.companyId = '選択しない'
        this.billingYear = new Date().getFullYear()
        this.billingMonth = new Date().getMonth() + 1
        this.getBillingStatement()
      }
    },
    created() {
      const year = new Date().getFullYear()
      for (let i = 0; i < this.yearPeriod; i++) {
        this.years.push(year - i)
      }
    },
    mounted () {
      if (!this.superFlag) {
        router.push({ path: '/admin/login'})
        return false
      }
      if (!this.$route.query.fromSidebar && this.saveCondition) {
        this.reloadPage()
      } else {
        this.$store.dispatch('billingStatementList/searchParamsAsync', null)
        this.handleClear()
      }
    }
  }
</script>

<style lang="css" scoped>
.fade-enter-active, .fade-leave-active {
  will-change: opacity;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.fade-enter, .fade-leave-to {
  opacity: 0
}

.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.btnCenter{
  margin-left: auto !important;
  margin-right: auto !important;
}

.priceCell{
  font-weight: bold;
  text-align: right;
}

.formLayout{
  flex-basis: auto;
}

.mg0{
  margin: 0;
}

.mgR2{
  margin-right: 2px;
}

.aSpace{
  font-size:12.5px;
  padding:4.5px 24px;
}

.btnSpace{
  margin: 0 2px;
}

.betweenTime{
  line-height: 59px;
}

.dateDelete{
  position: absolute;
  top: 0;
  right: 0;
}

.md-table-row:first-child .dropup .dropdown-toggle + .dropdown-menu{
  bottom: -100%;
}

@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }

  .is-xs-none{
    display: none;
  }

  .is-xs-block{
    display: block;
  }
}
</style>
../entrypoints/router.js