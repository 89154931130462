
<div class="md-layout md-layout-item md-size-40 md-xsmall-size-100">
  <md-field class="timeSelect" :value="value">
    <md-select v-model="hour" name="hour" :disabled='ableFlag' @input="updateValue">
      <md-option value="00">0</md-option>
      <md-option value="01">1</md-option>
      <md-option value="02">2</md-option>
      <md-option value="03">3</md-option>
      <md-option value="04">4</md-option>
      <md-option value="05">5</md-option>
      <md-option value="06">6</md-option>
      <md-option value="07">7</md-option>
      <md-option value="08">8</md-option>
      <md-option value="09">9</md-option>
      <md-option value="10">10</md-option>
      <md-option value="11">11</md-option>
      <md-option value="12">12</md-option>
      <md-option value="13">13</md-option>
      <md-option value="14">14</md-option>
      <md-option value="15">15</md-option>
      <md-option value="16">16</md-option>
      <md-option value="17">17</md-option>
      <md-option value="18">18</md-option>
      <md-option value="19">19</md-option>
      <md-option value="20">20</md-option>
      <md-option value="21">21</md-option>
      <md-option value="22">22</md-option>
      <md-option value="23">23</md-option>
    </md-select>
  </md-field>
  <div class="timeUnit md-layout-item">時</div>
  <md-field class="timeSelect mgL10">
    <md-select v-model="minute" name="minute" :disabled='ableFlag' @input="updateValue">
      <md-option value="00">00</md-option>
      <md-option value="10">10</md-option>
      <md-option value="20">20</md-option>
      <md-option value="30">30</md-option>
      <md-option value="40">40</md-option>
      <md-option value="50">50</md-option>
    </md-select>
  </md-field>
  <div class="timeUnit md-layout-item">分</div>
</div>
