
<div class="md-layout">
  <div class="md-layout-item md-size-100">
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-green">
        <div class="card-icon">
          <md-icon>search</md-icon>
        </div>
        <h4 class="title">店舗検索</h4>
      </md-card-header>

      <md-card-content class="md-layout formLayout">

        <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
          <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
            店舗コード
          </label>
          <div class="md-layout-item md-xsmall-size-100">
            <md-field>
              <md-input v-model="name"></md-input>
            </md-field>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
          <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
            店舗名
          </label>
          <div class="md-layout-item md-xsmall-size-100">
            <md-field>
              <md-input v-model="selectedShop"></md-input>
            </md-field>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100 md-small-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            ステータス
          </label>
          <div class="md-layout-item md-size-20 md-xsmall-size-100">
            <md-field class="UserStatus">
              <md-select v-model="status" name="status">
                <md-option value="not_active">無効</md-option>
                <md-option value="active">有効</md-option>
                <md-option value="選択しない">選択しない</md-option>
              </md-select>
            </md-field>
          </div>
        </div>

      </md-card-content>
      <md-card-actions class="mx-auto">
        <md-button class="md-success md-lg" @click.native="getShops()">検索</md-button>
        <md-button class="md-info md-lg mL40" @click="handleClear">検索条件クリア</md-button>
      </md-card-actions>
    </md-card>
  </div>
  <transition name="fade">
    <div class="md-layout-item md-size-100" v-if="resultFlag">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">店舗一覧</h4>
        </md-card-header>
        <md-card-content>
          <div class="dataTableWrapper">
            <div class="dataTable"> 
              <div class="tHead">
                <div class="tHead_th">店舗コード</div>
                <div class="tHead_th">店舗名</div>
                <div class="tHead_th">契約日</div>
                <div class="tHead_th">更新日</div>
                <div class="tHead_th">ステータス</div>
                <div class="tHead_th taR">アクション</div>
              </div>
              <div v-for="item in tableData" :data-id="item.id" class="tItem">
                <div class="tItem_td commentBody"><md-label style="word-break:break-all;">{{ item.code }}</md-label></div>
                <div class="tItem_td commentBody"><md-label style="word-break:break-all;">{{ item.name }}</md-label></div>
                <div class="tItem_td">{{ item.contractDay | dateFormat }}</div>
                <div class="tItem_td">{{ item.updateDay | dateFormat }}</div>
                <div class="tItem_td">{{ item.status }}</div>
                <div class="tItem_td taR td_actionTw">
                  <drop-down direction="up" class="dropDown">
                    <md-button slot="title" class="md-just-icon md-warning mg0 mgR2 dropdown-toggle" data-toggle="dropdown">
                      <md-icon>dvr</md-icon>
                    </md-button>
                    <ul class="dropdown-menu dropdown-menu-right">
                      <li><a @click="handleEdit(item.id)">基本情報設定</a></li>
                      <li class="is-xs-none"><a @click="calendarEdit(item.id)">休日カレンダー設定</a></li>
                    </ul>
                  </drop-down>
                  <md-button
                    class="md-just-icon md-danger mg0"
                    @click.native="handleDelete(item)">
                    <md-icon>close</md-icon>
                  </md-button>
                </div>
              </div>
            </div>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">全{{ pageData.totalCount | numberWithDelimiter }}件中 {{from | numberWithDelimiter}}件から {{to | numberWithDelimiter}}件を表示</p>
          </div>
          <Pagination class="Pagination-no-border Pagination-success"
            :currentPage="pageData.currentPage"
            :totalPage="pageData.totalPage"
            @get="getShops">
          </Pagination>
        </md-card-actions>
      </md-card>
    </div>
  </transition>
</div>

