
<div class="md-layout">
  <div class="md-layout-item md-size-100">
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-green">
        <div class="card-icon">
          <md-icon>assignment</md-icon>
        </div>
        <h4 class="title">スタンプ基本設定</h4>
      </md-card-header>
      <md-card-content>
        <form data-vv-scope="baseSetting">
        <div class="md-layout md-layout-item md-size-100 md-small-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            満了スタンプ数
          </label>
          <div class="md-layout-item md-size-10 md-xsmall-size-50">
            <md-field :class="[
              {'md-error': errors.has('baseSetting.limitStamp')},
              {'md-valid': !errors.has('baseSetting.limitStamp') && touched.limitStamp}]">
              <md-input
                v-model="limitStamp"
                data-vv-name="limitStamp"
                v-validate= "modelValidations.limitStamp"
                type="number"
                required>
              </md-input>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('baseSetting.limitStamp')">【必須】2桁以内の半角数字で入力してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('baseSetting.limitStamp')" style="margin-right:10px">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('baseSetting.limitStamp') && touched.limitStamp" style="margin-right:10px">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>
        <div class="md-layout md-layout-item md-size-100 md-small-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            有効期限
          </label>
          <div class="md-layout-item">
            <md-radio v-model="expireType" value="one_year">1年間</md-radio>
            <md-radio v-model="expireType" value="two_year">2年間</md-radio>
          </div>
        </div>
        <div class="md-layout md-layout-item md-size-100 md-small-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            スタンプ画像
          </label>
          <div class="md-layout-item  md-size-30 md-xsmall-size-100 pt20">
            <div class="file-input">
              <div v-if="!image">
                <div class="image-container">
                  <img :src="dummyImg" title="">
                </div>
              </div>
              <div class="image-container" v-else>
                <img :src="image" />
              </div>
              <div class="button-container">
                <md-button class="md-danger md-round" @click="removeImage" v-if="image" data-id="image"><i class="fa fa-times"></i>削除</md-button>
                <md-button class="md-success md-round md-fileinput">
                  <template v-if="!image">画像選択</template>
                  <template v-else>変更</template>
                  <input type="file" @change="onFileChange" data-id="image">
                </md-button>
              </div>
            </div>
          </div>
        </div>
        </form>
      </md-card-content>
      <md-card-actions md-alignment="left" class="mt60 mx-auto">
        <submit-button class="md-success md-lg" :onclick="validate" :buttonWord=this.buttonWord type="submit"></submit-button>
      </md-card-actions>
    </md-card>
  </div>
  <div class="md-layout-item md-size-100">
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-green">
        <div class="card-icon">
          <md-icon>assignment</md-icon>
        </div>
        <h4 class="title">スタンプ特典クーポン</h4>
      </md-card-header>
      <md-card-content>
        <form data-vv-scope="couponSetting">
        <div class="md-layout md-layout-item md-size-100 md-small-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            クーポン名
          </label>
          <div class="md-layout-item md-size-40  md-xsmall-size-100">
            <md-field :class="[
              {'md-error': errors.has('couponSetting.stampCouponName')},
              {'md-valid': !errors.has('couponSetting.stampCouponName') && touched.stampCouponName}]">
              <md-select
                v-model="stampCouponName"
                data-vv-name="stampCouponName"
                v-validate= "modelValidations.stampCouponName">
                <md-option v-for="item in listData" :value="item.id">{{item.name}}</md-option>
              </md-select>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('couponSetting.stampCouponName')">【必須】必ず一つ選択してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('couponSetting.stampCouponName')" style="margin-right:20px">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('couponSetting.stampCouponName') && touched.stampCouponName" style="margin-right:20px">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>
        <div class="md-layout md-layout-item md-size-100 md-small-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            特典提供スタンプ数
          </label>
          <div class="md-layout-item md-size-10 md-xsmall-size-50">
            <md-field :class="[
              {'md-error': errors.has('couponSetting.number')},
              {'md-valid': !errors.has('couponSetting.number') && touched.number}]">
              <md-input
                v-model="number"
                data-vv-name="number"
                v-validate= "modelValidations.number"
                type="number"
                required>
              </md-input>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('couponSetting.number')">【必須】2桁以内の半角数字で入力してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('couponSetting.number')" style="margin-right:10px">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('couponSetting.number') && touched.number" style="margin-right:10px">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>
        </form>
      </md-card-content>
      <md-card-actions md-alignment="left" class="mt60 mx-auto">
        <submit-button class="md-success md-lg" :onclick="stampCouponAcquisition" :buttonWord=this.buttonWord type="submit"></submit-button>
      </md-card-actions>
    </md-card>
  </div>
  <div class="md-layout-item md-size-100">
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-green">
        <div class="card-icon">
          <md-icon>assignment</md-icon>
        </div>
        <h4 class="title">スタンプ特典クーポン一覧</h4>
      </md-card-header>
      <md-card-content>
        <div class="dataTableWrapper">
          <div class="dataTable"> 
            <div class="tHead">
              <div class="tHead_th">クーポン名</div>
              <div class="tHead_th">スタンプ数</div>
              <div class="tHead_th taR">アクション</div>
            </div>
            <div v-for="item in tableData" :data-id="item.id" class="tItem">
              <div class="tItem_td"><md-label style="word-break:break-all;">{{ item.name }}</md-label></div>
              <div class="tItem_td"><md-label style="word-break:break-all;">{{ item.number }}</md-label></div>
              <div class="tItem_td taR td_actionTh">
                <md-button
                  class="md-just-icon md-danger mg0"
                  @click.native="handleDelete(item)">
                  <md-icon>close</md-icon>
                </md-button>
              </div>
            </div>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</div>
