
<div class="wrapper" :class="[{'nav-open': $sidebar.showSidebar}, {'rtl': $route.meta.rtlActive}]">
  <notifications></notifications>
  <side-bar>
    <user-menu></user-menu>
    <template slot="links">
      <sidebar-item :link="{name: '事前注文管理', icon: 'local_grocery_store'}" v-if="opt_preOrder">
        <sidebar-item :link="{name: '注文一覧', path: '/admin/pre_order_list?fromSidebar=true'}"></sidebar-item>
        <sidebar-item :link="{name: '商品一覧', path: '/admin/pre_product_list?fromSidebar=true'}" v-if="(superFlag || companyFlag)"></sidebar-item>
        <sidebar-item :link="{name: '商品登録', path: '/admin/pre_product/new'}" v-if="(superFlag || companyFlag)"></sidebar-item>
      </sidebar-item>
      <sidebar-item :link="{name: '事前予約管理', icon: 'event'}" v-if="opt_reserveOrder">
        <sidebar-item :link="{name: '予約一覧', path: '/admin/reserve_order_list?fromSidebar=true'}"></sidebar-item>
        <sidebar-item :link="{name: '商品一覧', path: '/admin/reserve_product_list?fromSidebar=true'}" v-if="(superFlag || companyFlag)"></sidebar-item>
        <sidebar-item :link="{name: '商品登録', path: '/admin/reserve_product/new'}" v-if="(superFlag || companyFlag)"></sidebar-item>
      </sidebar-item>
      <sidebar-item :link="{name: '商品カテゴリ管理', icon: 'dns', path: '/admin/category_list'}" v-if="(superFlag || companyFlag) && (opt_preOrder || opt_reserveOrder)">
      </sidebar-item>
      <sidebar-item  :link="{name: 'QR決済管理', icon: 'dashboard'}" v-if="opt_qrPayment">
        <sidebar-item :link="{name: 'QR決済一覧', path: '/admin/shop_order_list?fromSidebar=true'}"></sidebar-item>
        <sidebar-item :link="{name: '支払用QR発行', path: '/admin/qr_issue'}"></sidebar-item>
      </sidebar-item>
      <sidebar-item  :link="{name: 'ハウスマネー管理', icon: 'attach_money', path: '/admin/house_money_list?fromSidebar=true'}" v-if="opt_houseMoney">
      </sidebar-item>
      <sidebar-item :link="{name: '会員管理', icon: 'group'}" v-if="superFlag || companyFlag">
        <sidebar-item :link="{name: '会員一覧', path: '/admin/user_list?fromSidebar=true'}"></sidebar-item>
        <sidebar-item :link="{name: '会員ランク設定', path: '/admin/user_rank_list'}"></sidebar-item>
      </sidebar-item>
      <sidebar-item :link="{name: 'Push通知管理', icon: 'phonelink_ring'}" v-if="((superFlag || companyFlag) && opt_pushNotice)">
        <sidebar-item :link="{name: 'Push通知履歴', path: '/admin/notification_list?fromSidebar=true'}"></sidebar-item>
        <sidebar-item :link="{name: 'Push通知予約設定', path: '/admin/reserve_notification/new'}"></sidebar-item>
      </sidebar-item>
      <sidebar-item :link="{name: 'メルマガ配信管理', icon: 'mail_outline'}" v-if="((superFlag || companyFlag) && opt_mailMagazine)">
        <sidebar-item :link="{name: 'メルマガ配信履歴', path: '/admin/mail_magazine_list?fromSidebar=true'}"></sidebar-item>
        <sidebar-item :link="{name: 'メルマガ配信予約設定', path: '/admin/reserve_mail_magazine/new'}"></sidebar-item>
      </sidebar-item>
      <sidebar-item :link="{name: '販促管理', icon: 'message'}" v-if="superFlag || companyFlag">
        <sidebar-item :link="{name: 'お知らせ一覧', path: '/admin/information_list?fromSidebar=true'}"></sidebar-item>
        <sidebar-item :link="{name: 'お知らせ新規登録', path: '/admin/information/new'}"></sidebar-item>
        <sidebar-item :link="{name: 'クーポン一覧', path: '/admin/coupon_list?fromSidebar=true'}" v-if="((superFlag || companyFlag) && opt_coupon)"></sidebar-item>
        <sidebar-item :link="{name: 'クーポン新規登録', path: '/admin/coupon/new'}"  v-if="((superFlag || companyFlag) && opt_coupon)"></sidebar-item>
        <sidebar-item :link="{name: 'スタンプクーポン一覧', path: '/admin/stamp_coupon_list?fromSidebar=true'}" v-if="((superFlag || companyFlag) && opt_coupon)"></sidebar-item>
        <sidebar-item :link="{name: 'スタンプクーポン新規登録', path: '/admin/stamp_coupon/new'}"  v-if="((superFlag || companyFlag) && opt_coupon)"></sidebar-item>
        <sidebar-item :link="{name: 'ポイント設定', path: '/admin/point_setting/edit/' + this.$Cookies.get('companyId')}" v-if="((superFlag || companyFlag) && opt_point)"></sidebar-item>
        <sidebar-item :link="{name: 'ポイント倍率設定', path: '/admin/point_rate_list'}" v-if="((superFlag || companyFlag) && opt_point)"></sidebar-item>
        <sidebar-item :link="{name: 'スタンプ設定', path: '/admin/stamp_setting/edit/' + this.$Cookies.get('companyId')}" v-if="((superFlag || companyFlag) && opt_stamp)"></sidebar-item>
        <sidebar-item :link="{name: 'スタンプ倍率設定', path: '/admin/stamp_rate_list'}" v-if="((superFlag || companyFlag) && opt_stamp)"></sidebar-item>
      </sidebar-item>
      <sidebar-item :link="{name: '店舗管理', icon: 'store_mall_directory'}" v-if="superFlag || companyFlag">
        <sidebar-item :link="{name: '店舗一覧', path: '/admin/shop_list'}"></sidebar-item>
        <sidebar-item :link="{name: '店舗登録', path: '/admin/shop/new'}"></sidebar-item>
      </sidebar-item>
      <sidebar-item :link="{name: 'スタッフ管理', icon: 'group'}" v-if="opt_reserveOrder">
        <sidebar-item :link="{name: 'スタッフ一覧', path: '/admin/staff_list?fromSidebar=true'}"></sidebar-item>
        <sidebar-item :link="{name: 'スタッフ登録', path: '/admin/staff/new'}" v-if="opt_adminUser"></sidebar-item>
      </sidebar-item>
      <sidebar-item :link="{name: 'QA管理', icon: 'question_answer'}" v-if="superFlag">
        <sidebar-item :link="{name: 'QAカテゴリ一覧', path: '/admin/qa_category_list'}"></sidebar-item>
        <sidebar-item :link="{name: 'QA情報一覧', path: '/admin/qa_information_list?fromSidebar=true'}"></sidebar-item>
        <sidebar-item :link="{name: 'QA情報登録', path: '/admin/qa_information/new'}"></sidebar-item>
      </sidebar-item>
      <sidebar-item :link="{name: 'お問合せ管理', icon: 'contact_mail'}" v-if="superFlag">
        <sidebar-item :link="{name: 'お問合せカテゴリ一覧', path: '/admin/contact_category_list'}"></sidebar-item>
        <sidebar-item :link="{name: 'お問合せ一覧', path: '/admin/contact_list?fromSidebar=true'}"></sidebar-item>
      </sidebar-item>
      <sidebar-item :link="{name: '分析・集計管理', icon: 'insert_chart'}" v-if="superFlag || companyFlag">
        <sidebar-item :link="{name: '期間別売上レポート', path: '/admin/analysis_period'}"></sidebar-item>
        <sidebar-item :link="{name: '会員別売上レポート', path: '/admin/analysis_user'}"></sidebar-item>
        <sidebar-item :link="{name: '商品別売上レポート', path: '/admin/analysis_product'}"></sidebar-item>
      </sidebar-item>
      <sidebar-item
        v-if="((superFlag || companyFlag) && opt_adminUser)"
        :link="{name: 'システム管理', icon: 'widgets', path: '/widgets'}">
        <sidebar-item :link="{name: '管理ユーザー一覧', path: '/admin/admin_user_list'}"></sidebar-item>
        <sidebar-item :link="{name: '管理ユーザー新規登録', path: '/admin/admin_user/new'}"></sidebar-item>
      </sidebar-item>
      <sidebar-item
        v-if="superFlag || companyFlag"
        :link="{name: '企業管理', icon: 'business', path: '/widgets'}">
        <sidebar-item v-if="companyFlag" :link="{name: '企業設定', path: '/admin/company/service/edit/' + companyId }"></sidebar-item>
        <sidebar-item v-if="superFlag" :link="{name: '企業一覧', path: '/admin/company_list'}"></sidebar-item>
        <sidebar-item v-if="superFlag" :link="{name: '企業新規登録', path: '/admin/company/new'}"></sidebar-item>
        <sidebar-item v-if="superFlag" :link="{name: '請求情報', path: '/admin/billing_statement_list?fromSidebar=true'}"></sidebar-item>
      </sidebar-item>
      <sidebar-item :link="{name: 'ログアウト', icon: 'exit_to_app', path: '/admin/login' }">
      </sidebar-item>
    </template>
  </side-bar>
  <div class="main-panel">
    <top-navbar></top-navbar>

    <div :class="{content: !$route.meta.hideContent}" @click="toggleSidebar">
      <zoom-center-transition :duration="200" mode="out-in">
        <!-- your content here -->
        <router-view></router-view>
      </zoom-center-transition>
    </div>
    <content-footer v-if="!$route.meta.hideFooter"></content-footer>
  </div>
</div>
