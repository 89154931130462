
<div class="md-layout">
  <div class="md-layout-item md-size-100">
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-green">
        <div class="card-icon">
          <md-icon>create</md-icon>
        </div>
        <h4 class="title">メルマガ配信予約設定</h4>
      </md-card-header>

      <md-card-content class="md-layout formLayout">
        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-20 md-form-label md-xsmall-size-100">
            店舗名
          </label>
          <div class="md-layout-item md-size-40  md-xsmall-size-100">
            <shop-select v-model="shopId" :noSelectFlag="true"></shop-select>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100 md-xsmall-size-100">
          <label class="md-layout-item md-size-20 md-form-label md-xsmall-size-100">
            会員ランク
          </label>
          <div class="md-layout-item md-size-40  md-xsmall-size-100">
            <user-rank-select v-model='userRank' :noSelectFlag="true" ></user-rank-select>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-20 md-form-label md-xsmall-size-100">
            入会日
          </label>
          <div class="md-layout-item md-size-30 md-xsmall-size-100" style="position:relative">
            <vuejs-datepicker
              :format="DatePickerFormat"
              :highlighted="highlighted"
              v-model="selectedStart"
              :language="ja"
              data-vv-name="selectedStart"
              ></vuejs-datepicker>
            <md-button
              class="md-just-icon md-default md-simple dateDelete"
              @click.native="selectedStart = ''">
              <md-icon>close</md-icon>
            </md-button>
          </div>
          <div class="md-layout-item md-size-5 betweenTime is-xs-none"> 〜 </div>
          <div class="md-layout-item md-size-30 md-xsmall-size-100" style="position:relative">
            <vuejs-datepicker
              :format="DatePickerFormat"
              :highlighted="highlighted"
              v-model="selectedEnd"
              :language="ja"
              data-vv-name="selectedEnd"
              ></vuejs-datepicker>
            <md-button
              class="md-just-icon md-default md-simple dateDelete"
              @click.native="selectedEnd = ''">
              <md-icon>close</md-icon>
            </md-button>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-20 md-form-label md-xsmall-size-100">
            購入日
          </label>
          <div class="md-layout-item md-size-30 md-xsmall-size-100" style="position:relative">
            <vuejs-datepicker
              :format="DatePickerFormat"
              :highlighted="highlighted"
              v-model=" purchaseStart"
              :language="ja"
              data-vv-name="purchaseStart"
              ></vuejs-datepicker>
            <md-button
              class="md-just-icon md-default md-simple dateDelete"
              @click.native="purchaseStart = ''">
              <md-icon>close</md-icon>
            </md-button>
          </div>
          <div class="md-layout-item md-size-5 betweenTime is-xs-none"> 〜 </div>
          <div class="md-layout-item md-size-30 md-xsmall-size-100" style="position:relative">
            <vuejs-datepicker
              :format="DatePickerFormat"
              :highlighted="highlighted"
              v-model="purchaseEnd"
              :language="ja"
              data-vv-name="purchaseEnd"
              ></vuejs-datepicker>
            <md-button
              class="md-just-icon md-default md-simple dateDelete"
              @click.native="purchaseEnd = ''">
              <md-icon>close</md-icon>
            </md-button>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-20 md-form-label md-xsmall-size-100">
            メルマガ希望
          </label>
          <div class="md-layout-item md-size-30 md-xsmall-size-100">
            <md-field class="mailApprove">
              <md-select v-model="mailApprove">
                <md-option value="receive">希望する</md-option>
                <md-option value="not_receive">希望しない</md-option>
                <md-option value="not_select">選択しない</md-option>
              </md-select>
            </md-field>
          </div>
        </div>

      </md-card-content>

      <md-card-actions md-alignment="left" class="mt60 mx-auto">
        <md-button class="md-success md-lg" @click="searchTarget">上記に当てはまる対象者を検索</md-button>
      </md-card-actions>
    </md-card>

    <transition name="fade">
      <div class="md-layout-item md-size-100" v-if="resultFlag">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>create</md-icon>
            </div>
            <h4 class="title">メルマガ配信予約詳細設定</h4>
          </md-card-header>

          <md-card-content class="md-layout formLayout">
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-20 md-form-label md-xsmall-size-100">
              差出人アドレス
            </label>
            <div class="md-layout-item">
              <md-field :class="[
                {'md-error': errors.has('email_from')},
                {'md-valid': !errors.has('email_from') && touched.email_from}]">
                <md-input
                  v-model="email_from"
                  data-vv-name="email_from"
                  type="email_from"
                  v-validate= "modelValidations.email_from"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('email_from')">【必須】半角英数記号のメールアドレス形式で入力してください（255文字以内）</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('email_from')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('email_from') && touched.email_from">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100 md-xsmall-size-100">
            <label class="md-layout-item md-size-20 md-form-label md-xsmall-size-100">
              差出人名
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('email_sender')},
                {'md-valid': !errors.has('email_sender') && touched.email_sender}]">
                <md-input
                  v-model="email_sender"
                  data-vv-name="email_sender"
                  type="text"
                  v-validate= "modelValidations.email_sender"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('email_sender')">【必須】255文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('email_sender')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('email_sender') && touched.email_sender">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-20 md-form-label md-xsmall-size-100">
              件名
            </label>
            <div class="md-layout-item md-size-50 md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('subject')},
                {'md-valid': !errors.has('subject') && touched.subject}]">
                <md-input
                  v-model="subject"
                  data-vv-name="subject"
                  type="text"
                  v-validate= "modelValidations.subject"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('subject')">【必須】255文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('subject')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('subject') && touched.subject">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100 md-small-size-100">
            <label class="md-layout-item md-size-20 md-form-label md-xsmall-size-100">
              種別
            </label>
            <div class="md-layout-item pt5">
              <md-radio v-model="mime_type" value='html_mail'>HTML</md-radio>
              <md-radio v-model="mime_type" value='text_mail'>TEXT</md-radio>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-20 md-form-label md-xsmall-size-100">
              配信内容
            </label>
            <div class="md-layout-item" v-show="mime_type=='html_mail'">
              <div class="md-layout-item padTb">
                <froala :tag="'textarea'" :config="config" v-model="noticeText"></froala>
              </div>
            </div>
            <div class="md-layout-item" v-show="mime_type=='text_mail'">
              <md-field>
                <md-textarea id="txtArea" class="txtArea" v-model="noticeText" md-counter="140" maxlength="140"></md-textarea>
              </md-field>
              <div class="iconWrapper">
                <div class="iconTrigger" @click="openEmoticon">顔文字を挿入する</div>
                <transition name="fade">
                  <div class="iconModal" v-if="modalFlag" @mouseleave="closeEmoticon">
                    <div class="icons" @click="insertEmoticon" v-for="(item,index) in icons" :key="index" v-html="icons[index]" :data-code="icons[index]"></div>
                  </div>
                </transition>
              </div>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-20 md-form-label md-xsmall-size-100">
              テスト配信アドレス
            </label>
            <div class="md-layout-item">
              <md-field :class="[
                {'md-error': errors.has('email_test')},
                {'md-valid': !errors.has('email_test') && touched.email_test}]">
                <md-input
                  v-model="email_test"
                  data-vv-name="email_test"
                  type="email_test"
                  v-validate= "modelValidations.email_test"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('email_test')">半角英数記号のメールアドレス形式で入力してください（255文字以内）</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('email_test')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('email_test') && touched.email_test">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
            <md-button class="md-warning md-lg" @click="test_mail">テスト送信</md-button>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-20 md-form-label md-xsmall-size-100">
              配信時間
            </label>
            <div class="md-layout-item md-size-30 md-xsmall-size-100" style="position:relative">
              <vuejs-datepicker
                :format="DatePickerFormat"
                :highlighted="highlighted"
                v-model="deliveryStart"
                :language="ja"
                data-vv-name="deliveryStart"
                v-validate= "modelValidations.deliveryStart"
                ></vuejs-datepicker>
              <md-button
                class="md-just-icon md-default md-simple dateDelete"
                @click.native="deliveryStart = ''">
                <md-icon>close</md-icon>
              </md-button>
              <slide-y-up-transition>
                <div class="vErrorText" style="margin-left: 15px" v-show="errors.has('deliveryStart')">【必須】日付を選択してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error dateError" v-show="errors.has('deliveryStart')">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success dateSuccess" v-show="!errors.has('deliveryStart') && touched.deliveryStart">done</md-icon>
              </slide-y-down-transition>
            </div>
            <time-select ref="deliveryTime" v-model="deliveryTime"></time-select>
          </div>

          </md-card-content>
          <md-card-actions md-alignment="left" class="mt60 mx-auto">
            <submit-button class="md-success md-lg" :onclick="validate" :buttonWord=this.buttonWord type="submit"></submit-button>
          </md-card-actions>
        </md-card>
      </div>
    </transition>
  </div>
</div>

