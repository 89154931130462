
<div class="md-layout">
  <div class="md-layout-item md-size-100">
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-green">
        <div class="card-icon">
          <md-icon>account_box</md-icon>
        </div>
        <h4 class="title">請求情報詳細</h4>
      </md-card-header>

      <md-card-content class="formLayout">
        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            企業名
          </label>
          <div class="md-layout-item md-size-85 md-xsmall-size-100">
            <p class="form-control-static">{{companyName}}</p>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            請求日
          </label>
          <div class="md-layout-item md-size-85 md-xsmall-size-100">
            <p class="form-control-static">{{billingDate}}</p>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            企業登録料
          </label>
          <div class="md-layout-item md-size-85 md-xsmall-size-100">
            <p class="form-control-static">{{registrationCompany}} 円</p>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            店舗登録料
          </label>
          <div class="md-layout-item md-size-85 md-xsmall-size-100">
            <p class="form-control-static">{{registrationShop}} 円</p>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            月額利用料
          </label>
          <div class="md-layout-item md-size-85 md-xsmall-size-100">
            <p class="form-control-static">{{usageFee}} 円</p>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            売上手数料
          </label>
          <div class="md-layout-item md-size-85 md-xsmall-size-100">
            <p class="form-control-static">{{salesFee}} 円</p>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            SMS発行料
          </label>
          <div class="md-layout-item md-size-85 md-xsmall-size-100">
            <p class="form-control-static">{{smsFee}} 円</p>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            合計請求額
          </label>
          <div class="md-layout-item md-size-85 md-xsmall-size-100">
            <p class="form-control-static">{{totalCharge}} 円</p>
          </div>
        </div>
        
      </md-card-content>
      <md-card-actions class="mx-auto" md-alignment="left">
        <md-button class="md-warning md-lg" @click.native.prevent="returnBack">戻る</md-button>
      </md-card-actions>
    </md-card>
  </div>
</div>

