
<md-field :class="[
              {'md-error': errors.has('userRankId')},
              {'md-valid': !errors.has('userRankId') && touched.userRankId}]" >
  <md-select v-model="userRankId"  
    data-vv-name="userRankId"
    v-validate= "avoidValidFlag == true ? '' : modelValidations.userRankId" multiple v-if="mutlipleFlag">
    <md-option v-for="item in listData" :value="item.id" :key="item.id">{{item.name}}</md-option>
  </md-select>
  <md-select
    v-model="userRankId"
    data-vv-name="userRankId"
    v-validate= "avoidValidFlag == true ? '' : modelValidations.userRankId" v-if="!mutlipleFlag">
    <md-option v-for="item in listData" :value="item.id" :key="item.id">{{item.name}}</md-option>
  </md-select>
  <slide-y-up-transition>
    <div class="vErrorText" v-show="errors.has('userRankId')">【必須】必ず一つ選択してください</div>
  </slide-y-up-transition>
  <slide-y-down-transition>
    <md-icon class="error" v-show="errors.has('userRankId')" style="margin-right:20px">close</md-icon>
  </slide-y-down-transition>
  <slide-y-down-transition>
    <md-icon class="success" v-show="!errors.has('userRankId') && touched.userRankId" style="margin-right:20px">done</md-icon>
  </slide-y-down-transition>
</md-field>
