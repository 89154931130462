
<div class="md-layout">
  <div class="md-layout-item md-size-100">
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-green">
        <div class="card-icon">
          <md-icon>search</md-icon>
        </div>
        <h4 class="title">{{ productData.title }}商品検索</h4>
      </md-card-header>

      <md-card-content class="md-layout formLayout">
        <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
          <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
            商品名
          </label>
          <div class="md-layout-item md-xsmall-size-100">
            <md-field>
              <md-input v-model="itemName"></md-input>
            </md-field>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
          <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
            店舗名
          </label>
          <div class="md-layout-item md-xsmall-size-100">
            <shop-select v-model="shopId" :noSelectFlag="true"></shop-select>
          </div>
        </div>
        <div class="md-layout md-layout-item md-size-100 md-small-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            販売ステータス
          </label>
          <div class="md-layout-item md-size-30 md-xsmall-size-100">
            <md-field>
              <md-select v-model="status">
                <md-option value="選択しない">選択しない</md-option>
                <md-option value="active">販売中</md-option>
                <md-option value="not_active">販売停止中</md-option>
              </md-select>
            </md-field>
          </div>
        </div>
      </md-card-content>
      <md-card-actions class="mx-auto">
        <md-button class="md-success md-lg" @click="getItems()">検索</md-button>
        <md-button class="md-info md-lg mL40" @click="handleClear">検索条件クリア</md-button>
      </md-card-actions>
    </md-card>
  </div>
  <transition name="fade">
    <div class="md-layout-item md-size-100" v-if="resultFlag">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">商品一覧</h4>
        </md-card-header>
        <md-card-content>
          <div class="dataTableWrapper">
            <div class="dataTable"> 
              <div class="tHead">
                <div class="tHead_th">商品番号</div>
                <div class="tHead_th">ステータス</div>
                <div class="tHead_th">商品名</div>
                <div class="tHead_th taR">金額</div>
                <div class="tHead_th taR">アクション</div>
              </div>
              <div v-for="item in tableData" :data-id="item.id" class="tItem">
                <div class="tItem_td commentBody"><md-label style="word-break:break-all;">{{ item.number }}</md-label></div>
                <div class="tItem_td">
                  <md-field class="wth100">
                    <md-select v-model="item.status">
                      <md-option value="active">販売中</md-option>
                      <md-option value="not_active">販売停止中</md-option>
                    </md-select>
                  </md-field>
                </div>
                <div class="tItem_td commentBody"><md-label style="word-break:break-all;">{{ item.name }}</md-label></div>
                <div class="tItem_td taR boldCell">¥{{ item.price | numberWithDelimiter }}</div>
                <div class="tItem_td taR td_actionTh">
                  <md-button
                    class="md-just-icon md-success mg0"
                    @click.native="updateStatus(item.id, item.status)">
                    <md-icon>autorenew</md-icon>
                  </md-button>
                  <md-button
                    class="md-just-icon md-warning mg0"
                    @click.native="handleEdit(item.id)">
                    <md-icon>dvr</md-icon>
                  </md-button>
                  <md-button
                    class="md-just-icon md-danger mg0"
                    @click.native="handleDelete(item)">
                    <md-icon>close</md-icon>
                  </md-button>
                </div>
              </div>
            </div>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">全{{ pageData.totalCount | numberWithDelimiter }}件中 {{from | numberWithDelimiter}}件から {{to | numberWithDelimiter}}件を表示</p>
          </div>
          <Pagination class="Pagination-no-border Pagination-success"
            :currentPage="pageData.currentPage"
            :totalPage="pageData.totalPage"
            @get="getItems">
          </Pagination>
        </md-card-actions>
      </md-card>
    </div>
  </transition>
</div>

