
<div class="md-layout">
  <div class="md-layout-item md-size-100">
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-green">
        <div class="card-icon">
          <md-icon>create</md-icon>
        </div>
        <h4 class="title">管理ユーザー登録編集</h4>
      </md-card-header>

      <md-card-content class="md-layout formLayout">
        <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
          <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
            ログインID
          </label>
          <div class="md-layout-item md-xsmall-size-100">
            <md-field :class="[
              {'md-error': errors.has('login')},
              {'md-valid': !errors.has('login') && touched.login}]">
              <md-input
                v-model="login"
                data-vv-name="login"
                type="text"
                v-validate= "modelValidations.login"
                required>
              </md-input>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('login')">【必須】6文字以上30文字以内の半角英数で入力してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('login')">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('login') && touched.login">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>
        <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
          <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
            パスワード
          </label>
          <div class="md-layout-item md-xsmall-size-100">
            <md-field :class="[
              {'md-error': errors.has('password')},
              {'md-valid': !errors.has('password') && touched.password}]">
              <md-input
                v-model="password"
                data-vv-name="password"
                type="password"
                v-validate= "modelValidations.password">
              </md-input>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('password')">6文字以上12文字以内の半角英数で入力してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('password')">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('password') && touched.password">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>
        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            E-mail
          </label>
          <div class="md-layout-item">
            <md-field :class="[
              {'md-error': errors.has('email')},
              {'md-valid': !errors.has('email') && touched.email}]">
              <md-input
                v-model="email"
                data-vv-name="email"
                type="email"
                v-validate= "modelValidations.email"
                required>
              </md-input>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('email')">【必須】半角英数記号のメールアドレス形式で入力してください（255文字以内）</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('email')">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('email') && touched.email">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
          <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
            姓
          </label>
          <div class="md-layout-item md-xsmall-size-100">
            <md-field :class="[
              {'md-error': errors.has('lastName')},
              {'md-valid': !errors.has('lastName') && touched.lastName}]">
              <md-input
                v-model="lastName"
                data-vv-name="lastName"
                type="text"
                v-validate= "modelValidations.lastName"
                required>
              </md-input>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('lastName')">【必須】50文字以内で入力してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('lastName')">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('lastName') && touched.lastName">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
          <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
            名
          </label>
          <div class="md-layout-item md-xsmall-size-100">
            <md-field :class="[
              {'md-error': errors.has('firstName')},
              {'md-valid': !errors.has('firstName') && touched.firstName}]">
              <md-input
                v-model="firstName"
                data-vv-name="firstName"
                type="text"
                v-validate= "modelValidations.firstName"
                required>
              </md-input>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('firstName')">【必須】50文字以内で入力してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('firstName')">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('firstName') && touched.firstName">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
          <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
            姓（カナ）
          </label>
          <div class="md-layout-item md-xsmall-size-100">
            <md-field :class="[
              {'md-error': errors.has('lastKana')},
              {'md-valid': !errors.has('lastKana') && touched.lastKana}]">
              <md-input
                v-model="lastKana"
                data-vv-name="lastKana"
                type="text"
                v-validate= "modelValidations.lastKana"
                required>
              </md-input>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('lastKana')">【必須】50文字以内の全角カタカナで入力してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('lastKana')">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('lastKana') && touched.lastKana">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
          <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
            名（カナ）
          </label>
          <div class="md-layout-item md-xsmall-size-100">
            <md-field :class="[
              {'md-error': errors.has('firstKana')},
              {'md-valid': !errors.has('firstKana') && touched.firstKana}]">
              <md-input
                v-model="firstKana"
                data-vv-name="firstKana"
                type="text"
                v-validate= "modelValidations.firstKana"
                required>
              </md-input>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('firstKana')">【必須】50文字以内の全角カタカナで入力してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('firstKana')">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('firstKana') && touched.firstKana">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            ステータス
          </label>
          <div class="md-layout-item md-size-20 md-xsmall-size-100">
            <md-field :class="[
              {'md-error': errors.has('status')},
              {'md-valid': !errors.has('status') && touched.status}]">
              <md-select
                v-model="status"
                data-vv-name="status"
                v-validate= "modelValidations.status"
                required>
                <md-option value="approved">有効</md-option>
                <md-option value="not_approve">無効</md-option>
              </md-select>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('status')">【必須】必ず一つ選択してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('status')" style="margin-right:20px">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('status') && touched.status" style="margin-right:20px">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100" v-if="!editSuper">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            アカウント種別
          </label>
          <div class="md-layout-item">
            <md-switch class="acountLabel" v-model="switch1">店舗アカウント</md-switch>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100" v-if="switch1">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            店舗名
          </label>
          <div class="md-layout-item md-size-20 md-xsmall-size-100">
            <shop-select v-model="shopId" :touchedFlag="true"></shop-select>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100" v-if="superFlag">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            企業名
          </label>
          <div class="md-layout-item md-size-30 md-xsmall-size-100">
            <md-field :class="[
              {'md-error': errors.has('enterpriseName')},
              {'md-valid': !errors.has('enterpriseName') && touched.enterpriseName}]">
              <md-select
                v-model="enterpriseName"
                data-vv-name="enterpriseName"
                v-validate= "superFlag || switch1 ? modelValidations.enterpriseName : ''"
                required>
                <md-option v-for="item in enterpriseList" :value="item.id">{{item.name}}</md-option>
              </md-select>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('enterpriseName')">【必須】必ず一つ選択してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('enterpriseName')" style="margin-right:20px">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('enterpriseName') && touched.enterpriseName" style="margin-right:20px">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            権限
          </label>
          <div class="md-layout-item pt5">
            <md-checkbox v-model="opt_adminUser">個人情報閲覧</md-checkbox>
          </div>
        </div>

      </md-card-content>
      <md-card-actions md-alignment="left" class="mt60 mx-auto">
        <md-button class="md-warning md-lg" @click.native.prevent="returnBack">戻る</md-button>
        <submit-button class="md-success md-lg mL40" :onclick="validate" :buttonWord=this.buttonWord type="submit"></submit-button>
      </md-card-actions>
    </md-card>
  </div>
</div>

