
<div class="md-layout">
  <div class="md-layout-item md-size-100">
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-green">
        <div class="card-icon">
          <md-icon>create</md-icon>
        </div>
        <h4 class="title">{{ productData.title }}商品情報</h4>
      </md-card-header>

      <md-card-content class="md-layout formLayout">
        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            商品コード
          </label>
          <div class="md-layout-item md-size-30 md-xsmall-size-100">
            <md-field :class="[
              {'md-error': errors.has('productNumber')},
              {'md-valid': !errors.has('productNumber') && touched.productNumber}]">
              <md-input
                v-model="productNumber"
                data-vv-name="productNumber"
                type="text"
                v-validate= "modelValidations.productNumber"
                required>
              </md-input>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('productNumber')">【必須】150文字以内の半角英数で入力してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('productNumber')">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('productNumber') && touched.productNumber">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>
        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            商品名
          </label>
          <div class="md-layout-item md-size-50 md-xsmall-size-100">
            <md-field :class="[
              {'md-error': errors.has('productName')},
              {'md-valid': !errors.has('productName') && touched.productName}]">
              <md-input
                v-model="productName"
                data-vv-name="productName"
                type="text"
                v-validate= "modelValidations.productName"
                required>
              </md-input>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('productName')">【必須】255文字以内で入力してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('productName')">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('productName') && touched.productName">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            店舗名
          </label>

          <div v-if="parentId !== null" class="md-layout-item md-size-40  md-xsmall-size-100">
            <p class="form-control-static">{{shopName}}</p>
          </div>
          <div v-else class="md-layout-item md-size-40  md-xsmall-size-100">
            <shop-select v-model="shopId" :noSelectFlag="true" :touchedFlag="true"></shop-select>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            ステータス
          </label>
          <div class="md-layout-item  md-size-30 md-xsmall-size-100">
            <md-field :class="[
              {'md-error': errors.has('status')},
              {'md-valid': !errors.has('status') && touched.status}]">
              <md-select
                v-model="status"
                data-vv-name="status"
                v-validate= "modelValidations.status"
                required>
                <md-option value="active">販売中</md-option>
                <md-option value="not_active">販売停止中</md-option>
              </md-select>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('status')">一つ選択してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('status')" style="margin-right:20px">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('status') && touched.status" style="margin-right:20px">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            販売期間
          </label>
          <div class="md-layout-item md-size-30 md-xsmall-size-100" style="position:relative">
            <div>
              <vuejs-datepicker
                :format="DatePickerFormat"
                :highlighted="highlighted"
                v-model="selectedStart"
                :language="ja"
                data-vv-name="selectedStart"
                v-validate= "modelValidations.selectedStart"
                ></vuejs-datepicker>
              <md-button
                class="md-just-icon md-default md-simple dateDelete"
                @click.native="selectedStart = ''">
                <md-icon>close</md-icon>
              </md-button>
              <slide-y-up-transition>
                <div class="vErrorText" style="margin-left: 15px" v-show="errors.has('selectedStart')">【必須】日付を選択してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error dateError" v-show="errors.has('selectedStart')">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success dateSuccess" v-show="!errors.has('selectedStart') && touched.selectedStart">done</md-icon>
              </slide-y-down-transition>
            </div>
          </div>
          <div class="md-layout-item md-size-5 betweenTime is-xs-none"> 〜 </div>
          <div class="md-layout-item md-size-30 md-xsmall-size-100" style="position:relative">
            <vuejs-datepicker
              :format="DatePickerFormat"
              :highlighted="highlighted"
              v-model="selectedEnd"
              :language="ja"
              data-vv-name="selectedEnd"
              v-validate= "modelValidations.selectedEnd"
              ></vuejs-datepicker>
            <md-button
              class="md-just-icon md-default md-simple dateDelete"
              @click.native="selectedEnd = ''">
              <md-icon>close</md-icon>
            </md-button>
            <slide-y-up-transition>
              <div class="vErrorText" style="margin-left: 15px" v-show="errors.has('selectedEnd')">【必須】日付を選択してください</div>
            </slide-y-up-transition>
            <slide-y-down-transition>
              <md-icon class="error dateError" v-show="errors.has('selectedEnd')">close</md-icon>
            </slide-y-down-transition>
            <slide-y-down-transition>
              <md-icon class="success dateSuccess" v-show="!errors.has('selectedEnd') && touched.selectedEnd">done</md-icon>
            </slide-y-down-transition>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            商品説明
          </label>
          <div class="md-layout-item">
            <md-field>
              <md-textarea id="txtArea" class="txtArea" v-model="productDetail" md-counter="140" maxlength="140"></md-textarea>
            </md-field>
            <div class="iconWrapper">
              <div class="iconTrigger" @click="openEmoticon">顔文字を挿入する</div>
              <transition name="fade">
                <div class="iconModal" v-if="modalFlag" @mouseleave="closeEmoticon">
                  <div class="icons" @click="insertEmoticon" v-for="(item,index) in icons" :key="index" v-html="icons[index]" :data-code="icons[index]"></div>
                </div>
              </transition>
            </div>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100 md-small-size-100" v-show="productData.stockManagement">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            在庫
          </label>
          <div class="md-layout-item pt5">
            <md-radio v-model="stockUnlimited" :value="true">無制限</md-radio>
            <md-radio v-model="stockUnlimited" :value="false">制限あり</md-radio>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100" v-show="!stockUnlimited">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            在庫数
          </label>
          <div class="md-layout-item  md-size-20 md-xsmall-size-100">
            <md-field :class="[
              {'md-error': errors.has('stockNumber')},
              {'md-valid': !errors.has('stockNumber') && touched.stockNumber}]">
              <md-input
                v-model="stockNumber"
                data-vv-name="stockNumber"
                v-validate= "stockUnlimited === false ? modelValidations.stockNumber : ''"
                required
                type="number">
              </md-input>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('stockNumber')">【必須】6桁以内の半角数字で入力してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('stockNumber')" style="margin-right:10px">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('stockNumber') && touched.stockNumber" style="margin-right:10px">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            定価
          </label>
          <div class="md-layout-item md-size-30 md-xsmall-size-100">
            <md-field :class="[
              {'md-error': errors.has('listPrice')},
              {'md-valid': !errors.has('listPrice') && touched.listPrice}]">
              <md-input
                v-model="listPrice"
                data-vv-name="listPrice"
                v-validate= "modelValidations.listPrice"
                required
                type="number">
              </md-input>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('listPrice')">【必須】6桁以内の半角数字で入力してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('listPrice')" style="margin-right:10px">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('listPrice') && touched.listPrice" style="margin-right:10px">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            販売価格
          </label>
          <div class="md-layout-item  md-size-30 md-xsmall-size-100">
            <md-field :class="[
              {'md-error': errors.has('sellingPrice')},
              {'md-valid': !errors.has('sellingPrice') && touched.sellingPrice}]">
              <md-input
                v-model="sellingPrice"
                data-vv-name="sellingPrice"
                v-validate= "modelValidations.sellingPrice"
                required
                type="number">
              </md-input>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('sellingPrice')">【必須】6桁以内の半角数字で入力してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('sellingPrice')" style="margin-right:10px">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('sellingPrice') && touched.sellingPrice" style="margin-right:10px">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            タイムセール価格
          </label>
          <div class="md-layout-item  md-size-30 md-xsmall-size-100">
            <md-field :class="[
              {'md-error': errors.has('timeSellingPrice')},
              {'md-valid': !errors.has('timeSellingPrice') && touched.timeSellingPrice}]">
              <md-input
                v-model="timeSellingPrice"
                data-vv-name="timeSellingPrice"
                v-validate= "modelValidations.timeSellingPrice"
                type="number">
              </md-input>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('timeSellingPrice')">6桁以内の半角数字で入力してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('timeSellingPrice')" style="margin-right:10px">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('timeSellingPrice') && touched.timeSellingPrice" style="margin-right:10px">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            タイムセール開始日
          </label>
          <div class="md-layout-item md-size-30 md-xsmall-size-100" style="position:relative">
            <vuejs-datepicker
              :format="DatePickerFormat"
              :highlighted="highlighted"
              v-model="timeSaleStart"
              :language="ja"
              data-vv-name="timeSaleStart"
              v-validate= "timeSellingPrice === '' ? '' : modelValidations.timeSaleStart"
              ></vuejs-datepicker>
            <md-button
              class="md-just-icon md-default md-simple dateDelete"
              @click.native="timeSaleStart = '', startTime = ''">
              <md-icon>close</md-icon>
            </md-button>
            <slide-y-up-transition>
              <div class="vErrorText" style="margin-left: 15px" v-show="errors.has('timeSaleStart')">日付を選択してください</div>
            </slide-y-up-transition>
            <slide-y-down-transition>
              <md-icon class="error dateError" v-show="errors.has('timeSaleStart')">close</md-icon>
            </slide-y-down-transition>
            <slide-y-down-transition>
              <md-icon class="success dateSuccess" v-show="!errors.has('timeSaleStart') && touched.timeSaleStart">done</md-icon>
            </slide-y-down-transition>
          </div>
          <time-select class="md-layout-item md-xsmall-size-100" ref="startTimer" v-model="startTime"></time-select>
        </div>
        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            タイムセール終了日
          </label>
          <div class="md-layout-item md-size-30 md-xsmall-size-100" style="position:relative">
            <vuejs-datepicker
              :format="DatePickerFormat"
              :highlighted="highlighted"
              v-model="timeSaleEnd"
              :language="ja"
              data-vv-name="timeSaleEnd"
              v-validate= "timeSellingPrice === '' ? '' : modelValidations.timeSaleEnd"
              ></vuejs-datepicker>
            <md-button
              class="md-just-icon md-default md-simple dateDelete"
              @click.native="timeSaleEnd = '', endTime = ''">
              <md-icon>close</md-icon>
            </md-button>
            <slide-y-up-transition>
              <div class="vErrorText" style="margin-left: 15px" v-show="errors.has('timeSaleEnd')">日付を選択してください</div>
            </slide-y-up-transition>
            <slide-y-down-transition>
              <md-icon class="error dateError" v-show="errors.has('timeSaleEnd')">close</md-icon>
            </slide-y-down-transition>
            <slide-y-down-transition>
              <md-icon class="success dateSuccess" v-show="!errors.has('timeSaleEnd') && touched.timeSaleEnd">done</md-icon>
            </slide-y-down-transition>
          </div>
          <time-select class="md-layout-item md-xsmall-size-100" ref="endTimer" v-model="endTime"></time-select>
        </div>

        <div class="md-layout md-layout-item md-size-100" v-show="productData.timeManagement">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            所要時間
          </label>
          <div class="md-layout-item  md-size-30 md-xsmall-size-100">
            <md-field :class="[
              {'md-error': errors.has('requiredTime')},
              {'md-valid': !errors.has('requiredTime') && touched.requiredTime}]">
              <md-select
                v-model="requiredTime"
                data-vv-name="requiredTime"
                v-validate= "modelValidations.requiredTime"
                required>
                <md-option value="15">15分</md-option>
                <md-option value="30">30分</md-option>
                <md-option value="45">45分</md-option>
                <md-option value="60">60分</md-option>
                <md-option value="90">90分</md-option>
                <md-option value="120">120分</md-option>
              </md-select>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('requiredTime')">【必須】必ず一つ選択してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('requiredTime')" style="margin-right:20px">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('requiredTime') && touched.requiredTime" style="margin-right:20px">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100 md-small-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            消費税
          </label>
          <div class="md-layout-item pt5">
            <md-radio v-model="taxRate" value="normal">標準税率(10%)</md-radio>
            <md-radio v-model="taxRate" value="reduced">軽減税率(8%)</md-radio>
            <md-radio v-model="taxRate" value="free">非課税(0%)</md-radio>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            商品カテゴリ
          </label>
          <div class="md-layout-item md-size-50 md-xsmall-size-100 pt20">
            <div class="categoryContainer">
              <div class="bigCategory" v-for="item, index in categories" :key="item.id">
                <div class="categoryDetail">
                  <div class="checkArea">
                    <md-checkbox v-model="item.check" class="categoryCheck">{{item.name}}</md-checkbox>
                    <div class="toggleBox">
                      <md-button
                        class="md-just-icon md-simple"
                        @click.native="item.openFlag = !item.openFlag">
                        <md-icon v-show="!item.openFlag && item.children.length !== 0">keyboard_arrow_down</md-icon>
                        <md-icon v-show="item.openFlag && item.children.length !== 0">keyboard_arrow_up</md-icon>
                      </md-button>
                    </div>
                  </div>
                </div>
                <slide-y-up-transition>
                  <div class="progenyCategory" v-show="item.openFlag">
                    <div class="middleCategory" v-for="itemC, indexC in item.children" :key="itemC.id">
                      <div class="categoryDetail">
                        <div class="checkArea">
                          <md-checkbox v-model="itemC.check" class="categoryCheck">{{itemC.name}}</md-checkbox>
                          <div class="toggleBox">
                            <md-button
                              class="md-just-icon md-simple"
                              @click.native="itemC.openFlag = !itemC.openFlag">
                              <md-icon v-show="!itemC.openFlag && itemC.children.length !== 0">keyboard_arrow_down</md-icon>
                              <md-icon v-show="itemC.openFlag && itemC.children.length !== 0">keyboard_arrow_up</md-icon>
                            </md-button>
                          </div>
                        </div>
                      </div>
                      <slide-y-up-transition>
                        <div class="progenyCategory" v-show="itemC.openFlag">
                          <div class="smallCategory" v-for="itemCC, indexCC in itemC.children" :key="itemCC.id">
                            <div class="categoryDetail">
                              <div class="checkArea">
                                <md-checkbox v-model="itemCC.check" class="categoryCheck">{{itemCC.name}}</md-checkbox>
                              </div>
                            </div>
                          </div>
                        </div>
                      </slide-y-up-transition>
                    </div>
                  </div>
                </slide-y-up-transition>
              </div>

            </div>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            メイン画像
          </label>
          <div class="md-layout-item md-size-40 md-xsmall-size-100 pt20">
            <div class="file-input">
              <div v-if="!imageMain">
                <div class="image-container">
                  <img :src="dummyImg" title="">
                </div>
              </div>
              <div class="image-container" v-else>
                <img :src="imageMain" />
              </div>
              <div class="button-container">
                <md-button class="md-danger md-round" @click="removeMainImage" v-if="imageMain" data-id="imageMain"><i class="fa fa-times"></i>削除</md-button>
                <md-button class="md-success md-round md-fileinput">
                  <template v-if="!imageMain">画像選択</template>
                  <template v-else>変更</template>
                  <input type="file" @change="onFileMainChange" data-id="imageMain">
                </md-button>
              </div>
            </div>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            サブ画像
          </label>
          <div class="md-layout-item md-size-30 md-xsmall-size-100 pt20">
            <div class="file-input imageSub" v-for="(item,index) in imgList" :key="index">
              <div v-if="!item.url">
                <div class="image-container">
                  <img :src="dummyImg" title="">
                </div>
              </div>
              <div class="image-container" v-else>
                <img :src="item.url" />
              </div>
              <div class="button-container">
                <md-button class="md-danger md-round" @click="removeSubImage" v-if="item.url" :data-id="index"><i class="fa fa-times"></i>削除</md-button>
                <md-button class="md-success md-round md-fileinput">
                  <template v-if="!item.url">画像選択</template>
                  <template v-else>変更</template>
                  <input type="file" @input="onFileSubChange" :data-id="index">
                </md-button>
              </div>
            </div>

            <div v-show="buttonFlag" class="BtnAdd" @click="addImage"><md-icon>add</md-icon><span>サブ画像を追加する</span></div>
          </div>
        </div>

      </md-card-content>
      <md-card-actions md-alignment="left" class="mt60 mx-auto">
        <md-button class="md-warning md-lg" @click.native.prevent="returnBack">戻る</md-button>
        <submit-button class="md-success md-lg mL40" :onclick="validate" :buttonWord=this.buttonWord type="submit"></submit-button>
      </md-card-actions>
    </md-card>
  </div>
</div>

