<template>
  <LineChartGenerator 
    :data="data" 
    :options="options"
    :key="chartKey"
  />
</template>

<script>
import { Line as LineChartGenerator } from 'vue-chartjs';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineController,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
} from 'chart.js';

ChartJS.register(
  LineController,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement
);

export default {
  name: 'LineChart',
  components: {
    LineChartGenerator
  },
  props: {
    labels: {
      type: Array,
      default: () => []
    },
    data: {
      type: Object,
      default: () => {
        return {
          labels: [],
          series: []
        }
      }
    },
    backgroundColor: {
      type: String,
      default: ''
    },
  },
  setup(props) {
    const chartKey = Math.random();
    const data = {
      labels: props.data.labels,
      datasets: [
        {
          type: "line",
          label: "事前決済：平均金額",
          backgroundColor: '#ff9800',
          data: props.data.series[0]
        },
        {
          type: "line",
          label: "事前決済：合計金額",
          backgroundColor: '#9c27b0',
          data: props.data.series[1]
        },
        {
          type: "line",
          label: "事前予約：平均金額",
          backgroundColor: '#00bcd4',
          data: props.data.series[2]
        },
        {
          type: "line",
          label: "事前予約：合計金額",
          backgroundColor: '#f44336',
          data: props.data.series[3]
        },
        {
          type: "line",
          label: "店頭QR決済：平均金額",
          backgroundColor: '#4caf50',
          data: props.data.series[4]
        },
        {
          type: "line",
          label: "店頭QR決済：合計金額",
          backgroundColor: '#9C9B99',
          data: props.data.series[5]
        },
      ]
    }

    const options = {
      responsive: true,
      maintainAspectRatio: true,
      plugins: {
        legend: {
          display: props.title ? true : false,
          align: 'start',
          labels: {
            boxWidth: 0
          }
        }
      },
      scales: {
        y: {
          ticks: {
            stepSize: 1
          }
        }
      }
    }

    return {
      data,
      options,
      chartKey
    }
  },
}
</script>
