<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>create</md-icon>
          </div>
          <h4 class="title">管理ユーザー登録編集</h4>
        </md-card-header>

        <md-card-content class="md-layout formLayout">
          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              ログインID
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('login')},
                {'md-valid': !errors.has('login') && touched.login}]">
                <md-input
                  v-model="login"
                  data-vv-name="login"
                  type="text"
                  v-validate= "modelValidations.login"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('login')">【必須】6文字以上30文字以内の半角英数で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('login')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('login') && touched.login">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              パスワード
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('password')},
                {'md-valid': !errors.has('password') && touched.password}]">
                <md-input
                  v-model="password"
                  data-vv-name="password"
                  type="password"
                  v-validate= "modelValidations.password">
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('password')">6文字以上12文字以内の半角英数で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('password')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('password') && touched.password">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              E-mail
            </label>
            <div class="md-layout-item">
              <md-field :class="[
                {'md-error': errors.has('email')},
                {'md-valid': !errors.has('email') && touched.email}]">
                <md-input
                  v-model="email"
                  data-vv-name="email"
                  type="email"
                  v-validate= "modelValidations.email"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('email')">【必須】半角英数記号のメールアドレス形式で入力してください（255文字以内）</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('email')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('email') && touched.email">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              姓
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('lastName')},
                {'md-valid': !errors.has('lastName') && touched.lastName}]">
                <md-input
                  v-model="lastName"
                  data-vv-name="lastName"
                  type="text"
                  v-validate= "modelValidations.lastName"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('lastName')">【必須】50文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('lastName')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('lastName') && touched.lastName">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              名
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('firstName')},
                {'md-valid': !errors.has('firstName') && touched.firstName}]">
                <md-input
                  v-model="firstName"
                  data-vv-name="firstName"
                  type="text"
                  v-validate= "modelValidations.firstName"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('firstName')">【必須】50文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('firstName')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('firstName') && touched.firstName">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              姓（カナ）
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('lastKana')},
                {'md-valid': !errors.has('lastKana') && touched.lastKana}]">
                <md-input
                  v-model="lastKana"
                  data-vv-name="lastKana"
                  type="text"
                  v-validate= "modelValidations.lastKana"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('lastKana')">【必須】50文字以内の全角カタカナで入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('lastKana')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('lastKana') && touched.lastKana">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              名（カナ）
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('firstKana')},
                {'md-valid': !errors.has('firstKana') && touched.firstKana}]">
                <md-input
                  v-model="firstKana"
                  data-vv-name="firstKana"
                  type="text"
                  v-validate= "modelValidations.firstKana"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('firstKana')">【必須】50文字以内の全角カタカナで入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('firstKana')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('firstKana') && touched.firstKana">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              ステータス
            </label>
            <div class="md-layout-item md-size-20 md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('status')},
                {'md-valid': !errors.has('status') && touched.status}]">
                <md-select
                  v-model="status"
                  data-vv-name="status"
                  v-validate= "modelValidations.status"
                  required>
                  <md-option value="approved">有効</md-option>
                  <md-option value="not_approve">無効</md-option>
                </md-select>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('status')">【必須】必ず一つ選択してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('status')" style="margin-right:20px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('status') && touched.status" style="margin-right:20px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100" v-if="!editSuper">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              アカウント種別
            </label>
            <div class="md-layout-item">
              <md-switch class="acountLabel" v-model="switch1">店舗アカウント</md-switch>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100" v-if="switch1">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              店舗名
            </label>
            <div class="md-layout-item md-size-20 md-xsmall-size-100">
              <shop-select v-model="shopId" :touchedFlag="true"></shop-select>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100" v-if="superFlag">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              企業名
            </label>
            <div class="md-layout-item md-size-30 md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('enterpriseName')},
                {'md-valid': !errors.has('enterpriseName') && touched.enterpriseName}]">
                <md-select
                  v-model="enterpriseName"
                  data-vv-name="enterpriseName"
                  v-validate= "superFlag || switch1 ? modelValidations.enterpriseName : ''"
                  required>
                  <md-option v-for="item in enterpriseList" :value="item.id">{{item.name}}</md-option>
                </md-select>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('enterpriseName')">【必須】必ず一つ選択してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('enterpriseName')" style="margin-right:20px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('enterpriseName') && touched.enterpriseName" style="margin-right:20px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              権限
            </label>
            <div class="md-layout-item pt5">
              <md-checkbox v-model="opt_adminUser">個人情報閲覧</md-checkbox>
            </div>
          </div>

        </md-card-content>
        <md-card-actions md-alignment="left" class="mt60 mx-auto">
          <md-button class="md-warning md-lg" @click.native.prevent="returnBack">戻る</md-button>
          <submit-button class="md-success md-lg mL40" :onclick="validate" :buttonWord=this.buttonWord type="submit"></submit-button>
        </md-card-actions>
      </md-card>
    </div>
  </div>

</template>

<script>
  import ShopSelect from '../components/additional/ShopSelect.vue'
  import router from '../entrypoints/router.js'
  import role from "../mixins/role.js"
  import EventBus from "../mixins/event-bus.js"
  import SubmitButton from '../components/additional/SubmitButton.vue';

  export default {
    mixins: [ role ],
    components: {
      SubmitButton,
      ShopSelect
    },
    computed: {
    },
    props: {
    },
    data () {
      return {
        buttonWord: '登録',
        login: '',
        password: '',
        email: '',
        lastName: '',
        firstName: '',
        lastKana: '',
        firstKana: '',
        status: '',
        switch1: false,
        shopId: '',
        opt_adminUser: true,
        listData: [],
        enterpriseName: '',
        enterpriseList: [],
        editSuper: false,
        newFlag: false,
        touched: {
          login: false,
          password: false,
          email: false,
          lastName: false,
          firstName: false,
          lastKana: false,
          firstKana: false,
          status: false,
          enterpriseName: false,
        },
        modelValidations: {
          login: {
            required: true,
            min: 6,
            max: 30,
            regex: '^[a-zA-Z0-9!-/:-@¥[-`{-~]+$'
          },
          password: {
            min: 6,
            max: 12,
            alpha_num: true
          },
          email: {
            max: 255,
            required: true,
            email: true
          },
          lastName: {
            max: 50,
            required: true
          },
          firstName: {
            max: 50,
            required: true
          },
          lastKana: {
            max: 50,
            required: true,
            regex: '^([ァ-ヴ][ァ-ヴー・]*)$'
          },
          firstKana: {
            max: 50,
            required: true,
            regex: '^([ァ-ヴ][ァ-ヴー・]*)$'
          },
          status: {
            required: true
          },
          shopId: {
            required: true
          },
          enterpriseName: {
            required: true
          },
        }
      }
    },
    methods: {
      validate() {
        return new Promise((resolve) => {
          setTimeout(() => {
            EventBus.$emit('validate', this.errors)
            this.$validator.validateAll().then(isValid => {
              if (this.errors.items.length == 0) {
                this.putUser()
              } else {
                this.validateError()
              }
            })
            resolve();
          }, this.ResolveTime);
        });
      },
      returnBack () {
        router.push({ path: '/admin/admin_user_list/' })
      },
      getUser () {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/admin_users/' + this.$route.params.id,
          timeout : 30000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.login = response.data.login
          this.email = response.data.email
          this.lastName = response.data.last_name
          this.firstName = response.data.first_name
          this.lastKana = response.data.last_name_kana
          this.firstKana = response.data.first_name_kana
          this.status = response.data.status
          this.opt_adminUser = response.data.browsing_right
          if (response.data.role === 'shop') {
            this.switch1 = true
            this.shopId = response.data.shop.id
          } else if (response.data.role === 'super'){
            this.editSuper = true
          }

          if (this.superFlag) {
            this.enterpriseName = response.data.company.id
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('管理ユーザー情報を取得できませんでした')
          }
        })
      },
      putUser () {
        var condition = {
          admin_user: {
            login: this.login,
            email: this.email,
            last_name: this.lastName,
            first_name: this.firstName,
            last_name_kana: this.lastKana,
            first_name_kana: this.firstKana,
            status: this.status,
            role: this.switch1 === false ? 'company' : 'shop',
            shop_id: this.switch1 === true ? this.shopId : null,
            browsing_right: this.opt_adminUser
          }
        }

        if (this.password !== '') {
          condition.admin_user.password = this.password
        }

        if (this.superFlag === true){
          condition.admin_user.company_id = this.enterpriseName
        }

        if (this.editSuper === true){
          delete condition.admin_user.role
        }

        this.axios({
          method  : this.newFlag === true ? 'POST' : 'PUT',
          url     : this.newFlag === true ? this.AdminApiUrl + '/admin_users/' : this.AdminApiUrl + '/admin_users/' + this.$route.params.id,
          timeout : 10000,
          data: condition,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.successAndBackPage(`管理ユーザー情報を`+this.buttonWord+`しました`,'/admin/admin_user_list/')
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify(`管理ユーザー情報を正しく`+this.buttonWord+`できませんでした`)
          }
        })
      },
      getCompany () {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/companies/select_list',
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          if (response.data.length === 0) {
            this.successAndBackPage(`選択できる企業がありませんでした`)
          } else {
            this.createComList(response.data)
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('企業情報を正しく取得できませんでした')
          }
        })
      },
      createComList (obj) {
        this.enterpriseList.splice(0)
        for (var i = 0;i < obj.length; i++) {
          var item = {
            id: obj[i].id,
            name: obj[i].name,
          }
          this.enterpriseList.push(item)
        }
      },
      resetData () {
        this.login = ''
        this.password = ''
        this.email = ''
        this.lastName = ''
        this.firstName = ''
        this.lastKana = ''
        this.firstKana = ''
        this.status = ''
        this.switch1 = false
        this.shopId = ''
        this.enterpriseName = ''
        this.$validator.reset()
        setTimeout (times =>{
          for (var key in this.touched) {
            this.touched[key] = false
          }
        },100)
      }
    },
    mounted () {
      // role check
      if (this.shopFlag) {
        router.push({ path: '/admin/login'})
        return false
      }
      this.getCompany ()
      var url = location.href
      url = url.split('/')
      if(url[url.length - 1] === 'new') {
        this.newFlag = true
        this.modelValidations.password.required = true
      } else {
        this.buttonWord = '更新'
        this.getUser()
      }
    },
    watch: {
      '$route.path'(value){
        if (value.match('new')) {
          this.newFlag = true
          this.buttonWord = '登録'
          this.modelValidations.password.required = true
          if(this.switch1 === false) {this.modelValidations.shopId.required = false}
          this.resetData()
        } else {
          this.newFlag = false
          this.buttonWord = '更新'
          if(this.switch1 === false) {this.modelValidations.shopId.required = false}
          this.getUser()
        }
      },
      login () {
        this.touched.login = true
      },
      password () {
        this.touched.password = true
      },
      email () {
        this.touched.email = true
      },
      lastName () {
        this.touched.lastName = true
      },
      firstName () {
        this.touched.firstName = true
      },
      lastKana () {
        this.touched.lastKana = true
      },
      firstKana () {
        this.touched.firstKana = true
      },
      status () {
        this.touched.status = true
      },
      enterpriseName () {
        this.touched.enterpriseName = true
      }
    }
  }
</script>

<style lang="css" scoped>
.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.btnCenter{
  margin-left: auto !important;
  margin-right: auto !important;
}

.priceCell{
  font-weight: bold;
  text-align: right;
}

.formLayout{
  flex-basis: auto;
}

.mg0{
  margin: 0;
}

.mt30{
  margin-top: 30px;
}

.mt60{
  margin-top: 60px;
}

.acountLabel:before{
  /* position: absolute; */
  line-height: 20px;
  color: rgba(0, 0, 0, .26);
  content: "企業アカウント";
  margin-right: 10px;
}

.md-switch{
  margin-top: 20px;
}

.is-l-none{
  display: none;
}

@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }

  .is-xs-none{
    display: none;
  }

  .is-xs-block{
    display: block;
  }
}

</style>
../entrypoints/router.js