<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">{{ rateData.title }}倍率設定</h4>
        </md-card-header>

        <md-card-content>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-20 md-form-label md-xsmall-size-100">
              キャンペーン名
            </label>
            <div class="md-layout-item">
              <md-field :class="[
                {'md-error': errors.has('campaignName')},
                {'md-valid': !errors.has('campaignName') && touched.campaignName}]">
                <md-input
                  v-model="campaignName"
                  data-vv-name="campaignName"
                  type="text"
                  v-validate= "modelValidations.campaignName"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('campaignName')">【必須】255文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('campaignName')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('campaignName') && touched.campaignName">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-20 md-form-label md-xsmall-size-100">
              倍率
            </label>
            <div class="md-layout-item  md-size-20 md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('rate')},
                {'md-valid': !errors.has('rate') && touched.rate}]">
                <md-input
                  v-model="rate"
                  data-vv-name="rate"
                  v-validate= "modelValidations.rate"
                  required
                  type="number">
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('rate')">【必須】2桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('rate')" style="margin-right:10px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('rate') && touched.rate" style="margin-right:10px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100 md-small-size-100">
            <label class="md-layout-item md-size-20 md-form-label md-xsmall-size-100">
              適用範囲
            </label>
            <div class="md-layout-item pt5">
              <md-radio v-model="scale" value="shop">店舗</md-radio>
              <md-radio v-model="scale" value="user_rank">会員ランク</md-radio>
              <md-radio v-model="scale" value="all_scale">全て</md-radio>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100" v-if="this.scale==='shop'">
            <label class="md-layout-item md-size-20 md-form-label md-xsmall-size-100">
              店舗名
            </label>
            <div class="md-layout-item">
              <shop-select v-model="shopId" :touchedFlag="true" :mutlipleFlag="true"></shop-select>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100" v-if="this.scale==='user_rank'">
            <label class="md-layout-item md-size-20 md-form-label md-xsmall-size-100">
              会員ランク
            </label>
            <div class="md-layout-item">
              <user-rank-select v-model="userRankId" :touchedFlag="true" :mutlipleFlag="true"></user-rank-select>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-20 md-form-label md-xsmall-size-100">
              ステータス
            </label>
            <div class="md-layout-item md-size-20 md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('status')},
                {'md-valid': !errors.has('status') && touched.status}]">
                <md-select
                  v-model="status"
                  data-vv-name="status"
                  v-validate= "modelValidations.status"
                  required>
                  <md-option value="active">有効</md-option>
                  <md-option value="not_active">無効</md-option>
                </md-select>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('status')">【必須】必ず一つ選択してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('status')" style="margin-right:20px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('status') && touched.status" style="margin-right:20px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-20 md-form-label md-xsmall-size-100">
              キャンペーン開始日
            </label>
            <div class="md-layout-item md-size-30 md-xsmall-size-100" style="position:relative">
              <vuejs-datepicker
                :format="DatePickerFormat"
                :highlighted="highlighted"
                v-model="selectedStart"
                :language="ja"
                data-vv-name="selectedStart"
                v-validate= "modelValidations.selectedStart"
                ></vuejs-datepicker>
              <md-button
                class="md-just-icon md-default md-simple dateDelete"
                @click.native="selectedStart = ''">
                <md-icon>close</md-icon>
              </md-button>
              <slide-y-up-transition>
                <div class="vErrorText" style="margin-left: 15px" v-show="errors.has('selectedStart')">【必須】日付を選択してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error dateError" v-show="errors.has('selectedStart')">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success dateSuccess" v-show="!errors.has('selectedStart') && touched.selectedStart">done</md-icon>
              </slide-y-down-transition>
            </div>
            <time-select class="md-layout-item md-xsmall-size-100" ref="startTimer" v-model="startTime"></time-select>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-20 md-form-label md-xsmall-size-100">
              キャンペーン終了日
            </label>
            <div class="md-layout-item md-size-30 md-xsmall-size-100" style="position:relative">
              <vuejs-datepicker
                :format="DatePickerFormat"
                :highlighted="highlighted"
                v-model="selectedEnd"
                :language="ja"
                data-vv-name="selectedEnd"
                v-validate= "modelValidations.selectedEnd"
                ></vuejs-datepicker>
              <md-button
                class="md-just-icon md-default md-simple dateDelete"
                @click.native="selectedEnd = ''">
                <md-icon>close</md-icon>
              </md-button>
              <slide-y-up-transition>
                <div class="vErrorText" style="margin-left: 15px" v-show="errors.has('selectedEnd')">【必須】日付を選択してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error dateError" v-show="errors.has('selectedEnd')">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success dateSuccess" v-show="!errors.has('selectedEnd') && touched.selectedEnd">done</md-icon>
              </slide-y-down-transition>
            </div>
            <time-select class="md-layout-item md-xsmall-size-100" ref="endTimer" v-model="endTime"></time-select>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="left" class="mt60 mx-auto">
          <submit-button class="md-success md-lg" :onclick="validate" :buttonWord=this.buttonWord type="submit"></submit-button>
        </md-card-actions>
      </md-card>
    </div>

    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">{{rateData.title}}倍率一覧</h4>
        </md-card-header>
        <md-card-content>
          <div class="dataTableWrapper">
            <draggable @sort="updateList" draggable=".tItem" class="dataTable" :options="{handle:'.cursorGrab',animation:200}">
              <div class="tHead">
                <div class="tHead_th">キャンペーン名</div>
                <div class="tHead_th">倍率</div>
                <div class="tHead_th">適用範囲</div>
                <div class="tHead_th">ステータス</div>
                <div class="tHead_th">キャンペーン期間</div>
                <div class="tHead_th taR">アクション</div>
              </div>
              <div v-for="item in tableData" :data-id="item.id" class="tItem">
                <div class="tItem_td commentBody"><md-label style="word-break:break-all;">{{ item.campaignName }}</md-label></div>
                <div class="tItem_td"><md-label style="word-break:break-all;">{{ item.rate }}</md-label></div>
                <div class="tItem_td"><md-label style="word-break:break-all;">{{ item.scale }}</md-label></div>
                <div class="tItem_td"><md-label style="word-break:break-all;">{{ item.status }}</md-label></div>
                <div class="tItem_td">{{ item.period }}</div>
                <div class="tItem_td taR td_actionTh">
                  <md-button
                    class="md-just-icon md-warning mg0"
                    @click.native="jumpDetail(item)">
                    <md-icon>dvr</md-icon>
                  </md-button>
                  <md-button
                    class="md-just-icon md-danger mg0"
                    @click.native="handleDelete(item)">
                    <md-icon>close</md-icon>
                  </md-button>
                </div>
              </div>
            </draggable>
          </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
  import router from '../../entrypoints/router.js'
  import EventBus from '../../mixins/event-bus.js';
  import role from "../../mixins/role.js"
  import Draggable from 'vuedraggable'
  import Fuse from 'fuse.js'
  import ShopSelect from './ShopSelect.vue'
  import TimeSelect from './TimeSelect.vue'
  import SubmitButton from './SubmitButton.vue'
  import UserRankSelect from './UserRankSelect.vue'
  import swal from 'sweetalert2'
  import qs from 'qs'

  export default {
    mixins: [ role ],
    components: {
      SubmitButton,
      ShopSelect,
      UserRankSelect,
      TimeSelect,
      Draggable
    },
    props: {
      rateData: Object
    },
    data () {
      return {
        buttonWord: '登録',
        campaignName: '',
        rate: '',
        scale: '選択しない',
        shopId: [],
        userRankId: [],
        selectedStart: '',
        selectedEnd: '',
        startTime: '',
        endTime: '',
        status: '',
        touched: {
          campaignName: false,
          rate: false,
          scale: false,
          selectedStart: false,
          selectedEnd: false,
          status: false
        },
        modelValidations: {
          campaignName: {
            required: true,
            max: 255
          },
          rate: {
            required: true,
            max: 2,
            numeric: true
          },
          scale: {
            required: true
          },
          shopId: {
            required: true
          },
          status: {
            required: true
          },
          selectedStart: {
            required: true
          },
          selectedEnd: {
            required: true
          }
        },
        tableData: [],
        shopSelect: [],
        userRankSelect: []
      }
    },
    methods: {
      updateList (event) {
        var id = event.item.getAttribute('data-id')
        this.updateStatus(id, event.newIndex + 1)
      },
      getRate () {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + this.rateData.apiUrl,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.createList(response.data)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify(this.rateData.title + '倍率設定を取得できませんでした')
          }
        })
      },
      createList (obj) {
        var res = obj.data
        this.tableData.splice(0)
        for (var i in res) {
          var item = {
            id: res[i].id,
            campaignName: res[i].attributes.name,
            rate: res[i].attributes.rate,
            scale: res[i].attributes.scale === 'shop' ? '店舗' : res[i].attributes.scale === 'user_rank' ? '会員ランク' : "全て",
            shopName: this.shopChecker(res[i].attributes['shop-id']),
            userRankName: this.userRankChecker(res[i].attributes['user-rank-id']),
            status: res[i].attributes.status === 'active' ? '有効' : '無効',
            period: this.initDate(res[i].attributes['public-from']) + ' 〜 ' + this.initDate(res[i].attributes['public-to'])
          }
          this.tableData.push(item)
        }
      },
      validate() {
        return new Promise((resolve) => {
          setTimeout(() => {
            EventBus.$emit('validate', this.errors)
            this.$validator.validateAll().then(isValid => {
              if (this.errors.items.length == 0) {
                this.updateRate()
              } else {
                this.validateError()
              }
            })
            resolve();
          }, this.ResolveTime);
        });
      },
      updateRate(){
        var condition = {
          rate: {
            name: this.campaignName,
            rate: this.rate,
            scale: this.scale,
            shop_ids: this.scale === "shop" ? this.shopId === '' ? '' : this.shopId.join(',') : '',
            user_rank_ids: this.scale === "user_rank" ? this.userRankId === '' ? '' : this.userRankId.join(',') : '',
            status:this.status,
            public_from: this.initDate(this.selectedStart) + ' ' + this.startTime,
            public_to: this.initDate(this.selectedEnd) + ' ' + this.endTime
          }
        }
        this.axios({
          method  : 'POST',
          url     : this.AdminApiUrl + this.rateData.apiUrl,
          timeout : 30000,
          data: qs.stringify(condition),
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          swal.fire({
            title: this.rateData.title+`倍率設定を`+this.buttonWord+`しました`,
            buttonsStyling: false,
            icon: 'success',
            customClass: {
              confirmButton: "md-button md-success",
            },
          }).then(response => {
              this.resetData()
              this.getRate()
              router.push({ path: this.rateData.returnBack })
          })
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify(this.rateData.title+`倍率設定を正しく`+this.buttonWord+`できませんでした`)
          }
        })
      },
      handleDelete (item) {
        swal.fire({
          title: 'この' + this.rateData.title + '倍率設定を削除してもよろしいですか?',
          text: `削除してしまうと、元に戻すことは出来ません！`,
          icon: 'warning',
          showCancelButton: true,
          customClass: {
            confirmButton: "md-button md-success",
            cancelButton: "md-button md-danger"
          },
          confirmButtonText: 'OK',
          buttonsStyling: false
        }).then((result) => {
          if(result.value){
            this.deleteOrder(item)
          }
        });
      },
      deleteOrder (item) {
        this.axios({
          method  : 'DELETE',
          url     : this.AdminApiUrl + this.rateData.apiUrl + item.id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.deleteRow(item)
          this.successAndBackPage(this.rateData.title + `倍率設定を削除しました`)
          this.getRate()
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify(this.rateData.title + '倍率設定を削除することが出来ませんでした')
          }
        })
      },
      deleteRow(item){
        let indexToDelete = this.tableData.findIndex((tableRow) => tableRow.id === item.id);
        if (indexToDelete >= 0) {
          this.tableData.splice(indexToDelete, 1)
        }
      },
      jumpDetail (item) {
        router.push({ path: this.rateData.editUrl + item.id })
      },
      getShop (page) {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/shops/select_list',
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
        }).then(response => {
          this.shopSelect = response.data
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('店舗リストを取得できませんでした')
          }
        })
      },
      getUserRank (page) {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/user_ranks/select_list',
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
        }).then(response => {
          this.userRankSelect = response.data
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('会員ランクリストを取得できませんでした')
          }
        })
      },
      shopChecker (id) {
        var name = ''
        for (var i = 0 ; i < this.shopSelect.length ; i++) {
          if (this.shopSelect[i].id === id) {
            name = this.shopSelect[i].name
            break
          }
        }
        return name
      },
      userRankChecker (id) {
        var name = ''
        for (var i = 0 ; i < this.userRankSelect.length ; i++) {
          if (this.userRankSelect[i].id === id) {
            name = this.userRankSelect[i].name
            break
          }
        }
        return name
      },
      setShop () {
        var shopSelect = sessionStorage.getItem('shopSelect')
        if (shopSelect === null) {
          this.getShop()
        } else {
          this.shopSelect = JSON.parse(shopSelect)
        }
      },
      setUserRank () {
        var userRankSelect = sessionStorage.getItem('userRankSelect')
        if (userRankSelect === null) {
          this.getUserRank()
        } else {
          this.userRankSelect = JSON.parse(userRankSelect)
        }
      },
      resetData () {
        this.campaignName = ''
        this.rate = ''
        this.scale = ''
        this.shopId = []
        this.userRankId = []
        this.status = ''
        this.selectedStart = null
        this.startTime = '00:00'
        this.$refs.startTimer.lock()
        this.selectedEnd = null
        this.endTime = '00:00'
        this.$refs.endTimer.lock()
        this.$validator.reset()
        setTimeout (times =>{
          for (var key in this.touched) {
            this.touched[key] = false
          }
        },100)
      }      
    },
    mounted () {
      // role check
      if (this.shopFlag) {
        router.push({ path: '/admin/login'})
        return false
      }
      this.setShop()
      this.setUserRank()
      this.getRate()
    },
    watch: {
      campaignName () {
        this.touched.campaignName = true
      },
      rate () {
        this.touched.rate = true
      },
      scale(){
        this.touched.scale = true
        if (this.scale==='shop') {
          this.requiredFlag=false
        } else {
          this.requiredFlag=true
        }
      },
      status () {
        this.touched.status = true
      },
      selectedStart () {
        this.$refs.startTimer.unlock()
      },
      selectedEnd () {
        this.$refs.endTimer.unlock()
      }
    }
  }
</script>

<style lang="css" scoped>
.fade-enter-active, .fade-leave-active {
  will-change: opacity;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.fade-enter, .fade-leave-to {
  opacity: 0
}

.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.btnCenter{
  margin-left: auto !important;
  margin-right: auto !important;
}

.priceCell{
  font-weight: bold;
  text-align: right;
}

.formLayout{
  flex-basis: auto;
}

.mg0{
  margin: 0;
}

.btnSpace{
  margin: 0 2px;
}


.betweenTime{
  line-height: 59px;
}

.dateDelete{
  position: absolute;
  top: 0;
  right: 0;
}

.numberInput{
  text-align: right;
  width: 100%;
}

.dragHandle{
  margin-left: 10px;
}

.cursorGrab{
  cursor: grab;
}

@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }

  .is-xs-none{
    display: none;
  }

  .is-xs-block{
    display: block;
  }
}
</style>
../../entrypoints/router.js