<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>create</md-icon>
          </div>
          <h4 class="title">会員ランク情報</h4>
        </md-card-header>
        <md-card-content class="md-layout formLayout">
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              ランク順
            </label>
            <div class="md-layout-item">
              <md-field style="width: 50px" :class="[
                {'md-error': errors.has('number')},
                {'md-valid': !errors.has('number') && touched.number}]">
                <md-input
                  v-model="number"
                  data-vv-name="number"
                  type="number"
                  v-validate= "modelValidations.number"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('number')">3桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('number')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('number') && touched.number">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              会員ランク
            </label>
            <div class="md-layout-item md-size-30 md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('rankName')},
                {'md-valid': !errors.has('rankName') && touched.rankName}]">
                <md-input
                  v-model="rankName"
                  data-vv-name="rankName"
                  type="text"
                  v-validate= "modelValidations.rankName"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('rankName')">【必須】255文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('rankName')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('rankName') && touched.rankName">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              説明
            </label>
            <div class="md-layout-item">
              <md-field :class="[
                {'md-error': errors.has('rankDetail')},
                {'md-valid': !errors.has('rankDetail') && touched.rankDetail}]">
                <md-textarea
                  class="txtArea"
                  v-model="rankDetail" 
                  data-vv-name="rankDetail"
                  type="text"
                  v-validate= "modelValidations.rankDetail" 
                  md-counter="140" 
                  maxlength="140"
                  required>
                </md-textarea>
                <slide-y-up-transition>
                  <div class="vErrorText errorMsg" v-show="errors.has('rankDetail')">【必須】140文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('rankDetail')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('rankDetail') && touched.rankDetail">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              会員有効期限
            </label>
            <div class="md-layout-item  md-size-30 md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('period')},
                {'md-valid': !errors.has('period') && touched.period}]">
                <md-select
                  v-model="period"
                  data-vv-name="period"
                  v-validate= "modelValidations.period"
                  required>
                  <md-option value="none_years">なし</md-option>
                  <md-option value="half_years">半年</md-option>
                  <md-option value="one_years">１年</md-option>
                </md-select>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('period')">【必須】一つ選択してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('period')" style="margin-right:20px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('period') && touched.period" style="margin-right:20px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100 md-small-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              適用ルール
            </label>
            <div class="md-layout-item">
              <md-radio v-model="rule" value="order_amount" >注文金額</md-radio>
              <md-radio v-model="rule" value="order_count" >注文回数</md-radio>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100 md-small-size-100" v-show="rule==='order_amount'">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              注文金額
            </label>
            <div class="md-layout-item md-size-10 md-xsmall-size-50">
              <md-field :class="[
                {'md-error': errors.has('ruleOrderAmount')},
                {'md-valid': !errors.has('ruleOrderAmount') && touched.ruleOrderAmount}]">
                <md-input
                  v-model="ruleOrderAmount"
                  data-vv-name="ruleOrderAmount"
                  v-validate= "rule === 'order_amount' ? modelValidations.ruleOrderAmount : ''"
                  type="number"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('ruleOrderAmount')">【必須】6桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('ruleOrderAmount')" style="margin-right:10px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('ruleOrderAmount') && touched.ruleOrderAmount" style="margin-right:10px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
            <div class="md-layout-item unitLabel">円以上</div>
          </div>
          <div class="md-layout md-layout-item md-size-100 md-small-size-100" v-show="rule==='order_amount'">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              期間
            </label>
            <div class="md-layout-item md-size-15 md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('rulePeriodOrderAmount')},
                {'md-valid': !errors.has('rulePeriodOrderAmount') && touched.rulePeriodOrderAmount}]">
                <md-select
                  v-model="rulePeriodOrderAmount"
                  data-vv-name="rulePeriodOrderAmount"
                  v-validate= "rule === 'order_amount' ? modelValidations.rulePeriodOrderAmount : ''"
                  required>
                  <md-option value="last_year">過去１年間</md-option>
                  <md-option value="half_year">過去半年</md-option>
                </md-select>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('rulePeriodOrderAmount')">一つ選択してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('rulePeriodOrderAmount')" style="margin-right:20px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('rulePeriodOrderAmount') && touched.rulePeriodOrderAmount" style="margin-right:20px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100 md-small-size-100" v-show="rule==='order_count'">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              注文回数
            </label>
            <div class="md-layout-item md-size-10 md-xsmall-size-50">
              <md-field :class="[
                {'md-error': errors.has('ruleOrderCount')},
                {'md-valid': !errors.has('ruleOrderCount') && touched.ruleOrderCount}]">
                <md-input
                  v-model="ruleOrderCount"
                  data-vv-name="ruleOrderCount"
                  v-validate= "rule === 'order_count' ? modelValidations.ruleOrderCount : ''"
                  type="number"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('ruleOrderCount')">【必須】6桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('ruleOrderCount')" style="margin-right:10px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('ruleOrderCount') && touched.ruleOrderCount" style="margin-right:10px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
            <div class="md-layout-item unitLabel">個以上</div>
          </div>
          <div class="md-layout md-layout-item md-size-100 md-small-size-100" v-show="rule==='order_count'">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              期間
            </label>
            <div class="md-layout-item md-size-15 md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('rulePeriodOrderCount')},
                {'md-valid': !errors.has('rulePeriodOrderCount') && touched.rulePeriodOrderCount}]">
                <md-select
                  v-model="rulePeriodOrderCount"
                  data-vv-name="rulePeriodOrderCount"
                  v-validate= "rule === 'order_count' ? modelValidations.rulePeriodOrderCount : ''"
                  required>
                  <md-option value="last_year">過去１年間</md-option>
                  <md-option value="half_year">過去半年</md-option>
                </md-select>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('rulePeriodOrderCount')">一つ選択してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('rulePeriodOrderCount')" style="margin-right:20px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('rulePeriodOrderCount') && touched.rulePeriodOrderCount" style="margin-right:20px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="left" class="mt60 mx-auto">
          <submit-button class="md-success md-lg mL40" :onclick="validate" :buttonWord=this.buttonWord type="success"></submit-button>
        </md-card-actions>
      </md-card>

      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">会員ランク一覧</h4>
        </md-card-header>
        <md-card-content>
          <div class="dataTableWrapper">
            <draggable @sort="updateList" draggable=".tItem" class="dataTable" :options="{handle:'.cursorGrab',animation:200}">
              <div class="tHead">
                <div class="tHead_th">ランク順</div>
                <div class="tHead_th">会員ランク</div>
                <div class="tHead_th">説明</div>
                <div class="tHead_th taR">アクション</div>
              </div>
              <div v-for="item in tableData" :data-id="item.id" class="tItem">
                <div class="tItem_td cursorGrab"><md-icon class="dragHandle">reorder</md-icon></div>
                <div class="tItem_td"><md-label style="word-break:break-all;">{{ item.name }}</md-label></div>
                <div class="tItem_td"><md-label style="word-break:break-all;">{{ item.description }}</md-label></div>
                <div class="tItem_td taR td_actionTh">
                  <md-button
                    class="md-just-icon md-warning mg0"
                    @click.native="jumpDetail(item)">
                    <md-icon>dvr</md-icon>
                  </md-button>
                  <md-button
                    class="md-just-icon md-danger mg0"
                    @click.native="handleDelete(item)">
                    <md-icon>close</md-icon>
                  </md-button>
                </div>
              </div>
            </draggable>
          </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
  import router from '../entrypoints/router.js'
  import swal from 'sweetalert2'
  import Draggable from 'vuedraggable'
  import role from "../mixins/role.js"
  import EventBus from '../mixins/event-bus.js'
  import SubmitButton from '../components/additional/SubmitButton.vue'

  export default {
    mixins: [ role ],
    components: {
      SubmitButton,
      Draggable
    },
    data () {
      return {
        tableData: [],
        buttonWord: '登録',
        number: null,
        rankName: '',
        rankDetail: '',
        rule: false,
        period: '',
        ruleOrderAmount: '',
        ruleOrderCount: '',
        rulePeriodOrderAmount: '',
        rulePeriodOrderCount: '',
        touched: {
          number: false,
          rankName: false,
          rankDetail: false,
          period: false,
          ruleOrderAmount: false,
          ruleOrderCount: false,
          rulePeriodOrderAmount: false,
          rulePeriodOrderCount: false
        },
        modelValidations: {
          number: {
            max: 3,
            numeric: true
          },
          rankName: {
            max: 255,
            required: true
          },
          rankDetail: {
            required: true
          },
          period:{
            required: true
          },
          ruleOrderAmount: {
            max: 6,
            required: true
          },
          ruleOrderCount: {
            max: 6,
            required: true
          },
          rulePeriodOrderAmount: {
            required: true
          },
          rulePeriodOrderCount: {
            required: true
          }
        }
      }
    },
    methods: {
      validate() {
        return new Promise((resolve) => {
          setTimeout(() => {
            EventBus.$emit('validate', this.errors)
            this.$validator.validateAll().then(isValid => {
              if (this.errors.items.length == 0) {
                this.updateUserRank()
              } else {
                this.validateError()
              }
            })
            resolve();
          }, this.ResolveTime);
        });
      },
      getUserRanks () {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/user_ranks',
          timeout : 30000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
        }).then(response => {
            this.createList(response.data)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('会員ランク情報を正しく取得できませんでした')
          }
        })
      },
      createList (obj) {
        var res = obj.data
        this.tableData.splice(0)
        for (var i in res) {
          var item = {
            id: res[i].id,
            name: res[i].attributes.name,
            description: res[i].attributes.description,
            period: res[i].attributes.period,
            rule: res[i].attributes.rule,
            rule_order_amount: res[i].attributes.rule_order_amount,
            rule_order_count: res[i].attributes.rule_order_count,
            rule_period_order_amount: res[i].attributes.rule_period_order_amount,
            rule_period_order_count: res[i].attributes.rule_period_order_count,
            position: res[i].attributes.position
          }
          this.tableData.push(item)
        }
      },
      customSort (value) {
        return value.sort((a, b) => {
          const sortBy = this.currentSort
          if (this.currentSortOrder === 'desc') {
            return a[sortBy].localeCompare(b[sortBy])
          }
          return b[sortBy].localeCompare(a[sortBy])
        })
      },
      handleDelete (item) {
        swal.fire({
          title: 'この会員ランクを削除してもよろしいですか?',
          text: `削除してしまうともとに戻すことは出来ません！`,
          icon: 'warning',
          showCancelButton: true,
          customClass: {
            confirmButton: "md-button md-success",
            cancelButton: "md-button md-danger"
          },
          confirmButtonText: 'OK',
          buttonsStyling: false
        }).then((result) => {
          if(result.value){
            this.deleteUserRank(item)
          }
        });
      },
      deleteRow(item){
        let indexToDelete = this.tableData.findIndex((tableRow) => tableRow.id === item.id);
        if (indexToDelete >= 0) {
          this.tableData.splice(indexToDelete, 1)
        }
      },
      deleteUserRank (item) {
        this.axios({
          method  : 'DELETE',
          url     : this.AdminApiUrl + '/user_ranks/' + item.id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
          data: {
            order_status : status
          }
        }).then(response => {
          this.deleteRow(item)
          this.successAndBackPage(`会員ランク情報を削除しました`)
          this.getUserRanks()
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('会員ランク情報を正しく削除できませんでした')
          }
        })
      },
      updateList (event) {
        var id = event.item.getAttribute('data-id')
        this.updateStatus(id, event.newIndex) 
      },
      updateStatus (id, position) {
        var condition = {
          user_rank: {
            'position': position
          }
        }
        this.axios({
          method  : 'PUT',
          url     : this.AdminApiUrl + '/user_ranks/' + id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
          data: condition
        }).then(response => {
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('ランク順の更新に失敗しました')
          }
        })
      },
      jumpDetail (item) {
        router.push({ path: '/admin/user_rank/edit/' + item.id })
      },
      updateUserRank () {
        var condition = {
          user_rank: {
            'position': this.number,
            'name': this.rankName,
            'description': this.rankDetail,
            'period': this.period,
            'rule': this.rule,
            'rule_order_amount': this.ruleOrderAmount,
            'rule_order_count': this.ruleOrderCount,
            'rule_period_order_amount': this.rulePeriodOrderAmount,
            'rule_period_order_count': this.rulePeriodOrderCount
          }
        }

        this.axios({
          method  : 'POST',
          url     : this.AdminApiUrl + '/user_ranks/',
          timeout : 10000,
          data: condition,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          swal.fire({
            title: `会員ランク情報を登録しました`,
            buttonsStyling: false,
            icon: 'success',
            customClass: {
              confirmButton: "md-button md-success",
            },
          }).then(response => {
              this.resetData()
              this.getUserRanks()
              router.push({ path: '/admin/user_rank_list/' })
          })
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('会員ランクを登録できませんでした')
          }
        })
      },
      resetData () {
        this.number = null
        this.rankName = ''
        this.rankDetail = ''
        this.period = ''
        this.rule = false
        this.ruleOrderAmount = ''
        this.ruleOrderCount = ''
        this.rulePeriodOrderAmount = ''
        this.rulePeriodOrderCount = ''
        this.$validator.reset()
        setTimeout (times =>{
          for (var key in this.touched) {
            this.touched[key] = false
          }
        },100)
      }
    },
    mounted () {
      if (this.shopFlag) {
        router.push({ path: '/admin/login'})
        return false
      }
      this.getUserRanks()
    },
    watch: {
      number () {
        this.touched.number = true
      },
      rankName(){
        this.touched.rankName = true
      },
      rankDetail(){
        this.touched.rankDetail = true
      },
      period(){
        this.touched.period = true
      },
      rule(){
        this.touched.rule = true
      },
      ruleOrderAmount(){
        this.touched.ruleOrderAmount = true
      },
      ruleOrderCount(){
        this.touched.ruleOrderCount = true
      },
      rulePeriodOrderAmount(){
        this.touched.rulePeriodOrderAmount = true
      },
      rulePeriodOrderCount(){
        this.touched.rulePeriodOrderCount = true
      }
    }
  }
</script>

<style lang="css" scoped>
.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.btnCenter{
  margin-left: auto !important;
  margin-right: auto !important;
}

.priceCell{
  font-weight: bold;
  text-align: right;
}

.formLayout{
  flex-basis: auto;
}

.mg0{
  margin: 0;
}

.pt20{
  padding-top: 20px;
}

.btnSpace{
  margin: 0 2px;
}

.unitLabel{
  padding: 10px 0 0 0;
  line-height: 46px;
}

.txtArea{
  padding: 10px 0px 0px !important;
}

.errorMsg{
  bottom: -15px !important;
}

.dragHandle{
  margin-left: 10px;
}

.cursorGrab{
  cursor: grab;
}

@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }

  .is-xs-none{
    display: none;
  }

  .is-xs-block{
    display: block;
  }
}
</style>
../entrypoints/router.js