
<div class="md-layout">
  <div class="md-layout-item md-size-100">
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-green">
        <div class="card-icon">
          <md-icon>account_box</md-icon>
        </div>
        <h4 class="title">決済詳細</h4>
      </md-card-header>

      <md-card-content class="formLayout">
        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            注文番号
          </label>
          <div class="md-layout-item md-size-85 md-xsmall-size-100">
            <p class="form-control-static">{{orderNumber}}</p>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            支払番号
          </label>
          <div class="md-layout-item md-size-85 md-xsmall-size-100">
            <p class="form-control-static">{{approveNo}}</p>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            決済日
          </label>
          <div class="md-layout-item md-size-85 md-xsmall-size-100">
            <p class="form-control-static">{{orderDate | dateFormat}}</p>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            ステータス
          </label>
          <div class="md-layout-item md-size-85 md-xsmall-size-100">
            <md-field class="productStatus">
              <md-select v-model="orderStatus" name="status">
                <md-option value="recieved">完了</md-option>
                <md-option value="cancel">キャンセル</md-option>
              </md-select>
            </md-field>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            決済店舗
          </label>
          <div class="md-layout-item md-size-85 md-xsmall-size-100">
            <p class="form-control-static">{{shopName}}</p>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            決済金額
          </label>
          <div class="md-layout-item md-size-85 md-xsmall-size-100">
            <p class="form-control-static">
              <small>¥</small>
              {{orderPrice | numberWithDelimiter}}
            </p>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            ポイント利用
          </label>
          <div class="md-layout-item md-size-85 md-xsmall-size-100">
            <p class="form-control-static" v-if="usePoint === 0">
              なし
            </p>
            <p class="form-control-static" v-else>
              {{usePoint | numberWithDelimiter}}
            </p>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            クーポン利用
          </label>
          <div class="md-layout-item md-size-85 md-xsmall-size-100">
            <p class="form-control-static" v-if="couponDiscount === 0">
              なし
            </p>
            <p class="form-control-static" v-else>
              <small>¥ -</small>
              {{couponDiscount | numberWithDelimiter}}
            </p>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            コメント
          </label>
          <div class="md-layout-item md-size-85 md-xsmall-size-100">
            <md-field>
              <md-textarea v-model="comment"></md-textarea>
            </md-field>
          </div>
        </div>

      </md-card-content>
      <md-card-actions class="mx-auto" md-alignment="left">
        <md-button class="md-warning md-lg" @click.native.prevent="returnBack">戻る</md-button>
        <md-button class="md-success md-lg mL40" @click.native.prevent="validate">更新</md-button>
      </md-card-actions>
    </md-card>

  </div>
</div>

