<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>search</md-icon>
          </div>
          <h4 class="title">お問合せ管理</h4>
        </md-card-header>

        <md-card-content class="md-layout formLayout">
          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              メールアドレス
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field>
                <md-input v-model="email"></md-input>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              お問合せカテゴリ名
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <contact-category-select v-model="contactCategoryId" :noSelectFlag="true"></contact-category-select>
            </div>
          </div>
          
          <div class="md-layout md-layout-item md-size-50">
            <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
              対応ステータス
            </label>
            <div class="md-layout-item md-xsmall-size-100">
              <md-field class="UserStatus">
                <md-select v-model="status" name="status">
                  <md-option value="選択しない">選択しない</md-option>
                  <md-option value="not_reply">未回答</md-option>
                  <md-option value="reply">回答済み</md-option>
                  <md-option value="no_need">回答不要</md-option>
                </md-select>
              </md-field>
            </div>
          </div>

        </md-card-content>
        <md-card-actions class="mx-auto">
          <md-button class="md-success md-lg" @click="getInfo()">検索</md-button>
          <md-button class="md-info md-lg mL40" @click="handleClear">検索条件クリア</md-button>
        </md-card-actions>
      </md-card>
    </div>
    <transition name="fade">
      <div class="md-layout-item md-size-100" v-if="resultFlag">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>assignment</md-icon>
            </div>
            <h4 class="title">お問合せ一覧</h4>
          </md-card-header>
          <md-card-content>
            <div class="dataTableWrapper">
              <div class="dataTable"> 
                <div class="tHead">
                  <div class="tHead_th">メールアドレス</div>
                  <div class="tHead_th">問合せカテゴリ</div>
                  <div class="tHead_th">問合せ日時</div>
                  <div class="tHead_th">ステータス</div>
                  <div class="tHead_th taR">アクション</div>
                </div>
                <div v-for="item in tableData" :data-id="item.id" class="tItem">
                  <div class="tItem_td">{{ item.email }}</div>
                  <div class="tItem_td">{{ item.contactCategoryName }}</div>
                  <div class="tItem_td">{{ item.createdat | timeFormat2}}</div>
                  <div class="tItem_td">{{ item.status }}</div>
                  <div class="tItem_td taR td_actionTh">
                    <md-button
                      class="md-just-icon md-warning mg0"
                      @click.native="jumpDetail(item)">
                      <md-icon>dvr</md-icon>
                    </md-button>
                    <md-button
                      class="md-just-icon md-danger mg0"
                      @click.native="handleDelete(item)">
                      <md-icon>close</md-icon>
                    </md-button>
                  </div>
                </div>
              </div>
            </div>
          </md-card-content>
          <md-card-actions md-alignment="space-between">
            <div class="">
              <p class="card-category">全{{ pageData.totalCount | numberWithDelimiter }}件中 {{from | numberWithDelimiter}}件から {{to | numberWithDelimiter}}件を表示</p>
            </div>
            <Pagination class="Pagination-no-border Pagination-success"
              :currentPage="pageData.currentPage"
              :totalPage="pageData.totalPage"
              @get="getInfo">
            </Pagination>
          </md-card-actions>
        </md-card>
      </div>
    </transition>
  </div>

</template>

<script>
  import Pagination from '../components/additional/Pagination.vue'
  import ContactCategorySelect from '../components/additional/ContactCategorySelect.vue'
  import router from '../entrypoints/router.js'
  import Fuse from 'fuse.js'
  import swal from 'sweetalert2'
  import role from "../mixins/role.js"

  export default {
    mixins: [ role ],
    components: {
      Pagination,
      ContactCategorySelect
    },        
    computed: {
      to () {
        return (this.pageData.currentPage - 1) * 25 + this.tableData.length
      },
      from () {
        return (this.pageData.currentPage - 1) * 25 + 1
      },
      saveCondition() {
        return this.$store.getters['contactList/condition']
      }
    },
    data () {
      return {
        resultFlag: false,
        email: '',
        contactCategoryId: '選択しない',
        status: '選択しない',
        pageData: {
          totalPage: 0,
          currentPage: 0,
          nextPage: null,
          prevPage: null,
          totalCount: 0
        },
        tableData: []
      }
    },
    methods: {
      getInfo (page = 1) {
        var condition = {
          page: page,
          email: this.email,
          contact_category_id: this.contactCategoryId === '選択しない' ? '' : this.contactCategoryId,
          status: this.status === '選択しない' ? '' : this.status
        }
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/contacts/',
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
          params: condition
        }).then(response => {
          if (response.data.data.length === 0) {
            this.resultFlag = false
            this.noSearchResults()
          } else {
            this.createList(response.data)
            this.pageData.totalPage = response.data.meta['total-pages']
            this.pageData.currentPage = response.data.meta['current-page']
            this.pageData.totalCount = response.data.meta['total-count']
          }
          this.$store.dispatch('contactList/searchParamsAsync', condition)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('お問合せ情報を取得できませんでした')
          }
        })
      },
      createList (obj) {
        var res = obj.data
        this.tableData.splice(0)
        for (var i in res) {
          var item = {
            id: res[i].id,
            email: res[i].attributes.email,
            contactCategoryName: res[i].attributes['contact-category-name'],
            createdat: res[i].attributes['created-at']
          }
          switch (res[i].attributes.status) {
            case 'not_reply':
              item.status = '未回答'
              break;
            case 'reply':
              item.status = '回答済み'
              break;
            case 'no_need':
              item.status = '回答不要'
              break;
            default:
              item.status = '識別不明'
          }
          this.tableData.push(item)
        }
        this.resultFlag = true
      },
      handleDelete (item) {
        swal.fire({
          title: 'このお問合せ情報を削除してもよろしいですか?',
          text: `削除してしまうと、元に戻すことは出来ません！`,
          icon: 'warning',
          showCancelButton: true,
          customClass: {
            confirmButton: "md-button md-success",
            cancelButton: "md-button md-danger"
          },
          confirmButtonText: 'OK',
          buttonsStyling: false
        }).then((result) => {
          if(result.value){
            this.deleteOrder(item)
          }
        });
      },
      deleteOrder (item) {
        this.axios({
          method  : 'DELETE',
          url     : this.AdminApiUrl + '/contacts/' + item.id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.deleteRow(item)
          this.successAndBackPage(`お問合せ情報を削除しました`)
          this.getInfo()
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('お問合せ情報を削除することが出来ませんでした')
          }
        })
      },
      deleteRow(item){
        let indexToDelete = this.tableData.findIndex((tableRow) => tableRow.id === item.id);
        if (indexToDelete >= 0) {
          this.tableData.splice(indexToDelete, 1)
        }
      },
      jumpDetail (item) {
        router.push({ path: '/admin/contact/edit/' + item.id })
      },
      reloadPage() {
        this.email = this.saveCondition.email
        this.contactCategoryId = this.saveCondition.contact_category_id || '選択しない'
        this.status = this.saveCondition.status || '選択しない'
        this.getInfo(this.saveCondition.page)
      },
      handleClear() {
        this.email = ''
        this.contactCategoryId = '選択しない'
        this.status = '選択しない'
        this.getInfo()
      }
    },
    mounted() {
      // role check
      if (!this.superFlag) {
        router.push({ path: '/admin/login'})
        return false
      }
      if (!this.$route.query.fromSidebar && this.saveCondition) {
        this.reloadPage()
      } else {
        this.$store.dispatch('contactList/searchParamsAsync', null)
        this.handleClear()
      }
    }
  }
</script>

<style lang="css" scoped>
.fade-enter-active, .fade-leave-active {
  will-change: opacity;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.fade-enter, .fade-leave-to {
  opacity: 0
}

.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.priceCell{
  font-weight: bold;
  text-align: right;
}

.formLayout{
  flex-basis: auto;
}

.mg0{
  margin: 0;
}

.btnSpace{
  margin: 0 2px;
}


.betweenTime{
  line-height: 59px;
}

.dateDelete{
  position: absolute;
  top: 0;
  right: 0;
}

.numberInput{
  text-align: right;
  width: 100%;
}

.dragHandle{
  margin-left: 10px;
}

.cursorGrab{
  cursor: grab;
}

@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }

  .is-xs-none{
    display: none;
  }

  .is-xs-block{
    display: block;
  }
}
</style>
../entrypoints/router.js