
<div class="md-layout">
  <div class="md-layout-item md-size-100">
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-green">
        <div class="card-icon">
          <md-icon>local_atm</md-icon>
        </div>
        <h4 class="title">支払用QRコード出力</h4>
      </md-card-header>
      <md-card-content class="md-layout formLayout" >
        <div class="md-layout md-layout-item md-size-100" v-if="!shopFlag">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              店舗名
            </label>
            <div class="md-layout-item md-size-40  md-xsmall-size-100">
              <shop-select v-model="shopId" :touchedFlag="true"></shop-select>
            </div>
        </div>
        <div class="md-layout md-layout-item md-size-50 md-xsmall-size-100">
          <label class="md-layout-item md-size-30 md-form-label md-xsmall-size-100">
            決済金額
          </label>
          <div class="md-layout-item md-xsmall-size-100">
            <md-field :md-counter="false" :class="[
              {'md-error': errors.has('sales_price')},
              {'md-valid': !errors.has('sales_price') && touched.sales_price}]">
              <md-input
                v-model="sales_price"
                data-vv-name="sales_price"
                type="number"
                v-validate= "modelValidations.sales_price"
                required>
              </md-input>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('sales_price')">6桁以内の半角数字で入力してください</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('sales_price')">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('sales_price') && touched.sales_price">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>
      </md-card-content>

      <md-card-actions md-alignment="left" class="mt60 mx-auto">
          <md-button class="md-success md-lg mL40" @click="validate">発行する</md-button>
      </md-card-actions>
    </md-card>
    <md-card v-show="qr_flg">
      <md-card-header class="md-card-header-icon md-card-header-green">
        <div class="card-icon">
          <md-icon>assignment</md-icon>
        </div>
        <h4 class="title">支払用QRコード</h4>
      </md-card-header>
        <md-card-content class="md-layout formLayout md-alignment-center">
          <div class="md-layout-item md-size-40 md-xsmall-size-100 pt20">
            <div class="md-layout-item md-size-100">
              <div class="file-input imageWidth">
                <div class="image-container">
                  <img :src="image_url" />
                </div>
              </div>
            </div>
            <div class="md-layout md-layout-item md-size-100">
              <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
                支払金額
              </label>
              <div class="md-layout-item md-size-85 md-xsmall-size-100">
                <p class="form-control-static">¥{{ qr_price | fewWithDelimiter}}円</p>
              </div>
            </div>
            <div class="md-layout md-layout-item md-size-100">
              <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
                有効期限
              </label>
              <div class="md-layout-item md-size-85 md-xsmall-size-100">
                <p class="form-control-static">{{ sale_to_at | timeFormat}}まで</p>
              </div>
            </div>
          </div>
        </md-card-content>
    </md-card>
  </div>
</div>

