<template>
  <div class="full-page">
    <notifications></notifications>
    <div class="wrapper wrapper-full-page">
      <div class="page-header header-filter" :class="setPageClass" filter-color="black" :style="setBgImage">
        <div class="container md-offset">
          <zoom-center-transition :duration="pageTransitionDuration" mode="out-in">
            <div class="md-layout text-center">
              <div class="md-layout-item md-size-33 md-medium-size-50 md-small-size-70 md-xsmall-size-100">
                <login-card header-color="green">
                  <div slot="description" style="height:36px;">
                  新しいパスワードを入力してください
                  </div>
                  <md-field class="md-form-group" slot="inputs" :class="[
                      {'md-invalid': errors.has('パスワード')},
                      {'md-error': errors.has('パスワード')}]">
                    <md-icon>lock_outline</md-icon>
                    <label>パスワード</label>
                    <md-input v-model="password" name="password" data-vv-name="パスワード" type="password" v-validate="'required|alpha_num|min:6|max:12'"></md-input>
                    <span class="md-error">{{ errors.first('パスワード') }}</span>
                  </md-field>
                    <md-button slot="footer" class="md-button md-success md-theme-default" @click="submitPassword" style="height: 36px"> 
                      次へ
                    </md-button>
                </login-card>
              </div>
            </div>
          </zoom-center-transition>
        </div>
        <footer class="footer">
          <div class="container md-offset">
              <div class="copyright text-center">
                &copy; {{ new Date().getFullYear() }} <a href="https://www.samurai-net.co.jp" target="_blank">Samurai, inc</a>&nbsp;All Rights Reserved.
              </div>
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>
<script>
  import {ZoomCenterTransition} from 'vue2-transitions'
  import LoginCard from '../components/Cards/LoginCard.vue'
  import { csrfToken } from 'rails-ujs'
  import router from '../entrypoints/router.js'
  import store from '../entrypoints/store.js'

  export default {
    components: {
      ZoomCenterTransition,
      LoginCard
    },
    props: {
      backgroundColor: {
        type: String,
        default: 'black'
      }
    },
    inject: {
      autoClose: {
        default: true
      }
    },
    data() {
      return {
        responsive: false,
        showMenu: false,
        menuTransitionDuration: 250,
        pageTransitionDuration: 300,
        year: new Date().getFullYear(),
        company: this.$Cookies.get('company'),
        login: this.$Cookies.get('login'),
        password: null
      }
    },
    computed: {
      setBgImage(){
        let image = '/images/login/login-' + (Math.floor(Math.random() * 5) + 1) + '.jpg'
        return {
          backgroundImage: `url(${image})`
        }
      },
      setPageClass(){
        return `login-page`.toLowerCase();
      },
      authenticityToken(){
        return csrfToken()
      }
    },
    methods: {
      onResponsiveInverted () {
        if (window.innerWidth < 991){
          this.responsive = true
        } else {
          this.responsive = false
        }
      },
      submitPassword(){
        this.$validator.validateAll().then(result =>  {
          if (result) {
            this.sendPassword()
          }
        })
      },
      sendPassword (page) {
        var condition = {
          page: page,
          password: this.password
        }
        this.axios({
          method  : 'PATCH',
          url     : this.AdminApiUrl + '/password_reminders' ,
          timeout : 10000,
          params  : condition
        }).then(response => {
          this.successAndBackPage(`パスワードの再設定が完了しました。<br>ログインしてください。`)
          router.push({ path: '/admin/login' })
        })
        .catch(error => {
          router.push({ path: '/admin/login' })
          this.dangerNotify('エラーが発生しました。<br>再度リセットしてください。')
        })
      }
    },
    mounted() {
      this.onResponsiveInverted();
      window.addEventListener('resize', this.onResponsiveInverted)
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.onResponsiveInverted)
    },
    beforeRouteUpdate(to, from, next) {
      // Close the mobile menu first then transition to next page
      if (this.showMenu) {
        setTimeout(() => {
          next()
        }, this.menuTransitionDuration)
      } else {
        next()
      }
    }
  }
</script>
<style lang="scss" scoped>
  $scaleSize: .1;
  $zoomOutStart: .7;
  $zoomOutEnd: .46;
  @keyframes zoomIn8 {
    from {
      opacity: 0;
      transform: scale3d($scaleSize, $scaleSize, $scaleSize);
    }
    100% {
      opacity: 1;
    }
  }
  .wrapper-full-page .zoomIn {
    animation-name: zoomIn8;
  }
  @keyframes zoomOut8 {
    from {
      opacity: 1;
      transform: scale3d($zoomOutStart, $zoomOutStart, $zoomOutStart);
    }
    to {
      opacity: 0;
      transform: scale3d($zoomOutEnd, $zoomOutEnd, $zoomOutEnd);

    }
  }
  .wrapper-full-page .zoomOut {
    animation-name: zoomOut8;
  }
</style>../entrypoints/router.js../entrypoints/store.js