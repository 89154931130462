
<div class="md-layout">
  <div class="md-layout-item md-size-100">
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-green">
        <div class="card-icon">
          <md-icon>create</md-icon>
        </div>
        <h4 class="title">企業配信情報</h4>
      </md-card-header>
      <md-card-content class="md-layout formLayout">

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            宛先<br>メールアドレス
          </label>
          <div class="md-layout-item">
            <md-field :class="[
              {'md-error': errors.has('email')},
              {'md-valid': !errors.has('email') && touched.email}]">
              <md-input
                v-model="email"
                data-vv-name="email"
                type="email"
                v-validate= "modelValidations.email">
              </md-input>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('email')">半角英数記号のメールアドレス形式で入力してください（255文字以内）</div>
              </slide-y-up-transition>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('email')">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('email') && touched.email">done</md-icon>
              </slide-y-down-transition>
            </md-field>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            メールフッター<br>(HTML)
          </label>
          <div class="md-layout-item pt20">
            <froala :tag="'textarea'" :config="config" v-model="footerHtml"></froala>
          </div>
        </div>

        <div class="md-layout md-layout-item md-size-100">
          <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
            メールフッター<br>(テキスト)
          </label>
          <div class="md-layout-item pt20">
            <md-field>
              <md-textarea v-model="footerText"></md-textarea>
            </md-field>
          </div>
        </div>

      </md-card-content>
      <md-card-actions md-alignment="left" class="mt60 mx-auto">
        <md-button class="md-warning md-lg" @click.native.prevent="returnBack">戻る</md-button>
        <submit-button class="md-success md-lg mL40" :onclick="validate" :buttonWord=this.buttonWord type="submit"></submit-button>
      </md-card-actions>
    </md-card>

    <div class="linkCont">
      <div class="bottomLink" @click="goSetting" v-show="!basicLock">基本情報を編集する</div>
      <div class="bottomLink" @click="goService">サービス情報を編集する</div>
      <div class="bottomLink" @click="goApp">アプリ用画像情報を編集する</div>
    </div>
  </div>
</div>

