<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>search</md-icon>
          </div>
          <h4 class="title">カテゴリ一覧</h4>
        </md-card-header>

        <md-card-content class="md-layout formLayout">
          <div class="" v-show="emptyFlag">カテゴリが登録されていません。新しくカテゴリを作成してください</div> 
          <big-category :rootCategory.sync="categories"></big-category>
        </md-card-content>
      </md-card>
      <transition name="fade">
        <div class="sliderBg" v-show='caterogyBgFlag'></div>
      </transition>
    </div>
  </div>

</template>

<script>
  import router from '../entrypoints/router.js'
  import Fuse from 'fuse.js'
  import BigCategory from '../components/additional/category/BigCategory.vue'
  import role from "../mixins/role.js"

  export default {
    mixins: [ role ],
    components: {
      BigCategory,
    },
    computed: {
    },
    data () {
      return {
        emptyFlag: true,
        caterogyBgFlag: false,
        categories: [],
      }
    },
    methods: {
      getCategory () {
        this.caterogyBgFlag = true
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/product_categories/',
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
          // params: condition
        }).then(response => {
          if (response.data.data.length !== 0) {
            this.createList(response.data)
            this.emptyFlag = false
          } else {
            this.caterogyBgFlag = false
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('商品情報が取得できませんでした')
          }
        })
      },
      createList (obj) {
        var res = obj.data
        this.categories.splice(0)
        for (var i in res) {
          var item = {
            id: res[i].id,
            name: res[i].attributes.name,
            children: [],
            openFlag: false
          }
          var child = res[i].attributes.children
          for (var j in child) {
            var itemC = {
              id: child[j].id,
              name: child[j].name,
              children: [],
              openFlag: false
            }
            for (var k in child[j].children) {
              var itemCC = {
                id: child[j].children[k].id,
                name: child[j].children[k].name,
                openFlag: false
              }
              itemC.children.push(itemCC)
            }
            item.children.push(itemC)
          }
          this.categories.push(item)
        }
        this.caterogyBgFlag = false
      }
    },
    mounted () {
      if (this.shopFlag) {
        router.push({ path: '/admin/login'})
        return false
      }
      this.getCategory()
    }
  }
</script>

<style lang="css" scoped>
.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.formLayout{
  flex-basis: auto;
}

.sliderBg{
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,.4);
}

.fade-enter-active, .fade-leave-active {
  will-change: opacity;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.fade-enter, .fade-leave-to {
  opacity: 0
}


@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }
}

</style>
../entrypoints/router.js