<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">QA情報設定</h4>
        </md-card-header>

        <md-card-content>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              表示順
            </label>
            <div class="md-layout-item">
              <md-field style="width: 50px" :class="[
                {'md-error': errors.has('number')},
                {'md-valid': !errors.has('number') && touched.number}]">
                <md-input
                  v-model="number"
                  data-vv-name="number"
                  type="text"
                  v-validate= "modelValidations.number"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('number')">【必須】半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('number')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('number') && touched.number">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              カテゴリ名
            </label>
            <div class="md-layout-item md-size-30 md-xsmall-size-100">
              <qa-category-select v-model="categoryName" :noSelectFlag="false"></qa-category-select>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              QA管理名称
            </label>
            <div class="md-layout-item">
              <md-field :class="[
                {'md-error': errors.has('name')},
                {'md-valid': !errors.has('name') && touched.name}]">
                <md-input
                  v-model="name"
                  data-vv-name="name"
                  type="text"
                  v-validate= "modelValidations.name"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('name')">【必須】255文字以内で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('name')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('name') && touched.name">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              質問内容
            </label>
            <div class="md-layout-item">
              <md-field :class="[
                {'md-error': errors.has('question')},
                {'md-valid': !errors.has('question') && touched.question}]">
                <md-input
                  v-model="question"
                  data-vv-name="question"
                  type="text"
                  v-validate= "modelValidations.question"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('question')">【必須】入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('question')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('question') && touched.question">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              回答内容
            </label>
            <div class="md-layout-item pt20">
            <div class="f-field">
              <froala :tag="'textarea'" :config="config" v-model="answer"
                  data-vv-name="answer"
                  type="text"
                  v-validate= "modelValidations.answer"
                  required>
              </froala>
              <slide-y-up-transition>
                <div class="vErrorText" v-show="errors.has('answer')">【必須】入力してください</div>
              </slide-y-up-transition>
              </div>
            </div>
          </div>
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              ステータス
            </label>
            <div class="md-layout-item md-size-15 md-xsmall-size-100">
              <md-field :class="[
                {'md-error': errors.has('status')},
                {'md-valid': !errors.has('status') && touched.status}]">
                <md-select
                  v-model="status"
                  data-vv-name="status"
                  v-validate= "modelValidations.status"
                  required>
                  <md-option value="active">有効</md-option>
                  <md-option value="not_active">無効</md-option>
                </md-select>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('status')">【必須】必ず一つ選択してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('status')" style="margin-right:20px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('status') && touched.status" style="margin-right:20px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
          </div>

        </md-card-content>
        <md-card-actions md-alignment="left" class="mt60 mx-auto">
          <md-button class="md-warning md-lg" @click.native.prevent="returnBack">戻る</md-button>
          <submit-button class="md-success md-lg mL40" :onclick="validate" :buttonWord=this.buttonWord type="submit"></submit-button>
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>

<script>
  import SubmitButton from '../components/additional/SubmitButton.vue'
  import QaCategorySelect from '../components/additional/QaCategorySelect.vue'
  import router from '../entrypoints/router.js'
  import qs from 'qs'
  import EventBus from '../mixins/event-bus.js';
  import role from "../mixins/role.js"
  import FroalaImageUpload from "../mixins/froala-image-upload.js"

  export default {
    mixins: [ role , FroalaImageUpload ],
    components: {
      SubmitButton,
      QaCategorySelect
    },
    computed: {
    },
    data () {
      return {
        id: '',
        buttonWord: '登録',
        number: null,
        name: '',
        categoryName: '',
        question: '',
        answer: '',
        status: '',
        newFlag: false,
        touched: {
          number: false,
          name: false,
          categoryName: false,
          question: false,
          answer: false,
          status: false
        },
        modelValidations: {
          number: {
            required: true,
            numeric: true
          },
          name: {
            required: true,
            max: 255
          },
          question: {
            required: true
          },
          answer: {
            required: true
          },
          status: {
            required: true
          }
        }
      }
    },
    methods: {
      returnBack () {
        router.push({ path: '/admin/qa_information_list/' })
      },
      getInformation () {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/qa_informations/' + this.$route.params.id,
          timeout : 30000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.number = response.data.position
          this.status = response.data.status
          this.name = response.data.name
          this.question = response.data.question
          this.answer = response.data.answer
          this.categoryName = response.data.qa_category_id
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('QA情報を正しく取得できませんでした')
          }
        })
      },
      validate() {
        return new Promise((resolve) => {
          setTimeout(() => {
            EventBus.$emit('validate', this.errors)
            this.$validator.validateAll().then(isValid => {
              if (this.errors.items.length == 0) {
                this.updateinformation()
              } else {
                this.validateError()
              }
            })
            resolve();
          }, this.ResolveTime);
        });
      },
      updateinformation(){
        var condition = {
          qa_information: {
            position: this.number,
            qa_category_id: this.categoryName,
            name: this.name,
            question: this.question,
            answer: this.answer,
            status:this.status
          }
        }
        this.axios({
          method  : this.newFlag === true ? 'POST' : 'PUT',
          url     : this.newFlag === true ? this.AdminApiUrl + '/qa_informations/' : this.AdminApiUrl + '/qa_informations/' + this.$route.params.id,
          timeout : 30000,
          data: qs.stringify(condition),
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.successAndBackPage(`QA情報を`+this.buttonWord+`しました`,'/admin/qa_information_list/')
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify(`QA情報を正しく`+this.buttonWord+`できませんでした`)
          }
        })
      },
      resetData () {
        this.number = ''
        this.categoryName = ''
        this.name = ''
        this.question = ''
        this.answer = ''
        this.status = 'active'
        this.$validator.reset()
        setTimeout (times =>{
          for (var key in this.touched) {
            this.touched[key] = false
          }
        },100)
      }
    },
    mounted () {
      if (!this.superFlag) {
        router.push({ path: '/admin/login'})
        return false
      }

      var url = location.href
      url = url.split('/')
      if(url[url.length - 1] === 'new') {
        this.newFlag = true
      } else {
        this.buttonWord = '更新'
        this.getInformation()
      }
    },
    watch: {
      '$route.path'(value){
        if (value.match('new')) {
          this.newFlag = true
          this.buttonWord = '登録'
          this.resetData()
        } else {
          this.newFlag = false
          this.buttonWord = '更新'
          this.getInformation()
        }
      },
      number () {
        this.touched.number = true
      },
      categoryName(){
        this.touched.categoryName = true
      },
      name(){
        this.touched.name = true
      },
      question(){
        this.touched.question = true
      },
      answer(){
        this.touched.answer = true
      },
      status(){
        this.touched.status = true
      }
    }
  }
</script>

<style lang="css" scoped>
.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.md-size-2{
  width: 2%;
  min-width: 2%;
  max-width: 2%;
}

.md-aSize-100{
  width: 100px;
  min-width: 100px;
  max-width: 100px;
}

.md-aSize-120{
  width: 120px;
  min-width: 120px;
  max-width: 120px;
}

.btnCenter{
  margin-left: auto !important;
  margin-right: auto !important;
}

.mt60{
  margin-top: 60px;
}
.zipBetween{
  padding: 10px 0 0 0;
  text-align: center;
  line-height: 46px;
}

.unitLabel{
  padding: 10px 0 0 0;
  line-height: 46px;
}

.weekLabel{
  font-weight: bold !important;
  text-align: left !important;
}

.timeLabel{
  font-weight: normal !important;
  text-align: left !important;
  padding: 20px 5px 0 0;
  font-size: 12px;
  color: #666666;
}

.weekLabelSelect{
  font-weight: bold !important;
  text-align: left !important;
  padding-top: 0 !important;
}
.is-red{
  color: #f44336 !important;
}

.is-restRed{
  color: #d9333f !important;
}

.is-blue{
  color: #4496d3 !important;
}

.addBtn{
  margin-top: 20px;
}

.addLabel{
  display: inline-block;
  line-height: 41px;
  margin: 5px 0;
}

.pt5{
  padding-top: 5px;
}

.mt-20{
  margin-top: 20px;
}

.pt20{
  padding-top: 20px;
}

.text-center{
  text-align: center;
}

.is-l-none{
  display: none;
}

.error{
  color: #f44336 !important;
}

.success{
  color: #4caf50 !important
}

.md-input{
  width: 100%;
}

.productStatus{
  max-width: 120px;
  width: 100%;
  display: inline-block;
}

.emptyError{
  color: #f44336 !important;
  font-size: 12px;
  position: absolute;
  top: 0;
  left: 0;
}

.is-xs-block{
  display: none;
}

@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }

  .is-xs-none{
    display: none;
  }

  .is-xs-block{
    display: block;
  }

  .timeLabel{
    line-height: 14px;
  }

  .weekLabel{
    margin-left: -5px !important;
  }
}

</style>
../entrypoints/router.js