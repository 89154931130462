
<md-field :class="[
              {'md-error': errors.has('companyId')},
              {'md-valid': !errors.has('companyId') && touched.companyId}]" >
  <md-select v-model="companyId"  
    data-vv-name="companyId"
    v-validate= "avoidValidFlag == true ? '' : modelValidations.companyId" multiple v-if="mutlipleFlag">
          <md-option v-for="item in listData" :value="item.id" :key="item.id">{{item.name}}</md-option>
  </md-select>
  <md-select
    v-model="companyId"
    data-vv-name="companyId"
    v-validate= "avoidValidFlag == true ? '' : modelValidations.companyId" v-if="!mutlipleFlag">
    <md-option v-for="item in listData" :value="item.id" :key="item.id">{{item.name}}</md-option>
  </md-select>
  <slide-y-up-transition>
    <div class="vErrorText" v-show="errors.has('companyId')">【必須】必ず一つ選択してください</div>
  </slide-y-up-transition>
  <slide-y-down-transition>
    <md-icon class="error" v-show="errors.has('companyId')" style="margin-right:20px">close</md-icon>
  </slide-y-down-transition>
  <slide-y-down-transition>
    <md-icon class="success" v-show="!errors.has('companyId') && touched.companyId" style="margin-right:20px">done</md-icon>
  </slide-y-down-transition>
</md-field>
