<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>calendar_today</md-icon>
          </div>
          <h4 class="title">営業カレンダー</h4>
        </md-card-header>

        <md-card-content>
          <FullCalendar :options="calendarOptions"  ref="fullCalendar" />
        </md-card-content>
        <md-card-actions md-alignment="left" class="mt60">
          <md-button class="md-success btnCenter" @click.native="updateHoliday">営業カレンダー登録</md-button>
        </md-card-actions>
      </md-card>
      <div class="linkCont">
        <div class="bottomLink" @click="goSetting">基本情報を編集する</div>
      </div>
    </div>
  </div>
</template>

<script>
  import router from '../entrypoints/router.js'
  import $ from 'jquery'
  import role from "../mixins/role.js"
  import moment from "moment";
  import FullCalendar from '@fullcalendar/vue'
  import dayGridPlugin from '@fullcalendar/daygrid'
  import interactionPlugin from '@fullcalendar/interaction';
  import '@fullcalendar/core/locales-all';

  var today = new Date()
  var y = today.getFullYear()
  var m = today.getMonth()
  var d = today.getDate()

  export default {
    mixins: [ role ],
    components: {
      FullCalendar
    },
    computed: {
    },
    props: {
    },
    data () {
      return {
        calendarOptions: {
          plugins: [
            dayGridPlugin,
            interactionPlugin
          ],
          locale: 'ja',
          headerToolbar: {
            left: '',
            center: 'title',
            right: 'today prev,next'
          },
          buttonText: {
            prev: '<',
            next: '>',
            today: '今日',
          },
          selectable: true,
          select: this.handleDateSelect,
          eventClick: this.handleEventClick,
        },
        id: '',
        horidayList: [],
        events: [],
        eventId: 0,
        staffFlag: false
      }
    },
    methods: {
      goSetting () {
        router.push({ path: this.staffFlag === true ? '/admin/staff/edit/' + this.$route.params.id : '/admin/shop/edit/' + this.$route.params.id})
      },
      handleDateSelect(selectInfo) {
        const start = selectInfo.start;
        const end = selectInfo.end;
        const calendarApi = this.$refs.fullCalendar.getApi();
        const events = calendarApi.getEvents();
        const isHoliday = events.some(event => {
          const eventStart = event.start;
          return moment(start).isSame(eventStart, 'day') && event.title === '休日';
        });
        if (!isHoliday) {
          const eventData = {
            id: `event-${this.eventId++}`,
            title: '休日',
            start: start,
            end: end,
            allDay: true,
            className: 'event-red'
          };
          calendarApi.addEvent(eventData);
          this.events.push(eventData);
        }
      },
      handleEventClick(clickInfo) {
        const event = clickInfo.event;
        if (event.title === '休日') {
          event.remove();
          this.events = this.events.filter(ev => ev.id !== event.id);
        }
      },
      getHoliday () {
        this.axios({
          method  : 'GET',
          url     : this.staffFlag === true ? this.AdminApiUrl + '/holidays_staff/' + this.$route.params.id   : this.AdminApiUrl + '/holidays/' + this.$route.params.id,
          timeout : 30000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.events.splice(0)
          const calendarApi = this.$refs.fullCalendar.getApi();

          const eventSource = response.data.map(holiday => ({
            id: `holiday-${this.eventId++}`,
            title: '休日',
            start: moment(holiday.value, "YYYY/MM/DD").toDate(),
            allDay: true,
            className: 'event-red'
          }));

          calendarApi.addEventSource(eventSource);
          calendarApi.render();
          this.events = eventSource;
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('休日情報を正しく取得できませんでした')
          }
        })
      },
      updateHoliday () {
        var value = ''
        for (var i in this.events) {
          if ( typeof this.events[i].start === 'string') {
            value += ',' + this.events[i].start
          } else {
            value += ',' + moment(this.events[i].start).format('YYYY/MM/DD')
          }
        }
        value = value.slice(1)
        this.setHoliday (value)
      },
      setHoliday (value) {
        var condition = {
          holiday: {
            value : value
          }
        }
        this.axios({
          method  : 'PUT',
          url     : this.staffFlag === true ? this.AdminApiUrl + '/holidays_staff/' + this.$route.params.id  : this.AdminApiUrl + '/holidays/' + this.$route.params.id,
          timeout : 30000,
          data: condition,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.successAndBackPage(`休日情報を登録しました`)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('休日情報を正しく登録できませんでした')
          }
        })
      }
    },
    mounted () {
      var url = location.href
      if(url.indexOf('staff') >= 0) {
        this.staffFlag = true
      }
      if ((this.staffFlag && !this.opt_reserveOrder) ||
          (this.shopFlag  && !this.opt_reserveOrder) || 
          (this.shopFlag  && !this.staffFlag)) {
        router.push({ path: '/admin/login'})
        return false
      }
      window.$ = window.jQuery = $
      this.getHoliday()
    },
    watch: {
    }
  }
</script>

<style lang="css" scoped>
.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.btnCenter{
  margin-left: auto !important;
  margin-right: auto !important;
}

#fullCalendar {
  min-height: 300px;
}

.md-card-calendar .md-card-content{
  padding: 0 !important;
}

</style>
../entrypoints/router.js