<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>account_box</md-icon>
          </div>
          <h4 class="title">注文詳細</h4>
        </md-card-header>

        <md-card-content class="formLayout">
          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              注文番号
            </label>
            <div class="md-layout-item md-size-85 md-xsmall-size-100">
              <p class="form-control-static">{{orderNumber}}</p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              支払番号
            </label>
            <div class="md-layout-item md-size-85 md-xsmall-size-100">
              <p class="form-control-static">{{approveNo}}</p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              注文日
            </label>
            <div class="md-layout-item md-size-85 md-xsmall-size-100">
              <p class="form-control-static">{{orderDate | dateFormat}}</p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              受取予定日
            </label>
            <div class="md-layout-item md-size-85 md-xsmall-size-100">
              <p class="form-control-static">{{readyTime | timeFormat2}}</p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              ステータス
            </label>
            <div class="md-layout-item md-size-85 md-xsmall-size-100" v-if="orderRequestStatus === 'cancel'">
              <p class="form-control-static">キャンセル</p>
            </div>
            <div class="md-layout-item md-size-85 md-xsmall-size-100" v-else-if="orderRequestStatus === 'recieved'">
              <md-field class="productStatus">
                <md-select v-model="orderStatus" name="status">
                  <md-option value="recieved">受渡済み</md-option>
                  <md-option value="cancel">キャンセル</md-option>
                </md-select>
              </md-field>
            </div>

            <div class="md-layout-item md-size-85 md-xsmall-size-100" v-else>
              <md-field class="productStatus">
                <md-select v-model="orderStatus" name="status">
                  <md-option value="accept">注文済み</md-option>
                  <md-option value="ready">準備中</md-option>
                  <md-option value="done">準備完了</md-option>
                  <md-option value="recieved">受渡済み</md-option>
                  <md-option value="cancel">キャンセル</md-option>
                </md-select>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              支払い方法
            </label>
            <div class="md-layout-item md-size-85 md-xsmall-size-100">
              <p class="form-control-static">{{paymentKind}}</p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              注文店舗
            </label>
            <div class="md-layout-item md-size-85 md-xsmall-size-100">
              <p class="form-control-static">{{shopName}}</p>
            </div>
          </div>

          <div class="md-layout md-layout-item md-size-100">
            <label class="md-layout-item md-size-15 md-form-label md-xsmall-size-100">
              注文金額
            </label>
            <div class="md-layout-item md-size-30 md-xsmall-size-100"  v-if="editPeriod">
              <md-field :class="[
                {'md-error': errors.has('totalPrice')},
                {'md-valid': !errors.has('totalPrice') && touched.totalPrice}]">
                <md-input
                  type="number"
                  v-model="totalPrice"
                  data-vv-name="totalPrice"
                  v-validate= "modelValidations.totalPrice"
                  required>
                </md-input>
                <slide-y-up-transition>
                  <div class="vErrorText" v-show="errors.has('totalPrice')">【必須】1以上6桁以内の半角数字で入力してください</div>
                </slide-y-up-transition>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('totalPrice')" style="margin-right:10px">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('totalPrice') && touched.totalPrice" style="margin-right:10px">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
            <div class="md-layout-item md-size-85 md-xsmall-size-100" v-else>
              <p class="form-control-static">{{totalPrice}}</p>
            </div>
          </div>
          
        </md-card-content>
        <md-card-actions class="mx-auto" md-alignment="left">
          <md-button class="md-warning md-lg" @click.native.prevent="returnBack">戻る</md-button>
          <md-button class="md-success md-lg mL40" @click.native.prevent="validate">更新</md-button>
        </md-card-actions>
      </md-card>

      <md-card class="mt30">
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">注文内容</h4>
        </md-card-header>
        <md-card-content>
          <div class="dataTableWrapper">
            <div class="dataTable"> 
              <div class="tHead">
                <div class="tHead_th t_image">商品画像</div>
                <div class="tHead_th">商品名</div>
                <div class="tHead_th taC">税区分</div>
                <div class="tHead_th taR">商品金額</div>
                <div class="tHead_th taR">個数</div>
                <div class="tHead_th taR">合計</div>
              </div>
              <div v-for="item in shoppingCartTable" :data-id="item.id" class="tItem">
                <div class="tItem_td t_image"><img :src="item.img" alt=""></div>
                <div class="tItem_td"><md-label style="word-break:break-all;">{{ item.name }}</md-label></div>
                <div class="tItem_td taC"><md-icon>{{item.taxIcon}}</md-icon></div>
                <div class="tItem_td taR boldCell">¥{{ item.price | numberWithDelimiter}}</div>
                <div class="tItem_td taR">{{ item.quantity }}</div>
                <div class="tItem_td taR boldCell">¥{{ item.priceIncludingTax * item.quantity | numberWithDelimiter}}</div>
              </div>
            </div>
          </div>
          <div class="table table-stats">
            <div class="td-price td-price-top">
              <div class="td-total">
                税抜合計
              </div>
              <span>
                <small>¥</small>
                {{this.totalPriceWithoutTax | numberWithDelimiter}}
              </span>
            </div>
            <div class="td-price">
              <div class="td-total">
                ポイント利用
              </div>
              <span v-if="usePoint===0">
                なし
              </span>
              <span v-else>
                {{this.usePoint | numberWithDelimiter}}
              </span>
            </div>
            <div class="td-price">
              <div class="td-total">
                クーポン利用
              </div>
              <span v-if="couponDiscount===0">
                なし
              </span>
              <span v-else>
                <small>¥ -</small>
                {{this.couponDiscount | numberWithDelimiter}}
              </span>
            </div>
            <div class="td-price">
              <div class="td-total">
                8%税率対象合計
              </div>
              <span>
                <small>¥</small>
                {{this.taxReduce | numberWithDelimiter}}
              </span>
            </div>
            <div class="td-price">
              <div class="td-total">
                10%税率対象合計
              </div>
              <span>
                <small>¥</small>
                {{this.taxNormal | numberWithDelimiter}}
              </span>
            </div>
            <div class="td-price">
              <div class="td-total">
                税込合計
              </div>
              <span style="font-weight: bold">
                <small style="font-weight: bold">¥</small>
                {{this.totalPrice | numberWithDelimiter}}
              </span>
            </div>
          </div>
        </md-card-content>
      </md-card>
    </div>
  </div>

</template>

<script>
  import swal from 'sweetalert2'
  import router from '../entrypoints/router.js'
  import role from "../mixins/role.js"

  export default {
    mixins: [ role ],
    components: {
    },
    computed: {
    },
    props: {
    },
    data () {
      return {
        approveNo: '',
        orderNumber: '',
        paymentKind: '',
        shopName: '',
        orderDate: '',
        readyTime: '',
        orderStatus: '',
        orderRequestStatus: '',
        startDay: '',
        endDay: '',
        usePoint: '',
        couponDiscount: '',
        taxReduce: '',
        taxNormal: '',
        totalPriceWithoutTax: '',
        totalPrice: '',
        id: '',
        editPeriod: false,
        shoppingCartTable: [],
        touched: {
          totalPrice: false
        },
        modelValidations: {
          totalPrice: {
            required: true,
            numeric: true,
            max: 6,
            min_value: 1
          }
        }
      }
    },
    methods: {
      returnBack () {
        router.push({ path: '/admin/pre_order_list/' })
      },
      getOrders () {
        this.axios({
          method  : 'GET',
          url     : this.AdminApiUrl + '/pre_orders/' + this.$route.params.id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')}
        }).then(response => {
          this.id = response.data.id
          this.orderNumber = response.data.order_number
          this.orderDate = response.data.created_at
          this.readyTime = response.data.ready_time
          this.orderStatus = response.data.order_status
          this.orderRequestStatus = response.data.order_status
          this.approveNo = response.data.approve_no
          this.paymentKind = response.data.payment_kind
          this.shopName = response.data.shop_name
          this.startDay = response.data.reserved_start_time
          this.usePoint = response.data.use_point
          this.couponDiscount = response.data.coupon_discount
          this.taxReduce = Math.floor(response.data.tax_reduce)
          this.taxNormal = Math.floor(response.data.tax_normal)
          this.totalPriceWithoutTax = Math.floor(response.data.total_price_without_tax)
          this.totalPrice = Math.floor(response.data.total_price)
          this.editPeriod = response.data.edit_period
          this.createList(response.data.pre_order_details)
        })
        .catch(error => {
          this.dangerNotify('注文情報の取得に失敗しました')
        })
      },
      createList (obj) {
        for (var i in obj) {
          var item = {
            img: obj[i].main_image.url !== null ? obj[i].main_image.url : '/images/no_image.png',
            name: obj[i].product_name !== null ? obj[i].product_name : '商品名がありません',
            taxIcon: obj[i].tax_rate == "reduced" ? "exposure_neg_2" : obj[i].tax_rate == "free" ? "exposure_zero" : "",            
            quantity: obj[i].quantity,
            price: obj[i].sales_price,
            priceIncludingTax: obj[i].price_including_tax
          }
          this.shoppingCartTable.push(item)
        }
      },
      validate() {
        this.$validator.validateAll().then(isValid => {
          if (isValid) {
            this.updateStatus()
          } else {
            this.validateError()
          }
        })
      },
      updateStatus () {
        var param = {
          order_status: this.orderStatus,
          total_price: this.totalPrice
        }
        this.axios({
          method  : 'PUT',
          url     : this.AdminApiUrl + '/pre_orders/' + this.id,
          timeout : 10000,
          headers : {'Authorization': this.$Cookies.get('accessToken')},
          data: {
            pre_order : param
          }
        }).then(response => {
          this.successAndBackPage(`注文情報を更新しました`)
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.tokenError()
          } else {
            this.dangerNotify('注文情報の更新に失敗しました')
          }
        })
      },
    },
    mounted () {
      if (!this.opt_preOrder) {
        router.push({ path: '/admin/login'})
        return false
      }
      
      this.getOrders()
    },
    watch: {
      totalPrice(){
        this.touched.totalPrice = true
      }
    }
  }
</script>

<style lang="scss" scoped>
.md-card .md-card-actions{
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.form-control-static{
  margin-top: 9px;
}

.mt30{
  margin-top: 30px;
}

.productStatus{
  max-width: 120px;
  width: 100%;
  display: inline-block;
}

.btnUpdate{
  margin: 10px 0 0 20px;
}

.text-right ::v-deep .md-table-cell-container{
  display: flex;
  justify-content: flex-end;
}
.md-table ::v-deep .md-table-head:last-child{
  text-align: right;
}

.table-stats {
  display: flex;
  align-items: center;
  text-align: right;
  flex-flow: row wrap;

  .td-price .td-total{
    display: inline-flex;
    font-weight: 500;
    font-size: 14px;
    margin-right: 50px;
  }

  &.table-striped .td-price{
    border-bottom: 0;
  }

  .td-price{
    font-size: 16px;
    border-bottom: 1px solid #d2d2d2;
  }

  .td-price-top{
    border-top: 1px solid #d2d2d2;
  }

  .td-price,
  > div{
    flex: 0 0 100%;
    padding: 12px 8px;
  }

  span{
    display: inline-block;
    width: 15%;
  }

}

.btnCenter{
  margin-left: auto !important;
  margin-right: auto !important;
}

.taC{
  text-align: center;
}

.mt30{
  margin-top: 30px;
}

.mt60{
  margin-top: 60px;
}

.pr60{
  padding-right: 60px;
}

.is-l-none{
  display: none;
}

@media screen and (max-width:600px) {
  .md-card .md-card-content .md-form-label{
    text-align: left;
  }

  .is-xs-none{
    display: none;
  }

  .is-xs-block{
    display: block;
  }
}

</style>
../entrypoints/router.js